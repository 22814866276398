/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import * as i18n from "util/i18n";
import Date from "pages/_components/fields/DateField";
import PageLoading from "pages/_components/PageLoading";
import DebinRequestsList from "pages/charges/_components/DebinRequestsList";
import { func, shape, bool, string } from "prop-types";
import { push } from "react-router-redux/actions";
import Notification from "pages/_components/Notification";
import moment from "moment";
import { allStatus } from "util/debin";
import ContextMenu from "pages/_components/ContextMenu";
import Yup from "yup";
import * as configUtils from "util/config";
import { actions as statusActions } from "reducers/status";

const FORM_ID = "charges.debin.sentRequests";

class DebinRequests extends Component {
    state = {
        selectedDateFrom: null,
        selectedDateTo: null,
    };

    static propTypes = {
        activeEnvironment: shape({
            permissions: shape({
                addRemoveDebinAccount: bool,
            }),
        }).isRequired,
        dateFrom: shape({}).isRequired,
        dateTo: shape({}).isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        filtersSentRequests: shape({
            dateFrom: shape({}).isRequired,
            dateTo: shape({}).isRequired,
            status: string.isRequired,
            recipient: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        setFieldValue: func.isRequired,
        values: shape({}).isRequired,
        location: shape({}).isRequired,
    };

    componentDidMount() {
        const { dispatch, setFieldValue, values, filtersSentRequests, location } = this.props;
        const dateFrom = filtersSentRequests.dateFrom || moment().add(-1, "weeks");
        const dateTo = filtersSentRequests.dateTo || moment();
        const status = filtersSentRequests.status || values.status;
        const { recipient } = filtersSentRequests;
        this.setState({ selectedDateFrom: dateFrom });
        this.setState({ selectedDateTo: dateTo });
        setFieldValue("dateFrom", dateFrom);
        setFieldValue("dateTo", dateTo);
        setFieldValue("status", status);
        setFieldValue("recipient", recipient);
        dispatch(debinActions.loadSentRequests({ dateFrom, dateTo, pageNumber: 1, status, recipient }));
        dispatch(debinActions.cleanIsBackFromSummary());
        dispatch(statusActions.saveLastHref(location));
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    rightContent = () => {
        const { dispatch, isDesktop, activeEnvironment } = this.props;

        if (isDesktop) {
            return (
                <>
                    <Button
                        block
                        onClick={() => dispatch(push("/charges/requestDebin"))}
                        label="charges.debin.sentRequests.button"
                        bsStyle="primary"
                        className="cmf-button-width"
                    />
                    {activeEnvironment.permissions.addRemoveDebinAccount ? (
                        <Button
                            className="btn-outline cmf-button-width"
                            block
                            onClick={() => dispatch(push("debin/addAccounts"))}
                            label="charges.debin.addAccounts.button"
                        />
                    ) : (
                        undefined
                    )}
                </>
            );
        }

        const actions = [
            {
                label: "charges.debin.sentRequests.button",
                onClick: () => dispatch(push("/charges/requestDebin")),
            },
        ];
        if (activeEnvironment.permissions.addRemoveDebinAccount) {
            actions.push({
                label: "charges.debin.addAccounts.button",
                onClick: () => dispatch(push("debin/addAccounts")),
            });
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName={`align-self-center account-dropdown-header-font ${
                    !isDesktop ? "font-black-alpha" : ""
                }`}
                items={actions}
            />
        );
    };

    renderEmptyState = () => {};

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    handleChangeDateTo = (selectedDate) => {
        this.setState({ selectedDateTo: selectedDate });
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("charges.debin.sentRequests.title")}</h1>
        </div>
    );

    render() {
        const { values, fetching, isDesktop, isSmallDesktop } = this.props;
        const { dateFrom, dateTo } = values;
        const { selectedDateFrom, selectedDateTo } = this.state;
        const defaultOptionState = { value: "Todos", label: "TODOS" };

        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        title={isDesktop ? "charges.debin.sentRequests.title" : null}
                        rightContent={this.rightContent}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={!isDesktop && this.centerContentMobile}
                        centerContentClassName={!isDesktop ? "mx-5 align-center" : "p-0"}
                        onBack={!isDesktop && this.handleBack}
                    />
                </div>
                <div className="above-the-fold">
                    <Form autocomplete="off" className="above-the-fold">
                        <Container
                            className={`flex-grow align-items-center container-white mb-3 ${
                                isDesktop ? "py-3" : "py-0"
                            }`}
                            gridClassName="form-content"
                            rowClassName={isSmallDesktop ? "justify-content-center" : "justify-content-start"}>
                            <Col sm={12} md={4} lg={3}>
                                <Field
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="recipient"
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength={configUtils.get("cuit.validation.character.amount")}
                                    minLength={0}
                                    formGroupClassName="ellipsis-span"
                                />
                            </Col>
                            {isDesktop && (
                                <>
                                    <Col sm={2} md={4} lg={isSmallDesktop ? 3 : 2}>
                                        <Field
                                            component={Selector}
                                            options={[defaultOptionState, ...allStatus]}
                                            idForm={FORM_ID}
                                            name="status"
                                            isRequired
                                            value={values?.status || defaultOptionState}
                                            searchable={isDesktop}
                                        />
                                    </Col>
                                    <Col sm={2} md={4} lg={2}>
                                        <Field
                                            idField="dateFrom"
                                            component={Date}
                                            idForm={FORM_ID}
                                            name="dateFrom"
                                            hidePlaceholder
                                            endDate={dateTo}
                                            startDate={dateFrom}
                                            maxDate={selectedDateTo}
                                            handleChange={this.handleChangeDateFrom}
                                            selectsStart
                                        />
                                    </Col>
                                    <Col sm={2} md={4} lg={2}>
                                        <Field
                                            idField="dateTo"
                                            component={Date}
                                            idForm={FORM_ID}
                                            name="dateTo"
                                            hidePlaceholder
                                            minDate={selectedDateFrom}
                                            handleChange={this.handleChangeDateTo}
                                            selectsEnd
                                            maxDate={new Date()}
                                            endDate={dateTo}
                                            startDate={dateFrom}
                                        />
                                    </Col>

                                    <Col sm={2} md={4} lg={2}>
                                        <div className="form-group mt-4">
                                            <Button
                                                label="fixedTermAcceptance.list.button.search"
                                                bsStyle="primary"
                                                type="submit"
                                                className="filter-butto mt-25 py-25"
                                                loading={fetching}
                                            />
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Container>
                        <PageLoading className="line-loader" loading={fetching}>
                            <DebinRequestsList itemsAreClickeable isDesktop={isDesktop} />
                        </PageLoading>
                    </Form>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    sentRequests: debinSelectors.getSentRequests(state),
    fetching: debinSelectors.getFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filtersSentRequests: debinSelectors.getFiltersSentRequests(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            recipient: "",
            status: "Todos",
        }),
        validationSchema: () =>
            Yup.object().shape({
                recipient: Yup.string()
                    .nullable()
                    .min(configUtils.get("cuit.validation.character.amount"), i18n.get(`${FORM_ID}.recipient.invalid`))
                    .max(configUtils.get("cuit.validation.character.amount"), i18n.get(`${FORM_ID}.recipient.invalid`)),
                dateTo: Yup.string()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.field.error.required`)),
                dateFrom: Yup.string()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.field.error.required`)),
            }),
        handleSubmit: (filters, formikBag) => {
            const { dispatch } = formikBag.props;
            const { recipient } = filters;
            dispatch(
                debinActions.loadSentRequests({
                    ...filters,
                    recipientType: recipient ? "comprador" : null,
                    pageNumber: 1,
                }),
            );
        },
    }),
)(DebinRequests);

import React, { Component, Fragment } from "react";
import { Switch, Redirect } from "react-router-dom";

import AdministrationAdvancedChannels from "pages/administration/advanced/Channels";
import AdministrationAdvancedChannelsConfirm from "pages/administration/advanced/confirmations/ChannelsConfirm";
import AdministrationAdvancedChannelsTicket from "pages/administration/advanced/tickets/ChannelsTicket";
import AdministrationAdvancedGroup from "pages/administration/advanced/Group";
import AdministrationAdvancedGroupFormContainer from "pages/administration/advanced/GroupFormContainer";
import AdministrationAdvancedGroupsOfUser from "pages/administration/advanced/GroupsOfUser";
import AdministrationAdvancedGroupsOfUserConfirm from "pages/administration/advanced/confirmations/GroupsOfUserConfirm";
import AdministrationAdvancedGroupsOfUserTicket from "pages/administration/advanced/tickets/GroupsOfUserTicket";
import AdministrationAdvancedGroupFormDataTicket from "pages/administration/advanced/tickets/GroupFormDataTicket";
import AdministrationAdvancedSignatureConfirm from "pages/administration/advanced/confirmations/SignatureConfirm";
import AdministrationAdvancedSignatureTicket from "pages/administration/advanced/tickets/SignatureTicket";
import AdministrationAdvancedSignaturesSchemeCreate from "pages/administration/advanced/SignaturesSchemeCreate";
import AdministrationAdvancedSignaturesSchemeCreateConfirmation from "pages/administration/advanced/confirmations/SignaturesSchemeCreate";
import AdministrationAdvancedSignaturesSchemeDeleteConfirmation from "pages/administration/advanced/confirmations/SignaturesSchemeDelete";
import AdministrationAdvancedSignaturesSchemeDetail from "pages/administration/advanced/SignatureSchemeDetails";
import AdministrationAdvancedSignaturesSchemeModify from "pages/administration/advanced/SignaturesSchemeModify";
import AdministrationAdvancedSignaturesSchemeModifyConfirmation from "pages/administration/advanced/confirmations/SignaturesSchemeModify";
import AdministrationAdvancedSignaturesSchemes from "pages/administration/advanced/SignaturesSchemes";
import AdministrationAdvancedSignaturesSchemeTicket from "pages/administration/advanced/tickets/SignaturesScheme";
import AdministrationGroups from "pages/administration/advanced/Groups";
import AdministrationMediumChannels from "pages/administration/medium/Channels";
import AdministrationMediumChannelsConfirm from "pages/administration/medium/confirmations/ChannelsConfirm";
import AdministrationMediumChannelsTicket from "pages/administration/medium/tickets/ChannelsTicket";
import AdministrationMediumDetails from "pages/administration/medium/Details";
import AdministrationAdvancedDetails from "pages/administration/advanced/Details";
import AdministrationMediumPermissions from "pages/administration/medium/Permissions";
import AdministrationMediumPermissionsConfirm from "pages/administration/medium/confirmations/PermissionsConfirm";
import AdministrationMediumPermissionsTicket from "pages/administration/medium/tickets/PermissionsTicket";
import AdministrationMediumSignatureConfirm from "pages/administration/medium/confirmations/SignatureConfirm";
import AdministrationMediumSignaturesSchemeCreate from "pages/administration/medium/SignaturesSchemeCreate";
import AdministrationMediumSignaturesSchemeCreateConfirmation from "pages/administration/medium/confirmations/SignaturesSchemeCreate";
import AdministrationMediumSignaturesSchemeDeleteConfirmation from "pages/administration/medium/confirmations/SignaturesSchemeDelete";
import AdministrationMediumSignaturesSchemeDetail from "pages/administration/medium/SignatureSchemeDetails";
import AdministrationMediumSignaturesSchemeModify from "pages/administration/medium/SignaturesSchemeModify";
import AdministrationMediumSignaturesSchemeModifyConfirmation from "pages/administration/medium/confirmations/SignaturesSchemeModify";
import AdministrationMediumSignaturesSchemes from "pages/administration/medium/SignaturesSchemes";
import AdministrationMediumSignaturesSchemeTicket from "pages/administration/medium/tickets/SignaturesScheme";
import AdministrationSimplePermissions from "pages/administration/simple/Permissions";
import AdministrationSimplePermissionsConfirm from "pages/administration/simple/PermissionsConfirm";
import AdministrationSimplePermissionsTicket from "pages/administration/simple/PermissionsTicket";
import AdministrationDispatcherConfirm from "pages/administration/_components/DispatcherConfirm";
import AdministrationUsers from "pages/administration/Users";
import GroupActionConfirmation from "pages/administration/advanced/GroupActionConfirmation";
import CreateGroupSignTransaction from "pages/administration/advanced/CreateGroupSignTransaction";
import GroupActionTicket from "pages/administration/advanced/GroupActionTicket";
import UserActionTicket from "pages/administration/UserActionTicket";
import UserActionConfirmation from "pages/administration/UserActionConfirmation";
import UserInviteTicket from "pages/administration/UserInviteTicket";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Restrictions from "pages/administration/restrictions/Restrictions";
import RestrictionsDeleteConfirmation from "pages/administration/restrictions/confirmations/RestrictionsDeleteConfirmation";
import RestrictionsManageConfirmation from "pages/administration/restrictions/confirmations/RestrictionsManageConfirmation";
import RestrictionsManageTicket from "pages/administration/restrictions/tickets/RestrictionsManageTicket";
import RestrictionsUserDeleteTicket from "pages/administration/restrictions/tickets/RestrictionsUserDeleteTicket";
import UpdateUserTicket from "pages/administration/advanced/tickets/UpdateUserTicket";
import { bool } from "yup";
import PrivateRoute from "pages/_components/route/PrivateRoute";

class Administration extends Component {
    static propTypes = {
        isDesktop: bool,
        isSmallDesktop: bool,
    };

    static defaultProps = {
        isDesktop: false,
        isSmallDesktop: false,
    };

    renderTicketRoutes = () => {
        const { isDesktop, isSmallDesktop } = this.props;
        const ticketRoutes = [
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/simple/permissions/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationSimplePermissionsTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/medium/permissions/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationMediumPermissionsTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/medium/channels/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationMediumChannelsTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/medium/signaturesSchemes/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationMediumSignaturesSchemeTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/advanced/signaturesSchemes/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationAdvancedSignaturesSchemeTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/users/actions/:idTransaction/ticket"
                render={({ match }) => (
                    <UserActionTicket match={match} isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/groups/actions/:idTransaction/ticket"
                render={({ match }) => (
                    <GroupActionTicket match={match} isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/medium/userInvite/:idTransaction/ticket"
                render={({ match }) => (
                    <UserInviteTicket match={match} isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/advanced/signature/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationAdvancedSignatureTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/advanced/channels/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationAdvancedChannelsTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/advanced/groupsOfUser/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationAdvancedGroupsOfUserTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
            <PrivateRoute
                excludeEnvType={["retail"]}
                exact
                path="/administration/advanced/groupFormData/:idTransaction/ticket"
                render={({ match }) => (
                    <AdministrationAdvancedGroupFormDataTicket
                        match={match}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                )}
            />,
        ];
        return ticketRoutes;
    };

    render() {
        const { isDesktop, isSmallDesktop } = this.props;
        const ticketRoutes = this.renderTicketRoutes();

        if (!isDesktop) {
            return (
                <Fragment>
                    <Notification scopeToShow="administration" />
                    <Switch>
                        {ticketRoutes.map((element) => element)}
                        <Redirect to="/desktop" />
                    </Switch>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Notification scopeToShow="administration" />

                <Switch>
                    {ticketRoutes.map((element) => element)}

                    <PrivateRoute excludeEnvType={["retail"]} path="/administration/simple/permissions/:id">
                        {(props) => (
                            <AdministrationSimplePermissions
                                {...props}
                                confirmRoute={
                                    <PrivateRoute
                                        excludeEnvType={["retail"]}
                                        exact
                                        path="/administration/simple/permissions/:id/confirm"
                                        component={AdministrationSimplePermissionsConfirm}
                                    />
                                }
                            />
                        )}
                    </PrivateRoute>

                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/users"
                        component={AdministrationUsers}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/confirmUserAction"
                        component={UserActionConfirmation}
                    />

                    {/* end of simple scheme routes */}
                    {/* medium scheme routes */}
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/details/:id"
                        component={AdministrationMediumDetails}
                    />

                    <PrivateRoute excludeEnvType={["retail"]} path="/administration/medium/permissions/:id">
                        {(props) => (
                            <AdministrationMediumPermissions
                                {...props}
                                confirmRoute={
                                    <PrivateRoute
                                        excludeEnvType={["retail"]}
                                        exact
                                        path="/administration/medium/permissions/:id/confirm"
                                        component={AdministrationMediumPermissionsConfirm}
                                    />
                                }
                            />
                        )}
                    </PrivateRoute>

                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/channels/:id"
                        component={AdministrationMediumChannels}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/channels/:id/confirm"
                        component={AdministrationMediumChannelsConfirm}
                    />

                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signaturesSchemes"
                        component={AdministrationMediumSignaturesSchemes}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signaturesSchemes/create"
                        component={AdministrationMediumSignaturesSchemeCreate}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signaturesSchemes/create/confirm"
                        component={AdministrationMediumSignaturesSchemeCreateConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signaturesSchemes/remove/:idSignature"
                        component={AdministrationMediumSignaturesSchemeDeleteConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signaturesSchemes/:idSignature"
                        component={AdministrationMediumSignaturesSchemeModify}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signaturesSchemes/:idSignature/confirm"
                        component={AdministrationMediumSignaturesSchemeModifyConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signature/:id/confirm"
                        component={AdministrationMediumSignatureConfirm}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signaturesSchemes/detail/:idSignature"
                        component={AdministrationMediumSignaturesSchemeDetail}
                    />
                    {/* end of medium scheme routes */}

                    {/* advanced scheme routes */}

                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/group/:id/details"
                        component={AdministrationAdvancedGroup}
                    />

                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signaturesSchemes"
                        component={AdministrationAdvancedSignaturesSchemes}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signaturesSchemes/create"
                        component={AdministrationAdvancedSignaturesSchemeCreate}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signaturesSchemes/create/confirm"
                        component={AdministrationAdvancedSignaturesSchemeCreateConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signaturesSchemes/remove/:idSignature"
                        component={AdministrationAdvancedSignaturesSchemeDeleteConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signaturesSchemes/:idSignature"
                        component={AdministrationAdvancedSignaturesSchemeModify}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signaturesSchemes/:idSignature/confirm"
                        component={AdministrationAdvancedSignaturesSchemeModifyConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signaturesSchemes/detail/:idSignature"
                        component={AdministrationAdvancedSignaturesSchemeDetail}
                    />

                    {/* end of advanced scheme routes */}

                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/users/dispatcher/:id/confirm"
                        component={AdministrationDispatcherConfirm}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/users/actions/:idTransaction/ticket"
                        render={({ match }) => (
                            <UserActionTicket match={match} isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/groups/actions/:idTransaction/ticket"
                        render={({ match }) => (
                            <GroupActionTicket match={match} isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/userInvite/:idTransaction/ticket"
                        render={({ match }) => (
                            <UserInviteTicket match={match} isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/groups"
                        component={AdministrationGroups}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/confirmGroupAction"
                        component={GroupActionConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        path="/administration/advanced/group/create"
                        component={AdministrationAdvancedGroupFormContainer}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/group/sign/create"
                        component={CreateGroupSignTransaction}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        path="/administration/advanced/group/:id"
                        component={AdministrationAdvancedGroupFormContainer}
                    />
                    {/* end of advanced scheme routes */}

                    {/* advanced scheme routes */}
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/details/:id"
                        component={AdministrationAdvancedDetails}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signature/:id/confirm"
                        component={AdministrationAdvancedSignatureConfirm}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/signature/:idTransaction/ticket"
                        render={({ match }) => (
                            <AdministrationAdvancedSignatureTicket
                                match={match}
                                isDesktop={isDesktop}
                                isSmallDesktop={isSmallDesktop}
                            />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/channels/:id"
                        component={AdministrationAdvancedChannels}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/channels/:id/confirm"
                        component={AdministrationAdvancedChannelsConfirm}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/:administrationScheme/usersUpdate/:idTransaction/ticket"
                        render={({ match }) => (
                            <UpdateUserTicket match={match} isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/channels/:idTransaction/ticket"
                        render={({ match }) => (
                            <AdministrationAdvancedChannelsTicket
                                match={match}
                                isDesktop={isDesktop}
                                isSmallDesktop={isSmallDesktop}
                            />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/groupsOfUser/:id"
                        component={AdministrationAdvancedGroupsOfUser}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/groupsOfUser/:id/confirm"
                        component={AdministrationAdvancedGroupsOfUserConfirm}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/groupsOfUser/:idTransaction/ticket"
                        render={({ match }) => (
                            <AdministrationAdvancedGroupsOfUserTicket
                                match={match}
                                isDesktop={isDesktop}
                                isSmallDesktop={isSmallDesktop}
                            />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/advanced/groupFormData/:idTransaction/ticket"
                        render={({ match }) => (
                            <AdministrationAdvancedGroupFormDataTicket
                                match={match}
                                isDesktop={isDesktop}
                                isSmallDesktop={isSmallDesktop}
                            />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/restrictions"
                        component={Restrictions}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/restrictions/:userId"
                        component={Restrictions}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/restrictions/delete/:userId"
                        component={RestrictionsDeleteConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/restrictions/manage/confirmation"
                        component={RestrictionsManageConfirmation}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/restrictions/manage/:idTransaction/ticket/"
                        render={({ match }) => (
                            <RestrictionsManageTicket
                                match={match}
                                isDesktop={isDesktop}
                                isSmallDesktop={isSmallDesktop}
                            />
                        )}
                    />
                    <PrivateRoute
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/restrictions/user/delete/:idTransaction/ticket/"
                        render={({ match }) => (
                            <RestrictionsUserDeleteTicket
                                match={match}
                                isDesktop={isDesktop}
                                isSmallDesktop={isSmallDesktop}
                            />
                        )}
                    />
                    <Redirect to="/desktop" />
                </Switch>
            </Fragment>
        );
    }
}

export default resizableRoute(Administration);

import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func, shape, string, number, arrayOf } from "prop-types";

import { selectors as fundcorpSelectors } from "reducers/fundcorp";
import { selectors as sessionSelectors } from "reducers/session";
import { RESCUE_TYPE } from "constants.js";
import * as i18nUtils from "util/i18n";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

import PageLoading from "pages/_components/PageLoading";
import TransactionTicket from "pages/_components/TransactionTicket";
import ContextMenu from "pages/_components/ContextMenu";
import { parseFloatToAmountFormat } from "util/number";
import RescueSummaryData from "./RescueSummaryData";

const FORM_ID = "fund.rescue.ticket";
const { BY_AMOUNT, BY_SHAREACCOUNT, TOTAL } = RESCUE_TYPE;
class RescueTicket extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }),
        fetching: bool,
        history: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        loggedUser: null,
        fetching: false,
    };

    state = {
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;

        if (!summary) {
            dispatch(push(`/pendingTransaction/list`));
        }
    }

    centerContentMobile = (isDesktop) => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: isDesktop && "-0.5rem 2rem" }}>{i18nUtils.get(`${FORM_ID}.title`)}</h1>
        </div>
    );

    getRescueDeadlineLabel = (rescatePlazo) => {
        switch (rescatePlazo) {
            case "0":
                return "Inmediato";
            case "1":
                return "24 hs";
            case "2":
                return "48 hs";
            default:
                return `${rescatePlazo} dias`;
        }
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { summary } = this.props;
        const FORM_SUMMARY = "fund.rescue.summary";
        const {
            selectedFund,
            equivalentAmount,
            equivalentCuotapartes,
            rescueType,
            creditAccountLabel,
            rescueDate,
        } = summary;
        const { tipoVCPDescripcion: fondoClase, fondoNombre, rescatePlazo, cuotapartistaNumero } = selectedFund;

        const bodyData = [
            getGeneralTicketItem(`forms.transaction.ticket.date`, `${rescueDate}`, "datefulltime"),
            getGeneralTicketItem(
                `forms.transaction.ticket.number`,
                summary?.idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem(`${FORM_SUMMARY}.fund.label`, `${fondoNombre}`, "string"),
        ];

        if ([BY_SHAREACCOUNT, TOTAL].includes(rescueType)) {
            bodyData.push(
                getGeneralTicketItem(
                    `${FORM_SUMMARY}.cuotapartesAmount.label`,
                    parseFloatToAmountFormat(equivalentCuotapartes, 4),
                    "string",
                ),
            );
        }
        if ([BY_AMOUNT, TOTAL].includes(rescueType)) {
            bodyData.push(
                getGeneralTicketItem(
                    `${FORM_SUMMARY}.rescueAmount.label`,
                    equivalentAmount.quantity,
                    `amount${i18nUtils.get(`currency.label.${equivalentAmount.currency}`)}`,
                ),
            );
        }

        bodyData.push(
            getGeneralTicketItem(`${FORM_SUMMARY}.class.label`, fondoClase, "string"),
            getGeneralTicketItem(`${FORM_SUMMARY}.deadline.label`, this.getRescueDeadlineLabel(rescatePlazo), "string"),
            getGeneralTicketItem(`${FORM_SUMMARY}.creditAccount.label`, creditAccountLabel, "string"),
            getGeneralTicketItem(`${FORM_SUMMARY}.shareAccount.label`, cuotapartistaNumero, "string"),
            getGeneralTicketItem(`${FORM_SUMMARY}.date.label`, summary?.creationDate, "date"),
        );

        const ticketData = {
            ticketName: i18nUtils.get(`${FORM_ID}.title`),
            ticketBody: bodyData,
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    handlePreCancel = () => {
        const { dispatch } = this.props;
        dispatch(push("/rescue/scheduled/cancel"));
    };

    buttonsDetail = () => {
        const { dispatch, activeEnvironment, summary } = this.props;
        const buttons = [
            {
                label: `${FORM_ID}.goToDesktop`,
                bsStyle: "outline",
                onClick: () => dispatch(push("/desktop")),
            },
        ];

        if (summary?.idTransactionStatus !== "SCHEDULED") {
            buttons.push({
                label: `${FORM_ID}.goToFundTrade`,
                bsStyle: "primary",
                onClick: () => dispatch(push("/fundcorpOperations")),
            });
        }

        if (
            activeEnvironment?.permissions?.scheduledTransactionsCancel &&
            summary?.idTransactionStatus === "SCHEDULED"
        ) {
            buttons.push({
                label: "scheduler.cancel.button",
                bsStyle: "primary",
                onClick: this.handlePreCancel,
            });
        }

        return buttons;
    };

    rightContentTransactionScheme = (isFinished) => {
        const { isDesktop, summary } = this.props;

        if (isDesktop || isFinished) {
            return <></>;
        }

        return (
            <>
                {summary?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    getTitle = () => {
        const { summary } = this.props;
        const { idActivity } = summary;
        let title;

        if (idActivity === "scheduled.transactions.cancel") {
            title = "activities.scheduled.transactions.cancel";
        } else {
            title = `${FORM_ID}.title`;
        }

        return title;
    };

    renderContent = () => {
        const { summary, isDesktop, isSmallDesktop } = this.props;
        const { showDrawerMobile, fetchingDownload } = this.state;
        const isFinished = summary?.idTransactionStatus === "FINISHED";

        return (
            <>
                {summary && (
                    <TransactionTicket
                        notificationScope="forms"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={isFinished}
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={summary}
                        generateSignFunction
                        ignoreHomeButton
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        summaryTitle={this.getTitle()}
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        isFetchingExport={fetchingDownload}>
                        <RescueSummaryData {...this.props} spaceBetween />
                    </TransactionTicket>
                )}
            </>
        );
    };

    render() {
        const { fetching } = this.props;

        return <PageLoading loading={fetching}>{!fetching && this.renderContent()}</PageLoading>;
    }
}

const mapStateToProps = (state) => ({
    summary: fundcorpSelectors.getSummary(state),
    fetching: fundcorpSelectors.getFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(RescueTicket);

import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { shape, func, bool, string } from "prop-types";
import { push } from "react-router-redux/actions";
import { isIOS } from "react-device-detect";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { selectors as assistantSelectors } from "reducers/assistant";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import * as i18n from "util/i18n";
import * as configUtil from "util/config";

import Credential from "pages/_components/fields/credentials/Credential";
import Container from "pages/_components/Container";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import SelectorInput from "pages/_components/fields/formik/SelectorInput";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import RegionSelectionLink from "pages/login/_components/RegionSelectionLink";
import { Mixpanel } from "util/clickstreaming";
import FooterDesktop from "pages/_components/FooterDesktop";
import isTokenActive from "util/token";
import FixedTermModal from "pages/login/FixedTermModal";
import Captcha from "pages/_components/fields/credentials/Captcha";
import ForgotUserButton from "./ForgotUserButton";

const FORM_ID = "login.step1";

class Step1Content extends Component {
    static propTypes = {
        availability: shape({
            isAvailable: bool,
            isHardwareDetected: bool,
        }),
        dispatch: func.isRequired,
        isMobileNative: bool.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        isFromAmazon: bool.isRequired,
        isFromGoogle: bool.isRequired,
        isFromWhatsapp: bool.isRequired,
        isFromMessenger: bool.isRequired,
        isSubmitting: bool.isRequired,
        document: string,
        documentType: string,
        userFirstName: string,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        username: string,
        values: shape({
            username: string,
            secondFactor: string,
            captcha: string,
            rememberEmail: bool,
        }).isRequired,
        fromOnboardingLoginData: shape({}).isRequired,
        fingerprintAvailable: shape({}).isRequired,
        fingerPrintEnvironmentRestricted: bool,
        location: shape({}).isRequired,
        fetching: bool.isRequired,
        fetchingBiometric: bool.isRequired,
        biometricLogin: bool.isRequired,
        fingerprintInitialized: bool,
        showCaptcha: bool.isRequired,
        resetCaptcha: bool.isRequired,
    };

    static defaultProps = {
        userFirstName: "",
        username: "",
        document: "",
        documentType: "",
        fingerPrintEnvironmentRestricted: false,
        availability: null,
        fingerprintInitialized: false,
    };

    state = {
        showFixedTermModal: false,
    };

    componentDidMount() {
        const { dispatch, isMobileNative, biometricLogin } = this.props;
        if (isMobileNative || isIOS) {
            window.common.hideSplashScreen();
            dispatch(fingerprintActions.fingerprintAvailability());
        }

        if (!biometricLogin) {
            dispatch(fingerprintActions.fingerprintGetInitialData());
        }
    }

    shouldComponentUpdate(nextProps) {
        const { username } = this.props;

        // when user selected to be remembered, this prevents showing user welcome message when navigating to step 2
        if (nextProps.username && username === "") {
            return false;
        }

        return true;
    }

    loginBiometria = () => {
        const { isMobileNative, dispatch } = this.props;

        const { fingerprintInitialized } = this.state;

        if (isMobileNative && !fingerprintInitialized) {
            dispatch(loginActions.fingerprintLoginPre());
        }
    };

    goToTokenPassword = () => {
        const { dispatch } = this.props;
        dispatch(push("/tokenPassword"));
    };

    reset = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.removeRememberedUser());
    };

    handleCancelModal = () => {
        this.setState({ showFixedTermModal: false });
    };

    handleRedirectModal = () => {
        window.open(configUtil.get("plazoFijoWeb.URL.PaginaCMF"), "_blank");
        this.handleCancelModal();
    };

    render() {
        const {
            isDesktop,
            isSubmitting,
            document,
            userFirstName,
            isFromAmazon,
            isFromGoogle,
            isFromMessenger,
            isFromWhatsapp,
            values,
            setValues,
            setErrors,
            location: { pathname },
            isMobileNative,
            fetchingBiometric,
            biometricLogin,
            showCaptcha,
            resetCaptcha,
        } = this.props;

        const { showFixedTermModal } = this.state;
        const showFixedTermButton = configUtil.getBoolean("plazoFijoWeb.Funcion.Activa");
        const fingerprintEnabled = configUtil.getBoolean("frontend.login.fingerprint.enabled");
        const captchaEnabled = configUtil.getBoolean("core.auth.captcha.enabled");

        let showBiometricLogin;

        if (biometricLogin) {
            showBiometricLogin = biometricLogin.document === document ? biometricLogin.showLogin : false;
        } else {
            showBiometricLogin = false;
        }

        return (
            <>
                <Container className="flex-grow align-items-center container--layout">
                    {!isDesktop && (isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && (
                        <AssistantAlert />
                    )}
                    <FixedTermModal
                        showModal={showFixedTermModal}
                        handleCancel={this.handleCancelModal}
                        handleGoToSite={this.handleRedirectModal}
                    />
                    <Form className="w-100" noValidate="novalidate">
                        <Col sm={12} md={12} lg={12} className={isDesktop ? "col col-12 px-0" : "col col-12 "}>
                            {document && (
                                <div className="text-lead">
                                    <h4>
                                        <I18n id="login.step1.welcome" />, {userFirstName}
                                    </h4>
                                    <Button
                                        bsStyle="link"
                                        label="login.step1.areNotYou"
                                        type="button"
                                        onClick={this.reset}
                                    />
                                </div>
                            )}

                            {!document && (
                                <div className="mt-3">
                                    <Field
                                        component={SelectorInput}
                                        name="document"
                                        selectorSize="small"
                                        selectProps={{
                                            name: "documentType",
                                            value: {
                                                value: values.documentType,
                                                label: values.documentType,
                                            },
                                            options: [
                                                { value: "DNI", label: "DNI" },
                                                { value: "CUIT", label: "CUIT" },
                                                { value: "CUIL", label: "CUIL" },
                                                { value: "CDI", label: "CDI" },
                                            ],
                                            disabled: isSubmitting,
                                        }}
                                        isFocused
                                        label={i18n.get("login.step1.documentType.label")}
                                        inputProps={{
                                            name: "document",
                                            value: values.document,
                                            disabled: isSubmitting,
                                            inputmode: "numeric",
                                            autofocus: true,
                                        }}
                                        labelRegular
                                        onChange={(type, stringDocument) => {
                                            setErrors({ document: "" });
                                            setValues({
                                                ...values,
                                                documentType: type?.value ? type.value : type,
                                                document: stringDocument,
                                            });
                                        }}
                                        isDisabled={isSubmitting}
                                    />

                                    <Field
                                        idForm={FORM_ID}
                                        name="rememberDocument"
                                        component={Checkbox}
                                        checked={values.rememberDocument}
                                    />
                                </div>
                            )}
                            <div className="login__label-required">
                                <Field
                                    idForm={FORM_ID}
                                    name="username"
                                    component={Credential}
                                    copyEnabled={false}
                                    isDisabled={isSubmitting}
                                    inputProps={
                                        document
                                            ? {
                                                  autofocus: true,
                                              }
                                            : false
                                    }
                                />
                            </div>
                        </Col>
                        {isMobileNative && fingerprintEnabled && document && showBiometricLogin && (
                            <Col sm={12} md={12} lg={12} className="col col-12">
                                <Button
                                    type="button"
                                    bsStyle="outline"
                                    label="biometrics.login"
                                    image="images/fingerprint.svg"
                                    loading={fetchingBiometric}
                                    imageStyle="mr-2"
                                    onClick={this.loginBiometria}
                                />
                            </Col>
                        )}
                        <Col sm={12} md={12} lg={12} className={isDesktop ? "col col-12 px-0" : "col col-12"}>
                            {captchaEnabled && showCaptcha && (
                                <Field
                                    name="captcha"
                                    idForm={FORM_ID}
                                    component={Captcha}
                                    resetCaptcha={resetCaptcha}
                                />
                            )}
                        </Col>
                        <ForgotUserButton {...this.props} />
                        <Col sm={12} md={12} lg={12} className={isDesktop ? "col col-12 px-0" : "col col-12"}>
                            <Button type="submit" bsStyle="primary" label="global.continue" loading={isSubmitting} />
                        </Col>
                    </Form>
                </Container>
                <div className="w-100 mt-3 pt-3 mx-3 justify-content-center d-flex flex-flow-wrap border-top-divider">
                    {showFixedTermButton && !userFirstName && (
                        <Col xs={6} className="col col-12 pr-1 pl-0">
                            <Button
                                onClick={() => {
                                    this.setState({ showFixedTermModal: true });
                                }}
                                type="button"
                                bsStyle="secondary"
                                defaultLabelText={configUtil.get("plazoFijoWeb.Boton.Nombre")}
                                image="images/world.svg"
                            />
                        </Col>
                    )}

                    {isTokenActive() && (
                        <Col xs={6} className="col col-12 pl-1 pr-0">
                            <Button
                                onClick={this.goToTokenPassword}
                                type="button"
                                bsStyle="secondary"
                                label="settings.token.login.button"
                                image="images/shield-check.svg"
                            />
                        </Col>
                    )}
                    {!isDesktop && (
                        <Col sm={12} md={12} className="px-0">
                            <FooterDesktop
                                isDesktop={isDesktop}
                                moreOptions={[<RegionSelectionLink disabled={pathname === "/regionSelection"} />]}
                            />
                        </Col>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    ...loginSelectors.getRememberedUser(state),
    fingerprintAvailable: fingerprintSelectors.getAvailability(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    fromOnboardingLoginData: loginSelectors.getFromOnboardingLoginData(state),
    fingerPrintEnvironmentRestricted: loginSelectors.getFingerPrintEnvironmentRestricted(state),
    isRememberedCredentials: !!loginSelectors.getRememberedUser(state),
    availability: fingerprintSelectors.getAvailability(state),
    fetching: loginSelectors.getFetching(state),
    fetchingBiometric: loginSelectors.getFetchingBiometric(state),
    biometricLogin: loginSelectors.getBiometricLogin(state),
    fingerprintInitialized: loginSelectors.getFingerprintInitialized(state),
});

const documentTypeFormats = (documentType, document) => {
    const isnum = /^\d+$/.test(document);
    if (documentType === "DNI") {
        if (document.length < 6 || document.length > 8 || !isnum) {
            return false;
        }
    }

    if (documentType === "CUIT" || documentType === "CUIL" || documentType === "CDI") {
        if (document.length !== 11 || !isnum) {
            return false;
        }
    }
    return true;
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            username: "",
            captcha: "",
            rememberDocument: false,
            documentType: props.documentType ? props.documentType : "DNI",
            document: props.document ? props.document : "",
        }),
        validationSchema: (props) =>
            Yup.object().shape({
                username: Yup.string().required(i18n.get(`${FORM_ID}.username.required`)),
                document: Yup.string().required(i18n.get(`${FORM_ID}.document.required`)),
                captcha: props.showCaptcha
                    ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`))
                    : Yup.string(),
            }),
        handleSubmit: ({ username, rememberDocument, document, documentType, captcha }, formikBag) => {
            Mixpanel.identify(document);
            Mixpanel.track(FORM_ID, { rememberDocument });
            const { isRememberedCredentials, rememberedUser } = formikBag.props;
            const formatValidation = !isRememberedCredentials
                ? documentTypeFormats(documentType, document, formikBag)
                : true;

            formikBag.props.dispatch(loginActions.setResetCaptcha(false));

            if (formatValidation) {
                formikBag.props.dispatch(
                    loginActions.loginStep1(
                        username,
                        !!rememberDocument,
                        document,
                        isRememberedCredentials ? rememberedUser.documentType : documentType,
                        captcha || "",
                        formikBag,
                    ),
                );
            } else {
                formikBag.setErrors({ document: i18n.get("login.step1.wrong.format") });
                formikBag.setSubmitting(false);
            }
        },
    }),
)(Step1Content);

/* eslint-disable import/no-unresolved */
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, string, bool, func, shape } from "prop-types";
import { Field, Form, withFormik } from "formik";
import filesize from "filesize";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import Parser from "html-react-parser";

import {
    actions as communicationTraysActions,
    selectors as communicationTraysSelectors,
} from "reducers/communicationTrays";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import { actions as notificationActions } from "reducers/notification";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import TextArea from "pages/_components/fields/TextArea";
import TextField from "pages/_components/fields/TextField";
import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";

import * as i18n from "util/i18n";
import * as config from "util/config";

const FORM_ID = "communications.compose";

class Compose extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        onCloseClick: func.isRequired,
        selectedFiles: arrayOf(string),
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
        detail: shape({}).isRequired,
        trays: arrayOf(shape({})),
        isReply: bool,
    };

    static defaultProps = {
        selectedFiles: [],
        trays: [],
        isReply: false,
    };

    state = {
        fileInputRef: React.createRef(),
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(communicationTraysActions.listRequest());
    }

    onChangeFile(event) {
        const { dispatch } = this.props;

        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        const { fileInputRef } = this.state;

        if (file) {
            const fileTypes = config.get("core.allowedFileExtensions").toLowerCase();

            const maxFileSize = config.getInteger("core.maxFileSize");

            const fileExtension = `${file.name.split(".").slice(-1)}`.toLowerCase();
            const fileSize = file.size;

            if (!fileTypes.includes(fileExtension)) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("communications.compose.attachment.invalid"),
                        "error",
                        ["communications"],
                    ),
                );
            } else if (maxFileSize < fileSize) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("communications.compose.attachment.maxSizeError", null, {
                            sizeLimit: filesize(maxFileSize),
                        }),
                        "error",
                        ["communications"],
                    ),
                );
            } else {
                dispatch(communicationActions.selectAttachment(file));
            }
        }

        fileInputRef.current.value = "";
    }

    removeSelectedFile = (index) => {
        const { dispatch } = this.props;

        dispatch(communicationActions.removeSelectedAttachment(index));
    };

    render() {
        const { trays, isReply, detail, isDesktop, isFetching, selectedFiles, onCloseClick } = this.props;
        const { fileInputRef } = this.state;

        return (
            <PageLoading loading={isFetching}>
                <Form className="above-the-fold communications__send-message">
                    <Container className="flex-grow align-items-center container--layout">
                        <Col className="col col-12">
                            {isDesktop && (
                                <Fragment>
                                    <div className="flex-container">
                                        {(!isReply && (
                                            <div className="view-title">
                                                <h3>
                                                    <I18n id="communications.compose.message.new" />
                                                </h3>
                                            </div>
                                        )) ||
                                            (detail && detail[0] && (
                                                <div className="view-title">
                                                    <h3>{detail[0].subject}</h3>
                                                </div>
                                            ))}
                                        <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                                            <Button
                                                className="toolbar-btn view-close"
                                                onClick={onCloseClick}
                                                image="images/cross.svg"
                                                bsStyle="link"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                </Fragment>
                            )}

                            {!isReply && (
                                <Field
                                    hidePlaceholder
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="subject"
                                    type="text"
                                    maxLength={100}
                                />
                            )}

                            {isReply ? (
                                detail && (
                                    <I18n
                                        component="h4"
                                        id={`communications.tray.${detail[0].communicationTray.idCommunicationTray}`}
                                    />
                                )
                            ) : (
                                <Field
                                    component={Selector}
                                    options={trays.map((tray) => ({
                                        value: tray.idCommunicationTray,
                                        label: Parser(i18n.get(`communications.tray.${tray.idCommunicationTray}`)),
                                    }))}
                                    className="slideFromBottom"
                                    idForm={FORM_ID}
                                    name="tray"
                                    type="text"
                                />
                            )}

                            <Field
                                hidePlaceholder
                                component={TextArea}
                                idForm={FORM_ID}
                                name="body"
                                maxLength={1500}
                                className="communications__send-message-textarea"
                            />

                            {selectedFiles && (
                                <div className="form-group">
                                    {selectedFiles.map((file, index) => (
                                        <div key={file.name}>
                                            {file.name} ({filesize(file.size)}){" "}
                                            <Button
                                                label="global.delete"
                                                className="btn-icon-only"
                                                onClick={() => this.removeSelectedFile(index)}
                                                image="images/delete-message.svg"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}

                            {isDesktop && (
                                <Button bsStyle="primary" label="communications.compose.send" type="submit" />
                            )}

                            {isDesktop && (
                                <Button
                                    className="btn-outline"
                                    image="images/attach.svg"
                                    label="communications.compose.attach"
                                    onClick={() => {
                                        fileInputRef.current.click();
                                    }}
                                />
                            )}
                        </Col>
                    </Container>

                    {!isDesktop && (
                        <Container className="align-items-center container--layout">
                            <Col className="col col-12">
                                <Button bsStyle="primary" label="communications.compose.send" type="submit" />
                            </Col>
                        </Container>
                    )}

                    <input
                        id="myInput"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) => this.onChangeFile(e)}
                    />
                </Form>
            </PageLoading>
        );
    }
}

const mapsStateToProps = (state) => ({
    trays: communicationTraysSelectors.list(state),
    detail: communicationSelectors.detail(state),
    isFetching: communicationSelectors.isFetching(state),
    selectedFiles: communicationSelectors.selectedAttachments(state),
});

const chargeSubject = ({ isReply, detail, preloadedMessage }) => {
    if (isReply) {
        if (detail && detail[0]) {
            return detail[0].subject;
        }
        if (preloadedMessage) {
            return preloadedMessage.subject;
        }
    }
    return "";
};

export default compose(
    connect(mapsStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            subject: chargeSubject(props),
            body: "",
            tray: "",
        }),
        validationSchema: (props) => {
            const { isReply } = props;

            return Yup.object().shape({
                subject: !isReply
                    ? Yup.string()
                          .trim()
                          .required(i18n.get("communications.subject.required"))
                    : Yup.string().notRequired(),
                body: Yup.string()
                    .trim()
                    .required(i18n.get("communications.body.required")),
                tray: !isReply
                    ? Yup.string()
                          .trim()
                          .required(i18n.get("communications.tray.required"))
                    : Yup.string().notRequired(),
            });
        },

        handleSubmit: (fields, formikBag) => {
            const { dispatch, selectedFiles, isReply, isDesktop } = formikBag.props;
            const body = fields.body.trim();
            const subject = fields.subject.trim();
            const { tray } = fields;

            if (isReply) {
                const { detail } = formikBag.props;

                dispatch(
                    communicationActions.replyRequest(
                        detail[0].communicationTray.idCommunicationTray,
                        `RE: ${detail[0].subject}`,
                        body,
                        detail[0].idCommunication,
                        0,
                        selectedFiles,
                        isDesktop,
                    ),
                );
            } else {
                dispatch(communicationActions.sendRequest(tray, subject, body, selectedFiles, isDesktop));
            }
        },
    }),
)(Compose);

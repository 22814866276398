/* eslint-disable import/no-unresolved */
import React from "react";
import * as i18nUtils from "util/i18n";
import { bool, func, shape } from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import { actions as cedipActions } from "reducers/cedip";
import InfoTag from "pages/_components/InfoTag";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedRate from "pages/_components/FormattedRate";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import ContextMenu from "pages/_components/ContextMenu";
import { push } from "react-router-redux";

function CedipCard({ cedip, isDesktop, dispatch, openDrawer, ownCedipSelected }) {
    const { cedipId, fraccionNumero, tna } = cedip;

    const handleClickViewDetail = (path) => {
        dispatch(push(path));
    };

    const handleClickReject = () => {
        dispatch(cedipActions.setSelectedCedip(cedip));
        dispatch(cedipActions.setRejecting());
        dispatch(push(`/cedip/reject`));
    };

    const handleOpenDrawer = () => {
        openDrawer();
    };

    const path = `/cedip/${cedipId}/${fraccionNumero}`;
    const consolidatedAmountLabel = i18nUtils.get("deposits.information.consolidatedAmount");
    const rateShortLabel = i18nUtils.get("deposits.information.rate.short");
    const dueDate = `${cedip.fechaVencimiento}T00:00:00.000-03`;
    const dueDateFormatted = moment(dueDate.toString().replace(".000Z", "")).format("DD/MM/YYYY");
    const creationDate = `${cedip.fechaEmision}T00:00:00.000-03`;
    const creationDateFormatted = moment(creationDate.toString().replace(".000Z", "")).format("DD/MM/YYYY");

    const creationDateLabel = i18nUtils.get("deposits.cedip.creation.label");
    const dueDateLabel = i18nUtils.get("deposits.list.item.expiration");
    const cedipNumber = i18nUtils.get("deposits.cedip.number.label");
    const datesText = i18nUtils.get("deposits.cedip.cardDate.label", null, {
        creationDate: creationDateFormatted,
        dueDate: dueDateFormatted,
    });

    const items = ownCedipSelected
        ? [
              /* {
                    label: "deposits.cedip.action.transmit",
                    onClick: () => this.handleClickTransmit(),
                }, */
              {
                  label: "deposits.cedip.action.detail",
                  onClick: () => handleClickViewDetail(path),
              },
          ]
        : [
              {
                  label: "deposits.cedip.action.admit",
                  onClick: () => handleOpenDrawer(),
              },
              {
                  label: "deposits.cedip.action.reject",
                  onClick: () => handleClickReject(),
              },
              {
                  label: "deposits.cedip.action.detail",
                  onClick: () => handleClickViewDetail(path),
              },
          ];

    const statusTagColor = (state) => {
        switch (state) {
            case "ACTIVO":
            case "PRESENTADO":
            case "DEPOSITADO":
            case "NEGOCIACION":
                return "#84efd0";
            case "ACTIVO-PENDIENTE":
                return "#FFE5B6";
            case "REPUDIADO":
            case "RECHAZADO":
            case "ANULADO":
            case "PAGADO":
            case "CADUCADO":
                return "#feb0af";
            default:
                return "#84efd0";
        }
    };

    if (isDesktop) {
        return (
            <>
                <CardContainer className="mt-0" renderAs={Link} to={path}>
                    <Card
                        className="mb-0"
                        title={
                            <>
                                {cedip.fechaVencimiento && (
                                    <div className="deposit__card-title">
                                        <div className="deposit__date no-wrap">
                                            <span>{datesText}</span>
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                        content={
                            <div className="deposit__card-content">
                                <div className="deposit__invested">
                                    <div className="deposit__invested-info">
                                        <span>{consolidatedAmountLabel}</span>
                                    </div>
                                    <div className="deposit__invested-data">
                                        <FormattedAmount
                                            quantity={cedip.montoDepositado}
                                            currency={cedip.tipoMoneda}
                                            notBold
                                        />
                                    </div>
                                </div>
                                <div className="deposit__tna">
                                    <div className="deposit__tna-info">
                                        <span>{rateShortLabel}</span>
                                    </div>
                                    <div className="deposit__tna-data">
                                        <FormattedRate rate={tna} symbolToTheLeft={false} />
                                    </div>
                                </div>
                            </div>
                        }
                        bottom={
                            <div className="deposit__card-bottom">
                                <div className="deposit__certificate">
                                    <div className="deposit__certificate-info">
                                        <span>{cedipNumber}</span>
                                    </div>
                                    <div className="deposit__certificate-data">
                                        <span>{cedip.numeroCertificado}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="cedip__card-right-content">
                                <InfoTag
                                    type="info"
                                    message={cedip.estado}
                                    tagBackground={statusTagColor(cedip.estado)}
                                    tagClass
                                    moreStyles={{
                                        alignSelf: "flex-end",
                                        width: "fit-content",
                                        color: "black",
                                        margin: "auto",
                                        marginRight: 0,
                                    }}
                                />
                                <ContextMenu items={items} />
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    }

    return (
        <>
            <CardContainer renderAs={Link} to={path} className="mt-0">
                <Card
                    className="mt-0 mx-3 mb-1"
                    hrClass="d-none"
                    title={
                        <>
                            <div className="cedip__card-title">
                                <div className="cedip__card-title-data">
                                    <div className="deposit__due-date">
                                        <span className="deposit__due-date-label">{dueDateLabel}: </span>
                                        <span className="deposit__due-date-value">{dueDateFormatted}</span>
                                    </div>
                                    <InfoTag
                                        type="info"
                                        message={`${cedipNumber}:${cedip.numeroCertificado}`}
                                        tagBackground="#E8EAF6"
                                        tagClass="m-0"
                                        moreStyles={{
                                            width: "fit-content",
                                            color: "black",
                                        }}
                                    />
                                </div>
                                <ContextMenu items={items} />
                            </div>
                            <hr className="mx-0 w-100" />
                        </>
                    }
                    content={
                        <div className="cedip__card-content">
                            <div className="cedip__row">
                                <div className="cedip__label">
                                    <span>{consolidatedAmountLabel}</span>
                                </div>
                                <div className="cedip__data">
                                    <InfoTag
                                        type="info"
                                        message={cedip.estado}
                                        tagBackground={statusTagColor(cedip.estado)}
                                        moreStyles={{
                                            alignSelf: "flex-end",
                                            width: "fit-content",
                                            color: "black",
                                            margin: "auto",
                                            marginRight: 0,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="cedip__row">
                                <div className="cedip__label">
                                    <span>{creationDateLabel}</span>
                                </div>
                                <div className="cedip__data">{creationDateFormatted}</div>
                            </div>
                            <div className="cedip__row">
                                <div className="cedip__label">
                                    <span>{consolidatedAmountLabel}</span>
                                </div>
                                <div className="cedip__data">
                                    <FormattedAmount
                                        notBold
                                        quantity={cedip.montoDepositado}
                                        currency={cedip.tipoMoneda}
                                    />
                                </div>
                            </div>
                            <div className="cedip__row">
                                <div className="cedip__label">
                                    <span>{rateShortLabel}</span>
                                </div>
                                <div className="cedip__data">
                                    <FormattedRate rate={tna} symbolToTheLeft={false} />
                                </div>
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        </>
    );
}

CedipCard.propTypes = {
    cedip: shape({}).isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    openDrawer: func.isRequired,
    ownCedipSelected: bool.isRequired,
};

export default CedipCard;

import React, { useEffect, useState } from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";

import PageLoading from "pages/_components/PageLoading";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import TransactionTicket from "pages/_components/TransactionTicket";

import {
    actions as multipleTransferActions,
    selectors as multipleTransferSelectors,
} from "reducers/multiple-transfers";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";

import * as i18n from "util/i18n";
import { getAccountDescription } from "util/accounts";
import { exportGenericTicketPdfWithSuccessMessage, getGeneralTicketItem } from "util/transaction";
import { actions as globalActions } from "reducers/types/global";
import { multipleTransfersPre } from "middleware/multiple-transfers";
import { VALIDATION_ERROR } from "util/responses";
import MultipleTransferTicketData from "./MultipleTransferTicketData";

const FORM_ID = "multiple.transfers";

function MultipleTransferTicket(props) {
    const {
        dispatch,
        fetching,
        isDesktop,
        summary: {
            batchId,
            idTransactionStatus,
            debitAccount,
            description,
            creationDate,
            executionDate,
            fileName,
            quantity,
            amount,
        },
        summary,
        snackbarMessage,
    } = props;

    const [fetchingDownload, setFetchingDownload] = useState(false);

    const title = `${FORM_ID}.ticket.title`;

    useEffect(() => {
        if (!summary?.idTransaction) {
            dispatch(push("/transactions/list"));
        } else {
            dispatch(multipleTransferActions.setRememberFormData(false));
            if (snackbarMessage && snackbarMessage !== "") {
                dispatch(notificationActions.showNotification(snackbarMessage, "warning", [FORM_ID]));
                dispatch(globalActions.setVoucherSnackbar(null));
            }
        }
    }, [dispatch, summary]);

    const additionalData = {
        debitAccount: {
            value: debitAccount?.number !== undefined && getAccountDescription(debitAccount),
            format: "string",
        },
        reference: {
            value: description,
            format: "string",
        },
        uploadDate: {
            value: creationDate,
            format: "datetime",
        },
        executionDate: {
            value: executionDate,
            format: "date",
        },
        fileName: {
            value: fileName,
            format: "string",
        },
        quantity: {
            value: quantity,
            format: "string",
        },
        amount: {
            value: amount?.quantity,
            format: `amount${i18n.get(`currency.label.${amount?.currency || "ARS"}`)}`,
        },
    };

    const data = batchId
        ? {
              id: {
                  value: batchId,
                  format: "string",
              },
              ...additionalData,
          }
        : additionalData;

    const generateTicketBody = () => {
        const formattedData = Object.entries(data).map(([key, item]) =>
            getGeneralTicketItem(`${FORM_ID}.summary.${key}.label`, item.value, item.format),
        );

        return formattedData;
    };

    const handleDownload = async () => {
        setFetchingDownload(true);

        const ticketData = {
            ticketName: i18n.get(title),
            ticketBody: generateTicketBody(),
        };

        await exportGenericTicketPdfWithSuccessMessage(ticketData, dispatch, i18n.get("global.download.success"));

        setFetchingDownload(false);
    };

    const centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 m-0 px-2">{i18n.get(title)}</h1>
        </div>
    );

    const buttonsDetail = () => {
        const buttons = [
            {
                label: `${FORM_ID}.ticket.goTo`,
                bsStyle: "primary",
                onClick: () => {
                    dispatch(push("/transfer/multiple"));
                },
            },
        ];

        return buttons;
    };

    const signTransaction = () => {
        const { idActivity } = summary;

        const signTransactionData = Object.entries(data).map(([key, item]) => (
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.summary.${key}.label`}
                labelClassName="no-wrap"
                data={(() => {
                    if (item.format.includes("date")) {
                        return (
                            <FormattedDate
                                date={item.value}
                                dateFormat={item.format === "datetime" ? "dd/MM/yyyy hh:mm:ss" : "dd/MM/yyyy"}
                                showTime={item.format === "datetime"}
                            />
                        );
                    }
                    if (item.format.includes("amount")) {
                        return (
                            <FormattedAmount
                                {...amount}
                                notBold
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            />
                        );
                    }
                    return item.value;
                })()}
                dataClassName="data-desc text-right"
            />
        ));

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity,
                idForm: "",
                content: signTransactionData,
                title,
                pendingDispatch: summary?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity,
                idTransaction: summary.idTransaction,
                idForm: "",
                ticketData: summary,
            }),
        );
    };

    const validateSignTransaction = async () => {
        dispatch(multipleTransferActions.setFetching(true));
        const { type, data: dataResponse } = await multipleTransfersPre();
        if (type === "W") {
            const { NO_FIELD } = dataResponse.data;
            if (dataResponse.code === VALIDATION_ERROR) {
                dispatch(notificationActions.showNotification(NO_FIELD, "warning", [FORM_ID]));
            }
        } else {
            signTransaction();
        }
        dispatch(multipleTransferActions.setFetching(false));
    };

    return (
        <PageLoading loading={fetching}>
            <TransactionTicket
                notificationScope={FORM_ID}
                handleClick={handleDownload}
                handleClickMessage="global.download"
                headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                centerElement={!isDesktop ? centerContentMobile : undefined}
                uniqueDownload={["FINISHED"].includes(idTransactionStatus)}
                buttonsDetail={buttonsDetail()}
                hasInlineButtons
                idSubtitle={`${FORM_ID}.ticket.subtitle.${idTransactionStatus?.toLowerCase()}`}
                hideMobileMenu={!isDesktop}
                summary={{ ...summary, idTransactionStatus }}
                isDesktop={isDesktop}
                onSignTicket={validateSignTransaction}
                summaryTitle={title}
                showDrawerMobile={false}
                isFetchingExport={fetchingDownload}
                ignoreHomeButton
                downloadImageWhite={!isDesktop}>
                <MultipleTransferTicketData
                    dispatch={dispatch}
                    isDesktop={isDesktop}
                    data={data}
                    batch={{ ...summary, idUser: summary?.idUserCreator }}
                />
            </TransactionTicket>
        </PageLoading>
    );
}

MultipleTransferTicket.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    summary: shape({}).isRequired,
    loggedUser: shape({}).isRequired,
    snackbarMessage: string.isRequired,
};

const mapStateToProps = (state) => ({
    fetching: multipleTransferSelectors.getFetching(state),
    summary: multipleTransferSelectors.getMultipleTransfer(state),
    loggedUser: sessionSelectors.getUser(state),
    snackbarMessage: multipleTransferSelectors.getSnackbarMessage(state),
});

export default connect(mapStateToProps)(MultipleTransferTicket);

import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";

class AdminGroupsDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { groupNameList, name },
            } = object.transaction;
            const groupNames =
                name || groupNameList?.map((groupName) => <div key={groupName}> {groupName} </div>) || "";
            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{submitDateTimeAsString}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">{groupNames}</Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="forms.transaction.ticket.date"
                        data={<span>{submitDateTimeAsString}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="transaction.ticket.group"
                        data={groupNames}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="transaction.ticket.group" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AdminGroupsDataTable);

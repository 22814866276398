/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import { func, shape, bool, string } from "prop-types";

export default function FundcorpFilterSelect({
    columnFilter,
    changeFilter,
    isDesktop,
    options,
    radioOptions,
    className,
    cleanFilter,
    setCleanFilter,
}) {
    const [ascendent, setAscendent] = useState(true);
    const [descendent, setDescendent] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedValue, setSelectedValue] = useState("0");

    FundcorpFilterSelect.propTypes = {
        columnFilter: shape({}),
        options: shape({}).isRequired,
        radioOptions: shape({}).isRequired,
        changeFilter: func.isRequired,
        isDesktop: bool.isRequired,
        className: string,
        cleanFilter: bool.isRequired,
        setCleanFilter: func.isRequired,
    };

    FundcorpFilterSelect.defaultProps = {
        columnFilter: { filterSelected: "amount" },
        className: "",
    };

    const hasMounted = useRef(false);

    const changeOrder = (value) => {
        setSelectedValue(value);

        if (selectedFilter.value) {
            if (value === "0") {
                setAscendent(false);
                setDescendent(true);
                changeFilter(selectedFilter.value, "ascendent");
            } else {
                setAscendent(true);
                setDescendent(false);
                changeFilter(selectedFilter.value, "descendent");
            }
        }
    };

    useEffect(() => {
        const newColumnFilter = { ...columnFilter };

        if (selectedValue === "0") {
            newColumnFilter.directionFilter = "ascendent";
        } else {
            newColumnFilter.directionFilter = "descendent";
        }

        if (columnFilter && columnFilter.filterSelected === selectedFilter) {
            if (newColumnFilter.directionFilter === "ascendent") {
                setDescendent(false);
            } else {
                setAscendent(false);
            }
        } else {
            setAscendent(true);
            setDescendent(false);
        }

        if (cleanFilter) {
            setSelectedFilter(options[0]);
            setSelectedValue("0");
            setAscendent(false);
            setDescendent(true);

            setCleanFilter(false);
        }

        if (!hasMounted.current) {
            setSelectedFilter(options[0]);
            setSelectedValue("0");
            setAscendent(false);
            setDescendent(true);
            changeFilter(options[0].value, "ascendent");
            hasMounted.current = true;
        } else if (options.length > 0 && !options.some((opt) => opt.value === selectedFilter?.value)) {
            setSelectedFilter(options[0]);
        }
    }, [columnFilter, selectedFilter, selectedValue, cleanFilter, setCleanFilter, changeFilter, options]);

    const handleChange = (selectFilter) => {
        setSelectedFilter(selectFilter);
        if (selectFilter.value) {
            const direction = selectedValue === "0" ? "ascendent" : "descendent";
            if (direction === "ascendent") {
                setAscendent(false);
                setDescendent(true);
            } else {
                setAscendent(true);
                setDescendent(false);
            }
            changeFilter(selectFilter.value, direction);
        }
    };

    return (
        <div className="px-0 pr-3">
            <div className="pl-0">
                <div className="form-group-text">
                    <label htmlFor="activity" className="control-label">
                        <I18n id="transactions.list.filters.transaction.orderby.label" />
                    </label>
                </div>
                <div className={`pending-transactions__orderby ${className}`}>
                    <div className="input-group mr-3">
                        <Select
                            name="activity"
                            className="slideFromBottom"
                            clearable={false}
                            searchable={isDesktop}
                            onChange={handleChange}
                            options={options}
                            optionClassName="needsclick"
                            placeholder={i18n.get("transactions.filters.placeholder")}
                            value={selectedFilter}
                            defaultValue={selectedFilter}
                            noResultsText={i18n.get("global.no.results")}
                        />
                    </div>
                    <RadioButtonGroup
                        inLineControl
                        name="radioGroup"
                        selectorId="radioGroup"
                        onChange={changeOrder}
                        optionClassName="needsclick"
                        options={radioOptions}
                        value={selectedValue}
                        valueKey="id"
                        labelKey="label"
                    />
                </div>
            </div>
        </div>
    );
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool, shape, arrayOf, number } from "prop-types";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import moment from "moment";
import Yup from "yup";
import classNames from "classnames";

import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import { DateField } from "pages/_components/fields/DateField";
import TextField from "pages/_components/fields/TextField";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Pagination from "pages/_components/pagination/Pagination";
import ContextMenu from "pages/_components/ContextMenu";
import I18n from "pages/_components/I18n";
import Hint from "pages/_components/hints/Hint";
import RowButtons from "./_components/RowButtons";
import ChecksDetailTable from "./_components/ChecksDetailTable";

const FORM_ID = "checksDetail";
const CHECK_TYPE_PROPIOS = 1;
const CHECK_TYPE_TERCEROS = 2;
const getLimitDate = (dateDif) => {
    const date = moment(new Date()); // the day before DST in the US

    date.add(dateDif, "days");

    return date;
};
class ChecksDetail extends Component {
    static propTypes = {
        isSubmitting: bool,
        dispatch: func.isRequired,
        fetchingDownload: bool,
        isEmittedSelected: bool.isRequired,
        isDepositedSelected: bool.isRequired,
        values: shape({}).isRequired,
        isDesktop: bool,
        isSmallDesktop: bool,
        listCheckDetail: arrayOf(shape({})).isRequired,
        checkDetailTotalPages: number.isRequired,
        checkDetailCurrentPageNumber: number.isRequired,
        setErrors: func.isRequired,
        setSubmitting: func.isRequired,
        setValues: func.isRequired,
        showForm: bool.isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
        fetchingDownload: false,
        isDesktop: true,
        isSmallDesktop: false,
    };

    componentDidMount = () => {
        this.setShowForm(false);
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleExport = (format) => {
        const { dispatch, values, isEmittedSelected, listCheckDetail, checkDetailCurrentPageNumber } = this.props;
        const { checkNumber, dateFrom, dateTo, amountFrom, amountTo } = values;
        const registersByPage = listCheckDetail.length + 1;
        const pageNumber = checkDetailCurrentPageNumber;
        dispatch(
            checksActions.downloadCheckDetail(
                format,
                isEmittedSelected ? CHECK_TYPE_PROPIOS : CHECK_TYPE_TERCEROS,
                pageNumber,
                checkNumber,
                dateFrom,
                dateTo,
                amountFrom,
                amountTo,
                registersByPage,
            ),
        );
    };

    handleChangeButton = (button) => {
        const { dispatch, setErrors, setValues } = this.props;
        setValues({
            checkNumber: "",
            dateFrom: getLimitDate(-1),
            dateTo: getLimitDate(0),
            amountFrom: "",
            amountTo: "",
        });
        setErrors({});
        dispatch(checksActions.clearListCheckDetail());
        if (button === "emitted.checks") {
            dispatch(checksActions.setEmmitedChecks());
        } else {
            dispatch(checksActions.setDepositedChecks());
        }
    };

    fetchCheckDetailNextPage = (pageNumber) => {
        const { dispatch, values, setSubmitting, isEmittedSelected } = this.props;
        const { checkNumber, dateFrom, dateTo, amountFrom, amountTo } = values;
        setSubmitting(true);
        dispatch(
            checksActions.fetchCheckDetailList(
                setSubmitting,
                isEmittedSelected ? CHECK_TYPE_PROPIOS : CHECK_TYPE_TERCEROS,
                pageNumber,
                checkNumber,
                dateFrom,
                dateTo,
                amountFrom,
                amountTo,
            ),
        );
    };

    getMaxDateFrom = () => {
        const maxDateFrom = configUtils.getInteger("echeq.max.dateFrom", 3);
        const date = new Date();

        return new Date(date.setMonth(date.getMonth() - maxDateFrom));
    };

    renderFirstRowFilter = (isDesktop, isSmallDesktop, dateFrom, isSubmitting, isEmittedSelected) => (
        <Container
            className={`flex-grow align-items-center container-white my-2 ${
                !isDesktop ? "transparent pt-0 mt-0" : "pt-3 pb-2"
            }`}>
            <Col
                xs={12}
                md={4}
                lg={isSmallDesktop ? 4 : 2}
                className={classNames({ "align-self-flex-start px-2 mb-2": isDesktop })}>
                <Field
                    component={TextField}
                    formGroupTextClassName="mt-1"
                    hidePlaceholder
                    idForm={FORM_ID}
                    name="checkNumber"
                    type="text"
                    pattern="[0-9]*"
                />
            </Col>
            {!isDesktop && (
                <div className="w-100 px-3">
                    <I18n
                        id={isEmittedSelected ? "checksDetail.dateDebit.label" : "checksDetail.dateDeposited.label"}
                        component="label"
                        componentProps={{
                            className: "control-label mt-2 mb-0",
                        }}
                    />
                </div>
            )}
            <Col
                xs={6}
                md={4}
                lg={isSmallDesktop ? 4 : 2}
                className={classNames({
                    "align-self-flex-start px-2": isDesktop,
                })}>
                <Field
                    component={DateField}
                    formGroupTextClassName="mt-1 ellipsis-span"
                    hidePlaceholder
                    idForm={isEmittedSelected ? "checksDetail" : "checksDetail.deposited"}
                    name="dateFrom"
                    customKey={!isDesktop && "echeqs.from.label"}
                    selectsStart
                    minDate={this.getMaxDateFrom()}
                    maxDate={moment().add(-1, "days")}
                    autocomplete="off"
                />
            </Col>
            <Col
                xs={6}
                md={4}
                lg={isSmallDesktop ? 4 : 2}
                className={classNames({
                    "align-self-flex-start px-2": isDesktop,
                })}>
                <Field
                    component={DateField}
                    formGroupTextClassName="mt-1 ellipsis-span"
                    hidePlaceholder
                    idForm={isEmittedSelected ? "checksDetail" : "checksDetail.deposited"}
                    name="dateTo"
                    customKey={!isDesktop && "echeqs.to.label"}
                    selectsEnd
                    minDate={dateFrom || this.getMaxDateFrom()}
                    maxDate={moment().add(0, "days")}
                    autocomplete="off"
                />
            </Col>
            <Col
                xs={6}
                md={4}
                lg={isSmallDesktop ? 4 : 2}
                className={classNames({ "align-self-flex-start px-2 mb-2": isDesktop })}>
                <Field
                    component={TextField}
                    formGroupTextClassName="mt-1"
                    hidePlaceholder
                    idForm={FORM_ID}
                    name="amountFrom"
                    type="text"
                    pattern="\d*"
                    inputmode="numeric"
                    formGroupClassName="my-0"
                />
            </Col>
            <Col
                xs={6}
                md={4}
                lg={isSmallDesktop ? 4 : 2}
                className={classNames({ "align-self-flex-start px-2 mb-2": isDesktop })}>
                <Field
                    component={TextField}
                    formGroupTextClassName="mt-1"
                    hidePlaceholder
                    idForm={FORM_ID}
                    name="amountTo"
                    type="text"
                    pattern="\d*"
                    inputmode="numeric"
                    formGroupClassName="my-0"
                />
            </Col>
            <Col
                xs={12}
                md={4}
                lg={isSmallDesktop ? 4 : 2}
                className={classNames("d-flex", {
                    "align-self-flex-start px-2": isDesktop,
                    "checks-search-button": !isDesktop,
                })}>
                <Button
                    type="submit"
                    label="echeq.filter"
                    className="mt-45 mb-0 mr-0 max-height-3"
                    bsStyle="primary"
                    loading={isSubmitting}
                />
            </Col>
        </Container>
    );

    rightContent = () => {
        const { dispatch, isDesktop } = this.props;

        const options = [
            {
                label: `${FORM_ID}.header.discounted.checks`,
                onClick: () => dispatch(push("/discountedChecksDetail")),
            },
            {
                label: "global.download",
                onClick: () => this.handleExport("pdf"),
            },
        ];

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={options}
            />
        );
    };

    getHeader = () => {
        const { dispatch, isDesktop, fetchingDownload, listCheckDetail } = this.props;

        if (isDesktop) {
            return (
                <>
                    <Head onBack={this.handleBack} />
                    <Head
                        title={`${FORM_ID}.header.title`}
                        exportList
                        handleDeleteClick={() => {
                            dispatch(push("/discountedChecksDetail"));
                        }}
                        handleDeleteMessage={`${FORM_ID}.header.discounted.checks`}
                        handleClick={this.handleExport}
                        handleClickMessage="global.download"
                        isFetchingExport={fetchingDownload}
                        headerClassName="checks-header"
                        hasInlineButtons
                        csvDownload
                        exportDisabled={listCheckDetail.length === 0}
                        titleClassName="no-wrap"
                        messageTooltip="checksDetail.header.hint"
                        alwaysShowMessage
                    />
                </>
            );
        }
        return (
            <Head
                title={`${FORM_ID}.header.title`}
                headerClassName="blue-main-header-mobile blue-main-title-mobile"
                centerContentClassName="mx-5"
                onBack={this.handleBack}
                rightContent={this.rightContent}
            />
        );
    };

    setShowForm = (showForm) => {
        const { dispatch } = this.props;
        dispatch(checksActions.setShowForm(showForm));
    };

    render() {
        const {
            dispatch,
            isEmittedSelected,
            isDepositedSelected,
            values,
            isDesktop,
            isSmallDesktop,
            isSubmitting,
            listCheckDetail,
            checkDetailCurrentPageNumber,
            checkDetailTotalPages,
            showForm,
        } = this.props;
        const { dateFrom } = values;

        return (
            <>
                <Notification scopeToShow="checksMain" />
                <div className={classNames("checks_container px-0 mb-0", { "admin-detail-head": isDesktop })}>
                    <Notification scopeToShow="checksDetail" />
                    {this.getHeader()}
                    <RowButtons
                        isButton1Selected={isEmittedSelected}
                        isButton2Selected={isDepositedSelected}
                        FORM_ID={FORM_ID}
                        button1Id="emitted.checks"
                        button2Id="deposited.checks"
                        handleChangeButton={this.handleChangeButton}
                    />
                    {!isDesktop && (
                        <>
                            <Col xs={12} className="mt-2">
                                <Hint
                                    idMessage="checksDetail.header.hint"
                                    classNameGroup="mb-0"
                                    classNameMessage=""
                                    classNameHintP="my-0"
                                    classNameImage="my-0 ml-2 justify-content-center align-self-center"
                                    alwaysShowMessage
                                />
                            </Col>
                            <Col xs={12} className="justify-content-end d-flex">
                                <Button
                                    block
                                    className="btn-link p-0 my-0 right"
                                    label={showForm ? "echeq.button.hideFilter" : "echeq.button.seeFilter"}
                                    onClick={() => this.setShowForm(!showForm)}
                                />
                            </Col>
                        </>
                    )}
                    {(isDesktop || showForm) && (
                        <Form>
                            {this.renderFirstRowFilter(
                                isDesktop,
                                isSmallDesktop,
                                dateFrom,
                                isSubmitting,
                                isEmittedSelected,
                            )}
                        </Form>
                    )}
                    <PageLoading className="line-loader" loading={isSubmitting}>
                        <ChecksDetailTable
                            listCheckDetail={listCheckDetail}
                            isDesktop={isDesktop}
                            tableLabel={FORM_ID}
                            isEmittedSelected={isEmittedSelected}
                            dispatch={dispatch}
                        />
                        {listCheckDetail.length > 0 && (
                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                <div className="d-flex mt-2">
                                    <Pagination
                                        totalPages={checkDetailTotalPages}
                                        pageNumber={checkDetailCurrentPageNumber}
                                        action={this.fetchCheckDetailNextPage}
                                    />
                                </div>
                            </div>
                        )}
                    </PageLoading>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isEmittedSelected: checksSelectors.isEmittedSelected(state),
    isDepositedSelected: checksSelectors.isDepositedSelected(state),
    listCheckDetail: checksSelectors.getCheckDetailList(state),
    checkDetailTotalPages: checksSelectors.getCheckDetailTotalPages(state),
    checkDetailCurrentPageNumber: checksSelectors.getCheckDetailCurrentPageNumber(state),
    showForm: checksSelectors.getShowForm(state),
    fetchingDownload: checksSelectors.getFetchingDownload(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            checkNumber: "",
            dateFrom: getLimitDate(-1),
            dateTo: getLimitDate(0),
            amountFrom: "",
            amountTo: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18nUtils.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().nullable(),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().nullable(),
                    checkNumber: values.checkNumber
                        ? Yup.string().matches(/^[0-9]*$/, i18nUtils.get("services.payment.field.isNotNumeric"))
                        : Yup.string().nullable(),
                    amountFrom: values.amountTo
                        ? Yup.number()
                              .typeError(i18nUtils.get(`${FORM_ID}.amount.amountFrom.required`))
                              .required(i18nUtils.get(`${FORM_ID}.amount.amountFrom.required`))
                              .max(values.amountTo, i18nUtils.get(`${FORM_ID}.amount.amountFrom.error`))
                        : Yup.string().nullable(),
                    amountTo: values.amountFrom
                        ? Yup.number()
                              .typeError(i18nUtils.get(`${FORM_ID}.amount.amountTo.required`))
                              .required(i18nUtils.get(`${FORM_ID}.amount.amountTo.required`))
                              .min(values.amountFrom, i18nUtils.get(`${FORM_ID}.amount.amountTo.error`))
                        : Yup.string().nullable(),
                }),
            ),
        handleSubmit: ({ checkNumber, dateFrom, dateTo, amountFrom, amountTo }, formikBag) => {
            const { dispatch, isEmittedSelected } = formikBag.props;
            const { setSubmitting } = formikBag;
            dispatch(
                checksActions.fetchCheckDetailList(
                    setSubmitting,
                    isEmittedSelected ? CHECK_TYPE_PROPIOS : CHECK_TYPE_TERCEROS,
                    "1",
                    checkNumber,
                    dateFrom,
                    dateTo,
                    amountFrom,
                    amountTo,
                ),
            );
        },
    }),
)(ChecksDetail);

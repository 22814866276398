import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { arrayOf, shape, string, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import { Link } from "react-router-dom";
import { selectors as debinSelectors } from "reducers/debin";
import { statusTagColorRecurrency, statusTagColorRecurrencyV3 } from "util/debin";
import InfoTag from "pages/_components/InfoTag";
import { accountDescription } from "util/checks";
import RecurrencyContextMenu from "pages/debinPayments/_components/recurrentDebin/RecurrencyContextMenu";
import Heading from "pages/_components/Heading";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import * as configUtils from "util/config";

const FORM_ID = "debin.recurrency";

class DebinRecurrency extends Component {
    static propTypes = {
        debinRecurrencies: arrayOf(shape({})).isRequired,
        isDesktop: bool,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                debinRecurrencyStatus: bool,
            }),
        }).isRequired,
    };

    static defaultProps = {
        isDesktop: false,
    };

    getAccountDescription = (cbu) => {
        const { accounts, isDesktop } = this.props;

        const account = accounts.find((a) => a.cbu === cbu);
        if (account?.cbu) {
            const { number, currency, productType } = account;
            let { productAlias } = account;
            let count = 24;
            if (!isDesktop) {
                count = 12;
            }
            productAlias = productAlias && productAlias.slice(0, count) + (productAlias.length > count ? "..." : "");
            return accountDescription(productAlias, number, currency, productType);
        }

        return cbu;
    };

    getEstado = (recurrency) => {
        if (recurrency.estado === "ACTIVA" && recurrency.autorizado === "AUTORIZADO") {
            return "ACTIVA";
        }
        if (recurrency.estado === "INACTIVA" && recurrency.autorizado === "AUTORIZADO") {
            return "INACTIVA";
        }
        if (
            (recurrency.estado === "ACTIVA" || recurrency.estado === "INACTIVA") &&
            recurrency.autorizado === "PENDIENTE"
        ) {
            return "PENDIENTE";
        }
        if (
            (recurrency.estado === "ACTIVA" || recurrency.estado === "INACTIVA") &&
            recurrency.autorizado === "RECHAZADO"
        ) {
            return "RECHAZADO";
        }
        return recurrency.estado;
    };

    tableBody = (useApiV3) => {
        const { debinRecurrencies, isDesktop, activeEnvironment } = this.props;

        return debinRecurrencies
            .filter((obj) => (useApiV3 ? obj.estado !== "INACTIVA DEFINITIVA" : true))
            .map((object) => (
                <Table.Row
                    className="container-white"
                    key={object?.id}
                    renderAs={Link}
                    to={`/payments/recurrent/detail/${object?.id}`}>
                    <Table.Data align="left">
                        <span>{object?.debin?.prestacion}</span>
                    </Table.Data>
                    <Table.Data align="center">
                        <span>{object?.debin?.referencia}</span>
                    </Table.Data>
                    {useApiV3 && (
                        <Table.Data align="center">
                            <FormattedDate date={object?.fecha_creacion} />
                        </Table.Data>
                    )}
                    <Table.Data align="center">
                        <span>{object?.debin?.detalle}</span>
                    </Table.Data>
                    <Table.Data align="center">
                        <span>{object?.vendedor?.cuit}</span>
                    </Table.Data>
                    <Table.Data align="center">
                        <span>{this.getAccountDescription(object?.comprador?.cbu)}</span>
                    </Table.Data>
                    <Table.Data align="center" className="data-wrapper-flex">
                        <InfoTag
                            type="info"
                            message={
                                object.estado === "INACTIVA DEFINITIVA"
                                    ? "BLOQUEADA"
                                    : useApiV3
                                    ? this.getEstado(object)
                                    : object.estado
                            }
                            tagBackground={
                                useApiV3
                                    ? statusTagColorRecurrencyV3(this.getEstado(object))
                                    : statusTagColorRecurrency(object.estado)
                            }
                            moreStyles={{
                                color: "#000",
                            }}
                            tagClass="mx-0 align-self-flex-end"
                        />
                    </Table.Data>
                    <Table.Data align="right" className="pr-0">
                        {activeEnvironment.permissions.debinRecurrencyStatus ? (
                            <button type="button" className="btn btn-link p-0" onClick={(e) => e.stopPropagation()}>
                                <RecurrencyContextMenu
                                    recurrency={object}
                                    isDesktop={isDesktop}
                                    contextClass="ml-0 mr-0 debin-recurrency-context"
                                />
                            </button>
                        ) : (
                            undefined
                        )}
                    </Table.Data>
                </Table.Row>
            ));
    };

    recurrencyListMobile = (recurrency, useApiV3) => {
        const rPrestacion = recurrency.debin.prestacion;
        const rDetalle = recurrency.debin.detalle;
        const rReferencia = recurrency.debin.referencia;
        const rFechaCreacion = recurrency.fecha_creacion;
        const rCuit = recurrency.comprador.cuit;
        const rCbu = recurrency.comprador.cbu;
        const rEstado = recurrency.estado;
        const rID = recurrency.id;

        const { isDesktop, activeEnvironment } = this.props;

        return (
            <div className="row container-white my-2 mx-0 transfer-component" key={rID}>
                <Col xs={12} className="px-1">
                    <Link to={`/payments/recurrent/detail/${rID}`}>
                        <Col xs={11} className="px-0 py-2">
                            <Col xs={12} className="d-flex space-between pr-0">
                                <Heading.DataGroup
                                    dataClassName="data-desc "
                                    containerClassName="transfer-data wrap data-wrapper-flex debin-detail f-dir-col mt-0"
                                    labelClassName="mb-0 mt-1 small-line"
                                    label={`${FORM_ID}.prestacion`}
                                    data={rPrestacion}
                                />
                                <InfoTag
                                    type="info"
                                    message={
                                        rEstado === "INACTIVA DEFINITIVA"
                                            ? "BLOQUEADA"
                                            : useApiV3
                                            ? this.getEstado(recurrency)
                                            : rEstado
                                    }
                                    tagBackground={
                                        useApiV3
                                            ? statusTagColorRecurrencyV3(this.getEstado(recurrency))
                                            : statusTagColorRecurrency(rEstado)
                                    }
                                    moreStyles={{ color: "000" }}
                                    tagClass="align-self-start m-0 mt-1"
                                />
                            </Col>
                            <Col xs={12} className="pr-0">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex debin-detail mb-0 space-between"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.referencia`}
                                    data={rReferencia}
                                />
                            </Col>
                            {useApiV3 && (
                                <Col xs={12} className="pr-0">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper-flex debin-detail mb-0 space-between"
                                        dataClassName="data-desc"
                                        label={`${FORM_ID}.fecha_creacion`}
                                        data={<FormattedDate date={rFechaCreacion} />}
                                    />
                                </Col>
                            )}
                            <Col xs={12} className="pr-0">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex debin-detail mb-0 space-between"
                                    dataClassName="data-desc break w-50 text-right"
                                    label={`${FORM_ID}.descripcion`}
                                    data={rDetalle}
                                />
                            </Col>
                            <Col xs={12} className="pr-0">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex debin-detail mb-0 space-between"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.cuit.vendedor`}
                                    data={rCuit}
                                />
                            </Col>
                            <Col xs={12} className="pr-0">
                                <Heading.DataGroup
                                    containerClassName="transfer-data debin-detail data-wrapper-flex space-between"
                                    dataClassName="data-desc small-font text-align-left"
                                    label={`${FORM_ID}.cuenta`}
                                    data={this.getAccountDescription(rCbu)}
                                />
                            </Col>
                        </Col>
                        <Col xs={1} className="px-0 py-2 transfer-component__header mt-1">
                            {activeEnvironment.permissions.debinRecurrencyStatus ? (
                                <RecurrencyContextMenu
                                    contextClass="no-left"
                                    recurrency={recurrency}
                                    isDesktop={isDesktop}
                                />
                            ) : (
                                undefined
                            )}
                        </Col>
                    </Link>
                </Col>
            </div>
        );
    };

    render() {
        const { debinRecurrencies, isDesktop } = this.props;

        const useApiV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);

        return (
            <>
                {isDesktop ? (
                    <Container className="flex-grow overflow-visible ">
                        <Col className="col col-12 px-0">
                            <div
                                ref={(tableRef) => {
                                    this.child = tableRef;
                                }}
                                className="debin-recurrency-table">
                                <Table>
                                    <Table.Header className="container-white">
                                        <Table.HeaderData align="left">
                                            <I18n id={`${FORM_ID}.prestacion`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="center">
                                            <I18n id={`${FORM_ID}.referencia`} />
                                        </Table.HeaderData>
                                        {useApiV3 && (
                                            <Table.HeaderData align="center">
                                                <I18n id={`${FORM_ID}.fecha_creacion`} />
                                            </Table.HeaderData>
                                        )}
                                        <Table.HeaderData align="center">
                                            configUtils <I18n id={`${FORM_ID}.descripcion`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="center">
                                            <I18n id={`${FORM_ID}.cuit.vendedor`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="center">
                                            <I18n id={`${FORM_ID}.cuenta`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right">
                                            <I18n id={`${FORM_ID}.estado`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData width="1rem" />
                                    </Table.Header>
                                    <Table.Body>{this.tableBody(useApiV3)}</Table.Body>
                                </Table>
                            </div>
                        </Col>
                    </Container>
                ) : (
                    debinRecurrencies
                        .filter((obj) => (useApiV3 ? obj.estado !== "INACTIVA DEFINITIVA" : true))
                        .map((debin) => this.recurrencyListMobile(debin, useApiV3))
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    debinRecurrencies: debinSelectors.getRecurrenciasDebin(state),
    accounts: debinSelectors.getAccounts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(DebinRecurrency);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { getAccountInfo } from "middleware/accounts";
import PageLoading from "pages/_components/PageLoading";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";

class RequestChecbookDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        body: "",
    };

    componentDidMount = () => {
        this.tableBody();
    };

    tableBody = () => {
        const { multipleSignaturesData } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        const bodyPromises = selectedTransactionRows.map((object) => {
            const { account } = object.transaction.data;
            return this.accountInfo(account);
        });

        Promise.all(bodyPromises).then((values) => {
            this.setState({
                body: selectedTransactionRows.map((object, index) => this.dataRow(object, values[index])),
            });
        });
    };

    dataRow = (object, cuenta) => {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <Table.Row>
                    <Table.Data align="left">
                        <span>{object.transaction.idTransaction.substring(0, 6).toUpperCase()}</span>
                    </Table.Data>
                    <Table.Data align="left">
                        <span>{cuenta}</span>
                    </Table.Data>
                    <Table.Data align="left">
                        <span>{object.transaction.data?.checksTypes.label}</span>
                    </Table.Data>
                    <Table.Data align="center">{object.transaction.data?.checkBooksAmount}</Table.Data>
                    <Table.Data align="center">{object.transaction.data?.checkBookChecksAmount}</Table.Data>
                    <Table.Data align="left">
                        <span>
                            {object.transaction.data?.document !== "" &&
                                object.transaction.data?.documentType.concat(" ", object.transaction.data?.document)}
                        </span>
                    </Table.Data>
                    <Table.Data align="left">
                        <span>
                            {object.transaction.data?.name !== "" &&
                                object.transaction.data?.name.concat(" ", object.transaction.data?.surname)}
                        </span>
                    </Table.Data>
                </Table.Row>
            );
        }

        const tableLabel = "table.requestCheckbook";

        return (
            <>
                <Heading.DataGroup
                    label="table.transferInternal.idTransaction"
                    data={<span>{object.transaction.idTransaction.substring(0, 6).toUpperCase()}</span>}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.account`}
                    data={<span>{cuenta}</span>}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.checkType`}
                    data={<span>{object.transaction.data?.checksTypes.label}</span>}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label="table.requestCheckbook.Sol"
                    data={object.transaction.data?.checkBooksAmount}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.checksAmount`}
                    data={object.transaction.data?.checkBookChecksAmount}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.document`}
                    data={
                        <span>
                            {object.transaction.data?.document !== "" &&
                                object.transaction.data?.documentType.concat(" ", object.transaction.data?.document)}
                        </span>
                    }
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.name`}
                    data={
                        <span>
                            {object.transaction.data?.name !== "" &&
                                object.transaction.data?.name.concat(" ", object.transaction.data?.surname)}
                        </span>
                    }
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
            </>
        );
    };

    accountInfo = async (account) => {
        const response = await getAccountInfo(account);
        const accountData = response.data.data.account;

        return accountData.number.concat(
            " ",
            accountData.productType,
            " ",
            i18n.get(`currency.label.${accountData.currency}`),
        );
    };

    render() {
        const { isDesktop } = this.props;
        const { body } = this.state;

        if (isDesktop) {
            return (
                <PageLoading loading={body === ""}>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="table.transferInternal.idTransaction" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.requestCheckbook.account" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.requestCheckbook.checkType" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.requestCheckbook.Sol" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.requestCheckbook.checksAmount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.requestCheckbook.document" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.requestCheckbook.name" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{body}</Table.Body>
                </PageLoading>
            );
        }

        return <Col className="col col-12 container-white py-2 my-2">{this.dataRow()}</Col>;
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(RequestChecbookDataTable);

import { call, put, takeLatest, take } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import * as depositsMiddleware from "middleware/deposits";
import { types } from "reducers/deposits";
import { downloadPdf, downloadXls } from "util/download";
import * as i18n from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as formActions } from "reducers/form";

import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(types.LIST_DEPOSITS_REQUEST, listDepositsRequest),
    takeLatest(types.DEPOSIT_READ_REQUEST, depositRead),
    takeLatest(types.DOWNLOAD_DEPOSITS_LIST_REQUEST, downloadList),
    takeLatest(types.DOWNLOAD_DEPOSIT_DETAIL_REQUEST, downloadDetail),
    takeLatest(types.SIMULATE_DEPOSIT_PREVIEW_REQUEST, simulateDepositPreview),
    takeLatest(types.SIMULATE_DEPOSIT_REQUEST, simulateDeposit),
    takeLatest(types.CONSTITUTE_DEPOSIT_REQUEST, constituteDeposit),
    takeLatest(types.CONFIRM_DEPOSIT_REQUEST, confirmDeposit),
];

export default sagas;

function* listDepositsRequest() {
    try {
        const response = yield call(depositsMiddleware.listDeposits);
        if (response && response.status === 200 && response.type !== "W") {
            const { depositsList, ARSTotalBalance, USDTotalBalance, serverDate } = response.data.data;
            yield put({
                type: types.LIST_DEPOSITS_SUCCESS,
                depositsList,
                ARSTotalBalance,
                USDTotalBalance,
                serverDate,
            });
        } else {
            yield put({ type: types.LIST_DEPOSITS_ERROR });
            yield put(notificationActions.showNotification(response.data.message, "error", ["deposits"]));
        }
    } catch (error) {
        yield put({ type: types.LIST_DEPOSITS_ERROR });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["deposits"]));
    }
}

function* depositRead({ idDeposit }) {
    const response = yield call(depositsMiddleware.readDeposit, idDeposit);

    if (response && response.status === 200) {
        yield put({
            type: types.DEPOSIT_READ_SUCCESS,
            ...response.data.data,
        });
    }
}

function* downloadList({ format }) {
    const { type, data } = yield call(depositsMiddleware.downloadList, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_DEPOSITS_LIST_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["deposit/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }
        yield put(notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["deposits"]));
        yield put({ type: types.DOWNLOAD_DEPOSITS_LIST_SUCCESS });
    }
}

function* downloadDetail({ idDeposit, format }) {
    const { type, data } = yield call(depositsMiddleware.downloadDetail, idDeposit, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_DEPOSIT_DETAIL_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["deposits/details"]),
        );
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_DEPOSIT_DETAIL_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["deposits/details"]),
        );
    }
}

function* simulateDepositPreview() {
    const { type, data } = yield call(depositsMiddleware.simulateDepositPreview);

    if (type === "W") {
        yield put({ type: types.SIMULATE_DEPOSIT_PREVIEW_FAILURE });

        const { NO_FIELD } = data.data;
        yield put(
            notificationActions.showNotification(
                NO_FIELD || i18n.get("global.unexpectedError"),
                NO_FIELD ? "warning" : "error",
                ["depositsConstitute"],
            ),
        );
    } else {
        const {
            minAmountPesos,
            minAmountDolares,
            holidays,
            depositTypes,
            environmentDate,
            defaultDaysQuantity,
        } = data.data;

        yield put({
            type: types.SIMULATE_DEPOSIT_PREVIEW_SUCCESS,
            minAmountPesos,
            minAmountDolares,
            holidays,
            depositTypes,
            environmentDate,
            defaultDaysQuantity,
        });
    }
}

function* simulateDeposit({ debitAccount, depositType, consolidatedAmount, termInDays, dueDate, formikBag }) {
    try {
        const { type, data } = yield call(
            depositsMiddleware.simulateDeposit,
            debitAccount,
            depositType,
            consolidatedAmount,
            termInDays,
            dueDate,
        );

        if (type === "W") {
            yield put({ type: types.SIMULATE_DEPOSIT_FAILURE });
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "warning", ["depositsConstitute"]));

            if (formikBag) {
                formikBag.setErrors(adjustIdFieldErrors(data.data));
            }
        } else {
            const {
                dueDate: dueDateReturnedValue,
                rate,
                capital,
                totalAmount,
                earnedInterests,
                requestCode,
                operationNumber,
                profitTaxCode,
                profitTax,
                witnessRate,
                codeRate,
                accountNumber,
            } = data.data;

            yield put({
                type: types.SIMULATE_DEPOSIT_SUCCESS,
                dueDate: dueDateReturnedValue,
                rate,
                capital,
                totalAmount,
                earnedInterests,
                requestCode,
                operationNumber,
                profitTaxCode,
                profitTax,
                witnessRate,
                codeRate,
                accountNumber,
                debitAccount: debitAccount.idProduct,
            });
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* constituteDeposit({
    debitAccount,
    depositType,
    consolidatedAmount,
    termInDays,
    dueDate,
    rate,
    capital,
    totalAmount,
    earnedInterests,
    automaticRenewal,
    actionToDue,
    requestCode,
    operationNumber,
    profitTaxCode,
    profitTax,
    witnessRate,
    codeRate,
    accountNumber,
    formikBag,
}) {
    const { type, data } = yield call(
        depositsMiddleware.constituteDeposit,
        debitAccount,
        depositType,
        consolidatedAmount,
        dueDate,
        termInDays,
        rate,
        automaticRenewal,
        requestCode,
        operationNumber,
        profitTaxCode,
        witnessRate,
        codeRate,
        accountNumber,
    );

    if (type === "W") {
        yield put({ type: types.CONSTITUTE_DEPOSIT_FAILURE });

        yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["depositsConstitute"]));

        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(data.data));
        }
    } else {
        const { dateAndHour, openDate, voucher } = data.data;

        yield put({
            type: types.CONSTITUTE_DEPOSIT_SUCCESS,
            debitAccount,
            depositType,
            consolidatedAmount,
            termInDays,
            dueDate,
            rate,
            capital,
            totalAmount,
            earnedInterests,
            automaticRenewal,
            actionToDue,
            requestCode,
            operationNumber,
            profitTaxCode,
            profitTax,
            witnessRate,
            codeRate,
            accountNumber,
            dateAndHour,
            voucher,
            openDate,
        });
        yield put(routerActions.push("/depositsSummary"));
    }
}

function* confirmDeposit({ summary, otp }) {
    const { formikBag } = summary;
    try {
        const response = yield call(depositsMiddleware.confirmDeposit, summary, otp);

        if (response.type === "W") {
            if (["COR020W"].includes(response.data.code)) {
                formikBag.setErrors(response.data.data);
                yield put(notificationActions.showNotification(response.data.message, "error", ["depositsSummary"]));
            } else if (["COR054W", "API524W"].includes(response.data.code)) {
                yield put(notificationActions.showNotification(response.data.message, "error", ["depositsSummary"]));
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("deposits.confirmation.error.message"), "error", [
                        "depositsConstitute",
                    ]),
                );

                yield put(routerActions.push("/depositsConstitute"));
            }
            yield put({ type: types.CONFIRM_DEPOSIT_FAILURE });
        } else {
            yield put({
                type: types.CONFIRM_DEPOSIT_SUCCESS,
                summary,
                idTransaction: response.data.data.idTransaction,
            });

            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.data.idTransaction}` }));

            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import { actions as formActions } from "reducers/form";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import { selectors as debinSelectors } from "reducers/debin";
import RecurrencyTransactionData from "pages/debinPayments/_components/recurrentDebin/RecurrencyTransactionData";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";

const FORM_ID = "debin.recurrency";
class RecurrencyTransaction extends Component {
    static propTypes = {
        recurrencyAction: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
    };

    state = {
        isModalDisplayed: false,
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { dispatch, recurrencyAction } = this.props;
        if (!recurrencyAction.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    centerContentMobile = (title) => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 px-2">{i18n.get(title)}</h1>
        </div>
    );

    summaryDataComponent = () => <RecurrencyTransactionData />;

    signTransaction = () => {
        const { dispatch, recurrencyAction } = this.props;

        const signTransactionData = this.summaryDataComponent();

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: recurrencyAction?.idTransaction,
                idActivity: recurrencyAction.idActivity,
                idForm: "",
                content: signTransactionData,
                title: `debin.recurrency.transaction.${recurrencyAction?.action?.toLowerCase()}.title`,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: recurrencyAction.idActivity,
                idTransaction: recurrencyAction.idTransaction,
                idForm: "",
                ticketData: recurrencyAction,
            }),
        );
    };

    rightContentTransactionScheme = () => {
        const { recurrencyAction, isDesktop } = this.props;

        if (recurrencyAction?.idTransactionStatus === "FINISHED") {
            return (
                <ContextMenu
                    isDesktop={isDesktop}
                    buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                    items={[
                        {
                            label: "global.download",
                            onClick: this.exportPdf,
                        },
                    ]}
                />
            );
        }

        return (
            <>
                {recurrencyAction?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { recurrencyAction } = this.props;

        const ticketData = {
            ticketName: i18n.get(`debin.recurrency.transaction.${recurrencyAction?.action?.toLowerCase()}.title`),
            ticketBody: [
                getGeneralTicketItem(
                    "negotiate.transaction.date.label",
                    recurrencyAction?.creationDate,
                    "datefulltime",
                ),
                getGeneralTicketItem(
                    `payments.debin.detail.table.header.voucher`,
                    recurrencyAction?.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.account.title`, recurrencyAction?.accountFull, "string"),
                getGeneralTicketItem(`${FORM_ID}.seller.name.title`, recurrencyAction?.sellerInfo?.titular, "string"),
                getGeneralTicketItem(`${FORM_ID}.seller.cuit.title`, recurrencyAction?.seller, "string"),
                getGeneralTicketItem(`${FORM_ID}.prestacion.title`, recurrencyAction?.prestacion, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.concept.title`,
                    recurrencyAction?.concepto
                        ? `${recurrencyAction?.concepto} - ${i18n.get(
                              `transfers.concept.${recurrencyAction?.concepto}`,
                          )}`
                        : "",
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.reference.title`, recurrencyAction?.detalle, "string"),
                getGeneralTicketItem(`${FORM_ID}.descripcion.title`, recurrencyAction?.referencia, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.response`, recurrencyAction?.coelsaResponse, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.code`, recurrencyAction?.coelsaCode, "string"),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch, recurrencyAction } = this.props;

        if (recurrencyAction) {
            const { idTransactionStatus } = recurrencyAction;
            if (idTransactionStatus !== "PENDING") {
                return [
                    {
                        label: "global.goToHome",
                        bsStyle: "primary",
                        onClick: () => dispatch(push("/desktop")),
                    },
                ];
            }
        }
        return [];
    };

    render() {
        const { recurrencyAction, isDesktop, dispatch, isSmallDesktop } = this.props;
        const { isModalDisplayed, showDrawerMobile, fetchingDownload } = this.state;

        const title = `debin.recurrency.transaction.${recurrencyAction?.action?.toLowerCase()}.title`;

        return (
            <>
                {recurrencyAction?.idTransaction && (
                    <TransactionTicket
                        notificationScope="echeqs"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={recurrencyAction?.idTransactionStatus === "FINISHED"}
                        rightContent={
                            recurrencyAction?.idTransactionStatus === "PENDING" && this.rightContentTransactionScheme
                        }
                        emptyRightOption={
                            (isDesktop && recurrencyAction?.idTransactionStatus !== "FINISHED") ||
                            (!isDesktop &&
                                recurrencyAction?.idTransactionStatus !== "PENDING" &&
                                recurrencyAction?.idTransactionStatus !== "FINISHED")
                        }
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={!isDesktop && (() => this.centerContentMobile(title))}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={recurrencyAction}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        onSignTicket={this.signTransaction}
                        ignoreHomeButton
                        summaryTitle={title}
                        isFetchingExport={fetchingDownload}>
                        <ModifyPendingTransactionModal
                            isDesktop={isDesktop}
                            isDisplayed={isModalDisplayed}
                            handleDismiss={this.handleDismiss}
                            dispatch={dispatch}
                            idTransaction={recurrencyAction.idTransaction}
                        />
                        <RecurrencyTransactionData isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    recurrencyAction: debinSelectors.getRecurrencyDebinAction(state),
});

export default connect(mapStateToProps)(RecurrencyTransaction);

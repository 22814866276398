/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { func, string, shape, arrayOf, bool } from "prop-types";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux/actions";
import Col from "react-bootstrap/lib/Col";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import TokenDisconectModal from "pages/_components/token/TokenDisconectModal";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import { selectors as sessionSelectors } from "reducers/session";
import classNames from "classnames";

class TokenRandomCode extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        tokenNumber: string.isRequired,
        loggedUser: shape({
            previousLoginInfo: arrayOf(
                shape({
                    idUser: string,
                }),
            ),
        }).isRequired,
        exchangeToken: string.isRequired,
        isDesktop: bool.isRequired,
        fetchingToken: bool.isRequired,
        propsGrid: shape({}).isRequired,
    };

    componentDidMount = () => {
        const { dispatch, exchangeToken } = this.props;
        dispatch(tokenActions.tokenPasswordGenerationRequest(exchangeToken));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/tokenPassword"));
    };

    centerContentMobile = () => (
        <div className="justify-content-center">
            <h1 className="m-0 text-white">{i18n.get("settings.tokenPassword")}</h1>
        </div>
    );

    codeActivation = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/codeActivation"));
    };

    showCode = () => {
        const { tokenNumber, fetchingToken } = this.props;
        const number = `${tokenNumber.substring(0, 3)} ${tokenNumber.substring(3)}`;
        return (
            <p
                className={classNames(
                    "f-size-0 color-black m-0",
                    { fadein: !fetchingToken },
                    { fadeout: fetchingToken },
                )}>
                {number}
            </p>
        );
    };

    loading = () => {
        const { fetchingToken } = this.props;
        return (
            <I18n
                id="settings.token.randomCode.loading"
                component="p"
                componentProps={{
                    className: classNames(
                        "f-size-4 color-black m-0",
                        { fadein: fetchingToken },
                        { fadeout: !fetchingToken },
                    ),
                }}
            />
        );
    };

    updateCode = () => {
        const { dispatch, exchangeToken } = this.props;
        dispatch(tokenActions.tokenPasswordGenerationRequest(exchangeToken));

        // repeat animation in 0.5 seconds
        return [true, 500];
    };

    exit = () => {
        const { dispatch } = this.props;
        dispatch(replace("/desktop"));
    };

    render() {
        const { isDesktop, propsGrid, loggedUser, fetchingToken } = this.props;
        const expirationTime = parseInt(configUtils.get("otp.token.expiration.time"), 10);

        return (
            <GridLayoutExternal scopeToShow="tokenCreateCodeInvitation" isDesktop={isDesktop} {...propsGrid}>
                <div className="admin-detail-head px-0 mb-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName="blue-main-header-mobile"
                        centerContentClassName="mx-65"
                        centerElement={this.centerContentMobile}
                        emptyRightOption
                    />
                </div>
                <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                    <div className="admin-content-center pt-3">
                        <I18n id="settings.tokenPassword.randomCode.title.label" />
                    </div>
                    <div className="admin-content-center pt-5 font-bold">
                        <I18n id="settings.tokenPassword.randomCode.label" />
                    </div>
                    <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                        <div className="coutdowncircletimer">
                            <CountdownCircleTimer
                                isPlaying={!fetchingToken}
                                duration={expirationTime}
                                trailColor={[["#18e2a7"]]}
                                colors={[["#d9d9d9"]]}
                                size={260}
                                strokeLinecap="square"
                                strokeWidth={9}
                                onComplete={() => this.updateCode()}>
                                {!fetchingToken ? this.showCode : this.loading()}
                            </CountdownCircleTimer>
                        </div>
                        <div className="admin-content-center">
                            <Button
                                onClick={this.exit}
                                bsStyle="primary"
                                label="global.exit"
                                className="btn-uppercase"
                            />
                        </div>
                    </Col>
                </Col>
                {!loggedUser ? <TokenDisconectModal /> : undefined}
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    tokenNumber: tokenSelectors.getTokenNumber(state),
    loggedUser: sessionSelectors.getUser(state),
    exchangeToken: tokenSelectors.getExchangeToken(state),
    fetchingToken: tokenSelectors.getFetchingToken(state),
});

export default connect(mapStateToProps)(TokenRandomCode);

import { call, put, takeLatest, take } from "redux-saga/effects";
import { types } from "reducers/thirdPayment";
import { push } from "react-router-redux";
import { actions as formActions } from "reducers/form";
import {
    validateThirdPayment,
    loadListRequest,
    downloadTransactionLinestMiddleware,
    downloadTransactionFiletMiddleware,
    downloadListRequestMiddleware,
    downloadConfirmation,
} from "middleware/thirdPayment";

import * as i18n from "util/i18n";
import * as form from "middleware/form";
import { downloadPdf, downloadXls } from "util/download";
import { actions as notificationActions } from "reducers/notification";

const sagas = [
    takeLatest(types.CREATE_THIRDPAYMENT_REQUEST, validateThirdPay),
    takeLatest(types.SUMMARY_THIRDPAYMENT_REQUEST, summaryThirdPay),
    takeLatest(types.LOAD_LIST_REQUEST, loadListThirdRequest),
    takeLatest(types.LOAD_TRANSACTION_REQUEST, loadTransactionRequest),
    takeLatest(types.DOWNLOAD_TRANSACTION_LINE_REQUEST, downloadTransactionLines),
    takeLatest(types.DOWNLOAD_TRANSACTION_FILE_REQUEST, downloadFileTransactionRequest),
    takeLatest(types.DOWNLOAD_LIST_REQUEST, downloadListRequest),
    takeLatest(types.DOWNLOAD_CONFIRMATION_REQUEST, downloadConfirm),
    takeLatest(types.LOAD_MORE_LIST_REQUEST, loadMoreListThirdRequest),
];

export default sagas;

function* summaryThirdPay() {
    yield put({
        type: types.SUMMARY_THIRDPAYMENT_SUCCESS,
    });
    yield put(push(`/payThirdParties/summary`));
}

function* validateThirdPay({ summary, otp }) {
    const {
        file,
        account,
        typePayment,
        dateProcess,
        description,
        formikBag,
        processedFileData,
        pendingLines,
    } = summary;

    try {
        const response = yield call(
            validateThirdPayment,
            file,
            description,
            typePayment,
            dateProcess,
            account,
            otp,
            processedFileData,
            pendingLines,
        );

        if (response.type === "W") {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["third"]));
            }
        } else {
            const transactionResponse = yield call(form.readTransaction, response.data.idTransaction);
            const { idTransactionStatus } = transactionResponse.data.data?.transaction;
            if (idTransactionStatus === "PENDING") {
                yield put(
                    notificationActions.showNotification(i18n.get("thirdpayment.send.pending.message"), "success", [
                        "forms",
                    ]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("thirdpayment.send.success.message"), "success", [
                        "forms",
                    ]),
                );
            }

            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* loadListThirdRequest({ filters }) {
    const response = yield call(loadListRequest, filters);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["third"]));
        yield put({ type: types.LOAD_LIST_FAILURE });
    } else {
        const { typePayment, latestTotalPages, latestPageNumber: newLatestMovementsPageNumber } = response.data.data;
        yield put({
            type: types.LOAD_LIST_SUCCESS,
            typeSelect: typePayment === "PP",
            transactionLinesStatus: response.data.data.transactionLinesStatus,
            latestTotalPages,
            latestPageNumber: newLatestMovementsPageNumber,
        });
    }
}

function* loadMoreListThirdRequest({ filters }) {
    const response = yield call(loadListRequest, filters);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["third"]));
        yield put({ type: types.LOAD_MORE_LIST_FAILURE });
    } else {
        const { latestTotalPages, latestPageNumber: newLatestMovementsPageNumber } = response.data.data;
        yield put({
            type: types.LOAD_MORE_LIST_SUCCESS,
            transactionLinesStatus: response.data.data.transactionLinesStatus,
            latestTotalPages,
            latestPageNumber: newLatestMovementsPageNumber,
        });
    }
}

function* loadTransactionRequest({ idTransaction }) {
    const response = yield call(form.readTransaction, idTransaction);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["form"]));
        yield put({ type: types.LOAD_TRANSACTION_FAILURE });
    } else {
        const creatorFullName = response.data.data?.transaction.userCreatorFirstName.concat(
            " ",
            response.data.data?.transaction.userCreatorLastName,
        );
        const creationDate = response.data.data?.transaction.creationDateTime;
        const { signatures, pendingDispatch, modificationDateTime } = response.data.data?.transaction;
        const userEditorFullName = response.data.data?.transaction.userEditorFirstName.concat(
            " ",
            response.data.data?.transaction.userEditorLastName,
        );
        const { data, idTransactionStatus, totalAmount } = response.data.data.transaction;
        const { file, typePayment, dateProcess, reference, debitAccount, cuit, pendingLines, amount } = data;
        yield put({
            type: types.LOAD_TRANSACTION_SUCCESS,
            file,
            typePayment,
            dateProcess,
            reference,
            debitAccount,
            cuit,
            idTransactionStatus,
            totalAmount,
            transactionOrder: pendingLines,
            signatures,
            pendingDispatch,
            modificationDateTime,
            userEditorFullName,
            creatorFullName,
            creationDate,
            amount,
        });
    }
}
function* downloadTransactionLines({ format, transactionLines }) {
    const { data } = yield call(downloadTransactionLinestMiddleware, format, transactionLines);

    const { content, fileName } = data.data;

    downloadPdf(fileName, content);
}
function* downloadListRequest({ format, transactionLinesStatus }) {
    const { data, type } = yield call(downloadListRequestMiddleware, format, transactionLinesStatus);
    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LIST_FAILURE });
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }
        yield put({ type: types.DOWNLOAD_LIST_SUCCESS });
    }
}

function* downloadFileTransactionRequest({
    format,
    transactionLines,
    typePayment,
    fileNam,
    id,
    reference,
    dateProcess,
    transactionOrder,
    currency,
    idTransactionStatus,
    accountName,
    transactionStatusName,
    isDetailDownload,
}) {
    const { data, type } = yield call(
        downloadTransactionFiletMiddleware,
        format,
        transactionLines,
        typePayment,
        fileNam,
        id,
        reference,
        dateProcess,
        transactionOrder,
        currency,
        idTransactionStatus,
        accountName,
        transactionStatusName,
        isDetailDownload,
    );

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_TRANSACTION_FILE_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_TRANSACTION_FILE_SUCCESS });
    }
}

function* downloadConfirm({ summary }) {
    const { type, data } = yield call(downloadConfirmation, summary);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_CONFIRMATION_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_CONFIRMATION_SUCCESS });
    }
}

import React, { Component, createRef } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Spring } from "react-spring";
import { shape, bool, string, func } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import moment from "moment";

import { actions as formsActions, selectors as formsSelectors } from "reducers/form";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import FormSignatures from "pages/forms/_components/FormSignatures";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import TransactionTicketCancelConfirmation from "pages/forms/_components/TransactionTicketCancelConfirmation";
import * as schedulerUtils from "util/scheduler";
import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FormattedAmount from "pages/_components/FormattedAmount";
import { getAccountDescription } from "util/accounts";
import FormattedDate from "pages/_components/FormattedDate";
import MultipleTransferTicketData from "pages/transfer/multiple/MultipleTransferTicketData";

const TRANSFER_KIND = [
    {
        value: `same`,
        label: "transfers.titular.same.label",
    },
    {
        value: `other`,
        label: "transfers.titular.other.label",
    },
];

class TransactionTicketBackoffice extends Component {
    state = {
        showDetails: false,
    };

    transactionTicketCancelSection = createRef();

    static propTypes = {
        // tells if the form is being rendered from Backoffice
        isCancelling: bool,
        transaction: shape({
            programed: bool,
            idTransaction: string,
            submitDateTimeAsString: string,
            idTransactionStatus: string,
        }),
        formActions: shape({}),
        // tells if we are rendering to confirm a recent transaction
        dispatch: func,
    };

    static defaultProps = {
        isCancelling: false,
        transaction: {},
        formActions: null,
        dispatch: () => {},
    };

    componentDidUpdate() {
        if (this.transactionTicketCancelSection && this.transactionTicketCancelSection.scrollIntoView) {
            this.transactionTicketCancelSection.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(formsActions.formClosed());
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    getCuitDescription = (clientUser) => {
        if (clientUser?.cuil !== undefined) {
            return ` ${i18nUtils.get(`transferSummary.cuil.input.label`)} ${clientUser?.cuil}`;
        }
        return ` ${i18nUtils.get(`transferSummary.cuit.input.label`)} ${clientUser?.cuit}`;
    };

    renderFieldContent = (label, value, rowClassName = "") => (
        <Row style={{ justifyContent: "space-between" }} className={rowClassName}>
            <FieldLabel labelKey={label} />
            <span className="data-desc">{value}</span>
        </Row>
    );

    getEcheqType = (type) => i18nUtils.get(`echeqs.emit.${type.toLowerCase()}.label`);

    renderEcheqs = () => {
        const {
            transaction: { idTransactionStatus, data },
        } = this.props;
        const echeqsGeneralData = JSON.parse(data?.echeqNumeration);
        const formEcheq = "echeq.transaction";

        return echeqsGeneralData?.map((echeq) => (
            <>
                {echeq?.chequera && this.renderFieldContent(`${formEcheq}.checkbook`, echeq?.chequera, "mt-4")}
                {echeq?.cheque_numero && this.renderFieldContent(`${formEcheq}.echeq.number`, echeq?.cheque_numero)}
                {echeq?.cheque_numero &&
                    this.renderFieldContent(
                        `${formEcheq}.check.amount.label`,
                        <FormattedAmount currency="0" quantity={parseFloat(data?.echeqsToEmit[0]?.amount)} notBold />,
                    )}

                {idTransactionStatus === "FINISHED" && (
                    <>
                        {data?.emittedEcheqs &&
                            this.renderFieldContent(
                                `${formEcheq}.echeq.id`,
                                data?.emittedEcheqs ? data?.emittedEcheqs[echeq?.cheque_numero] : "",
                            )}
                        {echeq?.cmc7 && this.renderFieldContent(`${formEcheq}.echeq.cmc7`, echeq?.cmc7)}
                    </>
                )}
            </>
        ));
    };

    renderMultipleTransferData = (data) => {
        const {
            batchId,
            account: debitAccount,
            description,
            creationDateTime,
            executionDate,
            fileName,
            quantity,
            amount,
        } = data;
        return {
            id: {
                value: batchId,
                format: "string",
            },
            debitAccount: {
                value: debitAccount?.number !== undefined && getAccountDescription(debitAccount),
                format: "string",
            },
            reference: {
                value: description,
                format: "string",
            },
            uploadDate: {
                value: creationDateTime,
                format: "datetime",
            },
            executionDate: {
                value: executionDate,
                format: "date",
            },
            fileName: {
                value: fileName,
                format: "string",
            },
            quantity: {
                value: quantity,
                format: "string",
            },
            amount: {
                value: amount?.quantity,
                format: `amount${i18nUtils.get(`currency.label.${amount?.currency || "ARS"}`)}`,
            },
        };
    };

    renderFields = () => {
        const {
            dispatch,
            transaction: { creationDateTime, idTransactionStatus, environmentName, idActivity, idUserCreator, data },
        } = this.props;
        // CUSTODY|NEGOTIATE|DEPOSIT|RESCUE
        const scheduledCancel = idActivity === "scheduled.transactions.cancel";
        const isTransfer = idActivity.match("transfers") != null;
        const amount = data?.amountToShow || data?.amount;
        const amountValue = amount?.quantity || data?.amount;
        const currencyValue = data?.currency?.toString() || (amount?.currency ? amount.currency.toString() : "");
        const echeqModel = data?.echeqList ? data?.echeqList[0] : null;
        if (idActivity === "transfer.multiple.send") {
            return (
                <MultipleTransferTicketData
                    isDesktop
                    dispatch={dispatch}
                    isBackoffice
                    data={this.renderMultipleTransferData({ ...data, idTransactionStatus })}
                    batch={{ ...data, idTransactionStatus, idUser: idUserCreator }}
                />
            );
        }
        return (
            <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                {scheduledCancel &&
                    data?.idTransactionToCancel &&
                    this.renderFieldContent(
                        "scheduled.transactions.cancel.idTransactionToCancel.label",
                        data?.idTransactionToCancel?.substring(0, 8).toUpperCase(),
                    )}
                {isTransfer &&
                    idTransactionStatus !== "PENDING" &&
                    environmentName &&
                    this.renderFieldContent("transferSummary.payer.label", environmentName)}
                {data?.debitAccount &&
                    data?.debitAccount?.number &&
                    this.renderFieldContent(
                        "transfers.debitAccount.label",
                        data?.debitAccount?.number !== undefined && getAccountDescription(data?.debitAccount),
                    )}
                {(data?.creditAccount?.number || data?.accountLabel || data?.cbu) &&
                    this.renderFieldContent(
                        "transfers.creditAccount.label",
                        data?.creditAccount?.number
                            ? getAccountDescription(data?.creditAccount)
                            : data?.accountLabel || (data?.cbu !== undefined && data?.cbu.slice(-14, -1)),
                    )}
                {data?.echeqsToEmit &&
                    data?.emision &&
                    this.renderFieldContent("echeqs.emit.emision.label", data?.emision && data?.emision.slice(-14, -1))}
                {(data?.echeqsToEmit || data?.echeqList) &&
                    data?.beneficiaryName &&
                    this.renderFieldContent("echeq.transaction.beneficiary", data?.beneficiaryName)}
                {data?.echeqsToEmit &&
                    data?.beneficiary &&
                    this.renderFieldContent("echeq.transaction.beneficiary.document", data?.beneficiary)}
                {data?.echeqList &&
                    data?.type &&
                    this.renderFieldContent(
                        "echeq.transaction.endorse",
                        i18nUtils.get(`echeqs.${data.type.toLowerCase()}.label`),
                    )}
                {data?.echeqsToEmit &&
                    data?.motive &&
                    this.renderFieldContent("echeq.transaction.motive", data?.motive)}
                {data?.echeqsToEmit && data?.mode && this.renderFieldContent("echeq.transaction.mode", data?.mode)}
                {data?.echeqsToEmit &&
                    data?.character &&
                    this.renderFieldContent("echeq.transaction.echeq.character", data?.character)}
                {data?.echeqsToEmit &&
                    idTransactionStatus === "FINISHED" &&
                    this.renderFieldContent(
                        "echeq.transaction.emision.date",
                        <FormattedDate date={moment(creationDateTime)} dateFormat="dd/MM/yyyy" />,
                    )}
                {data?.echeqsToEmit &&
                    data?.echeqsToEmit.length > 0 &&
                    this.renderFieldContent(
                        "echeq.transaction.payed",
                        <FormattedDate date={moment(data?.echeqsToEmit[0]?.date)} dateFormat="dd/MM/yyyy" />,
                    )}

                {data?.echeqsToEmit &&
                    data?.echeqsToEmit.length > 0 &&
                    data?.echeqsToEmit[0]?.type &&
                    this.renderFieldContent(
                        "echeq.transaction.echeq.type",
                        this.getEcheqType(data?.echeqsToEmit[0]?.type),
                    )}
                {data?.echeqsToEmit &&
                    data?.echeqsToEmit.length > 0 &&
                    this.renderFieldContent("echeq.transaction.reference", data?.echeqsToEmit[0]?.reference)}
                {data?.echeqsToEmit &&
                    data?.echeqsToEmit.length > 0 &&
                    data?.echeqsToEmit[0]?.referencevalue &&
                    this.renderFieldContent("echeq.transaction.value.reference", data?.echeqsToEmit[0]?.referencevalue)}
                {data?.echeqsToEmit &&
                    (data?.echeqsQuantity || data?.echeqsToEmit.length > 0) &&
                    this.renderFieldContent(
                        "echeq.transaction.echeq.quantity",
                        data?.echeqsQuantity || data?.echeqsToEmit.length,
                    )}
                {!data?.internalTransfer &&
                    !scheduledCancel &&
                    data?.transferKind &&
                    this.renderFieldContent(
                        "transfers.transferKind.label",
                        i18nUtils.get(TRANSFER_KIND.find(({ value }) => value === data?.transferKind)?.label),
                    )}
                {!data?.internalTransfer &&
                    !scheduledCancel &&
                    data?.creditAccountDetail?.cbu &&
                    this.renderFieldContent("transferSummary.cbuOrCvu.label", data?.creditAccountDetail?.cbu)}
                {!data?.isInternalPending &&
                    (data?.clientUser?.cuit || data?.clientUser?.cuil) &&
                    this.renderFieldContent("transferSummary.cuit.label", this.getCuitDescription(data?.clientUser))}
                {!data?.internalTransfer &&
                    data?.creditAccountDetail?.cuit &&
                    this.renderFieldContent(
                        "transferSummary.alias.label",
                        data?.clientUser?.cuit || data?.clientUser?.cuil,
                    )}
                {!data?.internalTransfer &&
                    !scheduledCancel &&
                    data?.clientUser?.firstName &&
                    this.renderFieldContent("transferSummary.owner.label", data?.clientUser?.firstName)}
                {!data?.isInternalPending &&
                    data?.creditAccountDetail?.bankName &&
                    this.renderFieldContent("transferSummary.bank.label", data?.creditAccountDetail?.bankName)}
                {data?.amount &&
                    this.renderFieldContent(
                        "transaction.amount.label",
                        <FormattedAmount currency={currencyValue} quantity={amountValue} notBold />,
                    )}
                {!data?.internalTransfer &&
                    data?.concept &&
                    !scheduledCancel &&
                    this.renderFieldContent(
                        "transfers.concept.label",
                        `${data?.concept} - ${i18nUtils.get(`transfers.concept.${data?.concept}`)}`,
                    )}
                {!scheduledCancel &&
                    data?.reference &&
                    this.renderFieldContent("transfers.reference.label", data?.reference)}
                {!scheduledCancel &&
                    data?.referencevalue &&
                    this.renderFieldContent("echeq.transaction.value.reference", data?.referencevalue)}
                {data?.sendEmail && data?.email && this.renderFieldContent("transfers.email.label", data?.email)}
                {data?.sendEmail &&
                    data?.email &&
                    data?.emailText &&
                    this.renderFieldContent("transfers.emailText.label", data?.emailText)}

                {echeqModel &&
                    echeqModel?.cheque_numero &&
                    this.renderFieldContent("echeq.transaction.echeq.number", echeqModel?.cheque_numero)}
                {echeqModel &&
                    echeqModel?.cheque_id &&
                    this.renderFieldContent("echeq.transaction.echeq.id", echeqModel?.cheque_id)}
                {echeqModel &&
                    echeqModel?.cheque_numero &&
                    this.renderFieldContent(
                        "echeq.transaction.emision",
                        <FormattedDate date={echeqModel?.fecha_emision} dateFormat="dd/MM/yyyy" />,
                    )}
                {echeqModel &&
                    echeqModel?.fecha_pago &&
                    this.renderFieldContent(
                        "echeq.transaction.payed",
                        <FormattedDate date={echeqModel?.fecha_pago} dateFormat="dd/MM/yyyy" />,
                    )}
                {echeqModel &&
                    echeqModel?.cheque_tipo &&
                    this.renderFieldContent("echeq.transaction.echeq.type", this.getEcheqType(echeqModel?.cheque_tipo))}
                {echeqModel &&
                    echeqModel?.cheque_caracter &&
                    this.renderFieldContent("echeq.transaction.echeq.character", echeqModel?.cheque_caracter)}
                {echeqModel &&
                    echeqModel?.cheque_modo &&
                    this.renderFieldContent(
                        "echeq.transaction.mode",
                        i18nUtils.get(`echeqs.${echeqModel.cheque_modo}.modo`),
                    )}
                {echeqModel &&
                    echeqModel?.cheque_motivo_pago &&
                    this.renderFieldContent("echeq.transaction.motive", echeqModel?.cheque_motivo_pago)}

                {data?.echeqNumeration && this.renderEcheqs()}

                {(data?.echeqsToEmit || data?.echeqList) &&
                    data?.coelsaResponse &&
                    this.renderFieldContent("echeq.transaction.coelsa.response", data?.coelsaResponse)}
                {(data?.echeqsToEmit || data?.echeqList) &&
                    data?.coelsaCode &&
                    this.renderFieldContent("echeq.transaction.coelsa.code", data?.coelsaCode)}
            </Col>
        );
    };

    render() {
        const { formActions, isCancelling, transaction } = this.props;
        const { idTransaction, submitDateTimeAsString } = transaction;
        const { showDetails } = this.state;
        const selectedOption = transaction.data.scheduler ? transaction.data.scheduler.selectedOption : null;

        const scheduled = selectedOption ? selectedOption !== schedulerUtils.TODAY : false;

        return (
            <>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout align-items-center " gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Col
                                    sm={12}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    className="col col-12 container-white"
                                    style={{ paddingBottom: "2rem" }}>
                                    <Container
                                        className="container--layout align-items-center"
                                        gridClassName="form-content">
                                        <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                            <Row>
                                                <I18n
                                                    component="h2"
                                                    id="forms.transaction.ticket"
                                                    componentProps={{ className: "font-size-28-px" }}
                                                />
                                            </Row>
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <FieldLabel labelKey="forms.transaction.ticket.date" />
                                                <span className="data-date">{submitDateTimeAsString}</span>
                                            </Row>
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <FieldLabel labelKey="forms.transaction.ticket.number" />
                                                <span className="data-desc">
                                                    {idTransaction.substring(0, 8).toUpperCase()}
                                                </span>
                                            </Row>
                                        </Col>
                                    </Container>
                                    <Spring
                                        from={{ height: 0 }}
                                        to={{ height: showDetails ? "auto" : 0, overflow: "hidden" }}>
                                        {() => (
                                            <Container
                                                className="container--layout align-items-center"
                                                gridClassName="form-content">
                                                {this.renderFields()}
                                                {scheduled && (
                                                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                                        <Row style={{ justifyContent: "space-between" }}>
                                                            <FieldLabel labelKey="forms.confirmation.scheduler" />
                                                            <Message value={transaction.data.scheduler} />
                                                        </Row>
                                                    </Col>
                                                )}
                                                <FormSignatures transaction={transaction} />
                                            </Container>
                                        )}
                                    </Spring>
                                </Col>
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Col sm={12} md={10} lg={10} xl={10} className="col col-12" style={{ display: "flex" }}>
                                    {formActions}
                                </Col>
                            </Col>
                        </Container>

                        {isCancelling && (
                            <Container
                                ref={(node) => {
                                    this.transactionTicketCancelSection = node;
                                }}
                                className="container--layout align-items-center flex-grow"
                                gridClassName="form-content">
                                {/* <section
                                ref={(node) => {
                                    this.transactionTicketCancelSection = node;
                                }}
                                className="container--layout align-items-center flex-grow"> */}
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <Col sm={12} md={10} lg={10} xl={10} className="col col-12">
                                        <TransactionTicketCancelConfirmation idTransaction={idTransaction} />
                                    </Col>
                                </Col>
                            </Container>
                        )}
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isCancelling: formsSelectors.getIsCancellingTransaction(state),
});

export default connect(mapStateToProps)(TransactionTicketBackoffice);

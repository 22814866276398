/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, shape } from "prop-types";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedAmount from "pages/_components/FormattedAmount";
import { getAccountInfo } from "middleware/accounts";
import PageLoading from "pages/_components/PageLoading";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";

class TransferExternalDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        body: "",
    };

    componentDidMount = () => {
        this.tableBody();
    };

    tableBody = () => {
        const { multipleSignaturesData } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        const bodyPromises = selectedTransactionRows.map((object) => {
            const { debitAccount } = object.transaction.data;
            return this.accountInfo(debitAccount);
        });

        Promise.all(bodyPromises).then((values) => {
            this.setState({
                body: selectedTransactionRows.map((object, index) => this.dataRow(object, values[index])),
            });
        });
    };

    dataRow = (object, account) => {
        const { isDesktop } = this.props;
        const [currency] = Object.keys(object.transactionAmounts);
        const amount = object.transactionAmounts[currency];
        if (isDesktop) {
            return (
                <Table.Row>
                    <Table.Data align="left">
                        <span>{object.transaction.idTransaction.substring(0, 8).toUpperCase()}</span>
                    </Table.Data>
                    <Table.Data align="left">
                        <span>{account}</span>
                    </Table.Data>
                    <Table.Data align="left">{`${object.transaction.data.concept} - ${i18n.get(
                        `transfers.concept.${object.transaction.data.concept}`,
                    )}`}</Table.Data>
                    <Table.Data align="right">
                        <FormattedAmount currency={currency} quantity={amount} />
                    </Table.Data>
                    <Table.Data align="right">
                        <span>{object.transaction.data.cbu}</span>
                    </Table.Data>
                </Table.Row>
            );
        }

        const tableLabel = "table.transferInternal";

        return (
            <Col className="col col-12 container-white py-2 my-2">
                <Heading.DataGroup
                    label="table.transferInternal.idTransaction"
                    data={<span>{object.transaction.idTransaction.substring(0, 8).toUpperCase()}</span>}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.debitAccount`}
                    data={<span>{account}</span>}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label="table.transferExternal.concept"
                    data={`${object.transaction.data.concept} - ${i18n.get(
                        `transfers.concept.${object.transaction.data.concept}`,
                    )}`}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.amount`}
                    data={<FormattedAmount currency={currency} quantity={amount} />}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label="table.transferExternal.cbu"
                    data={<span>{object.transaction.data.cbu}</span>}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
            </Col>
        );
    };

    accountInfo = async (debit) => {
        const responseDebit = await getAccountInfo(debit);

        const accountDebit = responseDebit.data.data.account;

        return accountDebit.number.concat(
            " ",
            accountDebit.productType,
            " ",
            i18n.get(`currency.label.${accountDebit.currency}`),
        );
    };

    render() {
        const { body } = this.state;
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <PageLoading loading={body === ""}>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="table.transferInternal.idTransaction" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.transferInternal.debitAccount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.transferExternal.concept" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="table.transferInternal.amount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="table.transferExternal.cbu" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{body}</Table.Body>
                </PageLoading>
            );
        }

        return body;
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(TransferExternalDataTable);

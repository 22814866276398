import React, { useEffect, useState } from "react";
import { Field, Form, withFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import moment from "moment";
import Yup from "yup";
import { compose } from "redux";
import { connect } from "react-redux";
import { isNil } from "lodash";

import * as configUtils from "util/config";
import * as schedulerUtils from "util/scheduler";
import * as i18n from "util/i18n";
import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as holidaysSelector } from "reducers/holidays";
import { toNumber } from "util/number";
import { getProductLabel, isEnabledMvp } from "util/fundcorp";
import { downloadUrl } from "util/download";
import { getCurrencyIso } from "util/cbuCmf";

import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import AmountField from "pages/_components/fields/formik/AmountField";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import { LEVEL_INVESTOR_PROFILE } from "constants.js";
import SelectFund from "./SelectFund";
import SelectedFundInfo from "./SelectedFundInfo";
import useFundConfigParam from "../hooks/useFundConfigParam";
import DebitAccountInfo from "./DebitAccountInfo";
import SubscribeMinAmount from "./SubscribeMinAmount";
import useUpdateFormValue from "../hooks/useUpdateFormValue";
import TermsAndCoditionsLink from "./TermsAndCoditionsLink";
import InfoRiskLevelModal from "./InfoRiskLevelModal";
import AssociateAccountModal from "./AssociateAccountModal";
import FundSubscribeScheduler from "./FundSubscribeScheduler";

const FORM_ID = "fund.operation.subscribe";

function SubscribeForm({
    isDesktop,
    activeEnvironment,
    location,
    openModal,
    selectedFundFeature,
    deselectFundFeature,
    shareAccounts,
    values,
    setFieldValue,
    fetching,
    toggleModalAssociate,
    setShareaccount,
    modalRiskLevelVisible,
    toggleModalRiskLevel,
    handleReset,
    accounts,
    modalAssociateVisible,
    selectedShareAccount,
    holidays,
    fetchingBankAccount,
    isSuccessAssociate,
}) {
    const [isValidAccount, setIsValidAccount] = useState(false);
    const [ibsAccount, setIbsAccount] = useState({});

    const fundConfigParam = useFundConfigParam(selectedFundFeature);
    const url = fundConfigParam?.link || "#";

    const importeMin = fundConfigParam?.importeMin && toNumber(fundConfigParam?.importeMin);

    useUpdateFormValue("importeMin", importeMin, setFieldValue);
    useUpdateFormValue("ibsAccount", ibsAccount, setFieldValue);

    const data = {
        enabledWeekDays: [1, 2, 3, 4, 5, 6, 7],
        firstWorkingDate: moment(),
        maxDaysToSchedule: 10000,
        nonWorkingDays: [],
        lang: "es",
        mode: "edit",
        programable: true,
        schedulable: true,
        activeEnvironment,
    };

    const handleResetModal = () => {
        deselectFundFeature();
        toggleModalRiskLevel(false);
        handleReset();
        setIbsAccount({});
    };

    const handleResetAssociateModal = () => {
        toggleModalAssociate(false);
        handleReset();
        setIbsAccount({});
        deselectFundFeature();
    };

    const getCurrencyOptions = () => {
        const { currency } = ibsAccount;
        if (!currency) {
            return [{ id: 0, label: i18n.get(`currency.label.ARS`) }];
        }

        const currencyOption = { id: toNumber(currency), label: i18n.get(`currency.label.${currency}`) };
        return [currencyOption];
    };

    const hasFundData = () => !!shareAccounts && shareAccounts.length > 0;

    const getShareAccountOptions = () => {
        if (!hasFundData()) {
            return [];
        }
        return shareAccounts.map(({ id, description }) => ({
            value: id,
            label: `${id} ${description}`,
        }));
    };

    const getSelectedShareAccount = () => {
        if (!values.shareAccount) {
            return {};
        }
        return shareAccounts.find(
            (shareAccount) =>
                shareAccount.id.toString() === values.shareAccount || shareAccount.id === values.shareAccount,
        );
    };

    const handleDownloadTyC = () => {
        downloadUrl(url);
    };

    const handleChangeShareaccount = (shareAccount) => {
        setShareaccount(shareAccount);
    };

    useEffect(() => {
        if (selectedFundFeature && values.shareAccount) {
            const shareAccountSelected = shareAccounts.find(
                (shareAccount) => shareAccount.id.toString() === values.shareAccount.toString(),
            );
            const debitAccount = shareAccountSelected?.monetaryAccounts.find(
                (account) =>
                    ![account.monedaIso, getCurrencyIso(account.cbu)].some((c) => c !== selectedFundFeature.moneda),
            );
            if (isEnabledMvp("MVP4") && !debitAccount) {
                toggleModalAssociate(true);
            }
        }
    }, [selectedFundFeature, values.shareAccount, shareAccounts, toggleModalAssociate]);

    useEffect(() => {
        if (!fetchingBankAccount) {
            toggleModalAssociate(false);
        }
        if (!fetchingBankAccount && values.shareAccount && !isSuccessAssociate && modalAssociateVisible) {
            handleResetAssociateModal();
        }
        // eslint-disable-next-line
    }, [fetchingBankAccount]);

    const showMvp5Release = configUtils.getBoolean("frontend.show.MVP5.functionalities");

    return (
        <>
            <InfoRiskLevelModal
                isDesktop={isDesktop}
                isVisible={modalRiskLevelVisible}
                toggleModal={toggleModalRiskLevel}
                selectedFundFeature={selectedFundFeature}
                setFieldValueSubcribe={setFieldValue}
                deselectFundFeature={deselectFundFeature}
                handleResetModal={handleResetModal}
                FORM_ID={FORM_ID}
            />
            <AssociateAccountModal
                isDesktop={isDesktop}
                accounts={accounts}
                isVisible={modalAssociateVisible}
                selectedFundFeature={selectedFundFeature}
                selectedShareAccount={selectedShareAccount}
                handleResetAssociateModal={handleResetAssociateModal}
                toggleModalAssociate={toggleModalAssociate}
            />
            <Form>
                <div className="above-the-fold">
                    <Container
                        className={classNames("flex-grow align-items-center container-white", {
                            "mb-2 py-2": isDesktop,
                            "pt-2": !isDesktop,
                        })}
                        gridClassName="form-content pb-0 px-0">
                        <Col sm={12} md={9} lg={6} xl={7}>
                            <SelectFund isDesktop={isDesktop} openModal={openModal} disabled={!!selectedFundFeature} />
                            {selectedFundFeature && (
                                <Field
                                    component={SelectedFundInfo}
                                    isDesktop={isDesktop}
                                    selectedFundFeature={selectedFundFeature}
                                    deselectFundFeature={deselectFundFeature}
                                />
                            )}
                            <Col
                                xs={12}
                                className={classNames("align-items-center px-md-3", { "px-0 pt-2": isDesktop })}>
                                <Field
                                    component={Selector}
                                    options={getShareAccountOptions()}
                                    idForm={FORM_ID}
                                    name="shareAccount"
                                    onCustomChange={(selected) => handleChangeShareaccount(selected)}
                                />
                            </Col>
                            <Col
                                xs={12}
                                className={classNames("align-items-center px-md-3", { "px-0 mb-2": isDesktop })}>
                                <DebitAccountInfo
                                    fund={selectedFundFeature}
                                    shareAccount={getSelectedShareAccount()}
                                    isDesktop={isDesktop}
                                    setIsValidAccount={setIsValidAccount}
                                    setIbsAccount={setIbsAccount}
                                    setFieldValue={setFieldValue}
                                />
                            </Col>
                            <Col xs={12} className={classNames("align-items-center px-md-3", { "px-0": isDesktop })}>
                                <Field
                                    idForm={FORM_ID}
                                    component={AmountField}
                                    name="amount"
                                    autocomplete="off"
                                    isFocused
                                    data={{
                                        options: getCurrencyOptions(),
                                    }}
                                    clearable={false}
                                    label={`${FORM_ID}.amount.label`}
                                    maxLength={15}
                                    disableSelect
                                    fixedDecimalScale
                                    formGroupClassName={classNames({
                                        "mb-0": isDesktop,
                                        "mt-0 mb-2": !isDesktop,
                                    })}
                                />
                                <SubscribeMinAmount
                                    currency={ibsAccount.currency}
                                    quantity={fundConfigParam.importeMin}
                                />
                            </Col>
                            {showMvp5Release && (
                                <Col xs={12} className="schedule justify-content-left">
                                    <Field
                                        component={FundSubscribeScheduler}
                                        data={data}
                                        name="scheduler"
                                        location={location}
                                        isDesktop={isDesktop}
                                        label={`${FORM_ID}.scheduler.label`}
                                        FORM_ID="fund.sheduler.subscribe"
                                        nonWorkingDays={holidays}
                                    />
                                </Col>
                            )}

                            <Col xs={12} className={classNames("d-flex align-items-center", { "my-2": isDesktop })}>
                                <Field
                                    name="termsAndConditions"
                                    idForm={FORM_ID}
                                    label={<TermsAndCoditionsLink url={url} isDesktop={isDesktop} />}
                                    component={Checkbox}
                                    checked={values.termsAndConditions}
                                    value={values.termsAndConditions}
                                    disabled={url === "#"}
                                    formGroupTextClassName="form-checkbox-link-label"
                                    formGroupClassName="mb-0"
                                />
                                {!isDesktop && (
                                    <Button
                                        type="button"
                                        className="my-0"
                                        bsStyle="link"
                                        block={false}
                                        image="images/download_bold.svg"
                                        onClick={() => handleDownloadTyC()}
                                        disabled={url === "#"}
                                    />
                                )}
                            </Col>
                        </Col>
                    </Container>
                </div>
                <Container className="flex-grow align-items-center mt-2" gridClassName="form-content">
                    <Col lg={3} xl={3} md={9} sm={12}>
                        <Row
                            className={classNames("admin-content-center", {
                                "mx-0": !isDesktop,
                            })}>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="global.continue"
                                disabled={!isValidAccount || !values.termsAndConditions}
                                loading={fetching}
                            />
                        </Row>
                    </Col>
                </Container>
            </Form>
        </>
    );
}

SubscribeForm.propTypes = {
    isDesktop: bool,
    activeEnvironment: shape({
        permmisions: shape({}),
    }).isRequired,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    openModal: func.isRequired,
    selectedFundFeature: shape({
        numero: number,
        rescatePlazo: number,
        suscripcionPlazo: number,
        nombre: string,
        estaAnulado: bool,
        horizonteInversionDescripcion: string,
        sociedadGerenteDescripcion: string,
        moneda: string,
        monedaDescripcion: string,
        regionDescripcion: string,
    }).isRequired,
    deselectFundFeature: func.isRequired,
    shareAccounts: arrayOf(shape({})).isRequired,
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    fetching: bool.isRequired,
    toggleModalAssociate: func.isRequired,
    setShareaccount: func.isRequired,
    modalRiskLevelVisible: bool.isRequired,
    toggleModalRiskLevel: func.isRequired,
    handleReset: func.isRequired,
    accounts: arrayOf(shape({})).isRequired,
    modalAssociateVisible: bool.isRequired,
    selectedShareAccount: shape({}).isRequired,
    holidays: arrayOf(string).isRequired,
    fetchingBankAccount: bool,
    isSuccessAssociate: bool.isRequired,
};

SubscribeForm.defaultProps = {
    isDesktop: true,
    fetchingBankAccount: false,
};

const mapStateToProps = (state) => ({
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    summary: fundcorpSelectors.getSummary(state),
    investorProfile: fundcorpSelectors.getInvestorProfile(state),
    holidays: holidaysSelector.getHolidays(state),
    fetchingBankAccount: fundcorpSelectors.getFetchingBankAccount(state),
    isSuccessAssociate: fundcorpSelectors.isSuccessAssociate(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => {
            const {
                summary,
                setShareaccount
            } = props;
            
            const { amount, selectedScheduler, shareAccount, termsAndConditions } = summary;
            const scheduler = { ...selectedScheduler, valueDate: selectedScheduler?.newValueDate };
            setShareaccount(shareAccount)
            
            return {
                shareAccount: props?.match?.params?.cuotapartistaNumero
                    ? props.match.params.cuotapartistaNumero
                    : shareAccount,
                amount: { currency: amount?.currency || "0", amount: amount?.quantity || ""},
                termsAndConditions,
                scheduler,
            };
        },

        validationSchema: () =>
            Yup.lazy((values) => {
                const isNumberValidCondition =
                    !isNil(values.accountBalance) && values.importeMin && typeof values.amount.amount === "number";
                return Yup.object().shape({
                    amount: Yup.object().shape({
                        amount: isNumberValidCondition
                            ? Yup.number()
                                  .max(values.accountBalance, i18n.get(`${FORM_ID}.maxAmount.error`))
                                  .min(values.importeMin, i18n.get(`${FORM_ID}.minAmount.error`))
                            : Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    }),
                    shareAccount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    termsAndConditions: Yup.bool().oneOf([true], i18n.get(`${FORM_ID}.termsAndConditions.error`)),
                });
            }),

        handleSubmit: ({ amount, ibsAccount, shareAccount, scheduler, termsAndConditions }, formikBag) => {
            const {
                dispatch,
                selectedFundFeature,
                shareAccounts,
                investorProfile,
                toggleModalRiskLevel,
            } = formikBag.props;

            const selectedShareAccount = shareAccounts.find((account) => account.id.toString() === shareAccount);
            const { setSubmitting } = formikBag;
            const label = getProductLabel(ibsAccount);
            const { regionDescripcion } = selectedFundFeature;
            const investorProfileType = investorProfile?.investorProfileType?.trim();

            let schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
            const newValueDate = schedulerToSend?.valueDate;
            schedulerToSend = { ...schedulerToSend, newValueDate };
            delete schedulerToSend.valueDate;

            const summary = {
                fundName: selectedFundFeature.nombre,
                selectedFund: selectedFundFeature,
                amount: { quantity: amount.amount, currency: ibsAccount.currency },
                debitAccontLabel: label,
                debitAccountId: ibsAccount.idProduct,
                shareAccount,
                subscribeDate: new Date(),
                selectedShareAccount,
                selectedScheduler: schedulerToSend,
                termsAndConditions
            };

            if (
                isEnabledMvp("MVP4") &&
                LEVEL_INVESTOR_PROFILE[regionDescripcion.toUpperCase()] > Number(investorProfileType)
            ) {
                dispatch(fundcorpActions.setSubscribeSummaryData(summary));
                toggleModalRiskLevel(true);
                setSubmitting(false);
                return;
            }

            dispatch(fundcorpActions.createSubscribeRequest(summary, formikBag));
        },
    }),
)(SubscribeForm);

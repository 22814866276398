import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { Col } from "react-bootstrap";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import { getAccountDescription } from "util/accounts";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";

class PaymentAFIPDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: {
                    paymentAFIP: { conceptDesc, subConceptDesc, amount, numberVEP },
                    debitAccount,
                },
            } = object.transaction;

            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{submitDateTimeAsString}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{numberVEP && Number(numberVEP)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <FormattedAmount
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                                currency={amount?.currency}
                                quantity={amount?.quantity}
                                notBold
                            />
                        </Table.Data>
                        {debitAccount ? (
                            <Table.Data align="right">
                                <span>{debitAccount?.number !== undefined && getAccountDescription(debitAccount)}</span>
                            </Table.Data>
                        ) : (
                            undefined
                        )}
                        <Table.Data align="right">
                            <span>{conceptDesc}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <span>{subConceptDesc}</span>
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="forms.transaction.ticket.date"
                        data={<span>{submitDateTimeAsString}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="pay.paymentAFIP.numberVEP"
                        data={<span>{numberVEP && Number(numberVEP)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="pay.paymentAFIP.amount"
                        data={
                            <FormattedAmount
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                                currency={amount?.currency}
                                quantity={amount?.quantity}
                                notBold
                            />
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    {debitAccount ? (
                        <Heading.DataGroup
                            label="pay.paymentAFIP.debitAccount"
                            data={
                                <span>{debitAccount?.number !== undefined && getAccountDescription(debitAccount)}</span>
                            }
                            containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                            labelClassName="mb-0"
                        />
                    ) : (
                        undefined
                    )}
                    <Heading.DataGroup
                        label="pay.paymentAFIP.concept"
                        data={<span>{conceptDesc}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="pay.paymentAFIP.subConcept"
                        data={<span>{subConceptDesc}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="pay.paymentAFIP.numberVEP" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="pay.paymentAFIP.amount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="pay.paymentAFIP.debitAccount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="pay.paymentAFIP.concept" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="pay.paymentAFIP.subConcept" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(PaymentAFIPDataTable);

import * as API from "middleware/api";

export const listCedip = (
    creationDate,
    creationDateFrom,
    creationDateTo,
    dueDate,
    dueDateFrom,
    dueDateTo,
    idCedip,
    option,
    orderColumName,
    orderDirection,
    pageNumber,
    status,
) =>
    API.executeWithAccessToken("/cedip.list", {
        creationDate,
        creationDateFrom,
        creationDateTo,
        dueDate,
        dueDateFrom,
        dueDateTo,
        idCedip,
        option,
        orderColumName,
        orderDirection,
        pageNumber,
        status,
    });

export const readCedip = (idCedip, fraction) => API.executeWithAccessToken("/cedip.detail", { idCedip, fraction });

export const downloadList = (format) => API.executeWithAccessToken("/cedip.exportList", { format });

export const downloadDetail = (idDeposit, format) =>
    API.executeWithAccessToken("/cedip.downloadInformation", { idDeposit, format });

export const rejectCedip = (idCedip, fraction) => API.executeWithAccessToken("/cedip.reject", { idCedip, fraction });

export const acceptCedip = (idCedip, fraction, cbu, accreditationType) =>
    API.executeWithAccessToken("/cedip.accept", { idCedip, fraction, cbu, accreditationType });

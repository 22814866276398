import { call, put, takeLatest } from "redux-saga/effects";
import { replace, push, routerActions } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE, INSUFFICIENT_SIGNATURES } from "util/responses.js";
import * as form from "middleware/form";
import { types, actions } from "reducers/administration/users";
import { actions as notificationActions } from "reducers/notification";
import * as administrationUsers from "middleware/administration/users";
import * as i18n from "util/i18n";
import * as utilDownload from "util/download";
import { credentialsToUnderscoreFormat } from "util/form.js";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreRequest),
    takeLatest(types.EXPORT_LIST_REQUEST, exportListRequest),
    takeLatest(types.CHANGE_USER_STATUS_PREVIEW, changeUserStatusPreview),
    takeLatest(types.CHANGE_USER_STATUS_CONFIRMATION, changeUserStatusConfirmation),
    takeLatest(types.UPDATE_DISPATCHER_REQUEST, updateDispatcher),
    takeLatest(types.EXPORT_ADMINISTRATION_SIGNATURE_HELP_REQUEST, exportAdministrationSignatureHelpRequest),
];

export default sagas;

const actionToStatus = { block: "blocked", unblock: "active" };

function* loadListRequest(params) {
    const { filters } = params;
    const response = yield call(administrationUsers.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put(actions.loadListSuccess(response.data.data));
    }
}

function* loadMoreRequest(params) {
    const { filters } = params;
    const response = yield call(administrationUsers.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put({
            type: types.LOAD_MORE_SUCCESS,
            data: response.data.data,
        });
    }
}

function* exportListRequest(params) {
    const { type, data } = yield call(administrationUsers.exportListRequest, params);
    const { format } = params;

    if (type === "W") {
        yield put({ type: types.EXPORT_LIST_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            utilDownload.downloadPdf(fileName, content);
        } else {
            utilDownload.downloadXls(fileName, content);
        }
        yield put({ type: types.EXPORT_LIST_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("global.download.success"), "success", [
                "administrationUsers",
            ]),
        );
    }
}

function* changeUserStatusPreview({ userList, userNameList, userAction }) {
    const params = {
        userIdList: userList,
        userNameList,
        newStatus: actionToStatus[userAction],
    };
    let response = null;
    if (userAction === "block" || userAction === "unblock") {
        response = yield call(administrationUsers.changeUsersStatusPreview, params);
    } else if (userAction === "delete") {
        response = yield call(administrationUsers.deleteUsersPreview, params);
    }
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        const responseCredentials = yield call(
            form.listCredentialsGroups,
            null,
            "administration.users.blockunblock.send",
        );
        const credentialGroups = responseCredentials.data.data.groups;
        yield put({
            type: types.CHANGE_USER_STATUS_PREVIEW_SUCCESS,
            userList,
            userNameList,
            userAction,
            credentialGroups,
        });
        yield put(push(`/administration/confirmUserAction`));
    }
}

function* changeUserStatusConfirmation({ usersToApplyAction, userNameList, userAction, credentials, formikBag }) {
    const params = {
        userIdList: usersToApplyAction,
        userNameList,
    };
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    let response = null;
    let successMessageSubKey = userAction;
    if (userAction === "block" || userAction === "unblock") {
        response = yield call(
            administrationUsers.changeUsersStatusConfirmation,
            { ...params, newStatus: actionToStatus[userAction] },
            credentialsWithUnderscore,
        );
        successMessageSubKey = actionToStatus[userAction];
    } else if (userAction === "delete") {
        response = yield call(administrationUsers.deleteUsersConfirmation, params, credentialsWithUnderscore);
    }

    formikBag.setSubmitting(false);
    if (response.type === "W") {
        if (response.data.data.NO_FIELD) {
            yield put(replace("/administration/users"));
            yield put(notificationActions.showNotification(response.data.data.NO_FIELD, "error", ["administration"]));
        } else if (response.data.code === "COR054W" || response.data.code === "API524W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["administration"]));
        } else {
            formikBag.setErrors(response.data.data);
            yield put(replace("/administration/confirmUserAction"));
        }
    } else {
        if (
            response.data.code &&
            (response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE ||
                response.data.code === INSUFFICIENT_SIGNATURES)
        ) {
            yield put(
                notificationActions.showNotification(i18n.get("administration.users.change.pending"), "warning", [
                    "administrationUsers",
                ]),
            );
        } else {
            let successMessageKey = `administration.users.${successMessageSubKey}.success`;
            if (usersToApplyAction.length > 1) {
                successMessageKey = `${successMessageKey}.plural`;
            }
            yield put(
                notificationActions.showNotification(i18n.get(successMessageKey), "success", ["administrationUsers"]),
            );
        }
        yield put(replace("/administration/users"));
    }
}

function* updateDispatcher({ data, formikBag }) {
    const { dispatcher, userId, credentials, ...rest } = data;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield call(administrationUsers.updateDispacther, {
        ...rest,
        ...credentialsWithUnderscore,
        userId,
        dispatcher,
    });
    const { setSubmitting, setErrors } = formikBag;
    setSubmitting(false);

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            setErrors(response.data.data);
        } else {
            const errorMessage = response.data.message || i18n.get("global.unexpectedError");
            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        }
        yield put(actions.updateDispatcherFailure());
    } else {
        const { notificationMessage } = response.data.data;
        if (notificationMessage) {
            yield put(notificationActions.showNotification(notificationMessage, "success", ["administration"]));
        } else {
            yield put(notificationActions.showNotification(response.data.message, "warning", ["administration"]));
        }
        yield put(routerActions.goBack());
        yield put(actions.updateDispatcherSuccess(response.data.data.userExtendedInfo));
    }
}

function* exportAdministrationSignatureHelpRequest({ format }) {
    const { type, data } = yield call(administrationUsers.exportAdministrationSignatureHelpRequest, format);

    if (type === "W") {
        yield put({ type: types.EXPORT_ADMINISTRATION_SIGNATURE_HELP_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            utilDownload.downloadPdf(fileName, content);
        } else {
            utilDownload.downloadXls(fileName, content);
        }
        yield put({ type: types.EXPORT_ADMINISTRATION_SIGNATURE_HELP_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("global.download.success"), "success", [
                "administrationUsers",
            ]),
        );
    }
}

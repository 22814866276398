/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import { bool, node, string } from "prop-types";

import { actions as transactionsActions } from "reducers/transactions";

import Button from "pages/_components/Button";
import AmountField from "pages/_components/fields/formik/AmountField";

import * as i18n from "util/i18n";

const FORM_ID = "transactions.list.filters.amount";

class AmountFilter extends Component {
    static propTypes = {
        fetching: bool,
        buttonLabel: string,
        withSelector: bool,
        cleanFiltersButton: node,
        rightButton: bool.isRequired,
    };

    static defaultProps = {
        fetching: false,
        buttonLabel: null,
        withSelector: false,
        cleanFiltersButton: null,
    };

    changeCurrencies = (value, form) => {
        form.setFieldValue(`maxAmount.currency`, value);
        form.setFieldValue(`minAmount.currency`, value);
    };

    render() {
        const { fetching, buttonLabel, withSelector, cleanFiltersButton, rightButton } = this.props;
        const currenciesAll = [{ id: 0, label: i18n.get(`currency.label.ARS`) }];
        const onCurrencyChange = withSelector ? this.changeCurrencies : false;
        const currency = withSelector ? currenciesAll[0] : null;

        if (withSelector) {
            currenciesAll.push({ id: 2, label: i18n.get(`currency.label.USD`) });
        }

        return (
            <Form>
                {!rightButton ? (
                    <Col sm={12} md={2} lg={2} className="d-flex f-dir-col mt-35 pt-3 px-3">
                        <Button
                            bsStyle="primary"
                            label={buttonLabel || "product.filters.filter"}
                            loading={fetching}
                            type="submit"
                            className="filter-button m-0"
                        />
                        {cleanFiltersButton}
                    </Col>
                ) : null}
                <Col sm={12} md={3} lg={3} className="amount-filter__amounts">
                    <Field
                        component={AmountField}
                        data={{ options: currenciesAll, onCurrencyChange }}
                        clearable={!withSelector}
                        currency={currency}
                        idForm={FORM_ID}
                        name="minAmount"
                        fixedDecimalScale
                    />
                </Col>
                <Col sm={12} md={3} lg={3} className="amount-filter__amounts">
                    <Field
                        component={AmountField}
                        clearable={!withSelector}
                        data={{ options: currenciesAll, onCurrencyChange }}
                        currency={currency}
                        idForm={FORM_ID}
                        name="maxAmount"
                        fixedDecimalScale
                    />
                </Col>
                {rightButton ? (
                    <Col sm={12} md={2} lg={2} className="d-flex f-dir-col mt-35 pt-3 px-3">
                        <Button
                            bsStyle="primary"
                            label={buttonLabel || "product.filters.filter"}
                            loading={fetching}
                            type="submit"
                            className="filter-button m-0"
                        />
                        {cleanFiltersButton}
                    </Col>
                ) : null}
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        minAmount: props.minAmount ? props.minAmount : {},
        maxAmount: props.maxAmount ? props.maxAmount : {},
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                minAmount: Yup.object().shape({
                    amount: values.maxAmount.amount
                        ? Yup.number()
                              .transform((cv, ov) => (ov === "" ? undefined : cv))
                              .nullable()
                              .max(values.maxAmount.amount, i18n.get("accounts.movements.filters.minAmount.error"))
                        : Yup.number()
                              .transform((cv, ov) => (ov === "" ? undefined : cv))
                              .nullable(),
                }),
                maxAmount: Yup.object().shape({
                    amount: values.minAmount.amount
                        ? Yup.number()
                              .transform((cv, ov) => (ov === "" ? undefined : cv))
                              .nullable()
                              .min(values.minAmount.amount, i18n.get("accounts.movements.filters.maxAmount.error"))
                        : Yup.number()
                              .transform((cv, ov) => (ov === "" ? undefined : cv))
                              .nullable(),
                }),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const {
            dispatch,
            isDesktop,
            onlyPendings,
            pendingDispatch,
            setFilterData,
            status,
            idActivity,
            cleanSelectedRows,
        } = formikBag.props;

        const { maxAmount, minAmount } = filters;

        if (cleanSelectedRows) {
            cleanSelectedRows();
        }

        const getCurrency = (amount) => (amount.currency ? amount.currency : 0);

        maxAmount.currency = getCurrency(maxAmount);
        minAmount.currency = getCurrency(minAmount);

        if (setFilterData) {
            setFilterData({ maxAmount: maxAmount.amount, minAmount: minAmount.amount });
        } else if (isDesktop) {
            dispatch(
                transactionsActions.loadListRequest(
                    {
                        ...filters,
                        status,
                        idActivity,
                        maxAmount: { amount: maxAmount.amount, currency: maxAmount.currency },
                        minAmount: { amount: minAmount.amount, currency: minAmount.currency },
                        filter: "amount",
                    },
                    onlyPendings,
                    pendingDispatch,
                    formikBag,
                ),
            );
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "amount",
                    ...filters,
                    maxAmount: { amount: maxAmount.amount, currency: maxAmount.currency },
                    minAmount: { amount: minAmount.amount, currency: minAmount.currency },
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
    },
})(AmountFilter);

import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";

import { selectors } from "reducers/checks";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import EcheqAcceptReturnTransactionData from "pages/checks/Echeqs/EcheqTransactions/EcheqAcceptReturnTransactionData";
import { actions as formActions } from "reducers/form";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";
import { echeqConceptLabel } from "util/checks";

const FORM_ID = "echeq.transaction";
class EcheqTransaction extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
    };

    state = {
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { dispatch, transactionData } = this.props;
        if (!transactionData.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    centerContentMobile = () => {
        const { transactionData } = this.props;
        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 style={{ width: "100%", marginTop: "2px" }}>
                    {i18n.get(`activities.${transactionData.idActivity}`)}
                </h1>
            </div>
        );
    };

    summaryDataComponent = () => <EcheqAcceptReturnTransactionData />;

    signTransaction = () => {
        const { dispatch, transactionData } = this.props;

        const signTransactionData = this.summaryDataComponent();

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: transactionData?.idTransaction,
                idActivity: transactionData.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "activities.accept.return.echeqs",
                pendingDispatch: transactionData?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData.idActivity,
                idTransaction: transactionData.idTransaction,
                idForm: "",
                ticketData: transactionData,
            }),
        );
    };

    rightContentTransactionScheme = () => {
        const { isDesktop } = this.props;

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={[
                    {
                        label: "pendingTransaction.info.scheme",
                        onClick: () => this.setState({ showDrawerMobile: true }),
                    },
                ]}
            />
        );
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { transactionData } = this.props;
        const { echeq } = transactionData;

        const ticketData = {
            ticketName: i18n.get(`activities.${transactionData.idActivity}`),
            ticketBody: [
                getGeneralTicketItem(`forms.transaction.ticket.date`, transactionData.creationDate, "datefulltime"),
                getGeneralTicketItem(
                    `pay.paymentAFIP.idTransaction`,
                    transactionData?.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.echeq.number`, echeq.cheque_numero, "string"),
                getGeneralTicketItem(`${FORM_ID}.echeq.id`, echeq.cheque_id, "string"),
                getGeneralTicketItem(`echeq.request.return.cuit`, echeq.cuit_solic_devol, "string"),
                getGeneralTicketItem(`echeqs.reference.beneficiary`, transactionData.solicitantName, "string"),
                getGeneralTicketItem(`${FORM_ID}.amount`, echeq.monto, "amountARS"),
                getGeneralTicketItem(`${FORM_ID}.emision`, echeq?.fecha_emision, "date"),
                getGeneralTicketItem(`${FORM_ID}.payed`, echeq?.fecha_pago, "date"),
                getGeneralTicketItem(
                    `${FORM_ID}.echeq.type`,
                    i18n.get(`echeqs.emit.${echeq?.cheque_tipo.toLowerCase()}.label`),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.echeq.character`, echeq.cheque_caracter, "string"),
                getGeneralTicketItem(`${FORM_ID}.mode`, i18n.get(`echeqs.${echeq.cheque_modo}.modo`), "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.concept`,
                    echeq.cheque_concepto.concat(" ", echeqConceptLabel(echeq.cheque_concepto, "-")),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.motive`, echeq.cheque_motivo_pago, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.response`, transactionData.coelsaResponse, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.code`, transactionData.coelsaCode, "string"),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch, transactionData } = this.props;

        if (transactionData.idTransactionStatus !== "PENDING") {
            return [
                {
                    label: "global.goToHome",
                    bsStyle: "primary",
                    onClick: () => dispatch(push("/desktop")),
                },
            ];
        }
        return [];
    };

    render() {
        const { transactionData, isDesktop, isSmallDesktop } = this.props;
        const { showDrawerMobile, fetchingDownload } = this.state;

        return (
            <>
                {transactionData?.idTransaction && (
                    <TransactionTicket
                        notificationScope="echeqs"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        emptyRightOption={
                            (isDesktop && transactionData?.idTransactionStatus !== "FINISHED") ||
                            (!isDesktop &&
                                transactionData?.idTransactionStatus !== "PENDING" &&
                                transactionData?.idTransactionStatus !== "FINISHED")
                        }
                        uniqueDownload={transactionData?.idTransactionStatus === "FINISHED"}
                        rightContent={
                            transactionData?.idTransactionStatus === "PENDING" && this.rightContentTransactionScheme
                        }
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        isFetchingExport={fetchingDownload}
                        summary={transactionData}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        summaryTitle={`activities.${transactionData.idActivity}`}
                        showDrawerMobile={showDrawerMobile}
                        onSignTicket={this.signTransaction}
                        ignoreHomeButton
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}>
                        <EcheqAcceptReturnTransactionData />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
});

export default connect(mapStateToProps)(EcheqTransaction);

import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import { selectors } from "reducers/checks";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import { actions as formActions } from "reducers/form";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import CesionEcheqTransactionData from "pages/checks/Echeqs/EcheqTransactions/CesionEcheqTransactionData";
import ContextMenu from "pages/_components/ContextMenu";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import TransactionTicket from "pages/_components/TransactionTicket";
import { echeqConceptLabel } from "util/checks";

const FORM_ID = "echeq.transaction";

class CesionEcheqTransaction extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
    };

    state = {
        isModalDisplayed: false,
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { dispatch, transactionData } = this.props;
        if (!transactionData.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", marginTop: "2px" }}>{i18n.get(`echeqs.emit-cesion.title`)}</h1>
        </div>
    );

    summaryDataComponent = () => <CesionEcheqTransactionData />;

    signTransaction = () => {
        const { dispatch, transactionData } = this.props;

        const signTransactionData = this.summaryDataComponent();

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: transactionData?.idTransaction,
                idActivity: transactionData.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "echeqs.transaction.cesion.label",
                pendingDispatch: transactionData?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData.idActivity,
                idTransaction: transactionData.idTransaction,
                idForm: "",
                ticketData: transactionData,
            }),
        );
    };

    rightContentTransactionScheme = () => {
        const { isDesktop, transactionData } = this.props;

        if (transactionData.idTransactionStatus === "FINISHED") {
            return (
                <ContextMenu
                    isDesktop={isDesktop}
                    buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                    items={[
                        {
                            label: "global.download",
                            onClick: this.exportPdf,
                        },
                    ]}
                />
            );
        }
        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={[
                    {
                        label: "pendingTransaction.info.scheme",
                        onClick: () => this.setState({ showDrawerMobile: true }),
                    },
                ]}
            />
        );
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });

        const { transactionData } = this.props;
        const { echeq } = transactionData;

        const concept = `${echeq.cheque_concepto} ${echeqConceptLabel(echeq.cheque_concepto, "-")}`;

        const ticketData = {
            ticketName: i18n.get("echeqs.emit-cesion.title"),
            ticketBody: [
                getGeneralTicketItem(`${FORM_ID}.date.time`, transactionData?.creationDate, "datetime"),
                getGeneralTicketItem(
                    `${FORM_ID}.transaction`,
                    transactionData.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.echeq.number`, echeq.cheque_numero, "string"),
                getGeneralTicketItem(`${FORM_ID}.echeq.id`, echeq.cheque_id, "string"),
                getGeneralTicketItem(`${FORM_ID}.cesion_date`, echeq.fecha_emision_cesion, "datetime"),
                getGeneralTicketItem(`${FORM_ID}.cesion.id`, transactionData?.cesion?.cesion_id, "string"),
                getGeneralTicketItem(`${FORM_ID}.beneficiary`, transactionData.beneficiary.reference, "string"),
                getGeneralTicketItem(`${FORM_ID}.beneficiary.document`, transactionData.beneficiary.document, "string"),
                getGeneralTicketItem(`${FORM_ID}.cesion.address`, transactionData?.address, "string"),
                getGeneralTicketItem(`${FORM_ID}.amount`, echeq.monto, "amountARS"),
                getGeneralTicketItem(`${FORM_ID}.emision`, echeq?.fecha_emision, "datetime"),
                getGeneralTicketItem(`${FORM_ID}.payed`, echeq?.fecha_emision, "datetime"),
                getGeneralTicketItem(
                    `${FORM_ID}.echeq.type`,
                    i18n.get(`echeqs.emit.${echeq?.cheque_tipo?.toLowerCase()}.label`),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.echeq.character`, echeq?.cheque_caracter, "string"),
                getGeneralTicketItem(`${FORM_ID}.mode`, i18n.get(`echeqs.${echeq.cheque_modo}.modo`), "string"),
                getGeneralTicketItem(`${FORM_ID}.concept`, concept, "string"),
                getGeneralTicketItem(`${FORM_ID}.motive`, echeq.cheque_motivo_pago, "string"),
                getGeneralTicketItem(`${FORM_ID}.reference`, transactionData.reference || "", "string"),
                getGeneralTicketItem(`${FORM_ID}.value.reference`, transactionData.referencevalue || "", "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.response`, transactionData.coelsaResponse, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.code`, transactionData.coelsaCode, "string"),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch, transactionData } = this.props;

        if (transactionData) {
            const { idTransactionStatus } = transactionData;
            if (idTransactionStatus !== "PENDING") {
                return [
                    {
                        label: "global.goToHome",
                        bsStyle: "primary",
                        onClick: () => dispatch(push("/desktop")),
                    },
                ];
            }
        }
        return [
            {
                label: "global.modify",
                bsStyle: "outline",
                onClick: () => this.setState({ isModalDisplayed: true }),
            },
        ];
    };

    render() {
        const { transactionData, isDesktop, dispatch, isSmallDesktop } = this.props;
        const { isModalDisplayed, showDrawerMobile, fetchingDownload } = this.state;

        return (
            <>
                {transactionData?.idTransaction && (
                    <TransactionTicket
                        notificationScope="echeqs"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={transactionData?.idTransactionStatus === "FINISHED"}
                        rightContent={
                            transactionData?.idTransactionStatus === "PENDING" && this.rightContentTransactionScheme
                        }
                        emptyRightOption={
                            (isDesktop && transactionData?.idTransactionStatus !== "FINISHED") ||
                            (!isDesktop &&
                                transactionData?.idTransactionStatus !== "PENDING" &&
                                transactionData?.idTransactionStatus !== "FINISHED")
                        }
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={transactionData}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        onSignTicket={this.signTransaction}
                        ignoreHomeButton
                        summaryTitle="echeqs.emit-cesion.title"
                        isFetchingExport={fetchingDownload}>
                        <ModifyPendingTransactionModal
                            isDesktop={isDesktop}
                            isDisplayed={isModalDisplayed}
                            handleDismiss={this.handleDismiss}
                            dispatch={dispatch}
                            idTransaction={transactionData.idTransaction}
                        />
                        <CesionEcheqTransactionData isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
});

export default connect(mapStateToProps)(CesionEcheqTransaction);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { func, arrayOf, shape, string } from "prop-types";

import { actions, selectors } from "reducers/administration/restrictions";
import { actions as formActions } from "reducers/form";
import { Col } from "react-bootstrap";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import * as i18nUtils from "util/i18n";
import { detailsSelectors } from "reducers/administration";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

class RestrictionsDeleteConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({}).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string,
        idActivity: string,
        user: shape({
            idUser: string,
            fullName: string,
        }),
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
        user: {},
    };

    componentDidMount() {
        const { dispatch, idTransaction, idActivity } = this.props;

        dispatch(actions.restrictionDeleteRequest(idTransaction, idActivity));
    }

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, match, idTransaction, idActivity, user } = this.props;

        if (!idTransaction) {
            const userFullName = user.fullName;
            dispatch(actions.deleteUserAccessRestrictions(match.params.userId, credentials, formikBag, userFullName));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const {
            credentialGroups,
            user: { fullName },
        } = this.props;
        if (!credentialGroups) {
            return null;
        }
        return (
            <Fragment>
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <div style={{ height: "2.0rem" }}>
                        <Head onBack={this.handleBack} closeLinkTo="/administration/users" />
                    </div>
                    <div className="mb-4">
                        <h1>
                            {`${i18nUtils.get("administration.users.restrictions.userRestrictions.title")} ${fullName}`}
                        </h1>
                    </div>
                </div>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout align-items-center flex-grow cmf-container-white"
                            gridClassName="form-content">
                            <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                                <div className="admin-content-center">
                                    <Col className="col col-6-xl">
                                        <h2 style={{ width: "100%" }}>
                                            {`${i18nUtils.get(
                                                "administration.restrictions.restrictions.beforeDelete.user",
                                            )}`}
                                        </h2>
                                        <I18n id="administration.restrictions.restrictions.beforeDelete.user.datail" />
                                    </Col>
                                </div>
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            buttonClassName="mt-8"
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
    user: detailsSelectors.getUser(state),
});

export default connect(mapStateToProps)(RestrictionsDeleteConfirmation);

import React, { Component } from "react";
import { connect } from "react-redux";

import { shape, func, bool } from "prop-types";
import { push } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import Head from "pages/_components/Head";
import FormattedAmount from "pages/_components/FormattedAmount";
import PageLoading from "pages/_components/PageLoading";
import ProyectedBalanceMovements from "pages/accounts/ProyectedBalanceMovements";
import Container from "pages/_components/Container";
import { maskedAccountNumber, productTypes } from "util/accounts";
import classNames from "classnames";

import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";

class ProyectedBalance extends Component {
    static propTypes = {
        loggedUser: shape({}).isRequired,
        selectedAccount: shape({}).isRequired,
        proyectedBalances: shape({}).isRequired,
        diferedMovements: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleBack = () => {
        const { dispatch, ...rest } = this.props;
        dispatch(push(`/accounts/${rest.match.params.id}`));
    };

    centerContentMobile = () => {
        const { selectedAccount } = this.props;
        return (
            <div className="title-account-header-multiline-background-blue">
                <h1>{i18nUtils.get("proyected.balance.title")}</h1>
                <p className="my-0">
                    {`${i18nUtils
                        .get(`accounts.productType.${productTypes[selectedAccount.productType]}`)
                        .toUpperCase()}  Nº ${maskedAccountNumber(selectedAccount?.number)}`}
                </p>
            </div>
        );
    };

    render() {
        const {
            selectedAccount,
            dispatch,
            loggedUser,
            proyectedBalances,
            diferedMovements,
            isDesktop,
            ...rest
        } = this.props;
        let balance = null;
        if (selectedAccount === null) {
            dispatch(accountsActions.details(rest.match.params.id, loggedUser.userId));
        } else if (proyectedBalances === null) {
            dispatch(accountsActions.proyectedBalance(selectedAccount.idProduct));
        } else if (diferedMovements === null) {
            dispatch(accountsActions.diferedMovements(selectedAccount.idProduct, "1"));
        } else {
            [balance] = proyectedBalances;
        }

        const capitalRealComponent = (
            <>
                <div className={`data-label ${!isDesktop ? "data-label-block" : ""} acoount-header-detail-label-font`}>
                    {i18nUtils.get("proyected.today")}
                </div>
                {balance && (
                    <FormattedAmount
                        className="acoount-header-detail-value-font mb-0"
                        medium
                        notBold
                        currency={selectedAccount.currency}
                        quantity={balance.capitalReal}
                    />
                )}
            </>
        );

        const capital24hsComponent = (
            <>
                <div className={`data-label ${!isDesktop ? "data-label-block" : ""} acoount-header-detail-label-font`}>
                    {i18nUtils.get("proyected.tomorrow")}
                </div>
                {balance && (
                    <FormattedAmount
                        className="acoount-header-detail-value-font mb-0"
                        medium
                        notBold
                        currency={selectedAccount.currency}
                        quantity={balance.capitalReal + balance.capital24Horas}
                    />
                )}
            </>
        );

        const capital48hsComponent = (
            <>
                <div className={`data-label ${!isDesktop ? "data-label-block" : ""} acoount-header-detail-label-font`}>
                    {i18nUtils.get("proyected.after.tomorrow")}
                </div>
                {balance && (
                    <FormattedAmount
                        className="acoount-header-detail-value-font mb-0"
                        medium
                        notBold
                        currency={selectedAccount.currency}
                        quantity={balance.capitalReal + balance.capital24Horas + balance.capital48Horas}
                    />
                )}
            </>
        );

        return (
            <PageLoading loading={!diferedMovements || !selectedAccount}>
                {selectedAccount && (
                    <>
                        <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                            <Head
                                onBack={this.handleBack}
                                headerClassName={classNames({
                                    "blue-main-header-mobile multiline-title-head": !isDesktop,
                                })}
                                centerElement={!isDesktop && this.centerContentMobile}
                                centerContentClassName="mx-5"
                            />

                            <div>
                                {isDesktop && (
                                    <>
                                        <h1 className="my-0">
                                            {`${i18nUtils.get("proyected.balance.title.to")} `}
                                            {selectedAccount.productAlias
                                                ? selectedAccount.productAlias
                                                : maskedAccountNumber(selectedAccount?.number)}
                                        </h1>

                                        <span>
                                            <p style={{ display: "inline-block" }} className="account-display-font">
                                                {`${i18nUtils
                                                    .get(
                                                        `accounts.productType.${
                                                            productTypes[selectedAccount.productType]
                                                        }`,
                                                    )
                                                    .toUpperCase()} Nº ${maskedAccountNumber(selectedAccount?.number)}`}
                                            </p>
                                        </span>
                                    </>
                                )}
                                <Container
                                    className="container--layout flex-grow align-items-center account-header-detail"
                                    gridClassName="px-0"
                                    rowClassName="mx-0">
                                    <Col
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        className={classNames("content-data-wrapper col", {
                                            "justify-content-center flex-column py-1": !isDesktop,
                                        })}>
                                        {capitalRealComponent}
                                    </Col>
                                    <Col
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        className={classNames("content-data-wrapper col justify-content-center", {
                                            "d-flex": isDesktop
                                        }, {
                                            "flex-column py-1": !isDesktop,
                                        })}>
                                        {capital24hsComponent}
                                    </Col>
                                    <Col
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        className={classNames("content-data-wrapper col justify-content-end", {
                                            "d-flex": isDesktop
                                        }, {
                                            "justify-content-center flex-column py-1": !isDesktop,
                                        })}>
                                        {capital48hsComponent}
                                    </Col>
                                </Container>
                            </div>
                        </div>

                        <div>
                            <MainContainer>
                                <div className="above-the-fold">
                                    <ProyectedBalanceMovements isDesktop={isDesktop} />
                                </div>
                            </MainContainer>
                        </div>

                        <span className="pl-3">{i18nUtils.get("proyected.seuo")}</span>
                    </>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    proyectedBalances: accountsSelectors.getProyectedBalances(state),
    diferedMovements: accountsSelectors.getDiferedMovements(state),
});

export default connect(mapStateToProps)(ProyectedBalance);

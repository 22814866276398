import React, { useCallback, useEffect, useState } from "react";
import { bool, func, shape, string, arrayOf, number, boolean } from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import classNames from "classnames";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";
import { selectors as accountsSelectors } from "reducers/accounts";
import { selectors as holidaysSelector, actions as holidaysActions } from "reducers/holidays";
import { actions as transactionsActions } from "reducers/transactions";
import * as i18n from "util/i18n";

import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { toNumber } from "util/number";
import SubscribeForm from "./_subscribe/SubscribeForm";
import FundListModal from "./FundListModal";

const FORM_ID = "fund.operation.subscribe";
function FundSubscribe(props) {
    const {
        isDesktop,
        dispatch,
        fetching,
        accountsFetching,
        match: {
            params: { fondoNumero },
        },
        fundFeatures,
        accounts,
        fetchingHolidays,
        location
    } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [modalAssociateVisible, setModalAssociateVisible] = useState(false);
    const [modalRiskLevelVisible, setRiskLevelVisible] = useState(false);
    const [selectedFundFeature, setSelectedFundFeature] = useState();
    const [selectedShareAccount, setSelectedShareAccount] = useState();

    useEffect(() => {
        dispatch(fundcorpActions.createSubscribePreRequest());
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname))
    }, [dispatch, location.pathname]);

    useEffect(() => {
        if (fondoNumero && fundFeatures) {
            setSelectedFundFeature(fundFeatures.find((fundFeature) => fundFeature.numero === toNumber(fondoNumero)));
        } else {
            setSelectedFundFeature(undefined);
        }
    }, [fondoNumero, fundFeatures]);

    useEffect(() => {
        dispatch(holidaysActions.listHolidays());
        // eslint-disable-next-line
    }, []);

    const handleBack = () => {
        dispatch(push("/fundcorpOperations"));
    };

    const selectFundFeature = (fundFeature) => {
        setSelectedFundFeature(fundFeature);
        setModalVisible(false);
    };

    const deselectFundFeature = () => {
        setSelectedFundFeature(null);
    };

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const toggleModalAssociate = useCallback(
        (status) => {
            setModalAssociateVisible(status);
        },
        [setModalAssociateVisible],
    );

    const setShareaccount = (shareAccount) => {
        setSelectedShareAccount(shareAccount);
    };

    const toggleModalRiskLevel = (status) => {
        setRiskLevelVisible(status);
    };

    const renderHead = () => (
        <>
            <Head
                onBack={handleBack}
                headerClassName={classNames({
                    "blue-main-header-mobile": !isDesktop,
                })}
                centerContentClassName={classNames({
                    "mx-5": !isDesktop,
                })}
                title={!isDesktop && `${FORM_ID}.head.label`}
                titleClassName="text-white"
                accessibilityTextId={`${FORM_ID}.head.label`}
            />
            {isDesktop && <h1 className="mt-1 mb-3">{i18n.get(`${FORM_ID}.head.label`)}</h1>}
        </>
    );

    return (
        <>
            <Notification scopeToShow="subscribe" />
            <div className="admin-detail-head px-0">
                {renderHead()}
                <FundListModal
                    isDesktop={isDesktop}
                    isVisible={modalVisible}
                    closeModal={closeModal}
                    onRowClick={selectFundFeature}
                />
            </div>
            <PageLoading loading={fetching || accountsFetching || fetchingHolidays}>
                <MainContainer>
                    <SubscribeForm
                        {...props}
                        isDesktop={isDesktop}
                        openModal={openModal}
                        selectedFundFeature={selectedFundFeature}
                        deselectFundFeature={deselectFundFeature}
                        toggleModalAssociate={toggleModalAssociate}
                        toggleModalRiskLevel={toggleModalRiskLevel}
                        modalRiskLevelVisible={modalRiskLevelVisible}
                        setShareaccount={setShareaccount}
                        dispatch={dispatch}
                        fetching={fetching}
                        accounts={accounts}
                        modalAssociateVisible={modalAssociateVisible}
                        selectedShareAccount={selectedShareAccount}
                    />
                </MainContainer>
            </PageLoading>
        </>
    );
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    fetching: fundcorpSelectors.getFetching(state),
    accountsFetching: accountsSelectors.getFetching(state),
    fundFeatures: fundcorpSelectors.getFundFeatures(state),
    summary: fundcorpSelectors.getSummary(state),
    accounts: accountsSelectors.getAccounts(state),
    fetchingHolidays: holidaysSelector.isFetching(state),
});

FundSubscribe.propTypes = {
    isDesktop: bool,
    dispatch: func.isRequired,
    fetching: bool,
    accountsFetching: bool,
    match: shape({
        params: shape({
            fondoNumero: string,
        }),
    }),
    fundFeatures: arrayOf(
        shape({
            numero: number,
            rescatePlazo: number,
            suscripcionPlazo: number,
            nombre: string,
            estaAnulado: boolean,
            horizonteInversionDescripcion: string,
            sociedadGerenteDescripcion: string,
            moneda: string,
            monedaDescripcion: string,
            regionDescripcion: string,
        }),
    ).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    fetchingHolidays: bool.isRequired,
};

FundSubscribe.defaultProps = {
    isDesktop: true,
    fetching: false,
    accountsFetching: false,
    match: shape({
        params: shape({
            fondoNumero: "",
        }),
    }),
};

export default connect(mapStateToProps)(FundSubscribe);

/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors as transferSelectors, actions as transferActions } from "reducers/transfer";
import { string, func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import TransferSummaryData from "pages/transfer/TransferSummaryData";
import TransferTransactionData from "pages/transfer/TransferTransactionData";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";
import PageLoading from "pages/_components/PageLoading";

import { actions as formActions } from "reducers/form";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as frequentDestinationSelectors } from "reducers/frequentDestination";
import { exportGenericTicketPdfWithSuccessMessage, getGeneralTicketItem } from "util/transaction";
import * as i18n from "util/i18n";

class TransferTransaction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            creditAccount: string.isRequired,
            amount: string.isRequired,
            currency: string.isRequired,
            reference: string,
        }).isRequired,
        internalTransfer: bool.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        scheduledCancel: bool.isRequired,
        idTransaction: string.isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    state = {
        isModalDisplayed: false,
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;
        if (!summary?.idTransaction) {
            dispatch(push("/pendingTransaction/list"));
        } else {
            dispatch(transactionsActions.getPotentialSignaturesData(summary.idTransaction));
        }
    }

    getAccountObject = (account, type) => {
        const { dispatch } = this.props;
        dispatch(transferActions.getAccountInfo(account, type, true));
    };

    getAccountDescription = ({ number, productType, currency }) => {
        if (!number) {
            return "";
        }
        return `${number} ${productType} ${i18n.get(`currency.label.${currency}`)}`;
    };

    generateTicketBody = () => {
        const {
            summary: {
                debitAccount,
                creditAccount,
                amount,
                reference,
                concept,
                transferKind,
                sendEmail,
                email,
                emailText,
                creditAccountDetail,
                clientUser,
                cbu,
                idTransactionStatus,
                scheduler,
                idTransactionToCancel,
                creationDate,
                currency,
                pspEntityName,
            },
            summary,
            internalTransfer,
            scheduledCancel,
            activeEnvironment,
            idTransaction,
        } = this.props;

        let creditAccountValue;

        if (internalTransfer) {
            creditAccountValue = creditAccount?.number
                ? this.getAccountDescription(creditAccount)
                : cbu !== undefined && cbu.slice(-14, -1);
        } else {
            creditAccountValue = cbu !== undefined && cbu.slice(-14, -1);
        }

        const isInternalPending = idTransactionStatus === "PENDING" && internalTransfer;

        const transferKindOptions = [
            {
                value: `same`,
                label: i18n.get("transfers.titular.same.label"),
            },
            {
                value: `other`,
                label: i18n.get("transfers.titular.other.label"),
            },
        ];

        const data = [];
        if (idTransactionStatus === "FINISHED") {
            data.push(getGeneralTicketItem(`transferSummary.date.label`, creationDate, "datefulltime"));
            data.push(
                getGeneralTicketItem(`transferConfirmation.idTransaction.label`, summary?.voucher || "", "string"),
            );
        } else {
            data.push(
                getGeneralTicketItem(
                    `transferConfirmation.idTransaction.label`,
                    summary?.idTransaction.substring(0, 8).toUpperCase() || idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
            );
        }

        if (scheduledCancel) {
            data.push(
                getGeneralTicketItem(
                    "scheduled.transactions.cancel.idTransactionToCancel.label",
                    idTransactionToCancel.substring(0, 8).toUpperCase(),
                    "string",
                ),
            );
        }

        data.push(getGeneralTicketItem("transferSummary.payer.label", activeEnvironment?.name, "string"));
        data.push(
            getGeneralTicketItem(
                "transfers.debitAccount.label",
                debitAccount?.number !== undefined && this.getAccountDescription(debitAccount),
                "string",
            ),
        );
        data.push(getGeneralTicketItem("transfers.creditAccount.label", creditAccountValue || "", "string"));

        if (!internalTransfer && !scheduledCancel) {
            data.push(
                getGeneralTicketItem(
                    "transfers.transferKind.label",
                    transferKindOptions.find(({ value }) => value === transferKind).label,
                    "string",
                ),
            );

            data.push(getGeneralTicketItem("transferSummary.cbuOrCvu.label", creditAccountDetail?.cbu, "string"));
        }

        if (!isInternalPending) {
            if (clientUser?.cuit || clientUser?.cuil) {
                data.push(
                    getGeneralTicketItem("transferSummary.cuit.label", this.getCuitDescription(clientUser), "string"),
                );
            }

            if (!internalTransfer && creditAccountDetail?.cuit) {
                data.push(
                    getGeneralTicketItem("transferSummary.alias.label", clientUser?.cuit || clientUser?.cuil, "string"),
                );
            }

            if (!internalTransfer && !scheduledCancel) {
                data.push(getGeneralTicketItem("transferSummary.owner.label", clientUser?.firstName || "", "string"));
            }

            if (pspEntityName) {
                data.push(getGeneralTicketItem("transferSummary.entity.label", pspEntityName, "string"));
            }
            if (!pspEntityName && creditAccountDetail?.bankName) {
                data.push(getGeneralTicketItem("transferSummary.bank.label", creditAccountDetail?.bankName, "string"));
            }
        }

        const amountValue = amount?.quantity || amount;
        const currencyValue = currency?.toString() || (amount?.currency ? amount.currency.toString() : "");
        data.push(
            getGeneralTicketItem(
                "transfers.amount.label",
                amountValue,
                `amount${i18n.get(`currency.label.${currencyValue}`)}`,
            ),
        );

        if (!internalTransfer && concept) {
            data.push(
                getGeneralTicketItem(
                    "transfers.concept.label",
                    `${concept} - ${i18n.get(`transfers.concept.${concept}`)}`,
                    "string",
                ),
            );
        }

        if (idTransactionStatus === "SCHEDULED") {
            data.push(getGeneralTicketItem("forms.confirmation.scheduler", scheduler, "string"));
        }

        if (!scheduledCancel) {
            data.push(getGeneralTicketItem("transfers.reference.label", reference || "", "string"));
        }

        if (email && sendEmail) {
            data.push(getGeneralTicketItem("transfers.email.label", email, "string"));
            if (emailText) {
                data.push(getGeneralTicketItem("transfers.emailText.label", emailText, "string"));
            }
        }

        return data;
    };

    handleDownload = async () => {
        this.setState({ fetchingDownload: true });

        const { internalTransfer, dispatch } = this.props;
        const title = internalTransfer ? "transfers.summary.internal.label" : "transfers.summary.external.label";

        const ticketData = {
            ticketName: i18n.get(title),
            ticketBody: [...this.generateTicketBody()],
        };

        await exportGenericTicketPdfWithSuccessMessage(ticketData, dispatch, i18n.get("global.download.success"));

        this.setState({ fetchingDownload: false });
    };

    centerContentMobile = () => {
        const { internalTransfer } = this.props;
        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 className="w-100 m-0 px-2">
                    {i18n.get(
                        internalTransfer ? "transfers.summary.internal.label" : "transfers.summary.external.label",
                    )}
                </h1>
            </div>
        );
    };

    getTitle = () => {
        const { summary } = this.props;
        const { idActivity } = summary;

        let title = "";

        if (idActivity === "transfers.internal.send" || idActivity === "transfers.internal.tad.send") {
            title = "transfers.summary.internal.label";
        } else if (idActivity === "transfers.thirdParties.send" || idActivity === "transfers.thirdParties.tad.send") {
            title = "transfers.summary.external.label";
        } else if (idActivity === "scheduled.transactions.cancel") {
            title = "activities.scheduled.transactions.cancel";
        }

        return title;
    };

    signTransaction = () => {
        const { dispatch, summary, isDesktop } = this.props;
        const { idActivity } = summary;

        const title = this.getTitle();

        const signTransactionData = <TransferSummaryData signature isDesktop={isDesktop} />;

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity,
                idForm: "",
                content: signTransactionData,
                title,
                pendingDispatch: summary?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity,
                idTransaction: summary.idTransaction,
                idForm: "",
                ticketData: summary,
            }),
        );
        if (idActivity === "transfers.internal.send") {
            dispatch(transferActions.loadInternalTransfer({ ...summary, action: "sign" }));
        } else if (idActivity === "transfers.thirdParties.send") {
            dispatch(transferActions.loadExternalTransfer({ ...summary, action: "sign" }));
        }
    };

    handleDismiss = () => {
        this.setState({ isModalDisplayed: false });
    };

    rightContentTransactionScheme = (isFinished) => {
        const { isDesktop, summary } = this.props;

        if (isDesktop || isFinished) {
            return <></>;
        }

        return (
            <>
                {summary?.idTransactionStatus !== "CANCELLED" ||
                    (summary?.idTransactionStatus !== "FAILED" && (
                        <ContextMenu
                            isDesktop={isDesktop}
                            buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                            items={[
                                {
                                    label: "pendingTransaction.info.scheme",
                                    onClick: () => this.setState({ showDrawerMobile: true }),
                                },
                            ]}
                        />
                    ))}
            </>
        );
    };

    buttonsDetailPending = () => {
        const { summary } = this.props;
        const { idTransactionToCancel } = summary;

        const isCancelScheduledTransaction = !!idTransactionToCancel;

        const pendingButtons = !isCancelScheduledTransaction
            ? [
                  {
                      label: "global.modify",
                      bsStyle: "primary",
                      onClick: () => this.setState({ isModalDisplayed: true }),
                  },
              ]
            : [];

        return pendingButtons;
    };

    buttonsDetailFinished = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "global.goToHome",
                bsStyle: "primary",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    getCuitDescription = (clientUser) => {
        if (clientUser?.cuil !== undefined) {
            return ` ${i18n.get(`transferSummary.cuil.input.label`)} ${clientUser?.cuil}`;
        }
        return ` ${i18n.get(`transferSummary.cuit.input.label`)} ${clientUser?.cuit}`;
    };

    render() {
        const { isDesktop, dispatch, summary, fetching } = this.props;
        const { isModalDisplayed, showDrawerMobile, fetchingDownload } = this.state;

        const isFinished = summary.idTransactionStatus === "FINISHED";
        const isPending = summary.idTransactionStatus === "PENDING";

        return (
            <PageLoading loading={fetching} className="screen-loader">
                <TransactionTicket
                    notificationScope="pendingTransfer"
                    handleClick={this.handleDownload}
                    handleClickMessage="global.download"
                    headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.centerContentMobile}
                    uniqueDownload={isFinished}
                    rightContent={() => this.rightContentTransactionScheme(isFinished)}
                    hasInlineButtons
                    hideMobileMenu={!isDesktop}
                    summary={summary}
                    buttonsDetail={isPending ? this.buttonsDetailPending() : this.buttonsDetailFinished()}
                    isDesktop={isDesktop}
                    onSignTicket={this.signTransaction}
                    summaryTitle={this.getTitle()}
                    closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                    showDrawerMobile={showDrawerMobile}
                    isFetchingExport={fetchingDownload}
                    ignoreHomeButton
                    downloadImageWhite={!isDesktop}>
                    <ModifyPendingTransactionModal
                        isDesktop={isDesktop}
                        isDisplayed={isModalDisplayed}
                        handleDismiss={this.handleDismiss}
                        dispatch={dispatch}
                        idTransaction={summary.idTransaction}
                        cancelTransactionData={() => <TransferSummaryData signature isDesktop={isDesktop} />}
                    />
                    <TransferTransactionData />
                </TransactionTicket>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: transferSelectors.getSummary(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
    loggedUser: sessionSelectors.getUser(state),
    fetching: transferSelectors.getFetching(state),
    idTransaction: transferSelectors.getIdTransaction(state),
    scheduledCancel: transferSelectors.getScheduledCancel(state),
    frequentDestination: frequentDestinationSelectors.getFrequentDestination(state),
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(TransferTransaction);

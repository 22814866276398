import React, { useEffect } from "react";
import { Field, withFormik, Form } from "formik";
import { func, bool, shape } from "prop-types";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { goBack } from "react-router-redux";
import { connect } from "react-redux";
import { compose } from "redux";
import Yup from "yup";

import { actions, selectors } from "reducers/checks";
import { actions as filesActions } from "reducers/files";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import isTokenActive from "util/token";
import EmitMassiveSummaryData from "./EmitMassiveSummaryData";

const FORM_ID = "echeq.massive.emit";
function EmitMassiveSummary({ isDesktop, dispatch, isSubmitting, isFetchingDownload, summary }) {
    const { file, account, amountToShow } = summary;

    const handleBack = () => {
        dispatch(goBack());
    };

    if (!file || !account || !amountToShow) {
        handleBack();
    }

    const handleDownloadErrorsClick = () => {
        const { fileId, fileName } = file;
        dispatch(filesActions.downloadFileRequest(fileId, fileName));
    };

    const confirmButton = (
        <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
            <div className="admin-content-center">
                <Button
                    image="images/arrowRight.svg"
                    imageStyle="mr-2"
                    type="submit"
                    bsStyle="primary"
                    label="global.confirm"
                    loading={isSubmitting}
                />
            </div>
        </Col>
    );

    const credentials = (
        <>
            <Row className="mt-2 mb-4">
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center">
                        <I18n
                            id="form.credential.otp.verify"
                            componentProps={{
                                className: classNames("font-light", {
                                    "f-size-65": !isDesktop,
                                    "f-size-55": isDesktop,
                                }),
                            }}
                        />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
            </Row>
            <Row>
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </Row>
        </>
    );

    return (
        <>
            <Notification scopeToShow="echeqMassive" />
            <div className="admin-detail-head px-0">
                <Head
                    onBack={handleBack}
                    title={!isDesktop ? FORM_ID : undefined}
                    titleClassName="text-white"
                    accessibilityTextId={FORM_ID}
                    headerClassName={classNames({
                        "blue-main-header-mobile": !isDesktop,
                    })}
                    centerContentClassName={classNames({
                        "mx-5": !isDesktop,
                    })}
                />
                {isDesktop && <h1 className="mt-1 mb-3">{i18nUtils.get(FORM_ID)}</h1>}
            </div>
            <Form className="confirmation__container">
                <Container
                    className="flex-grow align-items-center"
                    gridClassName="confirmation__form confirmation__box">
                    <Col sm={12} md={9} lg={12} className={!isDesktop && "cmf-container-white"}>
                        <Container
                            className={classNames({ "flex-grow align-items-center cmf-container-white": !isDesktop })}
                            gridClassName="form-content">
                            <div
                                className={classNames("admin-content-center", {
                                    "mb-3 px-4 pt-3": !isDesktop,
                                })}>
                                <I18n
                                    id={`${FORM_ID}.summary.checkInfo.label`}
                                    component={!isDesktop ? "h2" : "h1"}
                                    componentProps={{
                                        className: classNames({ "cmf-title-center f-size-35": !isDesktop }),
                                    }}
                                />
                            </div>
                            <Col className={`col-12 ${!isDesktop && "py-3 my-3"}`} lg={12} md={11} sm={12}>
                                <EmitMassiveSummaryData
                                    isDesktop={isDesktop}
                                    summary={summary}
                                    FORM_ID={FORM_ID}
                                    spaceBetween
                                />
                                <div className="d-flex justify-content-center mt-3">
                                    <Button
                                        block
                                        label={`${FORM_ID}.file.download.list`}
                                        loading={isFetchingDownload}
                                        onClick={() => handleDownloadErrorsClick()}
                                        className="btn-outline cmf-button-width"
                                        imageStyle="mr-2"
                                    />
                                </div>
                                {isDesktop && (
                                    <>
                                        <Col md={5} lg={12}>
                                            {credentials}
                                        </Col>
                                        <Container
                                            className="flex-grow align-items-center w-100"
                                            gridClassName="form-content">
                                            <Col lg={12} md={12} sm={12} className="px-0">
                                                {confirmButton}
                                            </Col>
                                        </Container>
                                    </>
                                )}
                            </Col>
                        </Container>
                    </Col>
                </Container>
                {!isDesktop && (
                    <Container
                        className="container--layaut flex-grow align-items-center cmf-container-white without-margin-top w-100"
                        gridClassName="form-content">
                        <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                            <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                {credentials}
                            </Col>
                            {confirmButton}
                        </Col>
                    </Container>
                )}
            </Form>
        </>
    );
}

EmitMassiveSummary.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool,
    isSubmitting: bool,
    summary: shape({}).isRequired,
    isFetchingDownload: bool,
};

EmitMassiveSummary.defaultProps = {
    isDesktop: true,
    isSubmitting: false,
    isFetchingDownload: false,
};

const mapStateToProps = (state) => ({
    summary: selectors.getEmitMassiveEcheqData(state),
    isFetchingDownload: selectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`form.credential.otp.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { props } = formikBag;
            const { dispatch, summary } = props;

            dispatch(actions.emitMassiveEcheqSend(otp, formikBag, summary));
        },
    }),
)(EmitMassiveSummary);

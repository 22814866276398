/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, createElement } from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { bool, func, instanceOf, number, shape, string } from "prop-types";
import { connect } from "react-redux";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import I18n from "pages/_components/I18n";
import AmountFilter from "pages/transactions/_components/AmountFilter";
import PeriodFilter from "pages/transactions/_components/PeriodFilter";
import LastUpdateFilter from "pages/transactions/_components/LastUpdateFilter";
import StatusFilter from "pages/transactions/_components/StatusFilter";
import TransactionFilter from "pages/transactions/_components/TransactionFilter";
import EnvironmentFilter from "pages/transactions/_components/EnvironmentFilter";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as i18n from "util/i18n";

const components = {
    creationDate: PeriodFilter,
    lastUpdate: LastUpdateFilter,
    amount: AmountFilter,
    environment: EnvironmentFilter,
    status: StatusFilter,
    transaction: TransactionFilter,
};

class HiddenFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        onlyPendings: bool,
        pendingDispatch: bool,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        filters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            minAmount: shape({ amount: number, currency: number }).isRequired,
            maxAmount: shape({ amount: number, currency: number }).isRequired,
            pageNumber: number,
            filter: string,
            idActivity: string,
        }),
    };

    static defaultProps = {
        fetching: false,
        onlyPendings: false,
        pendingDispatch: false,
        filters: null,
    };

    state = {
        selectedFilter: "",
    };

    componentWillMount() {
        this.setState({ selectedFilter: this.chargeSelectedFilter() });
    }

    compareByLabel = (a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        return a.label === b.label ? 0 : 1;
    };

    chargeSelectedFilter = () => {
        const { filters } = this.props;
        const filter = filters?.filter;
        const selectedFilter = filter && components[filter] ? filter : null;
        return selectedFilter;
    };

    handleChange = ({ value }) => {
        const { dispatch } = this.props;
        this.setState({ selectedFilter: value });
        dispatch(transactionsActions.setFilters(null));
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;

        const { dispatch, fetching, onlyPendings, pendingDispatch, filters, activeEnvironment } = this.props;
        let props = {
            ...filters,
            dispatch,
            isDesktop: true,
            fetching,
            onlyPendings,
            pendingDispatch,
            className: "cmf-button-width",
            buttonLabel: "transactions.list.filters.button.filter",
            showMonthYearDropdown: false,
            withSelector: true,
            selectedFilter,
            activeEnvironment,
            rightButton: true,
        };

        if (selectedFilter === "amount") {
            props = { ...props };
        }

        if (selectedFilter === "transaction") {
            props = { ...props, activity: filters?.idActivity };
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter } = this.state;

        const { activeEnvironment } = this.props;

        let options = [
            {
                value: "status",
                label: i18n.get("transactions.filters.searchBy.status"),
            },
            {
                value: "transaction",
                label: i18n.get("transactions.filters.searchBy.transaction"),
            },
            {
                value: "creationDate",
                label: i18n.get("transactions.filters.searchBy.creationDate"),
            },
            {
                value: "lastUpdate",
                label: i18n.get("transactions.filters.searchBy.lastUpdate"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
        ];

        if (activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE) {
            options = options.concat({
                value: "environment",
                label: i18n.get("accounts.movements.filters.searchBy.client"),
            });
        }

        options = options.sort(this.compareByLabel);

        return (
            <div>
                <Row className="filters">
                    <Col sm={12} md={4} lg={3}>
                        <div className="form-group form-group--select">
                            <div className="form-group-text">
                                <label htmlFor="searchBy" className="control-label">
                                    <I18n id="accounts.movements.filters.searchBy" />
                                </label>
                            </div>
                            <div className="input-group historic-list-filter">
                                <div style={{ flex: 1 }}>
                                    <Select
                                        name="searchBy"
                                        clearable={false}
                                        searchable
                                        onChange={this.handleChange}
                                        options={options}
                                        value={selectedFilter}
                                        optionClassName="needsclick"
                                        placeholder={i18n.get("transactions.filters.placeholder").toUpperCase()}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={8} lg={9} className="px-0">
                        {this.renderFilter()}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filters: transactionsSelectors.getFilters(state),
});

export default connect(mapStateToProps)(HiddenFilters);

import React from "react";
import { string } from "prop-types";

import * as i18n from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";

function SubscribeMinAmount({ currency, quantity }) {
    const parsedQuantity = quantity && Number(quantity.replace(",", "."));
    return (
        <div className="transfer-limits p-relative">
            <div className="item-limit justify-content-end">
                <span className="transfer-limit-label">{i18n.get("fund.operation.subscribe.minAmount.label")}</span>
                <FormattedAmount
                    className="transfer-limit-amount"
                    currency={currency}
                    quantity={parsedQuantity}
                    notBold
                />
            </div>
        </div>
    );
}

SubscribeMinAmount.propTypes = {
    currency: string,
    quantity: string,
};

SubscribeMinAmount.defaultProps = {
    currency: "0",
    quantity: "0",
};

export default SubscribeMinAmount;

import React, { Component } from "react";
import { string, arrayOf, shape, bool } from "prop-types";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Row from "react-bootstrap/lib/Row";
import classNames from "classnames";

const Title = ({ i18n }) => (
    <span className="data-label">
        <I18n id={i18n} />
    </span>
);

Title.propTypes = { i18n: string.isRequired };

const Line = ({ firstName, lastName, date, fullName, signatureLevel, isDesktop }) => (
    <div className={classNames({ "d-flex flex-wrap justify-content-right line-height-1i mb-2": isDesktop })}>
        <p className="data-desc text-right m-0 mr-1">
            {fullName || `${lastName}, ${firstName}`}
            {signatureLevel && ` (${signatureLevel})`}
            {" - "}
        </p>
        <p className="data-desc m-0 text-right">{date && <FormattedDate date={date} />}</p>
        <br />
    </div>
);

Line.propTypes = {
    firstName: string,
    lastName: string,
    date: string,
    fullName: string,
    signatureLevel: string,
    isDesktop: bool.isRequired,
};

Line.defaultProps = {
    firstName: null,
    lastName: null,
    date: null,
    fullName: null,
    signatureLevel: null,
};

export default class Signatures extends Component {
    static propTypes = {
        list: arrayOf(shape({})).isRequired,
        title: string.isRequired,
        isDesktop: bool.isRequired,
    };

    static Line = Line;

    static Title = Title;

    render() {
        const { list, title, isDesktop } = this.props;

        return (
            <Row
                className={classNames(
                    { "d-block": !isDesktop },
                    { "space-between flex-no-wrap align-items-start": isDesktop },
                )}>
                {isDesktop ? (
                    <FieldLabel controlLabelClassName="line-height-1i mb-md-25" labelKey={title} notMarginY />
                ) : (
                    <FieldLabel labelKey={title} notMarginY />
                )}

                <div className="d-flex flex-column">
                    {list.map((signature) => (
                        <Line
                            key={`${signature.firstName}${signature.lastName}${signature.fullName}`}
                            isDesktop={isDesktop}
                            {...signature}
                        />
                    ))}
                </div>
            </Row>
        );
    }
}

import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form } from "formik";
import moment from "moment";
import classNames from "classnames";

import AmountField from "pages/_components/fields/formik/AmountField";
import { DateField } from "pages/_components/fields/DateField";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import AlfaNumericStgField from "pages/_components/fields/AlfaNumericStgField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Dropdown from "pages/_components/Dropdown";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

const FORM_ID = "accounts.movements";

function MovementsFilters({
    dispatch,
    isDesktop,
    selectedAccount,
    isFetchingMovements,
    latestMovementsPageNumber,
    pendingMovementsPageNumber,
    findBy,
    isLatestMovementsSelected,
    lastDateFrom,
    lastDateTo,
    pendingDateFrom,
    pendingDateTo,
    amountFrom,
    amountTo,
    voucher,
    detail,
    movementType,
    getPendingMinDate,
    handleMovementToShow,
    setDates,
}) {
    const TYPE_OPTIONS = [
        {
            value: 1,
            label: i18nUtils.get("accounts.movements.findBy.type.label.credit").toUpperCase(),
        },
        {
            value: 0,
            label: i18nUtils.get("accounts.movements.findBy.type.label.debit").toUpperCase(),
        },
        {
            value: 2,
            label: i18nUtils.get("accounts.movements.findBy.type.label.all").toUpperCase(),
        },
    ];

    const BASE_OPTIONS = [
        {
            value: "period",
            label: i18nUtils.get("accounts.movements.findBy.period.label").toUpperCase(),
        },
        {
            value: "amount",
            label: i18nUtils.get("accounts.movements.findBy.amount.label").toUpperCase(),
        },
        {
            value: "detail",
            label: i18nUtils.get("accounts.movements.findBy.detail.label").toUpperCase(),
        },
    ];

    const [showForm, setShowForm] = useState(false);

    const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.${selectedAccount.currency}`) }];

    const getMaxDateFrom = () => {
        const maxDateFrom = configUtils.getInteger("accounts.max.dateFrom", 3);

        return moment()
            .startOf("day")
            .add(maxDateFrom * -1, "months");
    };

    const getLatestMovementsOptions = () => {
        const options = BASE_OPTIONS;

        options.push({
            value: "voucher",
            label: i18nUtils.get("accounts.movements.findBy.voucher.label").toUpperCase(),
        });

        return options;
    };

    const getPendingMovementsOptions = () => {
        const options = BASE_OPTIONS;

        options.push({
            value: "movementType",
            label: i18nUtils.get("accounts.movements.findBy.operations.label").toUpperCase(),
        });

        return options;
    };

    const isValidAmount = (amount) => amount && amount.amount.toString().length > 0;

    const isButtonDisabled =
        (findBy === "period" &&
            isLatestMovementsSelected &&
            ((!lastDateFrom && !lastDateTo) || (lastDateFrom && lastDateTo && lastDateFrom > lastDateTo))) ||
        (findBy === "period" &&
            !isLatestMovementsSelected &&
            ((!pendingDateFrom && !pendingDateTo) ||
                (pendingDateFrom && pendingDateTo && pendingDateFrom > pendingDateTo))) ||
        (findBy === "amount" &&
            ((!isValidAmount(amountFrom) && !isValidAmount(amountTo)) ||
                (isValidAmount(amountFrom) && isValidAmount(amountTo) && amountFrom.amount > amountTo.amount))) ||
        (findBy === "voucher" && !voucher) ||
        (findBy === "detail" && !detail) ||
        (findBy === "movementType" && typeof movementType !== "number" && !movementType);

    const handleChangeDate = (fieldName) => (selectedDate, form) => {
        const maxDays = configUtils.getInteger("movements.maxDays.default", 10);

        let dateTo;
        let dateFrom;
        let diff;

        if (fieldName === "pendingDateFrom") {
            diff = pendingDateTo ? Math.abs(moment(pendingDateTo)?.diff(selectedDate, "days")) : null;
            dateFrom = selectedDate;
            dateTo = diff && diff > maxDays ? moment(selectedDate)?.add(maxDays, "days") : pendingDateTo;
        } else {
            diff = pendingDateFrom ? moment(selectedDate)?.diff(pendingDateFrom, "days") : null;
            dateTo = selectedDate;
            dateFrom = diff && diff > maxDays ? moment(selectedDate)?.subtract(maxDays, "days") : pendingDateFrom;
        }

        form.setFieldValue("pendingDateTo", dateTo);
        form.setFieldValue("pendingDateFrom", dateFrom);
    };

    const handleFindBy = (findByValue, form) => {
        switch (findByValue) {
            case "period":
                setDates();
                break;
            case "amount":
                form.setFieldValue("amountFrom", null);
                form.setFieldValue("amountTo", null);
                break;
            case "voucher":
            case "detail":
            case "movementType":
                form.setFieldValue(findByValue, null);
                break;
            default:
                break;
        }
    };

    const handleExport = (format) => {
        const pageNumber = 1;
        const isLatestSelected = document.getElementById("lastMovements").children.length > 1;

        dispatch(
            accountsActions.downloadLatestMovements({
                format,
                isLatestMovementsSelected: isLatestSelected,
                latestMovementsPageNumber,
                pendingMovementsPageNumber,
                findBy,
                dateFrom: isLatestSelected ? lastDateFrom : pendingDateFrom,
                dateTo: isLatestSelected ? lastDateTo : pendingDateTo,
                selectedAccount,
                pageNumber,
                amountFrom,
                amountTo,
                voucher,
                detail,
            }),
        );
    };

    const downloadButton = (
        <div className="d-flex align-items-end ml-auto">
            <Dropdown
                image="images/download_bold.svg"
                listClassName="min-width-maxcontent"
                buttonClass={classNames("btn btn-outline", { "mb-3": isDesktop }, { "m-0": !isDesktop })}
                fetching={isFetchingMovements}
                imageStyle="px-4 m-0"
                pullDown>
                <Button
                    onClick={() => handleExport("pdf")}
                    label="global.file.pdf"
                    className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                />
                <Button
                    onClick={() => handleExport("xls")}
                    label="global.file.xls"
                    className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                />
                {isDesktop && (
                    <Button
                        onClick={() => handleExport("csv")}
                        label="global.file.csv"
                        className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
                    />
                )}
            </Dropdown>
        </div>
    );

    return (
        <Form autoComplete="off">
            <Container
                className={classNames("align-items-center account-header-detail mb-2", {
                    "backgroud-transparent": !isDesktop,
                })}
                gridClassName="form-content px-0"
                rowClassName="d-flex flex-wrap w-100 p-3 mx-0">
                <div
                    className={classNames("d-flex align-self-center account-button-filters mt-35", {
                        "w-100": !isDesktop,
                    })}>
                    <div
                        className={classNames("data-wrapper-flex align-items-end", {
                            "justify-content-center": !isDesktop,
                            "w-100": !isDesktop,
                            "gap-1": isDesktop,
                        })}>
                        <div
                            className={classNames({
                                "tablet-selector-button": !isDesktop,
                                "w-100": !isDesktop,
                            })}>
                            <Button
                                label={`${FORM_ID}.lastMovements.label`}
                                id="lastMovements"
                                className={classNames("btn-outline btn-regular chip-selector-btn", {
                                    selected: isLatestMovementsSelected,
                                    "py-2 px-0 m-0 account-movements-selector": !isDesktop,
                                    "py-25 mx-0": isDesktop,
                                })}
                                replace={{
                                    componentProps: { className: !isDesktop ? "p-0" : "px-0" },
                                }}
                                block={false}
                                image={isLatestMovementsSelected && `images/check.svg`}
                                onClick={() => handleMovementToShow(true)}
                            />
                        </div>
                        <div
                            className={classNames({
                                "tablet-selector-button": !isDesktop,
                                "w-100": !isDesktop,
                            })}>
                            <Button
                                label={`${FORM_ID}.pendingMovements.label`}
                                id="pendingMovements"
                                className={classNames("btn-outline btn-regular chip-selector-btn", {
                                    selected: !isLatestMovementsSelected,
                                    "px-0 m-0 account-movements-selector": !isDesktop,
                                    "py-25 mx-0 min-width-maxcontent": isDesktop,
                                })}
                                block={false}
                                image={!isLatestMovementsSelected && `images/check.svg`}
                                onClick={() => handleMovementToShow(false)}
                            />
                        </div>
                    </div>
                </div>
                {!isDesktop ? (
                    <div className="w-100 justify-content-end d-flex">
                        <Button
                            block
                            className="btn-link p-0 right"
                            label={showForm ? "global.hide.filter.upper" : "global.see.filter.upper"}
                            onClick={() => setShowForm(!showForm)}
                        />
                    </div>
                ) : (
                    undefined
                )}
                {isDesktop || showForm ? (
                    <>
                        {!isDesktop ? downloadButton : undefined}
                        <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                            <Field
                                component={Selector}
                                options={
                                    isLatestMovementsSelected
                                        ? getLatestMovementsOptions()
                                        : getPendingMovementsOptions()
                                }
                                idForm={FORM_ID}
                                name="findBy"
                                inLineControl
                                isRequired
                                placeholder={i18nUtils.get("accounts.movements.findBy.placeholder")}
                                style={{ padding: "12px 1rem" }}
                                onCustomChange={handleFindBy}
                            />
                        </div>
                        {findBy === "period" && (
                            <>
                                {isLatestMovementsSelected ? (
                                    <>
                                        <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                            <Field
                                                component={DateField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="lastDateFrom"
                                                selectsStart
                                                minDate={getMaxDateFrom()}
                                                maxDate={moment().startOf("day")}
                                                autocomplete="off"
                                            />
                                        </div>
                                        <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                            <Field
                                                component={DateField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="lastDateTo"
                                                selectsEnd
                                                minDate={lastDateFrom || getMaxDateFrom()}
                                                maxDate={moment().startOf("date")}
                                                autocomplete="off"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                            <Field
                                                component={DateField}
                                                hidePlaceholder
                                                handleCustomChange={handleChangeDate("pendingDateFrom")}
                                                idForm={FORM_ID}
                                                name="pendingDateFrom"
                                                selectsStart
                                                minDate={getPendingMinDate()}
                                                maxDate={getPendingMinDate().add(+3, "months")}
                                                autocomplete="off"
                                            />
                                        </div>
                                        <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                            <Field
                                                component={DateField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                handleCustomChange={handleChangeDate("pendingDateTo")}
                                                name="pendingDateTo"
                                                selectsEnd
                                                minDate={pendingDateFrom || getPendingMinDate()}
                                                maxDate={getPendingMinDate().add(+3, "months")}
                                                autocomplete="off"
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {findBy === "amount" && (
                            <>
                                <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                    <Field
                                        autocomplete="off"
                                        component={AmountField}
                                        data={{ options: currencies }}
                                        idForm={FORM_ID}
                                        name="amountFrom"
                                        clearable={false}
                                        label="transfers.amount.label"
                                        maxLength={15}
                                        disableSelect
                                        fixedDecimalScale
                                    />
                                </div>
                                <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                    <Field
                                        autocomplete="off"
                                        component={AmountField}
                                        data={{ options: currencies }}
                                        idForm={FORM_ID}
                                        name="amountTo"
                                        clearable={false}
                                        label="transfers.amount.label"
                                        maxLength={15}
                                        disableSelect
                                        fixedDecimalScale
                                    />
                                </div>
                            </>
                        )}
                        {findBy === "voucher" && isLatestMovementsSelected ? (
                            <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                <Field
                                    component={AlfaNumericStgField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name={findBy}
                                    type="text"
                                    maxLength="9"
                                    errorClassName="p-absolute"
                                    isNumeric
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {findBy === "detail" ? (
                            <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name={findBy}
                                    type="text"
                                    maxLength="200"
                                    errorClassName="p-absolute"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {findBy === "movementType" && !isLatestMovementsSelected && (
                            <div className={isDesktop ? "pr-3 w-224px" : "w-100"}>
                                <Field
                                    component={Selector}
                                    options={TYPE_OPTIONS}
                                    idForm={FORM_ID}
                                    name="movementType"
                                    inLineControl
                                    isRequired
                                    placeholder={i18nUtils.get("accounts.movements.findBy.placeholder")}
                                    style={{ padding: "12px 1rem" }}
                                />
                            </div>
                        )}
                        <div className={isDesktop ? "d-flex align-items-end pr-3 pl-0" : "w-100"}>
                            <Button
                                bsStyle="primary"
                                label={`${FORM_ID}.btn.filter.label`}
                                loading={isFetchingMovements}
                                type="submit"
                                className={isDesktop ? "filter-button mb-3" : ""}
                                disabled={isButtonDisabled}
                            />
                        </div>
                        {isDesktop ? downloadButton : undefined}
                    </>
                ) : (
                    undefined
                )}
            </Container>
        </Form>
    );
}

const mapStateToProps = (state) => ({
    isFetchingMovements: accountsSelectors.getFetchingMovements(state),
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    latestMovementsPageNumber: accountsSelectors.getLatestMovementsPageNumber(state),
    pendingMovementsPageNumber: accountsSelectors.getPendingMovementsPageNumber(state),
});

MovementsFilters.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isDesktop: PropTypes.func.isRequired,
    selectedAccount: PropTypes.shape({
        currency: PropTypes.string,
    }).isRequired,
    findBy: PropTypes.string.isRequired,
    isLatestMovementsSelected: PropTypes.bool.isRequired,
    lastDateFrom: PropTypes.string.isRequired,
    lastDateTo: PropTypes.string.isRequired,
    pendingDateFrom: PropTypes.string.isRequired,
    pendingDateTo: PropTypes.string.isRequired,
    amountFrom: PropTypes.number.isRequired,
    amountTo: PropTypes.number.isRequired,
    voucher: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
    movementType: PropTypes.string.isRequired,
    isFetchingMovements: PropTypes.bool.isRequired,
    latestMovementsPageNumber: PropTypes.number.isRequired,
    pendingMovementsPageNumber: PropTypes.number.isRequired,
    getPendingMinDate: PropTypes.func.isRequired,
    handleMovementToShow: PropTypes.func.isRequired,
    setDates: PropTypes.func.isRequired,
};

export default compose(connect(mapStateToProps))(MovementsFilters);

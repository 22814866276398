import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import * as i18n from "util/i18n";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";

class AccountOpeningDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        const tableLabel = "table.accountOpening";
        return selectedTransactionRows.map((object) => {
            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{object.transaction.idTransaction.substring(0, 8).toUpperCase()}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>
                                {i18n.get(`accounts.new.account.accountType.${object?.transaction?.data?.accountType}`)}
                            </span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>
                                {i18n.get(`accounts.new.account.currency.${object?.transaction?.data?.currency}`)}
                            </span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{object?.transaction?.data?.message}</span>
                        </Table.Data>
                    </Table.Row>
                );
            }

            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="table.transferInternal.idTransaction"
                        data={<span>{object.transaction.idTransaction.substring(0, 8).toUpperCase()}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.accountType`}
                        data={
                            <span>
                                {i18n.get(`accounts.new.account.accountType.${object?.transaction?.data?.accountType}`)}
                            </span>
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.currency`}
                        data={
                            <span>
                                {i18n.get(`accounts.new.account.currency.${object?.transaction?.data?.currency}`)}
                            </span>
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.message`}
                        data={<span>{object?.transaction?.data?.message}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="table.transferInternal.idTransaction" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.accountOpening.accountType" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.accountOpening.currency" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.accountOpening.message" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AccountOpeningDataTable);

import { call, put, takeLatest, select } from "redux-saga/effects";
import { store } from "store";

import { types, actions, selectors } from "reducers/token";

import * as tokenMidleware from "middleware/token";

import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import getLoggedUserId from "util/session";

import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import isTokenActive from "util/token";
import { adjustIdFieldErrors } from "util/form.js";

import * as deviceUtils from "util/device";

const sagas = [
    takeLatest(types.TOKEN_CREATE_PREVIEW_REQUEST, tokenCreatePreview),
    takeLatest(types.TOKEN_CREATE_REQUEST, tokenCreate),

    takeLatest(types.TOKEN_ACTIVATION_PRE_REQUEST, tokenActivationPre),
    takeLatest(types.TOKEN_ACTIVATION_PREVIEW_REQUEST, tokenActivationPreview),
    takeLatest(types.TOKEN_ACTIVATION_REQUEST, tokenActivation),

    takeLatest(types.CHECK_USER_ACTIVE_TOKEN, checkUserActiveTokenWeb),

    takeLatest(types.TOKEN_CHANGE_PASSWORD_REQUEST, changeTokenPass),

    takeLatest(types.TOKEN_RECOVER_PASSWORD_REQUEST, recoverTokenPass),

    takeLatest(types.TOKEN_PASSWORD_VALIDATION_REQUEST, tokenPasswordValidation),
    takeLatest(types.TOKEN_PASSWORD_GENERATION_REQUEST, tokenGeneration),
    
    takeLatest(types.EXCHANGE_TOKEN_REFRESH_REQUEST, refreshExchangeToken),
    takeLatest(types.EXCHANGE_TOKEN_REFRESH_CHECK, checkExchangeToken),

    takeLatest(types.TOKEN_PASSWORD_DISABLED_REQUEST, tokenPasswordDisabled),

    takeLatest(types.TOKEN_STATUS_PASSWORD_REQUEST, getTokenStatusMobile),

    takeLatest(types.TOKEN_OTHER_DEVICES_NEW_TOKEN, changeNewToken),

    takeLatest(types.TOKEN_SYNC_NEW_DEVICE_PREVIEW_REQUEST, tokenOTPValidation),

    takeLatest(types.TOKEN_SYNC_NEW_DEVICE_REQUEST, tokenSyncNewDevice),

    takeLatest(types.TOKEN_SEND_NEW_CODE_REQUEST, tokenActivationSendNewCode),

    takeLatest(types.TOKEN_QUERY_CREDENTIAL, tokenQueryCredential),

    takeLatest(types.TOKEN_GENERATED_VALUE, getGeneratedTokenValue),

    takeLatest(types.TOKEN_RECOVER_ANONYMOUS_REQUEST, recoverTokenAnonymous),
];

export default sagas;

function* tokenCreatePreview(params) {
    const { idForms, userId } = params;

    let idDevice = null;

    if (window.app) {
        idDevice = window.app.getDeviceUUID();
    } else {
        idDevice = yield deviceUtils.getDeviceFingerprint();
    }
    if (idDevice) {
        const response = yield call(tokenMidleware.tokenCreatePreview, idDevice, idForms, userId);
        if (response.type === "W") {
            yield put(actions.tokenCreatePreviewFailed());
            if (response.data.data.NO_FIELD) {
                yield put(
                    notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                        "tokenCreateCodeInvitation",
                    ]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "tokenCreateCodeInvitation",
                    ]),
                );
            }
        } else {
            yield put(actions.tokenCreatePreviewSuccess(response.data.data));
        }
    }
}

function* tokenCreate(params) {
    const response = yield call(tokenMidleware.tokenCreate, params.sendChannel);
    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            yield put(actions.tokenCreateFailed());

            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        } else {
            yield put(actions.tokenCreateFailed());
            yield put(
                notificationActions.showNotification(response.data.message, "error", ["tokenCreateCodeInvitation"]),
            );
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("setting.token.request.success"), "success", [
                "tokenCreateCodeInvitation",
            ]),
        );
        yield put(actions.checkUserActiveToken());

        yield put(actions.tokenCreateSuccess(response.data.data));
    }
}

function* tokenQueryCredential(params) {
    if (isTokenActive()) {
        yield call(getGeneratedTokenValue, params);
    }
}

function* getGeneratedTokenValue(params) {
    const { form, field } = params;

    const idDevice = window.app.getDeviceUUID();

    const response = yield call(tokenMidleware.tokenGeneration, idDevice);

    if (response.type === "W") {
        yield put(notificationActions.showNotification("Error interno", "error", ["tokenPassValidation"]));
    } else if (form && field) {
        const { _tokenNumber } = response.data.data;
        form.setValues({ ...form.values, [field.name]: _tokenNumber });
    }
}

function* tokenActivationPre() {
    const response = yield call(tokenMidleware.tokenActivationPre);

    if (response.type === "W") {
        yield put(actions.tokenActivationPreFailed());

        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        }
    } else {
        if (!response.data.data.isQuery) {
            yield put(actions.tokenActivationPreFailed());
            yield put(
                notificationActions.showNotification(
                    i18n.get("setting.token.mobile.activation.request.failure"),
                    "warning",
                    ["tokenCreateCodeInvitation"],
                ),
            );
        } else {
            yield put(push("/settings/token/codeActivation"));
        }
        yield put(actions.tokenActivationPreSuccess(response.data.data));
    }
}

function* tokenActivationPreview(params) {
    const code = params.codeInvite;
    let response = yield call(tokenMidleware.tokenActivationPreview, code);

    if (response.type === "W") {
        yield put(actions.tokenActivationPreviewFailed());

        if (response.data.data.NO_FIELD) {
            if (response.data.data.NO_FIELD === "setting.token.activation.newCode.limitError") {
                response = yield call(tokenMidleware.tokenActivationSendNewCode);
                if (response.type === "W") {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("setting.token.activation.newCode.failed"),
                            "error",
                            ["tokenCreateCodeInvitation"],
                        ),
                    );
                } else {
                    yield put(
                        notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                            "tokenCreateCodeInvitation",
                        ]),
                    );
                }
            } else {
                yield put(
                    notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                        "tokenCreateCodeInvitation",
                    ]),
                );
            }
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        }
    } else {
        yield put(actions.tokenActivationPreviewSuccess(response.data.data));
        yield put(push("/settings/token/codeActivation/changuedPassword"));
    }
}

function* tokenActivationSendNewCode() {
    const response = yield call(tokenMidleware.tokenActivationResendCode);

    if (response.type === "W") {
        const message = response.data?.data?.NO_FIELD;

        yield put(actions.tokenActivationSendNewCodeFailed());
        yield put(
            notificationActions.showNotification(
                message || i18n.get("setting.token.activation.newCode.failed"),
                "error",
                ["tokenCreateCodeInvitation"],
            ),
        );
    } else {
        yield put(actions.tokenActivationSendNewCodeSuccess(response.data.data));
        yield put(
            notificationActions.showNotification(i18n.get("setting.token.activation.newCode.success"), "success", [
                "tokenCreateCodeInvitation",
            ]),
        );
    }
}

function* checkUserActiveTokenWeb() {
    const response = yield call(tokenMidleware.getTokenStatus);
    const { statusToken } = response.data.data;

    yield put({
        type: types.CHECK_USER_ACTIVE_TOKEN_SUCCESS,
        webTokenStatus: statusToken,
    });
}

function* tokenActivation(params) {
    const { password, passwordConfirmation, formikBag } = params;
    const idDevice = window.app.getDeviceUUID();
    const response = yield call(tokenMidleware.tokenActivation, password, passwordConfirmation, idDevice);
    if (response.type === "W") {
        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        } else if (response.data.code === "API527W") {
            formikBag.setErrors({ password: response.data.message });
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        }
        yield put(actions.tokenActivationFailed());
    } else {
        yield put(push("/settings/token"));
        if (window.isMobileApp()) {
            const userId = getLoggedUserId();
            yield put({
                type: types.TOKEN_STATUS_PASSWORD_REQUEST,
                userId,
            });
        }
        yield put(actions.tokenActivationSuccess());
    }
}

function* changeTokenPass({ password, newPassword, newPasswordConfirmation, formikBag }) {
    const response = yield call(tokenMidleware.changeTokenPass, password, newPassword, newPasswordConfirmation);
    if (response.type === "W") {
        const errorMessage =
            response.data?.data?.NO_FIELD || response.data.message || i18n.get("global.unexpectedError");
        yield put(notificationActions.showNotification(errorMessage, "error", ["changeTokenPass"]));
    } else if (response.data?.data?._result === "INVALIDO") {
        yield put(
            notificationActions.showNotification(i18n.get("settings.token.password.invalid"), "error", [
                "changeTokenPass",
            ]),
        );
    } else if (response.data?.data?._result === "DISABLED") {
        yield put(
            notificationActions.showNotification(i18n.get("settings.token.password.disabled"), "error", [
                "changeTokenPass",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("settings.token.changePassword.modify.success"), "success", [
                "tokenCreateCodeInvitation",
            ]),
        );
        yield put(push("/settings/token"));
    }
    formikBag.setSubmitting(false);
}

function* recoverTokenPass({ username, documentType, document, password, sendChannel, formikBag }) {
    const user = yield select(sessionSelectors.getUser);

    try {
        const response = yield call(
            user ? tokenMidleware.recoverTokenPass : tokenMidleware.recoverTokenPassAnonymous,
            username,
            documentType,
            document,
            password,
            sendChannel,
        );
        if (response.type === "W" || (response.data.data.error !== null && response.data.data.error !== undefined)) {
            yield put(actions.recoverTokenPasswordFailure());
            if (response.data.data.NO_FIELD) {
                yield put(
                    notificationActions.showNotification(response.data.data.NO_FIELD, "error", ["recoverTokenPass"]),
                );
            }
            if (response.data.data.error) {
                yield put(
                    notificationActions.showNotification(i18n.get(response.data.data.error), "error", [
                        "recoverTokenPass",
                    ]),
                );
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["recoverTokenPass"]));
            }
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("settings.token.recoverPassword.password.success"),
                    "success",
                    ["tokenCreateCodeInvitation", "login"],
                ),
            );
            yield put(actions.recoverTokenPasswordSuccess(response.data.data));
            yield put(push("/settings/token"));
        }
    } catch (error) {
        formikBag.setErrors(adjustIdFieldErrors(error));
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* tokenPasswordValidation({ password, formikBag, userId }) {
    const idDevice = window.app.getDeviceUUID();
    const response = yield call(tokenMidleware.tokenPasswordValidationExternal, password, userId, idDevice);
    try {
        if (
            response.type === "W" ||
            (response.data.data._result !== null &&
                response.data.data._result !== undefined &&
                response.data.data._result !== "VALIDO")
        ) {
            yield put(actions.tokenPasswordValidationFailed());
            if (response.data.data._result === "INVALIDO") {
                yield put(
                    notificationActions.showNotification(i18n.get("settings.token.password.invalid"), "error", [
                        "tokenPassValidation",
                    ]),
                );
            } else if (response.data.data._result === "DISABLED") {
                yield put(
                    notificationActions.showNotification(i18n.get("settings.token.password.disabled"), "error", [
                        "tokenPassValidation",
                    ]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["tokenPassValidation"]),
                );
            }
        } else {
            const { _exchangeToken, showNotificationIn } = response.data.data;
            yield put({
                type: types.TOKEN_PASSWORD_VALIDATION_SUCCESS,
                exchangeToken: _exchangeToken,
                showNotificationIn
            });
            if (!_exchangeToken) {
                yield put(push("/settings/token/tokenRandomCode"));
            } else {
                yield put(push("/tokenRandomCode"));
            }
        }
    } catch (error) {
        yield put(notificationActions.showNotification(error.message, "error", ["tokenPassValidation"]));
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* refreshExchangeToken(params) {
    const { exchangeToken, userId } = params;
    const idDevice = window.app.getDeviceUUID();
    const response = yield call(tokenMidleware.refreshTokenExternal, idDevice, userId, exchangeToken);

    if (response.data.code !== "COR000I") {
        yield put(push("/tokenPassword"))
        yield put(notificationActions.showNotification(response.data.message, "error", ["tokenPassValidation"]));
    } else {
        const { _exchangeToken, showNotificationIn } = response.data.data;
        if (_exchangeToken) {
                yield put({
                    type: types.EXCHANGE_TOKEN_REFRESH_SUCCESS,
                    exchangeToken: _exchangeToken,
                    showNotificationIn
                });
            } else {
                yield put(push("/tokenPassword"));
            }
    }
}

function* checkExchangeToken(params) {
    const { exchangeToken, userId } = params;
    const idDevice = window.app.getDeviceUUID();
    const response = yield call(tokenMidleware.checkTokenExternal, idDevice, userId, exchangeToken);

    if (response.data.code !== "COR000I" || !response.data.data._result) {
        yield put(push("/tokenPassword"))
        yield put(notificationActions.showNotification(response.data.message, "error", ["tokenPassValidation"]));
    }
}

function* tokenGeneration(params) {
    const { exchangeToken } = params;
    const idDevice = window.app.getDeviceUUID();

    let resp;
    let code;
    // We use this do/while for patch when the response give us a repeated token if it is used on the window number one
    // It fix the ticket ARBCMFIMP-6027
    do {
        const response = yield call(tokenMidleware.tokenGenerationExternal, idDevice, exchangeToken);
        resp  = response;
        code  = response.data.data._tokenNumber;
    } while (!code || (code === store.getState().token.tokenNumber && code!=="111111"));

    if (resp.type === "W") {
        yield put(notificationActions.showNotification("Error interno", "error", ["tokenPassValidation"]));
    } else {
        yield put(actions.tokenPasswordGenerationSuccess(resp.data.data));
    }
}

function* tokenPasswordDisabled(params) {
    const { password, formikBag } = params;
    const response = yield call(tokenMidleware.tokenPasswordDisabled, password);

    if (
        response.type === "W" ||
        (response.data.data._result !== null &&
            response.data.data._result !== undefined &&
            response.data.data._result !== "VALIDO")
    ) {
        if (response.data.data._result === "INVALIDO") {
            yield put(
                notificationActions.showNotification(i18n.get("settings.token.password.invalid"), "error", [
                    "changeTokenPassDisabled",
                ]),
            );
        } else if (response.data.data._result === "DISABLED") {
            yield put(
                notificationActions.showNotification(i18n.get("settings.token.password.disabled"), "error", [
                    "changeTokenPassDisabled",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(response.data.message, "error", ["changeTokenPassDisabled"]),
            );
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("settings.token.disabledTokenPass.success"), "success", [
                "tokenCreateCodeInvitation",
            ]),
        );
        yield put(push("/settings/token"));
    }
    formikBag.setSubmitting(false);
}

// Se utiliza solo en mobile, guarda el estado y userId en la memoria de la aplicacion
function* getTokenStatusMobile(params) {
    const { userId } = params;

    const idDevice = window.app.getDeviceUUID();

    const response = yield call(tokenMidleware.getTokenStatus, idDevice);

    if (response.type === "W") {
        yield put(actions.tokenStatusPasswordFailed());
    } else {
        yield put({
            type: types.TOKEN_STATUS_PASSWORD_SUCCESS,
            statusToken: response.data.data.statusToken,
            userIdToken: userId,
        });
    }
}

function* changeNewToken() {
    const response = yield call(tokenMidleware.changeNewToken);
    if (response.type === "W") {
        yield put(actions.tokenCreatePreviewFailed());

        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "tokenCreateCodeInvitation",
                ]),
            );
        }
    } else {
        yield put(push("/settings/token"));
    }
}

function* tokenOTPValidation({ codeRandom, formikBag }) {
    const response = yield call(tokenMidleware.tokenSyncNewDevicePreview, codeRandom);

    if (response.type === "W" || response.data.data.message !== null) {
        yield put(actions.tokenSyncNewDevicePreviewFailed());

        yield put(
            notificationActions.showNotification(i18n.get(response.data.data.message), "error", [
                "tokenValidateRandomCode",
            ]),
        );
    } else {
        yield put(actions.tokenSyncNewDevicePreviewSuccess(response.data.data));
        yield put(push("/settings/token/synchronization/tokenPassword"));
    }
    formikBag.setSubmitting(false);
}

function* recoverTokenAnonymous({ values, formikBag, config }) {
    const response = yield call(tokenMidleware.recoverTokenAnonymous, values);

    if (response.type === "W" || response.data.data.error) {
        yield put(actions.tokenRecoverAnonymousFailed());

        yield put(
            notificationActions.showNotification(
                i18n.get(response.type === "W" ? response.message : response.data.data.error),
                "error",
                ["recoverTokenPass"],
            ),
        );
    } else {
        yield put(actions.tokenRecoverAnonymousSuccess(response.data.data));

        if (config) {
            const resp = yield select(selectors.getTokenAnonymousData);

            yield put(actions.configureSelectChannel({ ...config, ...resp }));

            yield put(push("/settings/token/selectChannel"));
        }
    }
    formikBag.setSubmitting(false);
}

function* tokenSyncNewDevice({ password, formikBag }) {
    let idDevice = null;

    if (window.app) {
        idDevice = window.app.getDeviceUUID();
    } else {
        idDevice = yield deviceUtils.getDeviceFingerprint();
    }

    const response = yield call(tokenMidleware.tokenSyncNewDevice, password, idDevice);
    if (
        response.type === "W" ||
        (response.data.data._result !== null &&
            response.data.data._result !== undefined &&
            response.data.data._result !== "VALIDO")
    ) {
        yield put(actions.tokenPasswordValidationFailed());
        if (response.data.data._result === "INVALIDO") {
            yield put(
                notificationActions.showNotification(i18n.get("settings.token.password.invalid"), "error", [
                    "tokenPassValidation",
                ]),
            );
        } else if (response.data.data._result === "DISABLED") {
            yield put(
                notificationActions.showNotification(i18n.get("settings.token.password.disabled"), "error", [
                    "tokenPassValidation",
                ]),
            );
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["tokenPassValidation"]));
        }
    } else {
        if (window.isMobileApp()) {
            const userId = getLoggedUserId();
            yield put({
                type: types.TOKEN_STATUS_PASSWORD_REQUEST,
                userId,
            });
        }
        yield put(
            notificationActions.showNotification(i18n.get("settings.token.validate.codeRandom.success"), "success", [
                "tokenCreateCodeInvitation",
            ]),
        );
        yield put(push("/settings/token"));
    }
    formikBag.setSubmitting(false);
}

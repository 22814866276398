import React from "react";
import { bool, shape, number, string, func } from "prop-types";
import classNames from "classnames";

import getRescueDeadlineLabel from "util/fundcorp";
import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

function SelectedFundInfo({ isDesktop, selectedFundFeature, deselectFundFeature }) {
    const { nombre, monedaDescripcion, rescatePlazo, regionDescripcion: nivelRiesgo } = selectedFundFeature;
    return (
        <div
            className={classNames(" d-flex space-between w-100 px-0", {
                "px-3": !isDesktop,
                "pt-4": isDesktop,
            })}>
            <div className={classNames("w-100 px-md-3", { container: isDesktop })}>
                <div className={classNames("d-flex")}>
                    <I18n
                        id="fund.info.label.fund"
                        component="p"
                        componentProps={{ className: "f-size-55 text-uppercase pr-1 mb-0" }}
                    />
                    <p className="f-size-55 font-light text-uppercase pr-1 mb-0">{nombre}</p>
                </div>
                <div className={classNames("d-flex")}>
                    <I18n
                        id="fund.info.label.description"
                        component="p"
                        componentProps={{ className: "f-size-55 text-uppercase pr-1 mb-0" }}
                    />
                    <p className="f-size-55 font-light text-uppercase pr-1 mb-0">
                        {i18n.get("fund.info.label.rentaFija")} {monedaDescripcion}
                    </p>
                </div>
                <div className={classNames("d-flex")}>
                    <I18n
                        id="fund.info.label.rescueTerm"
                        component="p"
                        componentProps={{ className: "f-size-55 text-uppercase pr-1 mb-0" }}
                    />
                    <p className="f-size-55 font-light text-uppercase pr-1 mb-0">
                        {getRescueDeadlineLabel(rescatePlazo)}
                    </p>
                </div>
                <div className={classNames("d-flex")}>
                    <I18n
                        id="fund.info.label.risk"
                        component="p"
                        componentProps={{ className: "f-size-55 text-uppercase pr-1 mb-0" }}
                    />
                    <p className="f-size-55 font-light text-uppercase pr-1 mb-0">{nivelRiesgo}</p>
                </div>
            </div>
            <div className="align-items-start">
                <button className="btn-link pt-2" type="button" onClick={deselectFundFeature}>
                    <Image src="images/cross-mobile-blue.svg" styles={{ width: "25px", height: "25px" }} />
                </button>
            </div>
        </div>
    );
}

SelectedFundInfo.propTypes = {
    isDesktop: bool.isRequired,
    selectedFundFeature: shape({
        numero: number,
        rescatePlazo: number,
        suscripcionPlazo: number,
        nombre: string,
        estaAnulado: bool,
        horizonteInversionDescripcion: string,
        sociedadGerenteDescripcion: string,
        moneda: string,
        monedaDescripcion: string,
        regionDescripcion: string,
    }).isRequired,
    deselectFundFeature: func.isRequired,
};

export default SelectedFundInfo;

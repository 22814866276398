import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { Col } from "react-bootstrap";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";

class AdminUserInviteDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { firstName, lastName, email, mobilePhone },
            } = object.transaction;
            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{submitDateTimeAsString}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{firstName}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{lastName}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <span>{email}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <span>{mobilePhone}</span>
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="forms.transaction.ticket.date"
                        data={<span>{submitDateTimeAsString}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="administration.users.invite.firstName.label"
                        data={<span>{firstName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="administration.users.invite.lastName.label"
                        data={<span>{lastName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="administration.users.invite.email.label"
                        data={<span>{email}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="administration.users.invite.mobilePhone.label"
                        data={<span>{mobilePhone}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="administration.users.invite.firstName.label" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="administration.users.invite.lastName.label" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="administration.users.invite.email.label" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="administration.users.invite.mobilePhone.label" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AdminUserInviteDataTable);

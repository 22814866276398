import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from "pages/_components/Button";
import { string, arrayOf, func, bool, number } from "prop-types";
import { push } from "react-router-redux";
import moment from "moment";

import { selectors, actions } from "reducers/checks";
import { echeqShape } from "util/checks";
import * as configUtils from "util/config";

import Col from "react-bootstrap/lib/Col";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import RepudiateEcheq from "pages/checks/Echeqs/EcheqActions/RepudiateEcheq";
import DepositEcheq from "pages/checks/Echeqs/EcheqActions/DepositEcheq";
import CustodyEcheq from "pages/checks/Echeqs/EcheqActions/CustodyEcheq";
import Container from "pages/_components/Container";

class MultipleEcheqsButtons extends Component {
    static propTypes = {
        environmentCuit: string.isRequired,
        selectedRows: arrayOf(echeqShape).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        echeqsListTotal: number.isRequired,
    };

    state = {
        showDrawer: false,
        contentDrawer: "",
    };

    receivedEcheqAction = (action) => {
        const { selectedRows, dispatch, isDesktop, echeqsListTotal } = this.props;
        const echeqList = selectedRows;

        dispatch(actions.setEcheqsToDoActions({ action, echeqList, echeqsListTotal }));

        switch (action) {
            case "ADMIT": {
                dispatch(push("/echeqs/action/confirmation"));
                break;
            }
            case "REPUDIATE": {
                this.setState({
                    showDrawer: true,
                    contentDrawer: <RepudiateEcheq isDesktop={isDesktop} />,
                    title: !isDesktop && "echeqs.action.motive.title",
                });
                break;
            }
            case "DEPOSIT": {
                this.setState({
                    showDrawer: true,
                    contentDrawer: <DepositEcheq isDesktop={isDesktop} />,
                    title: !isDesktop && "echeqs.action.deposit.title",
                });
                break;
            }
            case "CUSTODY": {
                this.setState({
                    showDrawer: true,
                    contentDrawer: <CustodyEcheq isDesktop={isDesktop} />,
                    title: !isDesktop && "echeqs.action.custody.title",
                });
                break;
            }
            case "RESCUE": {
                dispatch(push("/echeqs/action/confirmation"));
                break;
            }
            case "ENDORSE": {
                dispatch(push("/echeqs/action/endorse"));
                break;
            }
            case "EMIT-CESION": {
                dispatch(push("/echeqs/action/cesion"));
                break;
            }
            case "NEGOTIATE": {
                dispatch(push("/echeqs/action/negotiate"));
                break;
            }

            default:
                break;
        }
    };

    checkEnableAdmit = (button) => {
        const { environmentCuit, selectedRows } = this.props;
        const echeqsActivoPendienteAutomationEnabled = configUtils.getBoolean(
            "echeq.config.automation.activo.pendiente.enabled",
        );
        const echeqsCesionPendienteAutomationEnabled = configUtils.getBoolean(
            "echeq.config.automation.cesion.pendiente.enabled",
        );
        const echeqsEmitidoPendienteAutomationEnabled = configUtils.getBoolean(
            "echeq.config.automation.emitido.pendiente.enabled",
        );
        const echeqsActivoPendienteAutomationDiscountEnabled = configUtils.getBoolean(
            "echeq.config.automationDiscount.activo.pendiente.enabled",
        );
        const echeqsCesionPendienteAutomationDiscountEnabled = configUtils.getBoolean(
            "echeq.config.automationDiscount.cesion.pendiente.enabled",
        );
        const echeqsEmitidoPendienteAutomationDiscountEnabled = configUtils.getBoolean(
            "echeq.config.automationDiscount.emitido.pendiente.enabled",
        );
        const echeqsCustodyAutomationDiscountEnabled = configUtils.getBoolean(
            "echeq.config.automationDiscount.custody.enabled",
        );

        let disable = !selectedRows.length > 0;
        if (button === "ADMIT" || button === "REPUDIATE") {
            selectedRows.forEach((echeq) => {
                if (
                    (echeq.estado === "ACTIVO-PENDIENTE" &&
                        (echeq.total_endosos === 0 || echeq.tenencia_beneficiario_documento !== environmentCuit)) ||
                    echeq.estado === "ACTIVO" ||
                    echeq.estado === "CESION-PENDIENTE" ||
                    echeq.estado === "CUSTODIA"
                ) {
                    disable = true;
                }
            });
        }
        switch (button) {
            case "CUSTODY": {
                selectedRows.forEach((echeq) => {
                    const validDateForCustody = moment(echeq.fecha_pago) > moment();
                    if (validDateForCustody) {
                        if (
                            echeq.estado !== "ACTIVO" &&
                            !(echeq.estado === "ACTIVO-PENDIENTE" && echeqsActivoPendienteAutomationEnabled) &&
                            !(echeq.estado === "EMITIDO-PENDIENTE" && echeqsEmitidoPendienteAutomationEnabled) &&
                            !(echeq.estado === "CESION-PENDIENTE" && echeqsCesionPendienteAutomationEnabled)
                        ) {
                            disable = true;
                        }
                    } else {
                        disable = true;
                    }
                });
                break;
            }
            case "DEPOSIT": {
                selectedRows.forEach((echeq) => {
                    const fechaPago = moment(echeq.fecha_pago)
                        .utc()
                        .format("YYYY-MM-DD");
                    const fechaHoy = moment()
                        .utc()
                        .format("YYYY-MM-DD");
                    const diff = Math.abs(moment(fechaPago).diff(moment(fechaHoy), "days"));
                    const validDateForDeposit = (fechaPago < fechaHoy && diff <= 30) || diff === 0;
                    if (validDateForDeposit) {
                        if (
                            echeq.estado !== "ACTIVO" &&
                            !(echeq.estado === "ACTIVO-PENDIENTE" && echeqsActivoPendienteAutomationEnabled) &&
                            !(echeq.estado === "EMITIDO-PENDIENTE" && echeqsEmitidoPendienteAutomationEnabled) &&
                            !(echeq.estado === "CESION-PENDIENTE" && echeqsCesionPendienteAutomationEnabled)
                        ) {
                            disable = true;
                        }
                    } else {
                        disable = true;
                    }
                });
                break;
            }
            case "ENDORSE": {
                selectedRows.forEach((echeq) => {
                    if (echeq.estado !== "ACTIVO" || echeq.cheque_caracter.toLowerCase() === "no a la orden") {
                        disable = true;
                    }
                });
                break;
            }
            case "CESION": {
                selectedRows.forEach((echeq) => {
                    if (echeq.estado !== "ACTIVO" || echeq.cheque_caracter.toLowerCase() !== "no a la orden") {
                        disable = true;
                    }
                });
                break;
            }
            case "NEGOTIATE": {
                selectedRows.forEach((echeq) => {
                    if (
                        echeq.estado !== "ACTIVO" &&
                        !(echeq.estado === "ACTIVO-PENDIENTE" && echeqsActivoPendienteAutomationDiscountEnabled) &&
                        !(echeq.estado === "EMITIDO-PENDIENTE" && echeqsEmitidoPendienteAutomationDiscountEnabled) &&
                        !(echeq.estado === "CESION-PENDIENTE" && echeqsCesionPendienteAutomationDiscountEnabled) &&
                        !(echeq.estado === "CUSTODIA" && echeqsCustodyAutomationDiscountEnabled)
                    ) {
                        disable = true;
                    }
                });
                break;
            }
            default: {
                break;
            }
        }

        return disable;
    };

    render() {
        const { isDesktop, echeqsListTotal } = this.props;
        const { showDrawer, contentDrawer, title } = this.state;
        return (
            <>
                {showDrawer && (
                    <DrawerModal
                        content={contentDrawer}
                        width={isDesktop ? "30%" : "100%"}
                        isDesktop={isDesktop}
                        title={title}
                        showDrawerRemote={showDrawer}
                        closeRemotely={() => this.setState({ showDrawer: false, contentDrawer: "" })}
                        drawerClassName="echeq__drawer"
                    />
                )}
                {echeqsListTotal > 0 && (
                        <div className="w-100 d-flex flex-flow-wrap">
                            <Button
                                bsStyle="primary"
                                className="echeq__action-button"
                                label="echeq.admit.echeq"
                                type="button"
                                disabled={this.checkEnableAdmit("ADMIT")}
                                onClick={() => this.receivedEcheqAction("ADMIT")}
                            />
                            <Button
                                bsStyle="primary"
                                className="echeq__action-button"
                                label="echeq.repudiate.echeq"
                                type="button"
                                disabled={this.checkEnableAdmit("REPUDIATE")}
                                onClick={() => this.receivedEcheqAction("REPUDIATE")}
                            />
                            <Button
                                bsStyle="primary"
                                className="echeq__action-button"
                                label="echeq.deposit.echeq"
                                type="button"
                                disabled={this.checkEnableAdmit("DEPOSIT")}
                                onClick={() => this.receivedEcheqAction("DEPOSIT")}
                            />
                            <Button
                                bsStyle="primary"
                                className="echeq__action-button"
                                label="echeq.custody.echeq"
                                type="button"
                                disabled={this.checkEnableAdmit("CUSTODY")}
                                onClick={() => this.receivedEcheqAction("CUSTODY")}
                            />
                            <Button
                                bsStyle="primary"
                                className="echeq__action-button"
                                label="echeqs.button.endorse"
                                type="button"
                                disabled={this.checkEnableAdmit("ENDORSE")}
                                onClick={() => this.receivedEcheqAction("ENDORSE")}
                            />
                            <Button
                                bsStyle="primary"
                                className="echeq__action-button"
                                label="echeq.emit.cesion.echeq"
                                type="button"
                                disabled={this.checkEnableAdmit("CESION")}
                                onClick={() => this.receivedEcheqAction("EMIT-CESION")}
                            />
                            <Button
                                bsStyle="primary"
                                className="echeq__action-button"
                                label="echeqs.button.discount"
                                type="button"
                                disabled={this.checkEnableAdmit("NEGOTIATE")}
                                onClick={() => this.receivedEcheqAction("NEGOTIATE")}
                            />
                        </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqsList: selectors.getEcheqsList(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
});

export default compose(connect(mapStateToProps))(MultipleEcheqsButtons);

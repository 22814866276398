/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/lib/Navbar";
import Link from "react-router-dom/Link";
import { routerActions } from "react-router-redux/actions";
import { string, func, node, bool, int, shape } from "prop-types";
import classNames from "classnames";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as communicationsSelectos } from "reducers/communications";
import { selectors as assistantSelectors } from "reducers/assistant";

import { resizableRoute } from "pages/_components/Resizable";
import I18n from "pages/_components/I18n";
import Menu from "pages/_components/menu/Menu";
import Image from "pages/_components/Image";
import Header from "pages/_components/header/Header";
import Badge from "pages/_components/Badge";
import Button from "pages/_components/Button";
import DownloadButton from "pages/_components/DownloadButton";
import Hint from "pages/_components/hints/Hint";
import ViewTitle from "pages/_components/header/components/ViewTitle";
import { Helmet } from "react-helmet";

import * as utilsI18n from "util/i18n";

class Head extends Component {
    static propTypes = {
        // i18n head title
        title: node,
        // specify a translated text instead a i18n key
        titleText: string,
        titleClassName: string,
        // back link url
        backLinkTo: string,
        // back function, ignored if backLinkTo is specified
        onBack: func,
        // close link url
        closeLinkTo: string,
        // onClose function link, ignored if closeLinkTo is specified
        onClose: func,
        // link url, it links located under title
        linkTo: string,
        // I18n id of link text, it is located under title
        linkText: string,
        // head logo
        logo: node,
        // head element
        centerElement: func,
        rightContent: func,
        viewCommunications: bool,
        communicationsRightIcon: func,
        // called right after button with closeLinkTo is clicked
        onCloseClick: func,
        hasCenterContent: bool,
        showPlusIcon: bool,
        navStyle: string,
        centerContentClassName: string,
        accessibilityTextId: string,
        dispatch: func.isRequired,
        hasActiveSession: bool,
        isMobile: bool,
        isDesktop: bool,
        imageStyle: string,
        unreadCommunications: int,
        onAdd: func.isRequired,
        addLinkTo: string,
        addLinkToLabel: string,
        exportList: bool,
        transfer: bool,
        uniqueDownload: bool,
        handleNewClick: func.isRequired,
        handleCreateClick: func.isRequired,
        handleCreateMessage: string.isRequired,
        handleDeleteClick: func.isRequired,
        handleDeleteMessage: string.isRequired,
        handleModifyClick: func.isRequired,
        handleAcceptDebinClick: func.isRequired,
        handleDeclineDebinClick: func.isRequired,
        environments: string,
        activeEnvironment: string,
        note: string,
        handleClick: func.isRequired,
        handleClickMessage: string.isRequired,
        isFetchingExport: bool,
        children: shape().isRequired,
        replace: string,
        hideNavbarInMobile: bool,
        hasInlineButtons: bool,
        csvDownload: bool,
        xlsDownload: bool,
        headerClassName: string,
        exportDisabled: bool,
        emptyRightOption: bool,
        downloadImageWhite: bool,
        closeImageWhite: bool,
        addButtonImageWhite: bool,
        hideMobileMenu: bool,
        messageTooltip: string,
        newButtonLabel: string,
        navbarClassName: string,
        addButtonFirstPosition: bool,
        fixed: bool,
        toolbarItemClassName: string,
        titleTab: string,
        hintClassName: string,
        tag: string,
        alwaysShowMessage: bool,
        nodeMessage: node,
        hintText: string,
    };

    static defaultProps = {
        title: null,
        titleText: "",
        titleClassName: "",
        navStyle: "default",
        backLinkTo: "",
        onBack: null,
        closeLinkTo: "",
        onClose: null,
        linkTo: "",
        linkText: "",
        logo: null,
        centerElement: null,
        rightContent: null,
        viewCommunications: false,
        communicationsRightIcon: null,
        onCloseClick: () => {},
        hasCenterContent: false,
        showPlusIcon: false,
        accessibilityTextId: "",
        centerContentClassName: "",
        hasActiveSession: false,
        isMobile: false,
        isDesktop: false,
        imageStyle: "",
        unreadCommunications: 0,
        addLinkTo: "",
        addLinkToLabel: "",
        exportList: false,
        transfer: false,
        uniqueDownload: false,
        environments: "",
        activeEnvironment: "",
        note: "",
        isFetchingExport: false,
        replace: "",
        hideNavbarInMobile: false,
        hasInlineButtons: false,
        csvDownload: false,
        xlsDownload: false,
        headerClassName: "",
        exportDisabled: false,
        emptyRightOption: false,
        downloadImageWhite: false,
        closeImageWhite: false,
        hideMobileMenu: false,
        messageTooltip: null,
        newButtonLabel: "administration.users.list.addUser",
        addButtonFirstPosition: false,
        addButtonImageWhite: false,
        fixed: false,
        toolbarItemClassName: "",
        navbarClassName: "",
        hintClassName: "",
        titleTab: null,
        tag: "",
        alwaysShowMessage: false,
        nodeMessage: null,
        hintText: "",
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    componentDidUpdate() {
        if (this.titleRef.current) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    back = () => {
        const { dispatch, backLinkTo } = this.props;
        dispatch(routerActions.replace({ pathname: backLinkTo, state: { transition: "transition-drill-out" } }));
    };

    getLeftOption = () => {
        const { backLinkTo, onBack, isMobile } = this.props;

        if (backLinkTo || onBack) {
            return (
                <div className="toolbar-item pl-0 mt-0">
                    <Button
                        className="pl-0 m-0 toolbar-btn view-back"
                        onClick={backLinkTo ? this.back : onBack}
                        image={`images/arrowLeft${isMobile ? "Mobile" : ""}.svg`}
                        label="global.back"
                        bsStyle="link"
                    />
                </div>
            );
        }

        return null;
    };

    close = () => {
        const { onCloseClick, dispatch, closeLinkTo } = this.props;
        onCloseClick();
        dispatch(routerActions.replace({ pathname: closeLinkTo, state: { transition: "transition-flow-close" } }));
    };

    add = () => {
        const { dispatch, onAdd, addLinkTo, isDesktop } = this.props;

        if (onAdd) {
            onAdd();
        }
        let routerAction = {
            pathname: addLinkTo,
            state: { transition: "transition-flow-open" },
        };

        if (isDesktop) {
            // con la transition no está funcionando en Desktop, se quita y se sigue el issue para encontrar el motivo
            routerAction = {
                pathname: addLinkTo,
            };
        }

        dispatch(routerActions.replace(routerAction));
    };

    getRightOption = () => {
        const {
            addLinkTo,
            addLinkToLabel,
            closeLinkTo,
            exportList,
            transfer,
            uniqueDownload,
            handleNewClick,
            handleCreateClick,
            handleCreateMessage,
            handleModifyClick,
            handleDeleteClick,
            handleDeleteMessage,
            handleAcceptDebinClick,
            handleDeclineDebinClick,
            hasActiveSession,
            isDesktop,
            isMobile,
            messageTooltip,
            onAdd,
            onClose,
            showPlusIcon,
            note,
            unreadCommunications,
            emptyRightOption,
            closeImageWhite,
            rightContent,
            viewCommunications,
            communicationsRightIcon,
            newButtonLabel,
            addButtonFirstPosition,
            addButtonImageWhite,
            toolbarItemClassName,
            hintClassName,
            alwaysShowMessage,
            hintText,
        } = this.props;

        if (emptyRightOption) {
            return (
                <>
                    <div className="toolbar-item empty-option" />
                </>
            );
        }

        const noteElement = (
            <div className="toolbar-item view-note">
                <span>{note}</span>
            </div>
        );

        const closeElement = (
            <div className="toolbar-item toolbar-item-right mt-0">
                <Button
                    className={classNames("toolbar-btn view-close", { "ml-auto": isMobile })}
                    onClick={closeLinkTo ? this.close : onClose}
                    image={`images/cross${isMobile ? `-mobile-${closeImageWhite ? "white" : "blue"}` : ""}.svg`}
                    bsStyle="link"
                    style={closeImageWhite ? { color: "white" } : {}}
                />
            </div>
        );

        if (closeLinkTo || onClose || note) {
            return (
                <>
                    {note && noteElement}
                    {(closeLinkTo || onClose) && closeElement}
                </>
            );
        }

        let buttons = [];
        if (rightContent) {
            buttons = [
                ...buttons,
                <div className={classNames("toolbar-item", { "toolbar-item-right": isMobile })}>{rightContent()}</div>,
            ];
        }
        if (!uniqueDownload && exportList) {
            buttons = [...buttons, <DownloadButton {...this.props} />];
        }

        if (uniqueDownload) {
            const { handleClick, handleClickMessage, downloadImageWhite, isFetchingExport, imageStyle } = this.props;
            buttons = [
                ...buttons,
                <div
                    className={classNames("toolbar-item", "mt-0", { "toolbar-item-right": isMobile })}
                    key="exportList">
                    <Button
                        onClick={() => handleClick("pdf")}
                        label={!isMobile && (handleClickMessage || "global.download")}
                        className={classNames("btn btn-outline download", {
                            "button-white-header mx-0": isMobile,
                            "medium-font": !isMobile,
                        })}
                        image={`images/download_bold${downloadImageWhite ? "_white" : ""}.svg`}
                        imageStyle={isMobile ? "toolbar-btn-image-mobile svg25" : imageStyle}
                        loading={isDesktop ? isFetchingExport : false}
                        disabled={isFetchingExport}
                    />
                </div>,
            ];
        }

        const addButton = (
            <div
                className={classNames("toolbar-item", "toolbar-item-right", "mt-0", {
                    "toolbar-item-right-1": isMobile && !addButtonFirstPosition,
                })}
                key="add">
                <Button
                    onClick={this.add}
                    className={classNames({
                        "btn-outline": isDesktop,
                        "toolbar-btn view-close mx-0": !isDesktop,
                    })}>
                    <Image src="images/plus.svg" styles={addButtonImageWhite ? { color: "white" } : {}} />
                    <I18n id={addLinkToLabel} componentProps={!isDesktop && { className: "visually-hidden" }} />
                </Button>
            </div>
        );

        if (addLinkTo || onAdd) {
            if (showPlusIcon) {
                return addButton;
            }
            buttons = [addButton, ...buttons];
        }

        if (handleNewClick) {
            buttons = [
                <div className="toolbar-item toolbar-item-right toolbar-item-right-1 mt-0" key="invite">
                    <Button
                        onClick={handleNewClick}
                        className={classNames("btn btn-small", {
                            "mx-0": isMobile,
                        })}
                        image={isMobile ? "/images/plusMobile.svg" : "/images/plus.svg"}
                        label={!isMobile ? newButtonLabel : null}
                        bsStyle="primary"
                        imageStyle={isMobile ? "toolbar-btn-image-mobile" : "mr-2"}
                    />
                </div>,
                ...buttons,
            ];
        }

        if (handleCreateClick) {
            buttons = [
                <div
                    className={classNames("toolbar-item", "toolbar-item-right", "mt-0", {
                        "toolbar-item-right-1": isMobile && !addButtonFirstPosition,
                    })}
                    key="invite">
                    <Button
                        onClick={handleCreateClick}
                        className={classNames("btn btn-small", {
                            "mx-0": isMobile,
                        })}
                        image={isMobile ? "/images/plusMobile.svg" : "/images/plus.svg"}
                        label={!isMobile && (handleCreateMessage || "administration.groups.list.createGroup")}
                        bsStyle="primary"
                        imageStyle={isMobile ? "toolbar-btn-image-mobile svg25" : "mr-2"}
                    />
                </div>,
                ...buttons,
            ];
        }

        if (handleModifyClick) {
            buttons = [
                <div key="invite" className={classNames("toolbar-item toolbar-item-right mt-0", toolbarItemClassName)}>
                    <Button
                        onClick={handleModifyClick}
                        className="btn btn-outline"
                        image="/images/pencil.svg"
                        label="administration.groups.list.modifyGroup"
                    />
                </div>,
                ...buttons,
            ];
        }
        if (handleDeleteClick) {
            buttons = [
                <div
                    className={classNames("toolbar-item", "toolbar-item-right", "mt-0", {
                        "toolbar-item-right-1": isMobile,
                    })}
                    key="delete">
                    <Button
                        onClick={handleDeleteClick}
                        className="btn btn-small"
                        label={!isMobile && (handleDeleteMessage || "administration.groups.list.delete")}
                        bsStyle="primary"
                        imageStyle={isMobile ? "toolbar-btn-image-mobile" : undefined}
                    />
                </div>,
                ...buttons,
            ];
        }

        if (handleDeclineDebinClick) {
            buttons = [
                <div className="toolbar-item toolbar-item-right mt-0" key="declineDebin">
                    <Button
                        onClick={handleDeclineDebinClick}
                        className="btn btn-outline"
                        label="payments.debin.table.menu.decline"
                    />
                </div>,
                ...buttons,
            ];
        }

        if (handleAcceptDebinClick) {
            buttons = [
                <div
                    className={classNames("toolbar-item", "toolbar-item-right", "mt-0", {
                        "toolbar-item-right-1": isMobile,
                    })}
                    key="acceptDebin">
                    <Button
                        onClick={handleAcceptDebinClick}
                        className="btn btn-small"
                        label="payments.debin.table.menu.accept"
                        bsStyle="primary"
                        imageStyle={isMobile ? "toolbar-btn-image-mobile" : undefined}
                    />
                </div>,
                ...buttons,
            ];
        }

        if (messageTooltip !== null && !isDesktop) {
            buttons = [
                <Hint
                    idMessage={messageTooltip}
                    classNameGroup="my-0"
                    classNameImage="mt-0 mb-0 justify-content-center align-self-center"
                    classNameHintP={`hint-text-header ${hintClassName}`}
                    blueBackground
                    alwaysShowMessage={alwaysShowMessage}
                    defaultValue={hintText}
                />,
                ...buttons,
            ];
        }

        if (hasActiveSession && isMobile && !closeLinkTo && !onClose && viewCommunications) {
            return (
                <div className="toolbar-item p-0 toolbar-item-right mailIcon mt-0">
                    <Link className="btn toolbar-btn p-relative mx-0 px-0" to="/communications">
                        <Image className="svg-icon svg-caret" src="images/email-white.svg" />
                        <I18n id="menu.chat" />
                        {unreadCommunications > 0 && (
                            <>
                                <Badge className="badge-header" />
                                <I18n id="menu.chat.badge" componentProps={{ className: "visually-hidden" }} />
                            </>
                        )}
                    </Link>
                    {communicationsRightIcon}
                </div>
            );
        }

        if (transfer) {
            buttons = [
                ...buttons,
                <div className={classNames("toolbar-item", "mt-0", { "toolbar-item-right": isMobile })}>
                    <Link
                        className={classNames("btn btn-outline", {
                            "button-white-header mx-0": isMobile,
                            "medium-font": !isMobile,
                        })}
                        to="/transfer">
                        <Image className="svg-icon svg-caret mr-3" src="images/transfer.svg" />
                        <I18n id="transfers.main.label" />
                    </Link>
                </div>,
            ];
        }

        return buttons.length ? <>{buttons}</> : null;
    };

    getCenterContent = () => {
        const {
            children,
            isDesktop,
            logo,
            messageTooltip,
            replace,
            title,
            titleText,
            hasCenterContent,
            accessibilityTextId,
            hasInlineButtons,
            centerElement,
            titleClassName,
            titleTab,
            hintClassName,
            tag,
            alwaysShowMessage,
            nodeMessage,
            hintText,
        } = this.props;

        return (
            (title || titleText || logo || centerElement || hasCenterContent || accessibilityTextId || titleTab) && (
                <>
                    <Helmet>
                        <title>
                            {// eslint-disable-next-line no-nested-ternary
                            utilsI18n.get(accessibilityTextId || title) !== "*undefined*"
                                ? `Banco CMF - ${`${utilsI18n.get(titleTab || accessibilityTextId || title, null, {
                                      USER_NAME: replace.USER_NAME || "",
                                  })}`}`
                                : titleText !== ""
                                ? `Banco CMF - ${titleText}`
                                : "Banco CMF"}
                        </title>
                    </Helmet>
                    {(title || titleText) &&
                        (hasInlineButtons || messageTooltip !== null || nodeMessage !== null ? (
                            <div className="d-flex align-items-center">
                                <I18n
                                    id={title}
                                    component={tag !== "" ? tag : "h1"}
                                    defaultValue={titleText}
                                    componentProps={{
                                        className: `title-content ${titleClassName}`,
                                    }}
                                    {...replace}
                                />
                                {messageTooltip !== null && nodeMessage === null && isDesktop && (
                                    <Hint
                                        idMessage={messageTooltip}
                                        classNameMessage=""
                                        classNameImage="my-0 ml-2 justify-content-center align-self-center"
                                        classNameHintP={`hint-text-header ${hintClassName}`}
                                        alwaysShowMessage={alwaysShowMessage}
                                        defaultValue={hintText}
                                    />
                                )}
                                {nodeMessage !== null && messageTooltip === null && isDesktop && (
                                    <Hint
                                        classNameMessage=""
                                        classNameImage="my-0 ml-2 justify-content-center align-self-center"
                                        classNameHintP={`hint-text-header ${hintClassName}`}
                                        alwaysShowMessage={alwaysShowMessage}
                                        nodeMessage={nodeMessage}
                                        defaultValue={hintText}
                                    />
                                )}
                                {children}
                            </div>
                        ) : (
                            <>
                                {title !== "" && (
                                    <ViewTitle
                                        id={title}
                                        accessibilityTextId={accessibilityTextId}
                                        replace={replace}
                                        defaultValue={titleText}
                                        titleClassName={titleClassName}
                                        tag={tag}
                                    />
                                )}
                            </>
                        ))}
                    {centerElement && typeof centerElement !== "object" && centerElement()}
                    {!hasInlineButtons && children}
                    {this.getCenterOption()}
                </>
            )
        );
    };

    getCenterOption = () => {
        const { linkTo, linkText } = this.props;
        if (linkTo) {
            return (
                <p>
                    <Link to={linkTo}>
                        <I18n id={linkText} />
                    </Link>
                </p>
            );
        }
        return null;
    };

    getMenuToggle = () => {
        const { backLinkTo, onBack, isMobile, hasActiveSession, hideNavbarInMobile, hideMobileMenu } = this.props;

        if (hasActiveSession && isMobile && !hideNavbarInMobile && !backLinkTo && !onBack && !hideMobileMenu) {
            return <Navbar.Toggle onClick={() => window.scrollTo(0, 0)} />;
        }
        return null;
    };

    getMenu = () => {
        const {
            backLinkTo,
            onBack,
            isMobile,
            hasActiveSession,
            hideNavbarInMobile,
            environments,
            activeEnvironment,
            hideMobileMenu,
        } = this.props;

        if (hasActiveSession && isMobile && !hideNavbarInMobile && !backLinkTo && !onBack && !hideMobileMenu) {
            return (
                <div className="toolbar-item toolbar-item-left mt-0 p-0">
                    <Navbar.Collapse>
                        {environments && (
                            <Link
                                className="change-environment-link"
                                to={{
                                    pathname: "/settings/changeEnvironment",
                                }}>
                                <div className="environment-section">
                                    <div className="row ml-0">
                                        <Image src={`images/${activeEnvironment.type}.svg`} />
                                        <div>
                                            <span className="data-desc">{activeEnvironment.name}</span>
                                        </div>
                                    </div>

                                    {Object.keys(environments).length > 1 && (
                                        <Image
                                            src="images/chevromRight.svg"
                                            className="environment-section-arrow"
                                            wrapperClassName="svg-wrapper svg-wrapper-right"
                                        />
                                    )}
                                </div>
                            </Link>
                        )}
                        <Menu isMobile={isMobile} />
                    </Navbar.Collapse>
                </div>
            );
        }
        return null;
    };

    render() {
        const { centerContentClassName, headerClassName, isDesktop, navStyle, navbarClassName } = this.props;

        return (
            <>
                <Header additionalClassName={headerClassName} navStyle={navStyle}>
                    <div
                        className={classNames(
                            "navbar-header",
                            { "px-2": !isDesktop },
                            { "margin-fix": !isDesktop },
                            {
                                "navbar-header-blue":
                                    headerClassName && headerClassName.includes("blue-main-header-mobile"),
                            },
                            {
                                "justify-content-end":
                                    headerClassName && headerClassName.includes("justify-content-end"),
                            },
                            navbarClassName,
                        )}>
                        {this.getMenuToggle()}
                        <div>{this.getLeftOption()}</div>
                        <div
                            className={classNames("toolbar-item view-title mt-0", centerContentClassName, {
                                "justify-content-center": !isDesktop,
                                "pl-0": isDesktop,
                            })}
                            ref={this.titleRef}>
                            {this.getCenterContent()}
                        </div>
                        <div>{this.getRightOption()}</div>
                    </div>
                    {this.getMenu()}
                </Header>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: assistantSelectors.isAssistantLogin(state) ? false : sessionSelectors.isLoggedIn(state),
    unreadCommunications: communicationsSelectos.getUnreadCommunications(state),
    environments: sessionSelectors.getEnvironments(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(resizableRoute(Head));

import { call, put, takeLatest } from "redux-saga/effects";

import * as cedipMiddleware from "middleware/cedip";
import { types } from "reducers/cedip";
import { downloadPdf, downloadXls } from "util/download";
import * as i18n from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { isEmpty } from "lodash";
import { routerActions } from "react-router-redux/actions";

const sagas = [
    takeLatest(types.LIST_CEDIP_REQUEST, listCedipRequest),
    takeLatest(types.READ_CEDIP_REQUEST, cedipRead),
    takeLatest(types.DOWNLOAD_CEDIP_LIST_REQUEST, downloadList),
    takeLatest(types.DOWNLOAD_CEDIP_DETAIL_REQUEST, downloadDetail),
    takeLatest(types.REJECT_CEDIP_REQUEST, rejectCedip),
    takeLatest(types.ACCEPT_CEDIP_REQUEST, acceptCedip),
];

export default sagas;

// TODO: eliminar mock y usar datos del servicio
function* listCedipRequest({ filters }) {
    const {
        creationDate,
        creationDateFrom,
        creationDateTo,
        dueDate,
        dueDateFrom,
        dueDateTo,
        idCedip,
        option,
        orderColumName,
        orderDirection,
        pageNumber,
        status,
    } = filters;
    const response = yield call(
        cedipMiddleware.listCedip,
        creationDate,
        creationDateFrom,
        creationDateTo,
        dueDate,
        dueDateFrom,
        dueDateTo,
        idCedip,
        option,
        orderColumName,
        orderDirection,
        pageNumber,
        status,
    );

    yield put({
        type: types.LIST_CEDIP_SUCCESS,
        cedipList: response.data.data.cedipList,
        totalPages: 1,
        pageNumber: 1,
    });
    yield put({
        type: types.LIST_CEDIP_SUCCESS,
        cedipList: response.data.data.cedipList,
        totalPages: response.data.data.totalPages || 1,
        pageNumber: response.data.data.pageNumber || 1,
    });
}

// TODO: eliminar mock y usar datos del servicio
function* cedipRead({ cedipId, fraccionNumero }) {
    const response = yield call(cedipMiddleware.readCedip, cedipId, fraccionNumero);

    // TODO 6912: manejo de errores, idem lista
    if (response && response.status === 200) {
        if (!isEmpty(response.data.data.cedip)) {
            yield put({
                type: types.READ_CEDIP_SUCCESS,
                cedip: response.data.data.cedip,
                ...response.data.data.cedip,
            });
        } else {
            yield put({
                type: types.READ_CEDIP_FAILURE,
                ...response.data.data,
            });
            yield put(notificationActions.showNotification(response.data.message, "error", ["cedip"]));
            yield put(routerActions.goBack());
        }
    }
}

function* downloadList({ format }) {
    const { type, data } = yield call(cedipMiddleware.downloadList, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_CEDIP_LIST_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["cedip"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }
        yield put(notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["cedip"]));
        yield put({ type: types.DOWNLOAD_CEDIP_LIST_SUCCESS });
    }
}

function* downloadDetail({ idCedip, format }) {
    const { type, data } = yield call(cedipMiddleware.downloadDetail, idCedip, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_CEDIP_DETAIL_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["cedip/detail"]));
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            downloadPdf(fileName, content);
        }
        yield put({ type: types.DOWNLOAD_CEDIP_DETAIL_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["cedip/detail"]),
        );
    }
}

function* rejectCedip({ idCedip, fraction }) {
    const response = yield call(cedipMiddleware.rejectCedip, idCedip, fraction);

    if (response && response.status === 200) {
        yield put({ type: types.REJECT_CEDIP_SUCCESS });
        yield put(routerActions.goBack());

        yield put(
            notificationActions.showNotification(i18n.get("deposits.cedip.reject.successful"), "success", ["cedip"]),
        );
    } else {
        yield put({
            type: types.REJECT_CEDIP_FAILURE,
            ...response.data.data,
        });
        yield put(routerActions.goBack());

        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["cedip"]));
    }
}

function* acceptCedip({ idCedip, fraction, cbu, accreditationType }) {
    const response = yield call(cedipMiddleware.acceptCedip, idCedip, fraction, cbu, accreditationType);

    if (response && response.status === 200) {
        yield put({ type: types.ACCEPT_CEDIP_SUCCESS });
        yield put(routerActions.goBack());

        yield put(
            notificationActions.showNotification(i18n.get("deposits.cedip.accept.successful"), "success", ["cedip"]),
        );
    } else {
        yield put({
            type: types.ACCEPT_CEDIP_FAILURE,
            ...response.data.data,
        });
        yield put(routerActions.goBack());

        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["cedip"]));
    }
}

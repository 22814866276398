import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { Col, Row } from "react-bootstrap";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";

class AdminUserChannelsDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { userFullName, enabledChannels, maxAmounts, capFrequencies },
            } = object.transaction;

            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{submitDateTimeAsString}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{userFullName}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{this.renderTotalAmount(enabledChannels, maxAmounts, capFrequencies)}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            {this.renderChannels(enabledChannels, maxAmounts, capFrequencies)}
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="forms.transaction.ticket.date"
                        data={<span>{submitDateTimeAsString}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="transaction.ticket.user"
                        data={<span>{userFullName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="administration.channels.topAmount"
                        data={<span>{this.renderTotalAmount(enabledChannels, maxAmounts, capFrequencies)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="administration.channels"
                        data={<span>{this.renderChannels(enabledChannels, maxAmounts, capFrequencies)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    renderChannels = (enabledChannels, maxAmounts, capFrequencies) =>
        enabledChannels
            .filter((channel) => channel !== "all")
            .map((channel, index) => (
                <Row className="mx-0 justify-content-end">
                    <span className="data-desc gray-pill background-color-efefef">
                        <I18n id={`channels.${channel}`} />
                    </span>
                    {this.renderCap(maxAmounts[index], capFrequencies[index])}
                </Row>
            ));

    renderTotalAmount = (enabledChannels, maxAmounts, capFrequencies) =>
        enabledChannels
            .filter((channel) => channel === "all")
            .map((channel, index) => this.renderCap(maxAmounts[index], capFrequencies[index]));

    renderCap = (quantity, frequency) => {
        const { isDesktop } = this.props;
        return (
            <FormattedAmount
                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                currency={configUtils.get("core.masterCurrency")}
                frequency={i18nUtils.get(`administration.channels.${frequency}`).toUpperCase()}
                quantity={quantity}
                notBold
            />
        );
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="transaction.ticket.user" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="administration.channels.topAmount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="administration.channels" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AdminUserChannelsDataTable);

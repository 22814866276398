import { call, put, takeLatest, take, select } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import { downloadPdf, downloadXls } from "util/download";
import { actions as formActions } from "reducers/form";
import {
    listAccounts,
    checkbookChecksAmount,
    requestChecks as sendRequestChecks,
    filterEcheqsList,
    downloadEcheqsList,
    executeEcheqAction,
    bankedBeneficiary,
    echeqDetailRequestMiddleware,
    sendEndorseEcheq,
    sendNegotiateEcheq,
    sendMultipleNegotiateEcheq,
    sendMultipleEndorseEcheq,
    sendMultipleCesionEcheq,
    sendEmitEcheq,
    sendCesionEcheq,
    acceptRequestReturnEcheq,
    listCheckDetailMiddleware,
    downloadCheckDetailMiddleware,
    listDiscountedChecksDetailMiddleware,
    downloadDiscountedChecksDetailMiddleware,
    downloadNegotiateEcheqTicketMiddleware,
    downloadChecksRequestTicketMiddleware,
    dowloadEcheqRequestDetailMobileMiddleware,
    downloadDiscountChecksMiddleware,
    dowloadCheckRequestDetailMobileMiddleware,
    emitMassiveEcheqPre,
    emitMassiveEcheqRequest,
    emitMassiveEcheq,
    echeqBatchDetailRequest,
    downloadBatchDetailRequest,
    downloadEcheqListByCuit,
} from "middleware/checks";
import { echeqMassiveTransactionActions } from "sagas/redirectTransactions";
import * as accountsMiddleware from "middleware/accounts";
import * as form from "middleware/form";
import { types, actions, selectors } from "reducers/checks";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import moment from "moment";
import { delay } from "redux-saga";
import { cleanEcheqObject, setTenenciaBeneficiario } from "util/checks";
import { INTERNAL_ERROR, VALIDATION_ERROR } from "util/responses";

const sagas = [
    takeLatest(types.FETCH_ACCOUNTS, fetchAccounts),
    takeLatest(types.CHECKS_AMOUNT, fetchCheckbookChecksAmount),
    takeLatest(types.REQUEST_CHECKS, requestChecks),
    takeLatest(types.FILTER_ECHEQ_LIST, filterEcheqList),
    takeLatest(types.FILTER_ECHEQ_LIST_MOBILE, filterEcheqList),
    takeLatest(types.DOWNLOAD_ECHEQ_LIST_REQUEST, downloadEcheqList),
    takeLatest(types.GET_ACCOUNT_INFO, getAccountInfo),
    takeLatest(types.EXECUTE_ACTION_ECHEQ, executeEcheqActionSagas),
    takeLatest(types.BANKED_BENEFICIARY, getBankedBenefiicary),
    takeLatest(types.ECHEQ_DETAIL_REQUEST, echeqDetailRequest),
    takeLatest(types.ENDORSE_ECHEQ, endorseEcheq),
    takeLatest(types.NEGOTIATE_ECHEQ, negotiateEcheq),
    takeLatest(types.NEGOTIATE_MULTIPLE_ECHEQ, negotiateMultipleEcheq),
    takeLatest(types.ENDORSE_MULTIPLE_ECHEQ, endorseMutlipleEcheq),
    takeLatest(types.CESION_MULTIPLE_ECHEQ, cesionMutlipleEcheq),
    takeLatest(types.EMIT_ECHEQ, emitEcheq),
    takeLatest(types.CESION_ECHEQ, cesionEcheq),
    takeLatest(types.ACCEPT_RETURN_ECHEQ, acceptReturnEcheq),
    takeLatest(types.LIST_CHECK_DETAIL_REQUEST, getListCheckDetail),
    takeLatest(types.DOWNLOAD_CHECK_DETAIL, getDowloadCheckDetail),
    takeLatest(types.LIST_DISCOUNTED_CHECKS_DETAIL_REQUEST, getListDiscountedChecksDetail),
    takeLatest(types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_REQUEST, getDowloadDiscounteChecksDetail),
    takeLatest(types.DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_REQUEST, getDowloadNegotiateEcheqTicket),
    takeLatest(types.DOWNLOAD_CHECKS_REQUEST_TICKET_REQUEST, getDowloadChecksRequestTicket),
    takeLatest(types.DOWNLOAD_ECHEQ_DETAIL_MOBILE_REQUEST, getDowloadEcheqRequestDetailMobile),
    takeLatest(types.SET_REQUEST_RETURN_SOLICITANT_NAME, getRequestReturnSolicitant),
    takeLatest(types.DOWNLOAD_DISCOUNT_CHECKS_REQUEST, getDownloadDiscountChecks),
    takeLatest(types.DOWNLOAD_CHECK_DETAIL_MOBILE_REQUEST, getDowloadCheckRequestDetailMobile),
    takeLatest(types.VALIDATE_ACCOUNT_EMIT_REQUEST, validateAccountEmit),
    takeLatest(types.EMIT_MASSIVE_ECHEQ_PREVIEW_REQUEST, emitMassiveEcheqPreview),
    takeLatest(types.EMIT_MASSIVE_ECHEQ_SEND_REQUEST, emitMassiveEcheqSend),
    takeLatest(types.ECHEQ_BATCH_DETAIL_REQUEST, echeqBatchDetail),
    takeLatest(types.DOWNLOAD_ECHEQ_BATCH_DETAIL, downloadBatchDetail),
    takeLatest(types.REQUEST_DOWNLOAD_ECHEQ_BY_CUIT, requestDownloadEcheqByCuit),
];

export default sagas;

function* getRequestReturnSolicitant({ cuit }) {
    if (cuit) {
        const response = yield call(bankedBeneficiary, { type: "CUIT", document: cuit });
        yield put({
            type: types.SET_REQUEST_RETURN_SOLICITANT_NAME,
            solicitantName: response.data.data.beneficiary.beneficiario_razon_social,
        });
    }
}

function* acceptReturnEcheq({ otp, setSubmitting, setErrors, echeqAction, userId }) {
    try {
        const environmentCuit = yield select(selectors.getEnvironmentCuit);
        const echeqActionCleaned = cleanEcheqObject(setTenenciaBeneficiario(echeqAction), environmentCuit);
        const response = yield call(acceptRequestReturnEcheq, otp, echeqActionCleaned, userId);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                yield put(notificationActions.showNotification(response.data.data.message, "error", ["echeqs"]));
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
            }
        } else {
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));

            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action

            yield put({
                type: types.CLEAN_ECHEQ_ACTION,
            });
        }
    } finally {
        setSubmitting(false);
    }
}
function* cesionEcheq({ otp, setSubmitting, setErrors, cesionData, echeqAction, userId }) {
    try {
        const environmentCuit = yield select(selectors.getEnvironmentCuit);
        const echeqActionCleaned = cleanEcheqObject(echeqAction, environmentCuit);
        const response = yield call(sendCesionEcheq, otp, cesionData, echeqActionCleaned, userId);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", ["echeqs", "form"]),
                );
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs", "form"]));
            }
        } else {
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));

            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action

            yield put({
                type: types.CLEAN_ECHEQ_ACTION,
            });
        }
    } finally {
        setSubmitting(false);
    }
}

function* emitEcheq({ otp, setSubmitting, setErrors, emitData, userId }) {
    try {
        const response = yield call(sendEmitEcheq, otp, emitData, userId);

        if (response.type === "W" && response.data?.code !== "API516W") {
            if (response.data?.data?.NO_FIELD) {
                yield put(notificationActions.showNotification(response.data?.data?.NO_FIELD, "error", ["echeqs"]));
            } else if (response.data?.code === "COR020W") {
                yield put(notificationActions.showNotification(response.data.data.message, "error", ["echeqs"]));
                setErrors(response.data?.data);
            } else if (response.data?.data?.signatureError) {
                yield put(notificationActions.showNotification(response.data.data.signatureError, "error", ["echeqs"]));
            } else {
                yield put(notificationActions.showNotification(response.data?.message, "error", ["echeqs"]));
            }
        } else {
            if (response?.data?.data?.signatureError) {
                yield put(notificationActions.showNotification(response.data.data.signatureError, "error", ["echeqs"]));
            }
            if (emitData?.modes !== "PERS" && !response?.data?.data?.signatureError) {
                if (response.data.data.multipleCheckbooks) {
                    // Different checkbooks for emision
                    yield put(routerActions.push("/echeqs/multiple/processing"));
                } else {
                    yield put({ type: types.CLEAN_EMISSION_DATA });
                    yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
                    yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
                }
            } else if (!response?.data?.data?.signatureError) {
                yield put(routerActions.push("/echeqs/multiple/processing"));
            }
        }
    } finally {
        setSubmitting(false);
    }
}

function* endorseEcheq({ otp, setSubmitting, setErrors, endorseData, echeqAction, userId }) {
    try {
        const environmentCuit = yield select(selectors.getEnvironmentCuit);
        const echeqActionCleaned = cleanEcheqObject(echeqAction, environmentCuit);
        const response = yield call(sendEndorseEcheq, otp, endorseData, echeqActionCleaned, userId);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
            }
        } else {
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));

            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action

            yield put({
                type: types.CLEAN_ECHEQ_ACTION,
            });
        }
    } finally {
        setSubmitting(false);
    }
}

function* negotiateEcheq({ otp, setSubmitting, setErrors, endorseData, echeqAction, userId, isAutomatedDiscount }) {
    try {
        const environmentCuit = yield select(selectors.getEnvironmentCuit);
        const echeqActionCleaned = cleanEcheqObject(echeqAction, environmentCuit);
        const response = yield call(sendNegotiateEcheq, otp, endorseData, echeqActionCleaned, userId);

        const { description, error } = response.data.data;

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                yield put(notificationActions.showNotification(response.data.data.message, "error", ["form"]));
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["form"]));
            }
        } else if (isAutomatedDiscount && error === "warning") {
            yield put(routerActions.push("/echeqs"));
            yield put(notificationActions.showNotification(description, error, ["echeqsMainPage"], null, false));
        } else {
            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    isAutomatedDiscount,
                    successMessage: description,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
            yield put({
                type: types.CLEAN_ECHEQ_ACTION,
            });
        }
    } finally {
        setSubmitting(false);
    }
}

function* cesionMutlipleEcheq({ otp, setSubmitting, setErrors, cesionData, echeqAction, userId }) {
    try {
        const environmentCuit = yield select(selectors.getEnvironmentCuit);
        const echeqActionCleaned = cleanEcheqObject(echeqAction, environmentCuit);
        const response = yield call(sendMultipleCesionEcheq, otp, cesionData, echeqActionCleaned, userId);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", ["echeqs", "form"]),
                );
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs", "form"]));
            }
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("multiple.signatures.notification.processing"),
                    "warning",
                    ["multipleSignaturesProcessing"],
                ),
            );

            yield put(routerActions.push("/echeqs/multiple/processing"));
        }
    } finally {
        setSubmitting(false);
    }
}

function* negotiateMultipleEcheq({ otp, setSubmitting, setErrors, endorseData, echeqAction, userId }) {
    try {
        const environmentCuit = yield select(selectors.getEnvironmentCuit);
        const echeqActionCleaned = cleanEcheqObject(echeqAction, environmentCuit);
        const response = yield call(sendMultipleNegotiateEcheq, otp, endorseData, echeqActionCleaned, userId);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
                const message = response.data.data.NO_FIELD || response.data.data.message;
                yield put(notificationActions.showNotification(message, "error", ["form"]));
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["form"]));
            }
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("multiple.signatures.notification.processing"),
                    "warning",
                    ["multipleSignaturesProcessing"],
                ),
            );

            yield put(routerActions.push("/echeqs/multiple/processing"));
        }
    } finally {
        setSubmitting(false);
    }
}

function* endorseMutlipleEcheq({ otp, setSubmitting, setErrors, endorseData, echeqAction, userId }) {
    try {
        const environmentCuit = yield select(selectors.getEnvironmentCuit);
        const echeqActionCleaned = cleanEcheqObject(echeqAction, environmentCuit);
        const response = yield call(sendMultipleEndorseEcheq, otp, endorseData, echeqActionCleaned, userId);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
            }
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("multiple.signatures.notification.processing"),
                    "warning",
                    ["multipleSignaturesProcessing"],
                ),
            );

            yield put(routerActions.push("/echeqs/multiple/processing"));
        }
    } finally {
        setSubmitting(false);
    }
}

function* getBankedBenefiicary({ document, setSubmitting, stringType }) {
    try {
        const response = yield call(bankedBeneficiary, document);

        if (response.type === "W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
        } else {
            const { beneficiary, codigo, description } = response.data.data;

            if (codigo !== "2500") {
                yield put(notificationActions.showNotification(description, "error", ["echeqs"]));
            } else {
                const beneficiaryInfo = {
                    document: beneficiary.beneficiario_documento_tipo.concat(" ", beneficiary.beneficiario_documento),
                    reference: beneficiary.beneficiario_razon_social,
                };

                yield put(actions.beneficiaryEcheq(beneficiaryInfo, stringType));
            }
        }
    } finally {
        setSubmitting(false);
    }
}

function* executeEcheqActionSagas({ echeqAction, userId }) {
    const response = yield call(executeEcheqAction, echeqAction, userId);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
    } else {
        const { description, error, echeqDetailErrors, automatedActionWarning } = response.data.data;
        const chequesFunctionalities = configUtils.getBoolean(
            "frontend.show.failedVoucher.chequesFunctionalities",
            false,
        );

        yield put({
            type: types.CLEAN_ECHEQ_ACTION,
        });
        yield put(routerActions.push("/echeqs"));

        const errorVoucher = response.data.data.voucherErrorCode;
        const messageVoucher = response.data.data.voucherErrorMessage;
        if (chequesFunctionalities && errorVoucher && messageVoucher) {
            const notificationMessage = i18n.get("notification.snackbar.failed.voucher", null, {
                VOUCHER_MESSAGE: messageVoucher,
                VOUCHER_ERROR: errorVoucher,
            });
            yield put(notificationActions.showNotification(notificationMessage, "error", ["echeqsMainPage"]));
        } else if (echeqDetailErrors?.length > 0) {
            yield put(
                notificationActions.showNotification(
                    i18n.get(`extract.echeqs.error.${echeqDetailErrors[0]}`),
                    "error",
                    ["echeqsMainPage"],
                ),
            );
        } else if (automatedActionWarning) {
            yield put(notificationActions.showNotification(description, error, ["echeqsMainPage"], null, false));
        } else {
            const depositMaxHour = configUtils.getInteger("echeq.deposit.warning.hour", 17);
            const actualHour = moment().hour();
            if (echeqAction.action === "DEPOSIT" && error === "success" && actualHour > depositMaxHour) {
                yield put(
                    notificationActions.showNotification(i18n.get("echeqs.deposit.warning.message"), "warning", [
                        "echeqsMainPage",
                    ]),
                );
            } else {
                yield put(notificationActions.showNotification(description, error, ["echeqsMainPage"]));
            }
        }
    }
}

function* filterEcheqList({ filters, setSubmitting, isDesktop }) {
    try {
        const response = yield call(filterEcheqsList, filters);
        if (response.type === "W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
        } else if (response.data.data.codigo !== "2400") {
            yield put(notificationActions.showNotification(response.data.data.description, "error", ["echeqs"]));
        } else if (isDesktop) {
            yield put({
                type: types.SET_ECHEQ_LIST,
                listEcheqs: response.data.data.echeqs,
                totalPages: response.data.data.totalPages,
                pageNumber: response.data.data.pageNumber,
                environmentCuit: response.data.data.environmentCuit,
                serverDate: response.data.data.serverDate,
                statusList: response.data.data?.statusList,
            });
        } else {
            yield put({
                type: types.SET_ECHEQ_LIST_MOBILE,
                echeqList: response.data.data.echeqs,
                totalPages: response.data.data.totalPages,
                pageNumber: response.data.data.pageNumber,
                environmentCuit: response.data.data.environmentCuit,
                serverDate: response.data.data.serverDate,
                statusList: response.data.data?.statusList,
            });
        }
    } finally {
        setSubmitting(false);
    }
}

function* downloadEcheqList({ filters }) {
    const response = yield call(downloadEcheqsList, filters);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["echeqsMainPage"]));
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["echeqsMainPage"]),
        );
        const { fileName, content } = response.data.data;

        downloadXls(fileName, content);

        yield put({
            type: types.DOWNLOAD_ECHEQ_LIST_SUCCESS,
        });
    }
}

function* getAccountInfo({ account }) {
    const response = yield call(accountsMiddleware.getAccountInfo, account);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["checksPending"]));
    } else {
        yield put({
            type: types.SET_ACCOUNT_INFO,
            accountInfo: response.data.data.account,
        });
    }
}

function* requestChecks({ checksRequestData, otp, userId, formikBag }) {
    try {
        const creationDate = new Date();

        const response = yield call(sendRequestChecks, checksRequestData, otp, creationDate, userId);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(
                    notificationActions.showNotification(
                        response.data.data.NO_FIELD || response.data.message,
                        "error",
                        ["checksSummary"],
                    ),
                );
            }
        } else {
            if (checksRequestData.idTransaction !== undefined) {
                yield put(
                    notificationActions.showNotification(i18n.get("modify.check.success.message"), "success", [
                        "checksConfirmation",
                    ]),
                );
            } else if (response.data.code !== "API516W") {
                const eCheck = !checksRequestData.checksTypes.showsDeliveryPoint;
                if (eCheck) {
                    yield put(
                        notificationActions.showNotification(i18n.get("new.check.echeq.success.message"), "success", [
                            "checksConfirmation",
                        ]),
                    );
                } else {
                    yield put(
                        notificationActions.showNotification(i18n.get("new.check.success.message"), "success", [
                            "checksConfirmation",
                        ]),
                    );
                }
            }
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* fetchAccounts({ idUser }) {
    const response = yield call(listAccounts, idUser);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["requestChecks"]));
    } else {
        const { accounts, checkTypes, concepts } = response.data.data;
        yield put({
            type: types.SET_ACCOUNT_LIST,
            accounts,
            checks: checkTypes,
            concepts,
        });
    }
    yield put({
        type: types.SET_ACCOUNT_FINISHED,
    });
}

function* fetchCheckbookChecksAmount({ checksType }) {
    const response = yield call(checkbookChecksAmount, checksType);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["requestChecks"]));
    } else {
        yield put({
            type: types.SET_CHECKS_AMOUNT,
            checksAmount: response.data.data.checkbookAmount,
        });
    }
}

function* echeqDetailRequest({ echeqId }) {
    const response = yield call(echeqDetailRequestMiddleware, echeqId);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
    } else if (response.data.data.codigo !== "2400") {
        yield put(notificationActions.showNotification(response.data.data.description, "error", ["echeqs"]));
    } else {
        yield put({
            type: types.ECHEQ_DETAIL_SUCCESS,
            echeqDetail: response.data.data.echeq,
            environmentCuit: response.data.data.environmentCuit,
        });
    }
}

function* getListCheckDetail({
    setSubmitting,
    checksTypeChamber,
    pageNumber,
    checkNumber,
    dateFrom,
    dateTo,
    amountFrom,
    amountTo,
}) {
    try {
        const response = yield call(
            listCheckDetailMiddleware,
            checksTypeChamber,
            pageNumber,
            checkNumber,
            dateFrom,
            dateTo,
            amountFrom,
            amountTo,
        );

        if (response && response.status === 200) {
            const { currentPageNumber, totalPages, listCheckDetail } = response.data.data;
            yield put({
                type: types.LIST_CHECK_DETAIL_SUCCESS,
                checkDetailCurrentPageNumber: currentPageNumber || 1,
                checkDetailTotalPages: totalPages || 0,
                listCheckDetail: listCheckDetail || [],
            });
        } else {
            yield put({
                type: types.LIST_CHECK_DETAIL_FAILURE,
            });
        }
    } finally {
        setSubmitting(false);
    }
}

function* getDowloadCheckDetail({
    format,
    checksTypeChamber,
    pageNumber,
    checkNumber,
    dateFrom,
    dateTo,
    amountFrom,
    amountTo,
    registersByPage,
}) {
    const { type, data } = yield call(
        downloadCheckDetailMiddleware,
        format,
        checksTypeChamber,
        pageNumber,
        checkNumber,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
        registersByPage,
    );

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_CHECK_DETAIL_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checksMain"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_CHECK_DETAIL_SUCESS });
        yield put(notificationActions.showNotification(i18n.get("global.download.success"), "success", ["checksMain"]));
    }
}

function* getListDiscountedChecksDetail({
    setSubmitting,
    pageNumber,
    checkNumber,
    liquidationNumber,
    checkStatus,
    dateFrom,
    dateTo,
}) {
    try {
        const response = yield call(
            listDiscountedChecksDetailMiddleware,
            pageNumber,
            checkNumber,
            liquidationNumber,
            checkStatus,
            dateFrom,
            dateTo,
        );
        if (response && response.status === 200) {
            const { currentPageNumber, totalPages, listDiscountedChecksDetail } = response.data.data;
            yield put({
                type: types.LIST_DISCOUNTED_CHECKS_DETAIL_SUCCESS,
                discountedChecksDetailCurrentPageNumber: currentPageNumber || 1,
                discountedChecksDetailTotalPages: totalPages || 0,
                listDiscountedChecksDetail: listDiscountedChecksDetail || [],
            });
        } else {
            yield put({
                type: types.LIST_DISCOUNTED_CHECKS_DETAIL_FAILURE,
            });
        }
    } finally {
        setSubmitting(false);
    }
}

function* getDowloadDiscounteChecksDetail({
    format,
    pageNumber,
    checkNumber,
    liquidationNumber,
    checkStatus,
    dateFrom,
    dateTo,
    registersByPage,
}) {
    const { type, data } = yield call(
        downloadDiscountedChecksDetailMiddleware,
        format,
        pageNumber,
        checkNumber,
        liquidationNumber,
        checkStatus,
        dateFrom,
        dateTo,
        registersByPage,
    );

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checksDetail"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_SUCCESS });
    }
}

function* getDowloadNegotiateEcheqTicket({
    creationDate,
    idTransaction,
    beneficiary,
    endorseType,
    accountLabel,
    reference,
    referencevalue,
    echeqNumber,
    idEcheq,
    emittedDate,
    paymentDate,
    totalAmount,
    echeqType,
    echeqCharacter,
    echeqMode,
    echeqMotive,
    echeqConcept,
}) {
    const { type, data } = yield call(
        downloadNegotiateEcheqTicketMiddleware,
        creationDate,
        idTransaction,
        beneficiary,
        endorseType,
        accountLabel,
        reference,
        referencevalue,
        echeqNumber,
        idEcheq,
        emittedDate,
        paymentDate,
        totalAmount,
        echeqType,
        echeqCharacter,
        echeqMode,
        echeqMotive,
        echeqConcept,
    );
    if (type === "W" && data.code !== "API516W") {
        yield put({ type: types.DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["echeqs"]));
    } else {
        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_SUCCESS });
    }
}

function* getDowloadChecksRequestTicket({
    dateTime,
    receipt,
    accountName,
    checksTypes,
    checkBookChecksAmount,
    checkBooksAmount,
    authorizedDocument,
    authorizedName,
    authorizedSurname,
    deliveryPoint,
    responseIBS,
}) {
    const { type, data } = yield call(
        downloadChecksRequestTicketMiddleware,
        dateTime,
        receipt,
        accountName,
        checksTypes,
        checkBookChecksAmount,
        checkBooksAmount,
        authorizedDocument,
        authorizedName,
        authorizedSurname,
        deliveryPoint,
        responseIBS,
    );
    if (type === "W" && data.code !== "API516W") {
        yield put({ type: types.DOWNLOAD_CHECKS_REQUEST_TICKET_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["echeqs"]));
    } else {
        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_CHECKS_REQUEST_TICKET_SUCCESS });
    }
}

function* getDowloadEcheqRequestDetailMobile({ format, echeqDetail }) {
    const { type, data } = yield call(dowloadEcheqRequestDetailMobileMiddleware, format, echeqDetail);

    if (type === "W" && data.code !== "API516W") {
        yield put({ type: types.DOWNLOAD_CHECKS_REQUEST_TICKET_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "echeqs",
                "echeqsMainPage",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", [
                "echeqs",
                "echeqsMainPage",
            ]),
        );
        yield call(delay, 3000);

        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_CHECKS_REQUEST_TICKET_SUCCESS });
    }
}

function* getDownloadDiscountChecks({ operationNumber }) {
    const format = "pdf";
    const { type, data } = yield call(downloadDiscountChecksMiddleware, format, operationNumber);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checksDetail"]));
    } else {
        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_SUCCESS });
    }
}
function* getDowloadCheckRequestDetailMobile({ format, checkDetail, isEmittedSelected }) {
    const { type, data } = yield call(
        dowloadCheckRequestDetailMobileMiddleware,
        format,
        checkDetail,
        isEmittedSelected,
    );

    if (type === "W" && data.code !== "API516W") {
        yield put({ type: types.DOWNLOAD_CHECK_DETAIL_MOBILE_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checks.detail.info"]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["checks.detail.info"]),
        );
        yield call(delay, 3000);

        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_CHECK_DETAIL_MOBILE_SUCCESS });
    }
}

function* validateAccountEmit({ documentType, document, setErrors }) {
    const response = yield call(emitMassiveEcheqPre, documentType, document);

    if (response.type === "W" && response.data.code !== "API516W") {
        if (response.data.code === "COR020W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get("echeq.massive.emit.field.error.account.message"),
                    "warning",
                    ["form"],
                ),
            );
            setErrors(response.data.data);
        } else {
            yield put(
                notificationActions.showNotification(response.data.data?.NO_FIELD || response.data.message, "error", [
                    "form",
                ]),
            );
        }
        yield put({
            type: types.VALIDATE_ACCOUNT_EMIT_FAILURE,
        });
    } else {
        yield put({
            type: types.VALIDATE_ACCOUNT_EMIT_SUCCESS,
        });
    }
}

function* emitMassiveEcheqPreview({ formikBag, emitMassiveEcheqData }) {
    try {
        const response = yield call(emitMassiveEcheqRequest, emitMassiveEcheqData);

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === VALIDATION_ERROR) {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("echeq.massive.emit.field.error.account.message"),
                        "error",
                        ["form"],
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(
                        response.data.data?.NO_FIELD || response.data.message,
                        "error",
                        ["form"],
                    ),
                );
            }
            yield put({
                type: types.EMIT_MASSIVE_ECHEQ_PREVIEW_FAILURE,
            });
        } else {
            const { batchId, furthestPaymentDate } = response.data.data;
            yield put({
                type: types.EMIT_MASSIVE_ECHEQ_PREVIEW_SUCCESS,
                emitMassiveEcheqData: { ...emitMassiveEcheqData, batchId, furthestPaymentDate },
            });
            yield put(routerActions.push("/echeqs/emit/massive/confirm"));
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* emitMassiveEcheqSend({ otp, formikBag, summary }) {
    try {
        const response = yield call(emitMassiveEcheq, otp, summary);

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === VALIDATION_ERROR) {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(
                    notificationActions.showNotification(
                        response.data.data?.NO_FIELD || response.data.message,
                        "error",
                        ["echeqMassive"],
                    ),
                );
            }
            yield put({
                type: types.EMIT_MASSIVE_ECHEQ_SEND_FAILURE,
            });
        } else {
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* echeqBatchDetail({ idTransaction, batchId, statusFilter, pageNumber }) {
    let data = null;
    if (idTransaction) {
        const transactionResponse = yield call(form.readTransaction, idTransaction);
        const { transaction } = transactionResponse.data?.data;
        const { idActivity } = transaction;
        yield call(echeqMassiveTransactionActions, idActivity, transaction);
        data = transaction?.data;
    }
    const response = yield call(
        echeqBatchDetailRequest,
        idTransaction ? data?.batchId : batchId,
        statusFilter,
        pageNumber,
    );

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["echeqs"]));
    } else {
        const {
            echeqBatch,
            batchDetail,
            batchErrors,
            pageNumber: pageNumberTmp,
            totalPages,
            file,
        } = response.data.data;
        yield put({
            type: types.ECHEQ_BATCH_DETAIL_SUCCESS,
            echeqBatch: { ...echeqBatch, file },
            batchDetail,
            batchErrors,
            pageNumber: pageNumberTmp,
            totalPages,
        });
    }
}

function* downloadBatchDetail({ batchId, statusFilter, fileName }) {
    try {
        const { type, data } = yield call(downloadBatchDetailRequest, batchId, statusFilter);

        if (type === "W") {
            yield put({ type: types.DOWNLOAD_ECHEQ_BATCH_DETAIL_FAILURE });
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["echeqs"]));
        } else {
            const { content } = data.data;
            downloadXls(fileName, content);
            yield put({ type: types.DOWNLOAD_ECHEQ_BATCH_DETAIL_SUCCESS });
        }
    } catch (error) {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["echeqs"]));
    }
}

function* requestDownloadEcheqByCuit({ fromDate, toDate }) {
    const response = yield call(downloadEcheqListByCuit, fromDate, toDate);
    if (response.type === "W" || response.data.data.error) {
        const message = i18n.get("download.echeqs.list.cuit.error");
        yield put(notificationActions.showNotification(message, "error", ["echeqListCuit"]));
    } else {
        const message = configUtils.get("download.echeqs.list.snackbar.text");
        yield put(notificationActions.showNotification(message, "success", ["echeqListCuit"]));
    }
    yield put({ type: types.REQUEST_DOWNLOAD_ECHEQ_BY_CUIT_FINISH });
}

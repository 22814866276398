export const types = {
    LIST_CEDIP_REQUEST: "cedip/LIST_CEDIP_REQUEST",
    LIST_CEDIP_SUCCESS: "cedip/LIST_CEDIP_SUCCESS",
    LIST_CEDIP_FAILURE: "cedip/LIST_CEDIP_FAILURE",
    READ_CEDIP_REQUEST: "cedip/READ_CEDIP_REQUEST",
    READ_CEDIP_SUCCESS: "cedip/READ_CEDIP_SUCCESS",
    READ_CEDIP_FAILURE: "cedip/READ_CEDIP_FAILURE",
    DOWNLOAD_CEDIP_LIST_REQUEST: "cedip/DOWNLOAD_CEDIP_LIST_REQUEST",
    DOWNLOAD_CEDIP_LIST_FAILURE: "cedip/DOWNLOAD_CEDIP_LIST_FAILURE",
    DOWNLOAD_CEDIP_LIST_SUCCESS: "cedip/DOWNLOAD_CEDIP_LIST_SUCCESS",
    DOWNLOAD_CEDIP_DETAIL_REQUEST: "cedip/DOWNLOAD_CEDIP_DETAIL_REQUEST",
    DOWNLOAD_CEDIP_DETAIL_FAILURE: "cedip/DOWNLOAD_CEDIP_DETAIL_FAILURE",
    DOWNLOAD_CEDIP_DETAIL_SUCCESS: "cedip/DOWNLOAD_CEDIP_DETAIL_SUCCESS",
    SET_SHOW_FORM: "cedip/SET_SHOW_FORM",
    SET_MY_CEDIP: "cedip/SET_MY_CEDIP",
    SET_RECIEVED_CEDIP: "cedip/SET_RECIEVED_CEDIP",
    SET_FILTERS: "cedip/SET_FILTERS",
    HANDLE_MANAGE_CEDIP: "cedip/HANDLE_MANAGE_CEDIP",
    MANAGE_CEDIP: "cedip/MANAGE_CEDIP",
    REJECT_CEDIP_REQUEST: "cedip/REJECT_CEDIP_REQUEST",
    REJECT_CEDIP_FAILURE: "cedip/REJECT_CEDIP_FAILURE",
    REJECT_CEDIP_SUCCESS: "cedip/REJECT_CEDIP_SUCCESS",
    ACCEPT_CEDIP_REQUEST: "cedip/ACCEPT_CEDIP_REQUEST",
    ACCEPT_CEDIP_FAILURE: "cedip/ACCEPT_CEDIP_FAILURE",
    ACCEPT_CEDIP_SUCCESS: "cedip/ACCEPT_CEDIP_SUCCESS",
    SET_ACCEPTING_CEDIP: "cedip/SET_ACCEPTING_CEDIP",
    SET_REJECTING_CEDIP: "cedip/SET_REJECTING_CEDIP",
};

export const INITIAL_STATE = {
    fetching: false,
    cedipList: [],
    selectedCedip: null,
    fetchingDownload: false,
    showForm: false,
    ownCedipSelected: false,
    recievedCedipSelected: true,
    singleCedip: null,
    cedipId: null,
    filters: {
        orderColumName: "fecha_vencimiento",
        orderDirection: "descendent",
        status: "ACTIVO-PENDIENTE",
        option: "received.cedips",
        idCedip: "",
    },
    manageCedip: "",
    showCleanFilterButton: true,
};

export default (state = INITIAL_STATE, action = {}) => {
    const { showForm, filters, cedipId } = action;

    switch (action.type) {
        case types.LIST_CEDIP_REQUEST:
            return {
                ...state,
                filters,
                fetching: true,
            };
        case types.LIST_CEDIP_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_CEDIP_SUCCESS:
            return {
                ...state,
                fetching: false,
                cedipList: action.cedipList,
                totalPages: action.totalPages,
                pageNumber: action.pageNumber,
            };
        case types.READ_CEDIP_REQUEST:
        case types.REJECT_CEDIP_REQUEST:
        case types.ACCEPT_CEDIP_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.READ_CEDIP_FAILURE:
        case types.REJECT_CEDIP_FAILURE:
        case types.REJECT_CEDIP_SUCCESS:
        case types.ACCEPT_CEDIP_FAILURE:
        case types.ACCEPT_CEDIP_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.READ_CEDIP_SUCCESS:
            return {
                ...state,
                selectedCedip: action.cedip,
                fetching: false,
            };
        case types.DOWNLOAD_CEDIP_LIST_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_CEDIP_LIST_FAILURE:
        case types.DOWNLOAD_CEDIP_LIST_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_CEDIP_DETAIL_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_CEDIP_DETAIL_FAILURE:
        case types.DOWNLOAD_CEDIP_DETAIL_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SET_SHOW_FORM:
            return {
                ...state,
                showForm,
            };
        case types.SET_MY_CEDIP:
            return {
                ...state,
                ownCedipSelected: true,
                recievedCedipSelected: false,
            };
        case types.SET_RECIEVED_CEDIP:
            return {
                ...state,
                ownCedipSelected: false,
                recievedCedipSelected: true,
            };
        case types.SET_FILTERS:
            return {
                ...state,
                filters,
            };
        case types.HANDLE_MANAGE_CEDIP:
            return {
                ...state,
                filters: { ...state.filters, idCedip: cedipId },
                showCleanFilterButton: true,
            };
        case types.SET_ACCEPTING_CEDIP:
            return {
                ...state,
                admitting: true,
                cbu: action.cbu,
            };
        case types.SET_REJECTING_CEDIP:
            return {
                ...state,
                admitting: false,
                cbu: "",
            };
        default:
            return state;
    }
};

export const actions = {
    listCedip: (filters) => ({
        type: types.LIST_CEDIP_REQUEST,
        filters,
    }),
    readCedip: ({ cedipId, fraccionNumero }) => ({
        type: types.READ_CEDIP_REQUEST,
        cedipId,
        fraccionNumero,
    }),
    setShowForm: (showForm) => ({
        type: types.SET_SHOW_FORM,
        showForm,
    }),
    setOwnCedipSelected: () => ({
        type: types.SET_MY_CEDIP,
    }),
    setRecievedCedipSelected: () => ({
        type: types.SET_RECIEVED_CEDIP,
    }),
    setFilters: (filters) => ({ type: types.SET_FILTERS, filters }),
    downloadList: (format) => ({
        type: types.DOWNLOAD_CEDIP_LIST_REQUEST,
        format,
    }),
    handleClickManageCedip: (cedipId) => ({
        type: types.HANDLE_MANAGE_CEDIP,
        cedipId,
        showCleanFilterButton: true,
    }),
    rejectCedip: ({ idCedip, fraction }) => ({ type: types.REJECT_CEDIP_REQUEST, idCedip, fraction }),
    acceptCedip: ({ idCedip, fraction, cbu, accreditationType }) => ({
        type: types.ACCEPT_CEDIP_REQUEST,
        idCedip,
        fraction,
        cbu,
        accreditationType,
    }),
    setSelectedCedip: (cedip) => ({ type: types.READ_CEDIP_SUCCESS, cedip }),
    setAccepting: (cbu) => ({ type: types.SET_ACCEPTING_CEDIP, cbu }),
    setRejecting: () => ({ type: types.SET_REJECTING_CEDIP }),
};

export const selectors = {
    getFetching: ({ cedip }) => cedip.fetching,
    getCedipList: ({ cedip }) => cedip.cedipList,
    getSelectedCedip: ({ cedip }) => cedip.selectedCedip,
    getSelectedCBU: ({ cedip }) => cedip.cbu,
    isAdmitting: ({ cedip }) => cedip.admitting,
    getFetchingDownload: ({ cedip }) => cedip.fetchingDownload,
    ownCedipSelected: ({ cedip }) => cedip.ownCedipSelected,
    recievedCedipSelected: ({ cedip }) => cedip.recievedCedipSelected,
    getShowForm: ({ cedip }) => cedip.showForm,
    getFilters: ({ cedip }) => cedip.filters,
    setId: ({ cedip }) => cedip.cedipId,
    setStatus: ({ cedip }) => cedip.estado,
};

import React, { Component } from "react";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import { push } from "react-router-redux";
import { bool, arrayOf, shape, string, func } from "prop-types";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";

const FORM_ID = "transactions.list.filters.transaction";

class TransactionFilter extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        activities: arrayOf(shape({})).isRequired,
        buttonLabel: string,
        values: shape({}),
    };

    static defaultProps = {
        buttonLabel: null,
        values: {},
    };

    filterOnChangeSelect = (activity) => {
        const { dispatch, isDesktop } = this.props;
        if (isDesktop) {
            dispatch(transactionsActions.loadListRequest({ idActivity: activity }, true, false));
        } else {
            dispatch(
                transactionsActions.setFilters({
                    idActivity: activity,
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
    };

    render() {
        const {
            fetching,
            activities,
            buttonLabel,
            isDesktop,
            values: { activity },
        } = this.props;

        const options =
            activities !== null &&
            activities.map(({ activityName, idActivity }) => ({
                value: idActivity,
                label: activityName,
            }));

        return (
            <Form autoComplete="off" className={classNames("d-flex filters my-0", { "pb-2": !isDesktop })}>
                <Col sm={12} lg={6} md={8}>
                    <Field
                        idForm={FORM_ID}
                        name="activity"
                        component={Selector}
                        searchable={isDesktop}
                        sortByLabel
                        onCustomChange={!isDesktop ? this.filterOnChangeSelect : undefined}
                        options={options}
                        placeholder={i18n.get("transactions.filters.placeholder").toUpperCase()}
                        formGroupClassName={classNames({ "my-0": !isDesktop })}
                    />
                </Col>
                {isDesktop && (
                    <Col sm={12} md={3} className="align-self-flex-end">
                        <Button
                            bsStyle="primary"
                            label={buttonLabel || "product.filters.filter"}
                            loading={fetching}
                            type="submit"
                            className="filter-button m-0"
                            disabled={!activity}
                        />
                    </Col>
                )}
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    activities: transactionsSelectors.getActivities(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: ({ activity }, formikBag) => {
            const { dispatch, isDesktop, onlyPendings, pendingDispatch, selectedFilter } = formikBag.props;

            if (isDesktop) {
                dispatch(
                    transactionsActions.loadListRequest(
                        { idActivity: activity, filter: selectedFilter },
                        onlyPendings,
                        pendingDispatch,
                        formikBag,
                    ),
                );
            } else {
                dispatch(
                    transactionsActions.setFilters({
                        idActivity: activity,
                    }),
                );
                dispatch(push(`/transactions/list/filters/results`));
            }
        },
    }),
)(TransactionFilter);

import React, { useEffect } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { push } from "react-router-redux";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";

import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import ContextMenu from "pages/_components/ContextMenu";

import {
    actions as multipleTransferActions,
    selectors as multipleTransferSelectors,
} from "reducers/multiple-transfers";
import { actions as notificationActions } from "reducers/notification";
import { actions as globalActions } from "reducers/types/global";

import * as i18n from "util/i18n";
import MultipleTransferList from "./MultipleTransferList";
import MultipleTransferFilters from "./MultipleTransferFilters";

function MultipleTransferDashboard({
    isDesktop,
    isSmallDesktop,
    dispatch,
    fetching,
    setValues,
    pageNumber,
    totalPages,
    filters,
    statusList,
    history,
}) {
    useEffect(
        () => () => {
            const nextPath = history?.location?.pathname;
            if (
                !nextPath.includes("/transfer/multiple/detail") &&
                !nextPath.includes("/transfer/multiple/create") &&
                !nextPath.includes("/transactions/cancel") &&
                !nextPath.includes("/transactions/sign")
            ) {
                dispatch(globalActions.resetSelectedElements());
            }
        },
        [history, dispatch],
    );
    const getListTrasfersMultiple = (page = 1) => {
        const defaultFilters = { ...filters, pageNumber: page };
        dispatch(multipleTransferActions.getMultipleTransfersList(defaultFilters, isDesktop));
    };

    useEffect(() => {
        getListTrasfersMultiple();
    }, [dispatch, multipleTransferActions.getMultipleTransfersList, isDesktop]);

    const handleBack = () => dispatch(push("/desktop"));

    const handleTransfer = () => {
        dispatch(multipleTransferActions.setRememberFormData(false));
        dispatch(multipleTransferActions.multipleTransferPre());
    };

    const getCenterElement = () =>
        !isDesktop && <I18n id="multiple.transfers.title" component="h1" componentProps={{ className: "m-0" }} />;

    return (
        <div className="transfer-multiple__dashboard">
            <div className="admin-detail-head px-0">
                <Notification scopeToShow="multipleTransfer.list" notificationClassname="navbar-fixed-top" />
                <Head
                    onBack={handleBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : undefined}
                    centerElement={() => getCenterElement()}
                    rightContent={() =>
                        (!isDesktop ? (
                            <ContextMenu
                                imageStyle="pr-0"
                                items={[
                                    {
                                        label: "multiple.transfers.create",
                                        onClick: () => {
                                            dispatch(
                                                notificationActions.showNotification(
                                                    i18n.get("multiple.transfers.create.mobile.error"),
                                                    "warning",
                                                    ["multipleTransfer.list"],
                                                ),
                                            );
                                        },
                                    },
                                ]}
                            />
                        ) : (
                            undefined
                        ))
                    }
                    accessibilityTextId="transfers.main.label"
                />
                {isDesktop ? (
                    <div className="transfer-multiple__header-desktop">
                        <I18n id="multiple.transfers.title" component="h1" />
                        <Button
                            type="button"
                            bsStyle="primary"
                            label="multiple.transfers.create"
                            className="px-2"
                            onClick={handleTransfer}
                        />
                    </div>
                ) : (
                    undefined
                )}
            </div>
            <div className="transfer-multiple__data-container">
                <MultipleTransferFilters
                    isDesktop={isDesktop}
                    dispatch={dispatch}
                    isSmallDesktop={isSmallDesktop}
                    setValues={setValues}
                    filters={filters}
                    statusList={statusList}
                    fetching={fetching}
                />
                <PageLoading
                    className={classNames({ "screen-loader": !isDesktop, "line-loader my-5": isDesktop })}
                    loading={(fetching && isDesktop) || (fetching && !isDesktop && !pageNumber && !totalPages)}>
                    <MultipleTransferList
                        dispatch={dispatch}
                        isDesktop={isDesktop}
                        getListTrasfersMultiple={getListTrasfersMultiple}
                    />
                </PageLoading>
            </div>
        </div>
    );
}

MultipleTransferDashboard.propTypes = {
    isSmallDesktop: bool.isRequired,
    dispatch: func.isRequired,
    setValues: func.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    filters: shape({}).isRequired,
    statusList: arrayOf(string).isRequired,
    pageNumber: number.isRequired,
    totalPages: number.isRequired,
    history: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    fetching: multipleTransferSelectors.getFetching(state),
    pageNumber: multipleTransferSelectors.getPageNumber(state),
    totalPages: multipleTransferSelectors.getTotalPages(state),
    filters: multipleTransferSelectors.getFilters(state),
    statusList: multipleTransferSelectors.getStatusList(state),
});

export default compose(connect(mapStateToProps))(MultipleTransferDashboard);

import React, { useEffect, useState } from "react";
import { shape, bool, func, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Container from "pages/_components/Container";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import classNames from "classnames";

import { actions, selectors } from "reducers/multiple-transfers";
import { actions as fileActions, selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";

import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Notification from "pages/_components/Notification";
import Row from "react-bootstrap/lib/Row";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import { saveLocation } from "util/transaction";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { downloadUrl } from "util/download";
import PageLoading from "pages/_components/PageLoading";
import Date from "pages/_components/fields/DateField";
import MultipleTransferFile from "pages/forms/_components/_fields/_multipletransferfile/MultipleTransferFile";
import I18n from "pages/_components/I18n";
import { getLimitDate } from "util/date";

const FORM_ID = "multiple.transfers.emit";

function MultipleTransfer(props) {
    const {
        dispatch,
        isDesktop,
        fetching,
        fetchingDownload,
        isSubmitting,
        accounts,
        values,
        summary,
        processedFileData,
        rememberMassiveData,
        setFieldValue,
    } = props;

    const [enableDragDrop, setEnableDragDrop] = useState(false);

    useEffect(() => {
        if (values?.account && values?.executionDate) {
            setEnableDragDrop(true);
        }
        if (!isDesktop) {
            dispatch(push("/transfer/multiple"));
        }
    }, [values, isDesktop, dispatch]);

    useEffect(() => {
        if (accounts?.length === 0) {
            dispatch(actions.multipleTransferPre());
        }
    }, [accounts, dispatch]);

    useEffect(() => {
        if (rememberMassiveData && summary?.idFile) {
            setFieldValue("account", summary?.debitAccount?.idProduct);
            setFieldValue("executionDate", summary?.executionDate);
            setFieldValue("description", summary?.description);
            setFieldValue("file", [processedFileData?.filesMetadata]);
        } else {
            dispatch(actions.cleanMassiveSummary());
            dispatch(fileActions.onFileRemoved());
            setFieldValue("account", "");
            setFieldValue("executionDate", getLimitDate(0));
            setFieldValue("description", "");
            setFieldValue("file", null);
        }

        saveLocation(props);
        // eslint-disable-next-line
    }, []);

    const handleBack = () => {
        dispatch(push("/transfer/multiple"));
    };

    const handleDownload = () => {
        const templateCsv = configUtils.get("frontend.MultipleTransfer.template.example");
        downloadUrl(templateCsv || "");
    };

    const renderDownloadDropdown = () => (
        <div className="toolbar-item--fixed align-items-center justify-content-end">
            <Button
                block
                label="echeq.massive.download"
                loading={fetchingDownload}
                image="images/download.svg"
                onClick={() => handleDownload()}
                className="btn-outline cmf-button-width"
                imageStyle="mr-2"
            />
        </div>
    );

    const rightContent = () => (
        <Col className="p-0 d-flex space-between align-items-center" xs={12}>
            <div className="toolbar-item justify-content-end">{renderDownloadDropdown()}</div>
        </Col>
    );

    const renderHeader = () => (
        <>
            <Head onBack={handleBack} />
            <Head
                title={FORM_ID}
                titleClassName="my-0 no-wrap"
                rightContent={() => rightContent()}
                headerClassName="mb-3"
                hintClassName="max-w-25"
                alwaysShowMessage
                nodeMessage={
                    <I18n
                        id={`${FORM_ID}.title.help`}
                        component="p"
                        componentProps={{ className: "hint-text hint-text-header" }}
                        NOTICE_MESSAGE={configUtils.get("frontend.MultipleTransfer.notice.message", null, {
                            MAX_ALLOWED_TIME: configUtils.getInteger("frontend.MultipleTransfer.max.allowed.time"),
                        })}
                    />
                }
            />
        </>
    );

    const continueButton = () => {
        const hasErrorFile = processedFileData?.invalidLines > 0;
        return (
            <Col md={3} sm={12}>
                <Row className={`${!isDesktop && "ml-0 mr-0"}`}>
                    <div className="admin-content-center">
                        <Button
                            type="submit"
                            bsStyle="primary"
                            label="global.continue"
                            disabled={!values.file || values.file.length < 1 || hasErrorFile || !values?.executionDate}
                            loading={isSubmitting}
                        />
                    </div>
                </Row>
            </Col>
        );
    };

    return (
        isDesktop && (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="form" />
                <div className="admin-detail-head w-100 px-0 mb-0">{renderHeader()}</div>
                <Form autoComplete="off">
                    <div className="multiple-transfer__form">
                        <Container
                            className="flex-grow align-items-center container-white pb-1"
                            gridClassName="form-content mb-3">
                            <Col sm={12} md={8} className="align-items-center pt-4">
                                <Col sm={6} className={classNames("pl-0")}>
                                    <Field
                                        name="account"
                                        component={Productselector}
                                        idField="account"
                                        data={{
                                            emptyOptionLabel: "",
                                            options: accounts,
                                        }}
                                        disabled={accounts.length === 0}
                                        isRequired
                                        mode="edit"
                                        labelText="transfers.debitaccount.label"
                                        idForm={FORM_ID}
                                        isDesktop={isDesktop}
                                        customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                        controlLabelClassName="input__required"
                                    />
                                </Col>
                                <Col sm={6} className={classNames("pr-0")}>
                                    <Field
                                        component={Date}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="executionDate"
                                        selectsStart
                                        minDate={getLimitDate(0)}
                                        maxDate={getLimitDate(
                                            configUtils.getInteger("frontend.MultipleTransfer.filter.max.dateTo", 30),
                                        )}
                                        autocomplete="off"
                                        controlLabelClassName="input__required"
                                        hintIdText={`${FORM_ID}.date.hint`}
                                    />
                                </Col>
                                <Col sm={12} className="px-0">
                                    <Field
                                        component={TextField}
                                        pattern={configUtils.get(
                                            "frontend.MultipleTransfer.file.validation.reference.regexp",
                                        )}
                                        renderAs="input"
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        maxLength="12"
                                        name="description"
                                        type="text"
                                        errorClassName={classNames({
                                            "p-absolute": isDesktop,
                                        })}
                                    />
                                </Col>
                                <Col sm={12} className="px-0 mt-3">
                                    <div className="new-payment-multiline-file">
                                        <Field
                                            name="file"
                                            component={MultipleTransferFile}
                                            type="multilinefile"
                                            mode="edit"
                                            formVersion="1"
                                            sub_type="echeqMassive"
                                            formTitle="echeqMassive"
                                            allowMultiple="false"
                                            acceptedFileTypes="text/csv"
                                            useForTotalAmount="true"
                                            maxFileSizeMB="4"
                                            idField="file"
                                            idForm="echeqMassive"
                                            isRequired
                                            fileUploaded={false}
                                            values={values}
                                            FORM_ID={FORM_ID}
                                            rememberMassiveData={rememberMassiveData}
                                            enabledUpload={enableDragDrop}
                                        />
                                    </div>
                                </Col>
                            </Col>
                        </Container>
                    </div>
                    <Container className="flex-grow align-items-center my-2" gridClassName="form-content">
                        {continueButton()}
                    </Container>
                </Form>
            </PageLoading>
        )
    );
}

MultipleTransfer.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool,
    isSubmitting: bool,
    fetching: bool,
    fetchingDownload: bool,
    rememberMassiveData: bool,
    values: shape({}).isRequired,
    summary: shape({}).isRequired,
    setFieldValue: func.isRequired,
    accounts: arrayOf({}).isRequired,
    processedFileData: shape({}).isRequired,
};

MultipleTransfer.defaultProps = {
    isDesktop: true,
    isSubmitting: false,
    fetching: false,
    fetchingDownload: false,
    rememberMassiveData: false,
};

const mapStateToProps = (state, ownProps) => ({
    fetching: selectors.getFetching(state),
    accounts: selectors.getAccounts(state),
    rememberMassiveData: selectors.getRememberMassiveData(state),
    summary: selectors.getMultipleTransfer(state),
    values: selectors.getValues(state),
    processedFileData: ownProps?.processedFileData || multilineFileSelectors.getProcessedFileData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            account: "",
            executionDate: getLimitDate(0),
            file: null,
            description: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    account: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    executionDate: Yup.string()
                        .nullable()
                        .required(i18n.get(`${FORM_ID}.field.error.required`)),
                    file: values.file
                        ? Yup.array()
                              .of(Yup.object())
                              .min(1, i18n.get(`${FORM_ID}.field.error.required`))
                        : Yup.array().notRequired(),
                }),
            ),
        handleSubmit: ({ account, executionDate, description }, formikBag) => {
            const { props } = formikBag;
            const { dispatch, accounts, processedFileData, summary } = props;
            const selectedAccount = accounts.find(({ idProduct }) => idProduct === account);
            const { number, productType, currency, balance } = selectedAccount;
            const emitMassiveData = {
                amount: processedFileData?.totalAmount,
                debitAccount: selectedAccount?.idProduct,
                account: { number, productType, currency, balance },
                executionDate,
                description,
                fileName: processedFileData?.filesMetadata?.fileName,
                quantity: processedFileData?.validLines,
                idFile: processedFileData?.idFile,
                oldBatchId: summary?.batchId,
            };
            dispatch(actions.createMultipleTransferPreview(emitMassiveData, formikBag));
        },
    }),
)(MultipleTransfer);

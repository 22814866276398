import React from "react";
import { Col } from "react-bootstrap";
import { bool, func } from "prop-types";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import classNames from "classnames";

function SelectFund({ isDesktop, openModal, disabled }) {
    return (
        <Col sm={12} className="align-items-center d-flex space-between w-100 px-md-3">
            {isDesktop && (
                <div className="d-flex align-items-center">
                    <I18n id="fund.operation.subscribe.selectFund.label" componentProps={{ className: "data-label" }} />
                </div>
            )}
            <div className={classNames({ "w-100": !isDesktop }, { "cmf-button-width": isDesktop })}>
                <Button className={classNames("btn-outline")} onClick={openModal} disabled={disabled}>
                    <I18n
                        id="fund.operation.subscribe.selectFund.button.label"
                        componentProps={{ style: { lineHeight: 1 } }}
                    />
                </Button>
            </div>
        </Col>
    );
}

SelectFund.propTypes = {
    isDesktop: bool.isRequired,
    openModal: func.isRequired,
    disabled: bool,
};

SelectFund.defaultProps = {
    disabled: false,
};

export default SelectFund;

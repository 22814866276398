/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";

import { Redirect } from "react-router-dom";
import { routerActions } from "react-router-redux";
import { shape, arrayOf, bool, string, func } from "prop-types";

import { actions, selectors } from "reducers/administration/usersInvite";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as formActions } from "reducers/form";
import * as i18nUtils from "util/i18n";

import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { Form } from "formik";

class UserInviteStep3 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        confirmationParams: shape({
            docCountry: string,
            docNumber: string,
            docType: string,
            document: string,
            email: string,
            firstName: string,
            lastName: string,
            mobilePhone: string,
            role: string,
            signatureLevel: string,
        }).isRequired,
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        idTransaction: string,
        idActivity: string,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, confirmationParams, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            dispatch(actions.userInviteConfirm(confirmationParams, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderMediumSchemeConfiguration = () => {
        const { confirmationParams } = this.props;
        let signatureKeyLabel = "administration.users.invite.transaction.signature.yes";
        if (confirmationParams.signatureLevel === "N") {
            signatureKeyLabel = "administration.users.invite.transaction.signature.no";
        }
        return (
            <Fragment>
                <div className="data-wrapper-flex">
                    <div className="data-label data-label-inline">
                        <I18n id="administration.users.invite.transaction.signature" />
                    </div>
                    <div className="data-desc data-desc-inline mb-0">
                        <I18n id={signatureKeyLabel} />
                    </div>
                </div>
                <div className="data-wrapper-flex">
                    <label className="data-label data-label-inline">
                        <I18n id="administration.users.invite.permissions.label" />
                    </label>
                    <div className="data-desc data-desc-inline mb-0">
                        <I18n id={`invitation.permissions.role.${confirmationParams.role}`} />
                    </div>
                </div>
            </Fragment>
        );
    };

    renderAdvancedSchemeConfiguration = () => {
        const {
            confirmationParams: { signatureLevel, groupNames },
        } = this.props;
        const signatureLabel =
            signatureLevel === "N"
                ? i18nUtils.get("administration.users.edit.signatureLevel.dontSign")
                : signatureLevel;
        const groupText = groupNames.map((groupName, idx) => (
            <Fragment key={groupName}>
                <span>
                    {" "}
                    {groupName} {idx === groupNames.length - 1 ? "" : ", "}{" "}
                </span>
            </Fragment>
        ));
        return (
            <>
                <div className="data-wrapper-flex">
                    <div className="data-label data-label-inline">
                        <I18n id="administration.users.edit.signatureLevel" />
                    </div>
                    <div className="data-desc data-desc-inline mb-0">{signatureLabel}</div>
                </div>

                <div className="data-wrapper-flex">
                    <div className="data-label data-label-inline">
                        <I18n id="administration.users.edit.groups" />
                    </div>
                    <div className="data-desc data-desc-inline mb-0">{groupText}</div>
                </div>
            </>
        );
    };

    render() {
        const {
            isDesktop,
            credentialGroups,
            confirmationParams,
            activeEnvironment: { administrationScheme },
        } = this.props;
        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        if (!confirmationParams) {
            return <Redirect to="/administration/users" />;
        }

        return (
            <>
                <Notification scopeToShow="administrationUsersInvite" />
                <div className="admin-detail-head admin-detail-head-section px-0 rigth-position-desktop">
                    <Head onBack={this.handleBack} closeLinkTo="/administration/users" />
                    <div className="mb-4">
                        <I18n
                            component="h1"
                            componentProps={{ className: "view-title" }}
                            id="administration.users.invite.title"
                        />
                    </div>
                </div>

                <div className="confirmation__container">
                    <Container
                        className={`align-items-center flex-grow px-0 ${
                            isDesktop ? "container--layout with-margin" : "pb-0"
                        }`}
                        gridClassName="confirmation__form confirmation__box">
                        <Container
                            className={`align-items-center flex-grow container-white ${isDesktop ? "" : "pb-0"}`}
                            gridClassName="form-content">
                            <Col className="col col-12">
                                <I18n
                                    component="h2"
                                    componentProps={{ className: "text-center mt-4 mb-3" }}
                                    id="administration.users.invite.personal.data.check.subtitle"
                                />
                            </Col>
                            <Col className="col col-12 inputs-invite-user px-0" md={12} lg={12}>
                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.firstName.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">
                                        {confirmationParams.firstName}
                                    </div>
                                </div>

                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.lastName.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">{confirmationParams.lastName}</div>
                                </div>

                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.birthdate.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">
                                        <FormattedDate date={confirmationParams.birthdate} dateFormat="dd/MM/yyyy" />
                                    </div>
                                </div>

                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.mobilePhone.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">
                                        {confirmationParams.mobilePhone}
                                    </div>
                                </div>

                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.email.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">{confirmationParams.email}</div>
                                </div>
                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.country.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">{confirmationParams.country}</div>
                                </div>
                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.province.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">{confirmationParams.province}</div>
                                </div>
                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.address.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">{confirmationParams.address}</div>
                                </div>
                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.number.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">{confirmationParams.number}</div>
                                </div>
                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.postalCode.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">
                                        {confirmationParams.postalCode}
                                    </div>
                                </div>
                                <div className="data-wrapper-flex">
                                    <div className="data-label data-label-inline">
                                        <I18n id="administration.users.invite.localidad.label" />
                                    </div>
                                    <div className="data-desc data-desc-inline mb-0">
                                        {confirmationParams.localidad}
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container
                            className={`align-items-center flex-grow container-white ${isDesktop ? "" : "pb-0"}`}
                            gridClassName="form-content">
                            <div className="background-white d-flex justify-content-center pb-45 w-100">
                                <Col className="col col-12 px-0 inputs-invite-user pt-3" md={12} lg={12}>
                                    {administrationScheme === "medium"
                                        ? this.renderMediumSchemeConfiguration()
                                        : this.renderAdvancedSchemeConfiguration()}
                                </Col>
                            </div>

                            <Col className="col col-12 p-0">
                                <AdministrationFormConfirmation
                                    credentialGroups={credentialGroups}
                                    onSubmit={this.handleSubmit}
                                    MDcolumnWidth={12}
                                    LGcolumnWidth={12}
                                    buttonLabel="global.confirm"
                                    buttonImage="images/arrowRight.svg"
                                    buttonImageClass="mr-2"
                                    formContainerClassName="confirmation__container"
                                />
                            </Col>
                        </Container>
                    </Container>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    confirmationParams: selectors.getConfirmationParams(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(UserInviteStep3);

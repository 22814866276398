/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { arrayOf, bool, func, shape, string, number } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { goBack, push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";

import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Container from "pages/_components/Container";
import AmountField from "pages/_components/fields/formik/AmountField";
import TextField from "pages/_components/fields/TextField";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import Yup from "yup";
import FieldLabel from "pages/_components/fields/FieldLabel";
import MainContainer from "pages/_components/MainContainer";
import Limits from "pages/transfer/_components/Limits";
import Selector from "pages/_components/fields/formik/Selector";
import Row from "react-bootstrap/lib/Row";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import CreateTemplateModal from "pages/forms/_components/CreateTemplateModal";
import TemplateList from "pages/forms/_components/TemplateList";
import CreateFrequentDesitinationModal from "pages/forms/_components/CreateFrequentDesetinationModal";
import TADWarningModal from "pages/forms/_components/TADWarningModal";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import Scheduler from "pages/forms/_components/_fields/Scheduler";
import * as configUtils from "util/config";

import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { actions as formActions } from "reducers/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as holidaysActions } from "reducers/holidays";
import { saveDraft } from "middleware/form";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import moment from "moment";
import classNames from "classnames";
import { saveLocation, updateSchedulerToSend } from "util/transaction";

const FORM_ID = "transfers";
const MIN_REQUIRED = "6";
const MAX_REQUIRED = "22";

class Transfer extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        dispatch: func.isRequired,
        values: shape({
            flagHint: bool.isRequired,
            debitAccount: string.isRequired,
            creditAccount: string.isRequired,
            amount: shape({}),
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        internalTransfer: bool.isRequired,
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        setTouched: func.isRequired,
        templates: {},
        idTransaction: string.isRequired,
        concepts: arrayOf(shape({})).isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                transferInternal: bool,
                transferThirdParties: bool,
            }),
        }).isRequired,
        isRetailEnvironment: bool.isRequired,
        tadTrx: bool.isRequired,
        isCVU: bool.isRequired,
        summary: shape({
            cbu: string.isRequired,
            email: string.isRequired,
            transferKind: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        templates: null,
    };

    state = {
        frequentVisible: false,
        tadWarningPopupVisible: false,
        tadWarningShowed: false,
        agendaVisible: true,
        buttonDisabled: false,
    };

    componentWillMount = () => {
        const { dispatch, location } = this.props;
        if (location?.pathname === "/transfer") {
            dispatch(transferActions.clearTransferData());
        }
    };

    componentDidMount() {
        const {
            dispatch,
            location,
            setValues,
            values,
            summary,
            values: {
                amount: { amount },
                debitAccount: debitAccountDraft,
            },
        } = this.props;

        dispatch(transferActions.preActivity());
        dispatch(holidaysActions.listHolidays());

        const { cbu, email, transferKind, isFrequentDestinations } = summary;

        if (location?.pathname === "/transfer") {
            saveLocation(this.props);
            if (location?.state) {
                const { isInternal, debitAccount } = location?.state;
                dispatch(transferActions.changeTransferGroup(isInternal));
                setValues({
                    ...values,
                    debitAccount,
                    creditAccount: "",
                    amount: shape({
                        amount: "",
                        currency: "",
                    }),
                    cbuOrAlias: "",
                    reference: "",
                    email: "",
                    emailText: "",
                    concept: "VAR",
                    transferKind: "",
                });
                dispatch(transferActions.accountLimitsActivities(debitAccount, this.getActivityId()));
                dispatch(templateActions.loadTemplateList(this.getFormName()));
            } else {
                const { activeEnvironment } = this.props;
                const isInternalTrx = transferKind === "other" ? false : activeEnvironment.permissions.transferInternal;

                if (!isInternalTrx && isFrequentDestinations) {
                    if (cbu) {
                        setValues({
                            ...values,
                            cbuOrAlias: cbu,
                            email,
                            transferKind,
                        });
                    }
                } else if (email && isFrequentDestinations) {
                    setValues({
                        ...values,
                        email,
                    });
                }
                const idForm = isInternalTrx ? "transferInternal" : "transferThirdParties";
                dispatch(transferActions.changeTransferGroup(isInternalTrx));
                dispatch(templateActions.loadTemplateList(idForm));
            }
        } else {
            dispatch(templateActions.loadTemplateList(this.getFormName()));
            if (location?.pathname === "/transfer/modify" && debitAccountDraft) {
                dispatch(transferActions.accountLimitsActivities(debitAccountDraft, this.getActivityId()));
            }
        }
        if (amount > 0) {
            this.setHint(amount);
        }
    }

    componentDidUpdate(prevProps) {
        const { values, isCVU, dispatch } = this.props;
        const { amount, sendEmail } = values;
        const { amount: amountValue } = amount;
        if (isCVU !== prevProps.isCVU) {
            if (amountValue > 0) {
                this.setHint(amountValue);
            }
        }
        if (sendEmail !== prevProps.values?.sendEmail) {
            dispatch(transferActions.setSendEmail(sendEmail));
        }
    }

    handleBack = () => {
        const { dispatch, location } = this.props;
        if (location?.pathname === "/transfer") {
            return dispatch(goBack());
        }
        return dispatch(push("/desktop"));
    };

    getFormName = () => {
        const { internalTransfer, location } = this.props;
        if (location?.state) {
            return location?.state?.isInternal ? "transferInternal" : "transferThirdParties";
        }
        return internalTransfer ? "transferInternal" : "transferThirdParties";
    };

    verifyCvu = (value) => {
        const { dispatch } = this.props;
        if (this.isValidCbu(value)) {
            const isCVU = value.startsWith("000");
            dispatch(transferActions.setIsCVU(isCVU));
        } else if (this.isValidAlias(value)) {
            dispatch(transferActions.getCbuCvuFromAlias(value));
        }
    };

    isValidCbu = (value) => {
        const reg = /^\d+$/;
        return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
    };

    isValidAlias = (value) => {
        /* Regex that accept letter from a to z including capital letters, (.), (-), numbers
        and excluding (Ñ) and (ñ) */
        const reg = /^(?:(?![ñÑ])[a-zA-Z.\-\d])+$/;
        return reg.test(value);
    };

    isTad = (amountWithCurrency) => {
        const { isRetailEnvironment, activeEnvironment, isCVU } = this.props;
        const value = amountWithCurrency.amount;

        const environmentTypeTag = isRetailEnvironment ? "Retail" : "Corporate";
        const currencyTag = amountWithCurrency.currency.toString() === "0" ? "Pesos" : "Dolares";
        const minAmount = configUtils.getInteger(`frontend.TAD.minAmount${currencyTag}.${environmentTypeTag}`, 100000);
        const maxAmount = configUtils.getInteger(`frontend.TAD.maxAmount${currencyTag}.${environmentTypeTag}`, 1000000);
        const enabledCorporate = configUtils.getBoolean("frontend.show.TAD.functionalities.Corporate", false);
        const enabledRetail = configUtils.getBoolean("frontend.show.TAD.functionalities.Retail", false);

        let tadEnabled = false;

        if (isCVU) {
            return false;
        }

        if (!isRetailEnvironment) {
            if (enabledCorporate) {
                tadEnabled = true;
            }
        } else if (enabledRetail) {
            tadEnabled = true;
        }

        if (tadEnabled && activeEnvironment?.permissions?.transferDiferredAccreditation) {
            if (value >= minAmount && value <= maxAmount) {
                return true;
            }
        }
        return false;
    };

    setHint = (value) => {
        const { setValues, values } = this.props;
        const { tadWarningShowed } = this.state;
        const { amount } = values;

        const currency = value.currency || amount.currency;
        const isTad = this.isTad({
            currency,
            amount: value.amount || value,
        });

        if (isTad) {
            setValues({
                ...values,
                amount: {
                    currency,
                    amount: value,
                },
                flagHint: true,
                tadTrx: true,
            });
            this.setState({
                agendaVisible: false,
            });
            if (!tadWarningShowed) {
                this.setState({
                    tadWarningPopupVisible: true,
                    tadWarningShowed: true,
                });
            }
        } else {
            setValues({
                ...values,
                amount: {
                    currency,
                    amount: value.amount || value,
                },
                flagHint: false,
                tadTrx: false,
            });
            this.setState({
                tadWarningPopupVisible: false,
                tadWarningShowed: false,
                agendaVisible: true,
            });
        }
    };

    getActivityId = () => {
        const { internalTransfer } = this.props;
        return internalTransfer ? "transfers.internal.send" : "transfers.thirdParties.send";
    };

    getActivityIdTad = () => {
        const { internalTransfer } = this.props;
        return internalTransfer ? "transfers.internal.tad.send" : "transfers.thirdParties.tad.send";
    };

    getCurrencyForDraft = () => {
        const { values, accounts } = this.props;
        const { debitAccount } = values;
        if (values?.amount?.currency !== null && values?.amount?.currency !== undefined) {
            return values?.amount?.currency.toString();
        }
        if (values.debitAccount) {
            return accounts.find(({ idProduct }) => debitAccount === idProduct).currency.toString();
        }
        return null;
    };

    getDraftSummary = () => {
        const { values, idTransaction, internalTransfer, accounts } = this.props;
        const {
            debitAccount,
            creditAccount,
            cbuOrAlias,
            amount: { amount, currency },
            reference,
            sendEmail,
            email,
            emailText,
            tadTrx,
            concept,
            transferKind,
            scheduler,
            flagHint,
        } = values;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
        if (internalTransfer) {
            const completeCreditAccount = accounts.find(({ idProduct }) => idProduct === creditAccount);
            return {
                debitAccount: completeDebitAccount,
                creditAccount: completeCreditAccount,
                currency,
                amount,
                reference,
                sendEmail,
                email,
                emailText,
                scheduler: schedulerToSend,
                idTransaction,
                tadTrx,
                flagHint,
            };
        }
        const isCbu = () => /^\d+$/.test(cbuOrAlias);
        return {
            accounts,
            debitAccount: completeDebitAccount,
            cbu: isCbu() ? cbuOrAlias : null,
            alias: !isCbu() ? cbuOrAlias : null,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            concept,
            transferKind,
            scheduler: schedulerToSend,
            idTransaction,
            tadTrx,
            flagHint,
        };
    };

    saveDraft = () => {
        const { dispatch, values, idTransaction, internalTransfer } = this.props;
        const { tadTrx } = values;

        this.setState({ buttonDisabled: true });

        dispatch(
            formActions.saveDraft({
                idForm: internalTransfer ? "transferInternal" : "transferThirdParties",
                idActivityDraft: tadTrx ? this.getActivityIdTad() : this.getActivityId(),
                data: {
                    ...values,
                    amount: {
                        quantity: values?.amount?.amount,
                        currency: this.getCurrencyForDraft(),
                    },
                    debitAccount: values.debitAccount ? { value: values.debitAccount } : null,
                    creditAccount: values.creditAccount ? { value: values.creditAccount } : null,
                },
                idTransaction: idTransaction || null,
                scheduler: values?.scheduler,
                summary: this.getDraftSummary(),
            }),
        );
    };

    handleCreateTemplateClick = () => {
        const { dispatch } = this.props;
        dispatch(templateActions.createTemplate());
    };

    handleTemplateSelect = (template) => {
        const { setValues, setErrors, dispatch, values } = this.props;
        const amount = template.amount?.amount || 0;
        const amountWithCurrency = template.amount;
        const isTad = this.isTad(amountWithCurrency);
        this.verifyCvu(template.cbuOrAlias);

        if (amount > 0) {
            this.setHint(amountWithCurrency);
        }
        setValues({
            ...values,
            debitAccount: template.debitAccount,
            creditAccount: template.creditAccount,
            transferKind: template.transferKind,
            cbuOrAlias: template.cbuOrAlias,
            amount: template.amount,
            concept: template.concept,
            reference: template.reference,
            sendEmail: template.sendEmail,
            email: template.email,
            flagHint: isTad,
            tadTrx: isTad,
        });

        setErrors({});
        dispatch(transactionLinesActions.loadTransactionLinesTemplateData(template));
        const { debitAccount } = template;
        if (debitAccount) {
            dispatch(transferActions.accountLimitsActivities(debitAccount, this.getActivityId()));
        }
    };

    changeCbu = (record) => {
        const { setValues, values } = this.props;
        const transferKindDestination = record.isOwn === "1" ? "same" : "other";
        setValues({
            ...values,
            cbuOrAlias: record.account,
            transferKind: transferKindDestination,
            email: record.email,
        });
        this.setState({ frequentVisible: false });
        this.verifyCvu(record.account);
    };

    getButtonStyle = (button) => {
        const { internalTransfer, isDesktop } = this.props;
        let style = { borderRadius: "25px", textTransform: "none", fontWeight: "400", padding: "8px 8px" };
        if (button === "internal") {
            style = {
                ...style,
                borderColor: `${internalTransfer ? "#0071ce" : "#ccc"}`,
                color: `${internalTransfer ? "#0071ce" : "#666"}`,
                marginRight: "10px",
            };
        } else {
            style = {
                ...style,
                borderColor: `${!internalTransfer ? "#0071ce" : "#ccc"}`,
                color: `${!internalTransfer ? "#0071ce" : "#666"}`,
                marginLeft: isDesktop && "10px",
            };
        }
        return style;
    };

    changeTransferGroup = (isInternal) => {
        const { dispatch, setErrors, setValues, values, setTouched } = this.props;

        dispatch(transferActions.changeTransferGroup(isInternal));
        const idForm = isInternal ? "transferInternal" : "transferThirdParties";
        dispatch(templateActions.loadTemplateList(idForm));
        setErrors({});
        setValues({
            ...values,
            debitAccount: "",
            creditAccount: "",
            amount: {
                amount: "",
                currency: 0,
            },
            cbuOrAlias: "",
            reference: "",
            email: "",
            emailText: "",
            concept: "VAR",
            transferKind: "",
            flagHint: false,
            scheduler: {},
            sendEmail: false,
        });

        this.setState({
            tadWarningPopupVisible: false,
            tadWarningShowed: false,
            agendaVisible: true,
        });
        setTouched({});
    };

    renderLimits = () => {
        const { limit, limitUsed, values, accounts } = this.props;
        const { currency } = values?.amount;
        const selectedAccount = accounts.find((object) => object.idProduct === values.debitAccount);
        return (
            <Limits
                limit={limit}
                limitUsed={limitUsed}
                currency={currency || parseInt(selectedAccount?.currency, 10)}
            />
        );
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("transfers.main.label")}</h1>
        </div>
    );

    rightContentMobile = () => (
        <ButtonDrawer
            buttonClassName=""
            width="100%"
            bsStyle="link"
            image="images/template-icon.svg"
            styleImage={{ width: "24px", height: "24px", color: "white" }}
            styleCloseButton={{ color: "white" }}
            headerContent={
                <div className="title-account-header-multiline-background-blue">
                    <h1 className="w-100 mb-0">{i18n.get("transfers.templates.label")}</h1>
                </div>
            }
            content={<TemplateList onSelect={this.handleTemplateSelect} className="navigational-list" />}
            contentClassname="mx-3"
            backButton
            closeButton={false}
        />
    );

    closeModal = () => {
        this.setState({ frequentVisible: false });
    };

    closeTADModal = () => {
        this.setState({ tadWarningPopupVisible: false });
    };

    onChangeAccount = (account) => {
        const { accounts, setValues, values, dispatch } = this.props;
        const { debitAccount } = values;
        const selectedAccount = accounts.find((object) => object.idProduct === account);
        if (selectedAccount) {
            setValues({
                ...values,
                amount: { currency: parseInt(selectedAccount.currency, 10), amount: values.amount.amount },
            });
            if (debitAccount !== account) {
                dispatch(transferActions.accountLimitsActivities(selectedAccount.idProduct, this.getActivityId()));
            }
        }
    };

    render() {
        const {
            values,
            fetching,
            accounts,
            internalTransfer,
            isDesktop,
            templates,
            concepts = [],
            location,
            activeEnvironment,
            isCVU,
        } = this.props;
        const { agendaVisible, frequentVisible, tadWarningPopupVisible, buttonDisabled } = this.state;
        const transferCvuEnabled = configUtils.getBoolean("transfer.cvu.enabled");
        const { debitAccount, creditAccount, sendEmail, flagHint } = values;
        const currenciesAll = [
            { id: 0, label: i18n.get(`currency.label.ARS`) },
            { id: 2, label: i18n.get(`currency.label.USD`) },
        ];
        const transferKindOptions = [
            {
                value: `same`,
                label: i18n.get("transfers.titular.same.label"),
            },
            {
                value: `other`,
                label: i18n.get("transfers.titular.other.label"),
            },
        ];
        const cbuOrAliasPlaceholder = i18n.get(`${FORM_ID}.cbuCvuOrAlias.placeholder`);
        const cbuOrAliasLabel = `${FORM_ID}.cbuCvuOrAlias.label`;
        const selectedDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const creditAccounts = debitAccount
            ? accounts.filter(
                  ({ idProduct: id, currency }) => id !== debitAccount && selectedDebitAccount?.currency === currency,
              )
            : accounts;

        const data = {
            enabledWeekDays: [1, 2, 3, 4, 5, 6, 7],
            firstWorkingDate: moment(),
            maxDaysToSchedule: 10000,
            nonWorkingDays: [],
            lang: "es",
            mode: "edit",
            programable: true,
            schedulable: true,
            activeEnvironment,
        };

        const handleKeyDown = (e) => {
            if (isDesktop) {
                if (e.key === "Enter") {
                    const { target } = e;
                    this.setHint(Number(target.value.replaceAll(".", "").replace(",", ".")));
                } else {
                    // eslint-disable-next-line no-useless-return
                    return;
                }
            } else {
                // eslint-disable-next-line no-useless-return
                return;
            }
        };

        return (
            <>
                <Notification scopeToShow="form" />
                {!isDesktop && (
                    <div className="admin-detail-head px-0">
                        <Head
                            onBack={this.handleBack}
                            headerClassName="blue-main-header-mobile"
                            centerElement={this.centerContentMobile}
                            rightContent={templates.length > 0 && this.rightContentMobile}
                            accessibilityTextId="transfers.main.label"
                        />
                    </div>
                )}
                <PageLoading
                    loading={fetching || !((selectedDebitAccount && debitAccount) || !debitAccount)}
                    className="screen-loader">
                    {isDesktop && (
                        <div className="admin-detail-head px-0">
                            <Head onBack={this.handleBack} accessibilityTextId="transfers.main.label" />
                            <h1>{i18n.get("transfers.main.label")}</h1>
                        </div>
                    )}
                    <CreateTemplateModal
                        values={values}
                        idForm={internalTransfer ? "transferInternal" : "transferThirdParties"}
                        idActivityTemplate={this.getActivityId()}
                    />
                    <CreateFrequentDesitinationModal
                        isVisible={frequentVisible}
                        onClick={this.changeCbu}
                        closeModal={this.closeModal}
                        isDesktop={isDesktop}
                        selectedDebitAccount={selectedDebitAccount}
                    />
                    <TADWarningModal
                        isVisible={tadWarningPopupVisible}
                        onClick={this.changeCbu}
                        closeModal={this.closeTADModal}
                        isDesktop={isDesktop}
                    />
                    <MainContainer>
                        <div className="above-the-fold transfer-screen">
                            {!fetching && accounts && (
                                <Form>
                                    <Container
                                        className="flex-grow align-items-center cmf-container-white"
                                        gridClassName="form-content"
                                        singleRow={!!isDesktop}>
                                        <Col
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            className="container-white px-0 py-1 align-items-end justify-content-around">
                                            {activeEnvironment.permissions.transferInternal ? (
                                                <Button
                                                    className={`btn-outline btn-regular mr-1 ${
                                                        !isDesktop ? "py-2" : "py-3 mt-3"
                                                    }`}
                                                    block={false}
                                                    image={internalTransfer && `images/check.svg`}
                                                    style={{
                                                        ...this.getButtonStyle("internal"),
                                                        width: "50%",
                                                        whiteSpace: "nowrap",
                                                        backgroundColor: internalTransfer ? "#f2f8fd" : "",
                                                    }}
                                                    onClick={() => this.changeTransferGroup(true)}
                                                    label="transfers.internal.label"
                                                />
                                            ) : (
                                                undefined
                                            )}
                                            {activeEnvironment.permissions.transferThirdParties ? (
                                                <Button
                                                    label="transfers.external.label"
                                                    className={`btn-outline btn-regular mr-1 ${
                                                        !isDesktop ? "py-2" : "py-3 mt-3"
                                                    }`}
                                                    block={false}
                                                    image={!internalTransfer && `images/check.svg`}
                                                    style={{
                                                        ...this.getButtonStyle("external"),
                                                        width: "45%",
                                                        backgroundColor: !internalTransfer ? "#f2f8fd" : "",
                                                    }}
                                                    onClick={() => this.changeTransferGroup(false)}
                                                />
                                            ) : (
                                                undefined
                                            )}
                                        </Col>
                                        <Container className="w-100" gridClassName="form-content pb-2 px-0">
                                            {isDesktop && templates.length > 0 && <Col sm={12} md={1} lg={3} />}
                                            <Col sm={12} md={8} lg={6} className="align-items-center px-0">
                                                <Col
                                                    sm={12}
                                                    className={classNames("col-no-pad-mobile", {
                                                        "mb-3": isDesktop,
                                                    })}>
                                                    {!isDesktop && debitAccount && this.renderLimits()}
                                                    <Field
                                                        name="debitAccount"
                                                        component={Productselector}
                                                        onCustomChange={this.onChangeAccount}
                                                        idField="debitAccount"
                                                        data={{
                                                            emptyOptionLabel: "",
                                                            options: accounts,
                                                        }}
                                                        value={debitAccount}
                                                        isRequired
                                                        mode="edit"
                                                        labelText="transfers.debitaccount.label"
                                                        idForm={FORM_ID}
                                                        isDesktop={isDesktop}
                                                        customPlaceholder={i18n.get(
                                                            "transfers.productSelector.placeholder",
                                                        )}
                                                        formGroupClassName={classNames({
                                                            "mb-0": isDesktop,
                                                        })}
                                                    />
                                                    {isDesktop && debitAccount && this.renderLimits()}
                                                </Col>
                                                <Col sm={12} className="col-no-pad-mobile">
                                                    {internalTransfer ? (
                                                        <Field
                                                            name="creditAccount"
                                                            component={Productselector}
                                                            idField="creditAccount"
                                                            data={{
                                                                emptyOptionLabel: "",
                                                                options: creditAccounts,
                                                            }}
                                                            disabled={!debitAccount}
                                                            value={creditAccount}
                                                            isRequired
                                                            mode="edit"
                                                            idForm={FORM_ID}
                                                            isDesktop={isDesktop}
                                                            customPlaceholder={i18n.get(
                                                                "transfers.productSelector.placeholder",
                                                            )}
                                                        />
                                                    ) : (
                                                        <>
                                                            <div className="my-2">
                                                                <FieldLabel
                                                                    mode="noedit"
                                                                    labelKey="transfers.creditAccount.label"
                                                                />
                                                            </div>
                                                            <Row
                                                                className={classNames({
                                                                    "align-items-start": isDesktop,
                                                                })}>
                                                                <Col sm={12} md={6}>
                                                                    <Field
                                                                        component={Selector}
                                                                        options={transferKindOptions}
                                                                        idForm={FORM_ID}
                                                                        name="transferKind"
                                                                        inLineControl
                                                                        isRequired
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    sm={12}
                                                                    md={6}
                                                                    className={classNames({
                                                                        "d-flex align-items-center": !isDesktop,
                                                                    })}>
                                                                    {isDesktop ? (
                                                                        <Field
                                                                            handleOnBlur={({ target }) => {
                                                                                this.verifyCvu(target.value);
                                                                            }}
                                                                            component={TextField}
                                                                            idForm={FORM_ID}
                                                                            name="cbuOrAlias"
                                                                            labelText={
                                                                                transferCvuEnabled
                                                                                    ? cbuOrAliasLabel
                                                                                    : undefined
                                                                            }
                                                                            placeholderText={
                                                                                transferCvuEnabled
                                                                                    ? cbuOrAliasPlaceholder
                                                                                    : undefined
                                                                            }
                                                                            type="text"
                                                                            maxLength={MAX_REQUIRED}
                                                                            minLength={MIN_REQUIRED}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <div
                                                                                style={
                                                                                    isDesktop ? {} : { width: "90%" }
                                                                                }>
                                                                                <Field
                                                                                    handleOnBlur={({ target }) => {
                                                                                        this.verifyCvu(target.value);
                                                                                    }}
                                                                                    component={TextField}
                                                                                    idForm={FORM_ID}
                                                                                    name="cbuOrAlias"
                                                                                    labelText={
                                                                                        transferCvuEnabled
                                                                                            ? cbuOrAliasLabel
                                                                                            : undefined
                                                                                    }
                                                                                    placeholderText={
                                                                                        transferCvuEnabled
                                                                                            ? cbuOrAliasPlaceholder
                                                                                            : undefined
                                                                                    }
                                                                                    type="text"
                                                                                    maxLength={MAX_REQUIRED}
                                                                                    minLength={MIN_REQUIRED}
                                                                                />
                                                                            </div>
                                                                            {activeEnvironment?.permissions
                                                                                .frequentDestination ? (
                                                                                <Button
                                                                                    disabled={!selectedDebitAccount}
                                                                                    bsStyle="link"
                                                                                    className={classNames(
                                                                                        "no-shadow svg25 ml-25 svg-icon-small",
                                                                                        {
                                                                                            "mt-25": isDesktop,
                                                                                            "mt-4": !isDesktop,
                                                                                        },
                                                                                    )}
                                                                                    image="images/frequent-destination.svg"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            frequentVisible: true,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                undefined
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Col>
                                                                {isDesktop &&
                                                                !internalTransfer &&
                                                                activeEnvironment?.permissions.frequentDestination ? (
                                                                    <div className="align-items-center p-0 transfer-frequent-destination-button-column">
                                                                        <Button
                                                                            disabled={!selectedDebitAccount}
                                                                            bsStyle="link"
                                                                            className="no-shadow transfer-frequent-destination-button svg30"
                                                                            image="images/frequent-destination.svg"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    frequentVisible: true,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    undefined
                                                                )}
                                                            </Row>
                                                        </>
                                                    )}
                                                </Col>
                                                <Col sm={12} className="col-no-pad-mobile">
                                                    <Field
                                                        onBlur={({ target }) =>
                                                            this.setHint(
                                                                Number(
                                                                    target.value.replaceAll(".", "").replace(",", "."),
                                                                ),
                                                            )
                                                        }
                                                        handleKeyDown={handleKeyDown}
                                                        autocomplete="off"
                                                        component={AmountField}
                                                        data={{ options: currenciesAll }}
                                                        idForm={FORM_ID}
                                                        name="amount"
                                                        clearable={false}
                                                        label="transfers.amount.label"
                                                        maxLength={15}
                                                        disableSelect
                                                        fixedDecimalScale
                                                        showWarn={flagHint}
                                                        isDesktop={isDesktop}
                                                        idWarningText="frontend.TAD.warning.text"
                                                        isConfigText
                                                        onPressWarn={() =>
                                                            this.setState({ tadWarningPopupVisible: true })
                                                        }
                                                    />
                                                </Col>
                                                {!internalTransfer && (
                                                    <Col sm={12} md={8} className="col-no-pad-mobile">
                                                        <Field
                                                            component={Selector}
                                                            options={
                                                                isCVU
                                                                    ? concepts
                                                                          .filter(
                                                                              ({ id }) =>
                                                                                  ![
                                                                                      "BRH",
                                                                                      "SON",
                                                                                      "ROP",
                                                                                      "OIH",
                                                                                      "BNR",
                                                                                      "APC",
                                                                                      "SIS",
                                                                                  ].includes(id),
                                                                          )
                                                                          .map(({ id, name }) => ({
                                                                              value: id,
                                                                              label: `${id} - ${name}`,
                                                                          }))
                                                                    : concepts.map(({ id, name }) => ({
                                                                          value: id,
                                                                          label: `${id} - ${name}`,
                                                                      }))
                                                            }
                                                            idForm={FORM_ID}
                                                            name="concept"
                                                            isRequired
                                                            searchable
                                                        />
                                                    </Col>
                                                )}
                                                <Col
                                                    sm={12}
                                                    md={internalTransfer ? 12 : 4}
                                                    className={isDesktop ? "" : "col-no-pad-mobile my-3"}>
                                                    <Field
                                                        component={TextField}
                                                        hidePlaceholder
                                                        idForm={FORM_ID}
                                                        name="reference"
                                                        type="text"
                                                        label="transfers.reference"
                                                        optional={i18n.get("transfers.reference.optional.label")}
                                                    />
                                                </Col>
                                                <Col
                                                    sm={12}
                                                    md={4}
                                                    className={classNames("mt-2 pl-3", {
                                                        "text-left px-0 py-1": !isDesktop,
                                                    })}>
                                                    <Field component={SwitchField} idForm={FORM_ID} name="sendEmail" />
                                                </Col>
                                                <Col
                                                    sm={12}
                                                    className={classNames("col-no-pad-mobile", {
                                                        "d-flex align-items-center": isDesktop,
                                                    })}>
                                                    {isDesktop && agendaVisible && (
                                                        <Col xs={8} className="justify-content-left d-flex pl-0">
                                                            <Field
                                                                component={Scheduler}
                                                                data={data}
                                                                name="scheduler"
                                                                location={location}
                                                                isDesktop={isDesktop}
                                                            />
                                                        </Col>
                                                    )}
                                                </Col>
                                                {sendEmail && (
                                                    <Col sm={12} className="col-no-pad-mobile">
                                                        <Field
                                                            component={TextField}
                                                            hidePlaceholder
                                                            idForm={FORM_ID}
                                                            name="email"
                                                            type="email"
                                                        />
                                                        <Field
                                                            component={TextField}
                                                            hidePlaceholder
                                                            idForm={FORM_ID}
                                                            name="emailText"
                                                            type="text"
                                                        />
                                                    </Col>
                                                )}
                                            </Col>
                                            {isDesktop && templates.length > 0 && (
                                                <Col sm={12} md={3} lg={3} className="align-items-center pl-4">
                                                    <h2 className="mt-0">{i18n.get("transfers.templates.label")}</h2>
                                                    <TemplateList
                                                        onSelect={this.handleTemplateSelect}
                                                        className="navigational-list"
                                                    />
                                                </Col>
                                            )}
                                        </Container>
                                    </Container>
                                    <Container
                                        className="flex-grow align-items-center"
                                        gridClassName="form-content mt-3">
                                        <Col sm={12} md={10} lg={6} className="align-items-center">
                                            <Col
                                                className={classNames(
                                                    "d-flex aligns-items-center px-0 transfer-bottom-buttons",
                                                    { "f-dir-col-reverse": !isDesktop },
                                                )}
                                                sm={12}>
                                                {activeEnvironment?.permissions.createDraft ? (
                                                    <Button
                                                        bsStyle="outline"
                                                        onClick={this.saveDraft}
                                                        label="transfers.actions.saveDraft"
                                                        disabled={buttonDisabled}
                                                    />
                                                ) : (
                                                    undefined
                                                )}
                                                <Button
                                                    bsStyle="outline"
                                                    onClick={this.handleCreateTemplateClick}
                                                    label="transfers.actions.saveTemplate"
                                                />
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    label="global.continue"
                                                    loading={fetching}
                                                />
                                                {!isDesktop && agendaVisible && (
                                                    <div className="w-100 text-lett mx-2">
                                                        <Field
                                                            component={Scheduler}
                                                            location={location}
                                                            data={data}
                                                            name="scheduler"
                                                        />
                                                    </div>
                                                )}
                                            </Col>
                                        </Col>
                                    </Container>
                                </Form>
                            )}
                        </div>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: transferSelectors.getFetching(state),
    accounts: transferSelectors.getAccounts(state),
    concepts: transferSelectors.getConcepts(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
    summary: transferSelectors.getSummary(state),
    templates: templateSelectors.getTemplateList(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    isCVU: transferSelectors.getIsCVU(state),
    sendEmail: transferSelectors.getSendEmail(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        mapPropsToValues: ({ summary, ...props }) => ({
            TDAenabledCorporate: configUtils.getBoolean("frontend.show.TAD.functionalities.Corporate", false),
            TDAenabledRetail: configUtils.getBoolean("frontend.show.TAD.functionalities.Retail", false),
            TDAminPesosCorporate: configUtils.get("frontend.TAD.minAmountPesos.Corporate", 100000),
            TDAmaxPesosCorporate: configUtils.get("frontend.TAD.maxAmountPesos.Corporate", 1000000),
            TDAminPesosRetail: configUtils.get("frontend.TAD.minAmountPesos.Retail", 100000),
            TDAmaxPesosRetail: configUtils.get("frontend.TAD.maxAmountPesos.Retail", 1000000),
            TDAminDolaresCorporate: configUtils.get("frontend.TAD.minAmountDolares.Corporate", 100000),
            TDAmaxDolaresCorporate: configUtils.get("frontend.TAD.maxAmountDolares.Corporate", 1000000),
            TDAminDolaresRetail: configUtils.get("frontend.TAD.minAmountDolares.Retail", 100000),
            TDAmaxDolaresRetail: configUtils.get("frontend.TAD.maxAmountDolares.Retail", 1000000),
            TDAhourFrom: configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00"),
            TDAhourTo: configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00"),

            debitAccount:
                props?.location?.pathname !== "/transfer"
                    ? summary?.debitAccount?.value || summary?.debitAccount?.idProduct || summary?.debitAccount
                    : "",
            creditAccount:
                props.location?.pathname !== "/transfer" && summary?.creditAccount
                    ? summary?.creditAccount?.value || summary?.creditAccount?.idProduct || summary?.creditAccount
                    : "",
            amount:
                props.location?.pathname !== "/transfer"
                    ? { currency: summary?.currency, amount: summary?.amount }
                    : { currency: 0, amount: "" },
            sendEmail: props.location?.pathname !== "/transfer" && summary?.sendEmail ? !!summary?.sendEmail : false,
            flagHint: props.location?.pathname !== "/transfer" && summary?.flagHint ? !!summary?.flagHint : false,
            email: props.location?.pathname !== "/transfer" && summary?.email ? summary?.email : "",
            emailText: props.location?.pathname !== "/transfer" && summary?.emailText ? summary?.emailText : "",
            transferKind:
                props.location?.pathname !== "/transfer" && summary?.transferKind ? summary?.transferKind : "",
            concept: props.location?.pathname !== "/transfer" && summary?.concept ? summary?.concept : "VAR",
            cbuOrAlias: props.location?.pathname !== "/transfer" ? summary?.cbuOrAlias || summary?.cbu : "",
            reference: props.location?.pathname !== "/transfer" && summary?.reference ? summary?.reference : "",
            scheduler:
                props.location?.pathname !== "/transfer" && summary?.scheduler
                    ? { ...summary?.scheduler, valueDate: summary?.scheduler?.newValueDate }
                    : {},
            isCVU: props.isCVU,
        }),
        validationSchema: (props) => {
            const { internalTransfer, sendEmail } = props;
            const isValidCbu = (value) => {
                const reg = /^\d+$/;
                return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
            };

            const isValidAlias = (value) => {
                /* Regex that accept letter from a to z including capital letters, (.), (-), numbers
                and excluding (Ñ) and (ñ) */
                const reg = /^(?:(?![ñÑ])[a-zA-Z.\-\d])+$/;
                return reg.test(value);
            };

            const validateCbuOrAlias = (value) => isValidCbu(value) || isValidAlias(value);

            return Yup.object().shape({
                debitAccount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                creditAccount: internalTransfer
                    ? Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`))
                    : Yup.string(),
                cbuOrAlias: !internalTransfer
                    ? Yup.string()
                          .min(
                              parseInt(MIN_REQUIRED, 10),
                              `${i18n.get("forms.validation.min.required")} ${MIN_REQUIRED}`,
                          )
                          .max(
                              parseInt(MAX_REQUIRED, 10),
                              `${i18n.get("forms.validation.max.required")} ${MAX_REQUIRED}`,
                          )
                          .required(i18n.get(`${FORM_ID}.field.error.required`))
                          .test("format", i18n.get(`${FORM_ID}.field.error.invalid`), validateCbuOrAlias)
                    : Yup.string(),
                concept: !internalTransfer
                    ? Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`))
                    : Yup.string(),
                amount: Yup.object().shape({
                    amount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                }),
                email: sendEmail
                    ? Yup.string()
                          .required(i18n.get(`${FORM_ID}.field.error.required`))
                          .email(i18n.get(`${FORM_ID}.field.error.invalid`))
                    : Yup.string(),
                transferKind: !internalTransfer
                    ? Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`))
                    : Yup.string(),
            });
        },
        handleSubmit: (
            {
                debitAccount,
                creditAccount,
                cbuOrAlias,
                amount: { amount, currency },
                reference,
                sendEmail,
                email,
                emailText,
                concept,
                transferKind,
                scheduler,
                TDAenabledCorporate,
                TDAenabledRetail,
                TDAminPesosCorporate,
                TDAminPesosRetail,
                TDAmaxPesosCorporate,
                TDAmaxPesosRetail,
                TDAminDolaresCorporate,
                TDAminDolaresRetail,
                TDAmaxDolaresCorporate,
                TDAmaxDolaresRetail,
                flagHint,
            },
            formikBag,
        ) => {
            const {
                dispatch,
                accounts,
                internalTransfer,
                location,
                summary,
                isRetailEnvironment,
                activeEnvironment,
                isCVU,
            } = formikBag.props;
            const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
            const completeCreditAccount = accounts.find(({ idProduct }) => idProduct === creditAccount);
            const isCbu = () => {
                const reg = /^\d+$/;
                return reg.test(cbuOrAlias);
            };

            let schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
            const newValueDate = schedulerToSend?.valueDate;
            schedulerToSend = { ...schedulerToSend, newValueDate };
            delete schedulerToSend.valueDate;

            let tadTrx = false;
            if (!isCVU) {
                if (activeEnvironment?.permissions?.transferDiferredAccreditation) {
                    if (!isRetailEnvironment) {
                        if (TDAenabledCorporate) {
                            if (currency === 0) {
                                tadTrx = !!(amount >= TDAminPesosCorporate && amount <= TDAmaxPesosCorporate);
                            } else {
                                tadTrx = !!(amount >= TDAminDolaresCorporate && amount <= TDAmaxDolaresCorporate);
                            }
                        }
                    } else if (TDAenabledRetail) {
                        if (currency === 0) {
                            tadTrx = !!(amount >= TDAminPesosRetail && amount <= TDAmaxPesosRetail);
                        } else {
                            tadTrx = !!(amount >= TDAminDolaresRetail && amount <= TDAmaxDolaresRetail);
                        }
                    }

                    if (tadTrx) {
                        const schedulerTad = updateSchedulerToSend();
                        if (schedulerTad) {
                            schedulerToSend = schedulerTad;
                        }
                    }
                }
            }

            if (internalTransfer) {
                dispatch(
                    transferActions.createInternalTransfer(
                        {
                            debitAccount: completeDebitAccount,
                            creditAccount: completeCreditAccount,
                            currency,
                            amount,
                            reference,
                            sendEmail,
                            email,
                            emailText,
                            scheduler: schedulerToSend,
                            idTransaction: summary.idTransaction,
                            tadTrx,
                            flagHint,
                        },
                        formikBag,
                    ),
                );
            } else {
                dispatch(
                    transferActions.createExternalTransfer(
                        {
                            accounts,
                            debitAccount: completeDebitAccount,
                            cbu: isCbu() ? cbuOrAlias : null,
                            alias: !isCbu() ? cbuOrAlias : null,
                            currency,
                            amount,
                            reference,
                            sendEmail,
                            email,
                            emailText,
                            concept,
                            transferKind,
                            scheduler: schedulerToSend,
                            idTransaction: summary.idTransaction,
                            tadTrx,
                            flagHint,
                        },
                        formikBag,
                    ),
                );
                if (location?.pathname === "/transfer/modify") {
                    saveDraft();
                }
            }
        },
    }),
)(Transfer);

import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { Col } from "react-bootstrap";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { getAccountDescription } from "util/accounts";
import { idTransactionFormat } from "util/format";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";

class CredinDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: {
                    debitAccount,
                    recipients,
                    amount: { currency, quantity },
                },
            } = object.transaction;

            const recipientNames = recipients.map(({ id, recipientName }) => <div key={id}> {recipientName} </div>);
            const accountName = debitAccount?.number !== undefined && getAccountDescription(debitAccount);

            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{accountName}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{recipientNames}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <FormattedAmount
                                quantity={quantity}
                                currency={currency}
                                notBold
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            />
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="requestCredin.confirm.table.header.debitAccount"
                        data={<span>{accountName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="requestCredin.confirm.table.header.creditAccount.owner"
                        data={<span>{recipientNames}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="requestCredin.confirm.table.header.amount"
                        data={
                            <FormattedAmount
                                quantity={quantity}
                                currency={currency}
                                notBold
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            />
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="requestCredin.confirm.table.header.debitAccount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="requestCredin.confirm.table.header.creditAccount.owner" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="requestCredin.confirm.table.header.amount" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(CredinDataTable);

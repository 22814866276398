/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import MultipleSignaturesBody from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesBody";

class MultipleSignaturesDataTables extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    selectDataTable = () => {
        const { multipleSignaturesData, isDesktop } = this.props;

        const returnContent = MultipleSignaturesBody?.[multipleSignaturesData?.activitySelected.idActivity];

        return returnContent ? returnContent(isDesktop) : "";
    };

    render() {
        const { isDesktop } = this.props;
        return isDesktop ? <Table>{this.selectDataTable()}</Table> : this.selectDataTable();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(MultipleSignaturesDataTables);

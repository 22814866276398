export const types = {
    SHOW_NOTIFICATION: "notification/SHOW_NOTIFICATION",
    REMOVE_NOTIFICATION: "notification/REMOVE_NOTIFICATION",
};

export const INITIAL_STATE = {
    message: null,
    level: null,
    scopes: [],
    title: null,
    autoDismiss: true,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SHOW_NOTIFICATION:
            return {
                ...state,
                ...action
            };
        case types.REMOVE_NOTIFICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Action creators
export const actions = {
    showNotification: (message, level, scopes, title, autoDismiss) => ({
        type: types.SHOW_NOTIFICATION,
        message,
        level,
        scopes,
        title,
        autoDismiss: autoDismiss === false ?  autoDismiss : INITIAL_STATE.autoDismiss ,
    }),
    removeNotification: () => ({
        type: types.REMOVE_NOTIFICATION,
    }),
};

// Selectors
export const selectors = {
    getMessage: (state) => state.notification.message,
    getLevel: (state) => state.notification.level,
    getScopes: (state) => state.notification.scopes,
    getTitle: (state) => state.notification.title,
    getAutoDismiss: (state) => state.notification.autoDismiss,
};

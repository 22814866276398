/* eslint-disable import/no-unresolved */
import { call, put, takeLatest, select, take } from "redux-saga/effects";
import { routerActions, LOCATION_CHANGE } from "react-router-redux";
import queryString from "query-string";

import { ACTIVITIES_WITHOUT_PREVIEW_STEP } from "constants.js";
import * as configUtils from "util/config";
import * as form from "middleware/form";
import * as i18n from "util/i18n";
import { getDisplay, getMobileOS } from "util/device";
import types from "reducers/types/form";
import { types as templateTypes } from "reducers/template";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors, actions as sessionActions } from "reducers/session";
import * as file from "middleware/file";
import { validateInternalTransaction, validateExternalTransaction } from "middleware/transfer";
import { createCredinPre } from "middleware/debin";
import { adjustIdFieldErrors, credentialsToUnderscoreFormat } from "util/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import transactionLinesTypes from "reducers/form/transactionLinesTypes";
import { downloadPdf, downloadXls } from "util/download";
import activitiesToRedirect from "util/redirectTransactions";
import {
    redirectFinished,
    redirectPending,
    redirectDraft,
    redirectCancelled,
    redirectFailed,
    redirectScheduler,
} from "sagas/redirectTransactions";
import { types as accountsTypes } from "reducers/accounts";
import { getResponseMessage } from "util/token";
import { globalTypes } from "reducers/types/global";

const administrationTicketRoutes = {
    "administration.modify.permissions.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/permissions/${id}/ticket`,
    "administration.users.blockunblock.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.users.delete.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.groups.blockunblock.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.groups.delete.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.medium.modify.channels.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/channels/${id}/ticket`,
    "administration.medium.modify.signature.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signature/${id}/ticket`,
    "administration.signatures.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.delete.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.user.detail.groups.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupsOfUser/${id}/ticket`,
    "administration.users.invite.send": (id) => `/administration/medium/userInvite/${id}/ticket`,
    "administration.advanced.group.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.advanced.group.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.users.update": (id, administrationScheme) =>
        `/administration/${administrationScheme}/usersUpdate/${id}/ticket`,
    "administration.restrictions.manage.send": (id) => `/administration/restrictions/manage/${id}/ticket`,
    "administration.restrictions.user.delete.send": (id) => `/administration/restrictions/user/delete/${id}/ticket`,
    "deposits.confirmDeposit": (id) => `/depositsConfirmation/${id}/ticket`,
    "pay.paymentAFIP": (id) => `/payPaymentAFIP/${id}/ticket`,
    "delete.paymentAFIP": (id) => `/deletePaymentAFIP/${id}/ticket`,
};

const sagas = [
    takeLatest(LOCATION_CHANGE, readForm),
    takeLatest(types.PREVIEW_FORM_REQUEST, previewForm),
    takeLatest(types.SEND_FORM_REQUEST, sendForm),
    takeLatest(LOCATION_CHANGE, readTransaction),
    takeLatest(LOCATION_CHANGE, readTransactionFromBackoffice),
    takeLatest(types.SAVE_DRAFT_REQUEST, saveDraftTransaction),
    takeLatest(types.CANCEL_TRANSACTION_PRE_REQUEST, cancelTransactionPre),
    takeLatest(types.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    takeLatest(types.MODIFY_TRANSACTION_REQUEST, modifyTransaction),
    takeLatest(types.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    takeLatest(types.SIGN_TRANSACTION_REQUEST, signTransaction),
    takeLatest(types.READ_TRANSACTION_REQUEST, readTransaction),
    takeLatest(types.SEND_FORM_DATA_FAILURE, logout),
    takeLatest(transactionLinesTypes.LIST_TRANSACTION_LINES_REQUEST, listTransactionLinesRequest),
    takeLatest(types.DOWNLOAD_TICKET_REQUEST, downloadTicket),
    takeLatest(types.SHARE_TICKET, shareTicket),
    takeLatest(types.UPDATE_SCHEDULER_TRANSACTION_REQUEST, updateScheduledTransactionRequest),
    takeLatest(types.UPDATE_SCHEDULER_SIGN_TRANSACTION_REQUEST, updateScheduledAndSignTransactionRequest),
];

export default sagas;

function* readForm({ payload }) {
    const {
        state = {
            shouldLoadForm: true,
        },
        pathname,
        search,
    } = payload;
    const [, route, idForm] = pathname.split("/");

    if (route === "form" && state.shouldLoadForm) {
        const { query: params } = queryString.parseUrl(search);
        const response = yield call(form.readForm, idForm, params);

        if (response.type === "W") {
            yield put({ type: types.READ_FORM_FAILURE, notification: { type: "error", code: response.data.code } });
        } else {
            const { form: formMetadata, formData } = response.data.data;

            yield put({ type: templateTypes.LOAD_TEMPLATE_LIST, idForm });
            yield put({ type: types.READ_FORM_SUCCESS, idForm, formMetadata, formData });
        }
    }
}

function* previewForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, formikBag } = payload;
    const index = idActivity.lastIndexOf(".send");
    const previewActivity = `${idActivity.substring(0, index)}.preview`;
    try {
        const { type, data } = yield call(form.preview, idForm, previewActivity, idTransaction, values);

        if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
            if (data.error) {
                yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
                if (data.error && data.error.details) {
                    formikBag.setErrors(adjustIdFieldErrors(data.error.details));
                }
            } else {
                const response = yield call(form.listCredentialsGroups, idForm, idActivity);

                yield put({
                    type: types.PREVIEW_FORM_SUCCESS,
                    idForm,
                    credentialsGroups: response.data.data.groups,
                    submitAction: formActions.sendForm,
                    submitActionParams: { idForm, idActivity, idTransaction, values },
                    previewData: data,
                });
                yield put(formActions.setData(values));
            }
        } else if (type === "W") {
            if (data.data.NO_FIELD) {
                yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
            } else {
                yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
            }

            formikBag.setErrors(adjustIdFieldErrors(data.data));
        } else {
            // TODO a WARNING here must be treated as an ERROR, right?
            const response = yield call(form.listCredentialsGroups, idForm, idActivity);

            yield put({
                type: types.PREVIEW_FORM_SUCCESS,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: { idForm, idActivity, idTransaction, values },
                previewData: data.data,
            });
            yield put(formActions.setData(values));
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* sendForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, credentials, formikBag } = payload;

    try {
        if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
            const response = yield call(form.send, idForm, idActivity, idTransaction, values, credentials);
            const { data, status } = response;
            if (status === 200 && typeof data === "string") {
                yield put(routerActions.replace(`/transaction/${data}`));
            } else {
                yield put({
                    type: types.SEND_FORM_DATA_FAILURE,
                    code: "",
                });
                if (data && data.error && data.error.message) {
                    yield put(notificationActions.showNotification(data.error.message, "error", ["form"]));
                } else {
                    yield put(notificationActions.showNotification(data[Object.keys(data)[0]], "error", ["form"]));
                }
            }
        } else {
            const { data, type } = yield call(form.send, idForm, idActivity, idTransaction, values, credentials);
            if (type === "W") {
                const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);

                if (hasIncorrectCredentials) {
                    formikBag.setErrors(adjustIdFieldErrors(data.data));
                    yield put({
                        type: types.SEND_FORM_CREDENTIAL_FAILURE,
                        code: data.code,
                    });
                } else {
                    yield put({
                        type: types.SEND_FORM_DATA_FAILURE,
                        code: data.code,
                    });
                    yield put(notificationActions.showNotification(data.message, "error", ["form"]));
                }
            } else {
                const transactionResponse = yield call(form.readTransaction, data.idTransaction);
                const { transaction } = transactionResponse.data.data;

                yield put({
                    type: types.SEND_FORM_SUCCESS,
                    transaction,
                });
            }
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* readTransaction({ payload }) {
    const [, route, idTransaction] = payload.pathname.split("/");
    const { newTransaction } = payload;

    if (route === "transaction") {
        const transactionResponse = yield call(form.readTransaction, idTransaction);
        const status = transactionResponse?.data?.data?.transaction?.idTransactionStatus;

        if (transactionResponse.type === "W") {
            yield put({
                type: types.READ_TRANSACTION_FAILURE,
                notification: { type: "error", code: transactionResponse.data.code },
            });
        } else {
            const {
                idForm,
                formVersion,
                idActivity,
                final,
                successfullyCompleted,
            } = transactionResponse.data.data.transaction;
            if (idForm === null && !activitiesToRedirect(idActivity)) {
                const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
                const user = yield select(sessionSelectors.getUser);
                if (final) {
                    if (status === "FINISHED" && successfullyCompleted) {
                        if (user.liberator) {
                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("forms.transaction.ticket.liberated"),
                                    "success",
                                    ["administration"],
                                ),
                            );
                        } else {
                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("forms.transaction.ticket.signed"),
                                    "success",
                                    ["administration"],
                                ),
                            );
                        }
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("deposits.confirmation.success.message"),
                                "success",
                                ["depositsConfirmation"],
                            ),
                        );
                    }
                    if (status === "CANCELLED") {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("forms.transaction.ticket.deleted"),
                                "success",
                                ["administration"],
                            ),
                        );
                    }
                }
                yield put(
                    routerActions.replace(administrationTicketRoutes[idActivity](idTransaction, administrationScheme)),
                );
            } else if (status === "DRAFT") {
                yield call(redirectDraft, idActivity, transactionResponse.data.data?.transaction);
            } else if (status === "FINISHED" && activitiesToRedirect(idActivity)) {
                const user = yield select(sessionSelectors.getUser);
                let messageNotification = "";
                const { isAutomatedDiscount, successMessage } = payload;
                const scopes = [
                    "accountOpeningConfirmation",
                    "checksConfirmation",
                    "pendingTransfer",
                    "forms",
                    "echeqs",
                ];
                if (idActivity === "transfers.internal.send" || idActivity === "transfers.thirdParties.send") {
                    messageNotification = i18n.get("transfers.success.message");
                } else if (
                    idActivity === "transfers.internal.tad.send" ||
                    idActivity === "transfers.thirdParties.tad.send"
                ) {
                    messageNotification = i18n.get(
                        idActivity === "transfers.internal.tad.send"
                            ? "transfer.TAD.internalTransfer.success.message"
                            : "transfer.TAD.externalTransfer.success.message",
                    );
                } else if (isAutomatedDiscount) {
                    messageNotification = successMessage;
                } else if (user.liberator) {
                    messageNotification = i18n.get("forms.transaction.ticket.liberated");
                } else if (idActivity === "endorse.echeq.activity") {
                    messageNotification = i18n.get("echeq.endorse.notification.confirm");
                } else if (idActivity === "cesion.echeq.activity") {
                    messageNotification = i18n.get("echeq.cesion.notification.confirm");
                } else if (idActivity === "negotiate.echeq.activity") {
                    messageNotification = i18n.get("echeq.negotiate.notification.confirm");
                } else {
                    messageNotification = i18n.get("forms.transaction.ticket.signed");
                }
                yield put(notificationActions.showNotification(messageNotification, "success", scopes));
                yield call(redirectFinished, idActivity, transactionResponse.data.data?.transaction);
            } else if (status === "PENDING" && activitiesToRedirect(idActivity)) {
                yield call(redirectPending, idActivity, transactionResponse.data.data?.transaction);
            } else if (status === "CANCELLED" && activitiesToRedirect(idActivity)) {
                yield put(
                    notificationActions.showNotification(i18n.get("forms.transaction.ticket.deleted"), "success", [
                        "administration",
                    ]),
                );
                yield call(redirectCancelled, idActivity, transactionResponse.data.data?.transaction);
            } else if (status === "FAILED" && activitiesToRedirect(idActivity)) {
                yield call(redirectFailed, idActivity, transactionResponse.data.data?.transaction);
            } else if (status === "PROCESSING" && activitiesToRedirect(idActivity)) {
                yield call(redirectFinished, idActivity, transactionResponse.data.data?.transaction);
                if (newTransaction && idActivity === "transfer.multiple.send") {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("multiple.transfers.ticket.success.notification"),
                            "success",
                            ["multiple.transfers"],
                        ),
                    );
                }
            } else if (status === "SCHEDULED" && activitiesToRedirect(idActivity)) {
                yield call(redirectScheduler, idActivity, transactionResponse.data.data?.transaction);
            } else {
                const formResponse = yield call(form.readForm, idForm, {
                    idTransactionToRead: idTransaction,
                    formVersion,
                });

                if (formResponse.type === "W") {
                    yield put({
                        type: types.READ_FORM_FAILURE,
                        notification: { type: "error", code: formResponse.data.code },
                    });
                } else {
                    const { children, parent } = transactionResponse.data.data;
                    let { transaction } = transactionResponse.data.data;
                    transaction = { ...transaction, dispatcher: transactionResponse.data.data.dispatcher };
                    yield put({
                        type: types.READ_TRANSACTION_SUCCESS,
                        idForm: transaction.idForm,
                        transaction,
                        childrenTransactions: children,
                        parentTransaction: parent,
                        formMetadata: formResponse.data.data.form,
                    });
                }
            }
        }
    }
}

function* readTransactionFromBackoffice({ payload }) {
    if (payload.pathname === "/forms/backoffice/ticket") {
        const { query } = queryString.parseUrl(payload.search);
        const exchangeToken = query._exchangeToken;

        const { data } = yield call(form.readTransactionFromBackoffice, exchangeToken);

        yield put({
            type: types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS,
            idForm: data.data.transaction.idForm,
            data: data.data.transactionData,
            formMetadata: data.data.form,
            transaction: data.data.transaction,
        });
    }
}

function* validateInternalTransfer(payload) {
    const {
        scheduler,
        summary: { debitAccount, creditAccount, currency, amount, reference, sendEmail, email, emailText, tadTrx },
        data,
    } = payload;
    const isSavingDraft = true;

    try {
        const response = yield call(
            validateInternalTransaction,
            debitAccount,
            creditAccount,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            scheduler,
            tadTrx,
            isSavingDraft,
        );
        if (response?.type === "I") {
            const { clientUser } = response.data.data;
            return { ...data, clientUser };
        }
        return data;
    } catch {
        return data;
    }
}

function* validateExternalTransfer(payload) {
    const {
        scheduler,
        summary: {
            accounts,
            debitAccount,
            cbu,
            alias,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            concept,
            transferKind,
            tadTrx,
        },
        data,
    } = payload;

    try {
        const isSavingDraft = true;
        const response = yield call(
            validateExternalTransaction,
            accounts,
            debitAccount,
            cbu,
            alias,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            concept,
            scheduler,
            transferKind,
            tadTrx,
            isSavingDraft,
        );
        if (response?.type === "I") {
            const { clientUser } = response.data.data;
            return { ...data, clientUser };
        }
        return data;
    } catch {
        return data;
    }
}

function* validateCredin(payload) {
    const {
        summary: {
            debinSeller: { concept, cbu, amount, idAccount },
            cbuList,
        },
        data,
    } = payload;
    const credin = {
        concept,
        cbu,
        amount,
        idAccount,
    };
    try {
        const response = yield call(createCredinPre, credin, cbuList);
        if (response?.type === "I") {
            const { recipients } = response.data.data;
            return { ...data, recipients };
        }
        return data;
    } catch {
        return data;
    }
}

const validateTransactionsFunctions = {
    "transfers.internal.send": validateInternalTransfer,
    "transfers.internal.tad.send": validateInternalTransfer,
    "transfers.thirdParties.send": validateExternalTransfer,
    "transfers.thirdParties.tad.send": validateExternalTransfer,
    "credin.sentRequest.send": validateCredin,
};

function* saveDraftTransaction({ payload }) {
    const { idForm, idActivityDraft, idTransaction, scheduler, summary, data } = payload;
    let validatedData;
    if (summary) {
        const validateTransaction = validateTransactionsFunctions[idActivityDraft];
        validatedData = yield validateTransaction(payload);
    }
    const response = yield call(
        form.saveDraft,
        idForm,
        validatedData || data,
        idActivityDraft,
        idTransaction,
        scheduler,
    );

    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("forms.saveDraft.errorMessage"), "error", ["form"]));
        yield put({ type: types.SAVE_DRAFT_FAILURE });
    } else {
        const confirmationMessage = i18n.get("forms.saveDraft.confirmationMessage");
        yield put({ type: types.SAVE_DRAFT_SUCCESS, idForm, data: response.data.data });
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["accounts", "desktop"]));
        yield put(routerActions.push("/desktop"));
    }
}

function* cancelTransactionPre({ payload }) {
    const { idActivity, idForm } = payload;

    const { type, data } = yield call(form.listCredentialsGroups, idForm, idActivity);

    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.pre.error"), "error", ["form"]),
        );
        yield put({
            type: types.CANCEL_TRANSACTION_PRE_ERROR,
        });
    } else {
        const { groups } = data.data;

        yield put({
            type: types.CANCEL_TRANSACTION_PRE_SUCCESS,
            credentialsGroups: groups,
        });
    }
}

function* cancelTransaction({ payload }) {
    const { credentials, formikBag, idTransaction, userId, rejectedTransactions, force } = payload;
    try {
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        let response = null;
        if (force) {
            response = yield call(form.forceCancelTransaction, idTransaction, {
                ...credentialsWithUnderscore,
            });
        } else {
            response = yield call(form.cancelTransaction, idTransaction, {
                ...credentialsWithUnderscore,
            });
        }

        if (response?.type === "W") {
            if (response?.data.code === "COR020W") {
                formikBag.setErrors(response?.data);
                yield put(
                    notificationActions.showNotification(i18n.get("forms.cancelTransaction.errorMessage"), "error", [
                        "cancelTransaction",
                    ]),
                );
            } else {
                yield put(notificationActions.showNotification(response?.data.message, "error", ["cancelTransaction"]));
            }
        } else {
            yield call(form.updateUserEditor, idTransaction, userId, "CANCELLED", null, rejectedTransactions);

            yield put({
                type: types.CANCEL_TRANSACTION_SUCCESS,
            });
            yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* downloadTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    if (type === "W") {
        yield put({ type: types.DOWNLOAD_TICKET_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_TICKET_SUCCESS });
    }
}

function* shareTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    const fileName = "Transaction Ticket";
    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        let contentData = `data:text/pdf;base64,${data.data.content}`;
        if (getMobileOS(getDisplay()) === "iOS") {
            contentData = `data:text/pdf:${fileName}'.pdf;base64,${data.data.content}`;
        }
        const options = {
            message: fileName,
            subject: fileName,
            files: [contentData],
        };
        window.plugins.socialsharing.shareWithOptions(options, null, null);
    }
}

function* modifyTransaction({ idTransaction }) {
    const response = yield call(form.moveToDraftTransaction, idTransaction);
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.modifyTransaction.errorMessage"), "error", ["form"]),
        );
        yield put({ type: types.CANCEL_TRANSACTION_FAILURE });
    } else {
        yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}` }));
    }
}

function* signTransactionPreview({ payload }) {
    const { idForm, idActivity, idTransaction, ticketData } = payload;
    const credentialsResponse = yield call(form.listCredentialsGroups, idForm, idActivity);
    const objectData = {
        type: types.SIGN_TRANSACTION_PREVIEW_SUCCESS,
        idForm,
        credentialsGroups: credentialsResponse.data.data.groups,
        submitAction: formActions.signTransaction,
        submitActionParams: { idForm, idActivity, idTransaction },
        ticketData,
    };
    if (ACTIVITIES_WITHOUT_PREVIEW_STEP.indexOf(idActivity) === -1) {
        const { type, data } = yield call(
            form.signPreview,
            idForm,
            idActivity.replace(".send", ".preview"),
            idTransaction,
        );
        if (type === "W") {
            // TODO, que se hace?
        } else if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
            yield put({ ...objectData, previewData: data });
        } else {
            yield put({ ...objectData, previewData: data.data });
        }
    } else {
        yield put(objectData);
    }
}

function* signTransaction({ payload }) {
    const {
        idForm,
        idActivity,
        idTransaction,
        credentials,
        formikBag,
        userId,
        pendingDispatch,
        useTransactionData,
    } = payload;
    try {
        const { data, type } = yield call(form.sign, idForm, idActivity, idTransaction, credentials);

        if (type === "W" && data.code !== "API516W") {
            if (data.data.signatureError) {
                yield put(notificationActions.showNotification(data.data.signatureError, "error", ["signature"]));
                yield put({
                    type: types.SEND_FORM_CREDENTIAL_FAILURE,
                });
            } else {
                const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);

                if (hasIncorrectCredentials) {
                    formikBag.setErrors(adjustIdFieldErrors(data.data));
                    yield put({
                        type: types.SEND_FORM_CREDENTIAL_FAILURE,
                        code: data.code,
                    });
                } else {
                    yield put(
                        notificationActions.showNotification(getResponseMessage({ data }), "error", ["signature"]),
                    );
                    yield put({
                        type: types.SEND_FORM_DATA_FAILURE,
                        code: data.code,
                    });
                }
            }
            yield put({
                type: types.SIGN_TRANSACTION_SET_RESULT,
                isSuccessSign: false,
            });
        } else {
            yield put({
                type: types.SIGN_TRANSACTION_SET_RESULT,
                isSuccessSign: true,
            });
            if (idActivity === "accounts.opening") {
                yield put({
                    type: accountsTypes.ACCOUNT_RESPONSE_DATA,
                    responseData: data.data,
                });
            }
            if (
                (idActivity === "transfers.internal.tad.send" || idActivity === "transfers.thirdParties.tad.send") &&
                formikBag.props.summary?.scheduler?.newValueDate
            ) {
                const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
                const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
                yield put(
                    notificationActions.showNotification(
                        i18n.get("transfer.TAD.warning.message.scheduled", null, {
                            DATE_FROM: TDAhourFrom.substring(0, 5),
                            DATE_TO: TDAhourTo.substring(0, 5),
                        }),
                        "warning",
                        ["transactionScheduled"],
                    ),
                );
            }
            if (useTransactionData) {
                if (idActivity === "credin.sentRequest.send" && data?.data?.isMultiple) {
                    if (data?.data?.frequentDestination) {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("credin.create.success.message.frequent"),
                                "success",
                                ["debin"],
                            ),
                        );
                    } else {
                        yield put(
                            notificationActions.showNotification(i18n.get("credin.create.success.message"), "success", [
                                "debin",
                            ]),
                        );
                    }
                    yield put(routerActions.push("/requestCredin"));
                } else {
                    yield put(
                        formActions.readTransaction({
                            pathname: `/transaction/${data?.idTransaction}`,
                            newTransaction: true
                        }),
                    );
                }
            } else {
                if (data?.data?.message) {
                    yield put({
                        type: globalTypes.SET_VOUCHER_SNACKBAR,
                        snackbarMessage: data.data.message,
                    });
                }
                yield call(form.updateUserEditor, idTransaction, userId, "SIGN", pendingDispatch);
                yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}`, newTransaction: true }));
                yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
            }
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* listTransactionLinesRequest({ payload }) {
    const response = yield call(file.listTransactionLines, payload);
    if (response.type === "W") {
        yield put(transactionLinesActions.listTransactionLinesFailure());
    } else {
        yield put(transactionLinesActions.listTransactionLinesSuccess(response.data.data));
    }
}

function* logout({ code }) {
    if (code === "API010W") {
        yield put(sessionActions.logout());
        yield put(notificationActions.showNotification(i18n.get("returnCode.API010W"), "error", ["login"]));
    }
}

function* updateScheduledTransactionRequest({ payload }) {
    const { idTransaction, schedulerData } = payload;
    const response = yield call(form.updateScheduledTransaction, idTransaction, schedulerData);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["signature"]));
    }
}

function* updateScheduledAndSignTransactionRequest({ payload }) {
    const { idTransaction, schedulerData } = payload;

    const response = yield call(form.updateScheduledTransaction, idTransaction, schedulerData);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["signature"]));
    } else {
        yield call(signTransaction, { payload });
    }
}

import React from "react";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";

const transfersRender = ({ clientUser }) => (
    <div>
        <I18n
            componentProps={{ className: "card__pending-transaction-data-creation" }}
            id="transaction.list.header.to"
        />
        <span>{clientUser?.firstName}</span>
    </div>
);

const TransactionCustomInfo = {
    "negotiate.echeq.activity": ({ echeqList }) => (
        <div>
            <I18n componentProps={{ className: "card__pending-transaction-data-creation" }} id="echeq.received.from" />
            <span>{echeqList?.map((echeq) => echeq.from?.emisorRazonSocial)}</span>
            {echeqList && (
                <>
                    <span className="px-2">|</span>
                    <I18n
                        componentProps={{ className: "card__pending-transaction-data-creation" }}
                        id="echeqs.cheque_numero"
                    />
                    {echeqList.map(({ cheque_numero }, index) => (
                        <>
                            {index !== 0 && <span className="px-2">|</span>}
                            <span> {cheque_numero} </span>
                        </>
                    ))}
                </>
            )}
        </div>
    ),
    "endorse.echeq.activity": ({ echeqList, beneficiaryName }) =>
        echeqList?.map((echeq) => (
            <div>
                <I18n
                    componentProps={{ className: "card__pending-transaction-data-creation" }}
                    id="echeq.received.from"
                />
                <span>{echeq.from?.emisorRazonSocial}</span>
                <span className="px-2">|</span>
                <I18n
                    componentProps={{ className: "card__pending-transaction-data-creation" }}
                    id="transaction.list.header.to"
                />
                <span>{beneficiaryName}</span>
                {echeqList && (
                    <>
                        <span className="px-2">|</span>
                        <I18n
                            componentProps={{ className: "card__pending-transaction-data-creation" }}
                            id="echeqs.cheque_numero"
                        />
                        {echeqList.map(({ cheque_numero }, index) => (
                            <>
                                {index !== 0 && <span className="px-2">|</span>}
                                <span> {cheque_numero} </span>
                            </>
                        ))}
                    </>
                )}
            </div>
        )),
    "emit.echeq.activity": ({ beneficiaryName, echeqNumeration }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.list.header.to"
            />
            <span>{beneficiaryName}</span>
            {echeqNumeration && (
                <>
                    <span className="px-2">|</span>
                    <I18n
                        componentProps={{ className: "card__pending-transaction-data-creation" }}
                        id="echeqs.cheque_numero"
                    />
                    {JSON.parse(echeqNumeration).map(({ cheque_numero }, index) => (
                        <>
                            {index !== 0 && <span className="px-2">|</span>}
                            <span> {cheque_numero} </span>
                        </>
                    ))}
                </>
            )}
        </div>
    ),
    "cesion.echeq.activity": ({ echeqList, beneficiaryName }) =>
        echeqList?.map((echeq) => (
            <div>
                <I18n
                    componentProps={{ className: "card__pending-transaction-data-creation" }}
                    id="echeq.received.from"
                />
                <span>{echeq.from?.emisorRazonSocial}</span>
                <span className="px-2">|</span>
                <I18n
                    componentProps={{ className: "card__pending-transaction-data-creation" }}
                    id="transaction.list.header.to"
                />
                <span>{beneficiaryName}</span>
                {echeqList && (
                    <>
                        <span className="px-2">|</span>
                        <I18n
                            componentProps={{ className: "card__pending-transaction-data-creation" }}
                            id="echeqs.cheque_numero"
                        />
                        {echeqList.map(({ cheque_numero }, index) => (
                            <>
                                {index !== 0 && <span className="px-2">|</span>}
                                <span> {cheque_numero} </span>
                            </>
                        ))}
                    </>
                )}
            </div>
        )),
    "accept.return.echeqs": ({ solicitantName, echeqList }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="table.acceptReturn.solicitante"
            />
            <span>{solicitantName}</span>
            {echeqList && (
                <>
                    <span className="px-2">|</span>
                    <I18n
                        componentProps={{ className: "card__pending-transaction-data-creation" }}
                        id="echeqs.cheque_numero"
                    />
                    {echeqList.map(({ cheque_numero }, index) => (
                        <>
                            {index !== 0 && <span className="px-2">|</span>}
                            <span> {cheque_numero} </span>
                        </>
                    ))}
                </>
            )}
        </div>
    ),
    "accounts.opening": ({ currency, accountType }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.list.header.aditionalInfo"
            />
            <span>{`${i18nUtils.get(`accounts.new.account.accountType.${accountType}`)} ${i18nUtils.get(
                `accounts.new.account.currency.${currency}`,
            )}`}</span>
        </div>
    ),
    "account.checks": ({ checksTypes }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.list.header.aditionalInfo"
            />
            <span>{checksTypes?.label}</span>
        </div>
    ),
    "scheduled.transactions.cancel": ({ clientUser }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.list.header.to"
            />
            <span>{clientUser?.firstName}</span>
        </div>
    ),
    "delete.paymentAFIP": ({ taxpayerCUIT }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="pay.paymentAFIP.taxpayerCUIT"
            />
            <span>{taxpayerCUIT}</span>
        </div>
    ),
    "payments.makePayment": ({ summaryInfo }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="services.newPayment.entities.label"
            />
            <span>{summaryInfo?.selectedEntity?.description}</span>
        </div>
    ),
    "pay.paymentAFIP": ({ paymentAFIP }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="pay.paymentAFIP.taxpayerCUIT"
            />
            <span>{paymentAFIP?.contributorsCUIT}</span>
        </div>
    ),
    "pay.multiline.send": ({ typePayment }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="payments.payThirdParties.newPayment.type.label"
            />
            <span>
                {typePayment === "PH"
                    ? i18nUtils.get("payments.payThirdParties.newPayment.salaryPayments.label")
                    : i18nUtils.get("payments.payThirdParties.newPayment.supplierPayments.label")}
            </span>
        </div>
    ),
    "change.status.debin.recurrency": ({ sellerName }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="table.acceptReturn.solicitante"
            />
            <span>{sellerName}</span>
        </div>
    ),
    "load.debin.recurrency": ({ sellerName }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="table.acceptReturn.solicitante"
            />
            <span>{sellerName}</span>
        </div>
    ),
    "credin.sentRequest.send": ({ recipients }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="requestCredin.summary.owner.label"
            />
            {Array.isArray(recipients) ? (
                recipients?.map(({ recipientName }, index) => (
                    <>
                        {index !== 0 ? <span className="px-2">|</span> : ""}
                        <span> {recipientName} </span>
                    </>
                ))
            ) : (
                <span>{recipients?.recipientName}</span>
            )}
        </div>
    ),
    "debin.incoming.accept.send": ({ sellerDetail }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="table.acceptReturn.solicitante"
            />
            <span>{sellerDetail?.titular}</span>
        </div>
    ),
    "administration.signatures.delete.send": ({ signatureLevelsCounts }, administrationScheme) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="administration.signatures.list.item.schema.label"
            />
            <span>
                {administrationScheme === "medium"
                    ? `${i18nUtils.get("administration.signatures.create.medium.confirm.signersCount")} ` +
                      `${signatureLevelsCounts.A}`
                    : Object.keys(signatureLevelsCounts).reduce(
                          (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                          "",
                      )}
            </span>
        </div>
    ),
    "administration.signatures.modify.send": ({ signatureLevelsCounts }, administrationScheme) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="administration.signatures.list.item.schema.label"
            />
            <span>
                {administrationScheme === "medium"
                    ? `${i18nUtils.get("administration.signatures.create.medium.confirm.signersCount")} ` +
                      `${signatureLevelsCounts.A}`
                    : Object.keys(signatureLevelsCounts).reduce(
                          (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                          "",
                      )}
            </span>
        </div>
    ),
    "administration.signatures.create.send": ({ signatureLevelsCounts }, administrationScheme) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="administration.signatures.list.item.schema.label"
            />
            <span>
                {administrationScheme === "medium"
                    ? `${i18nUtils.get("administration.signatures.create.medium.confirm.signersCount")} ` +
                      `${signatureLevelsCounts.A}`
                    : Object.keys(signatureLevelsCounts).reduce(
                          (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                          "",
                      )}
            </span>
        </div>
    ),
    "administration.restrictions.user.delete.send": ({ userFullName }) => (
        <div>
            <span className="card__pending-transaction-data-creation">{`${i18nUtils.get(
                "transaction.ticket.user",
            )}/${i18nUtils.get("transaction.ticket.group")}`}</span>
            <span>{userFullName}</span>
        </div>
    ),
    "administration.restrictions.manage.send": ({ userName }) => (
        <div>
            <span className="card__pending-transaction-data-creation">{`${i18nUtils.get(
                "transaction.ticket.user",
            )}/${i18nUtils.get("transaction.ticket.group")}`}</span>
            <span>{userName || `${i18nUtils.get("administration.restrictions.restrictions.environment")}`}</span>
        </div>
    ),
    "administration.simple.modify.permissions.send": ({ userFullName }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.user"
            />
            <span>{userFullName}</span>
        </div>
    ),
    "administration.medium.modify.permissions.send": ({ userFullName }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.user"
            />
            <span>{userFullName}</span>
        </div>
    ),
    "administration.medium.modify.signature.send": ({ userFullName }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.user"
            />
            <span>{userFullName}</span>
        </div>
    ),
    "administration.medium.modify.channels.send": ({ enabledChannels }) => (
        <div>
            <I18n componentProps={{ className: "card__pending-transaction-data-creation" }} id="tableHeader.channel" />
            {Array.isArray(enabledChannels)
                ? enabledChannels
                      .filter((channel) => channel !== "all")
                      .map((channel, index) => (
                          <>
                              {index !== 0 ? <span className="px-2">|</span> : ""}
                              <I18n id={`channels.${channel}`} component="span" />
                          </>
                      ))
                : ""}
        </div>
    ),
    "administration.users.invite.send": ({ firstName, lastName }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.user"
            />
            <span>{`${firstName} ${lastName}`}</span>
        </div>
    ),
    "administration.users.update": ({ userFullName }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.user"
            />
            <span>{userFullName}</span>
        </div>
    ),
    "administration.users.blockunblock.send": ({ userNameList }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.user"
            />
            {Array.isArray(userNameList)
                ? userNameList.map((userName, index) => (
                      <>
                          {index !== 0 ? <span className="px-2">|</span> : ""}
                          <span> {userName} </span>
                      </>
                  ))
                : ""}
        </div>
    ),
    "administration.users.delete.send": ({ userNameList }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.user"
            />
            {Array.isArray(userNameList)
                ? userNameList.map((userName, index) => (
                      <>
                          {index !== 0 ? <span className="px-2">|</span> : ""}
                          <span> {userName} </span>
                      </>
                  ))
                : ""}
        </div>
    ),
    "administration.groups.delete.send": ({ groupNameList }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.group"
            />
            {Array.isArray(groupNameList)
                ? groupNameList.map((groupName, index) => (
                      <>
                          {index !== 0 ? <span className="px-2">|</span> : ""}
                          <span> {groupName} </span>
                      </>
                  ))
                : ""}
        </div>
    ),
    "administration.groups.blockunblock.send": ({ groupNameList }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.group"
            />
            {Array.isArray(groupNameList)
                ? groupNameList.map((groupName, index) => (
                      <>
                          {index !== 0 ? <span className="px-2">|</span> : ""}
                          <span> {groupName} </span>
                      </>
                  ))
                : ""}
        </div>
    ),
    "administration.advanced.group.modify.send": ({ name }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.group"
            />
            <span>{name}</span>
        </div>
    ),
    "administration.groups.modify.send": ({ name }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.group"
            />
            <span>{name}</span>
        </div>
    ),
    "administration.advanced.group.create.send": ({ name }) => (
        <div>
            <I18n
                componentProps={{ className: "card__pending-transaction-data-creation" }}
                id="transaction.ticket.group"
            />
            <span>{name}</span>
        </div>
    ),
    "transfers.internal.send": transfersRender,
    "transfers.internal.tad.send": transfersRender,
    "transfers.thirdParties.send": transfersRender,
    "transfers.thirdParties.tad.send": transfersRender,
};

export default TransactionCustomInfo;

export default function activitiesToRedirect(activity) {
    const activitesToRedirect = [
        "accounts.opening",
        "accounts.opening",
        "account.checks",
        "accounts.opening",
        "transfers.internal.send",
        "transfers.internal.tad.send",
        "accounts.opening",
        "transfers.thirdParties.send",
        "transfers.thirdParties.tad.send",
        "accounts.opening",
        "debin.incoming.accept.send",
        "accounts.opening",
        "credin.sentRequest.send",
        "endorse.echeq.activity",
        "negotiate.echeq.activity",
        "echeq.admit.action",
        "echeq.repudiate.action",
        "echeq.deposit.action",
        "echeq.custody.action",
        "echeq.rescue.action",
        "pay.multiline.send",
        "payments.makePayment",
        "emit.echeq.activity",
        "echeq.admit-cesion.action",
        "echeq.repudiate-cesion.action",
        "cesion.echeq.activity",
        "echeq.anulate-echeq.action",
        "echeq.anulate-cesion.action",
        "echeq.anulate-endorse.action",
        "echeq.request-return.action",
        "echeq.repudiate-return.action",
        "echeq.anulate-return.action",
        "accept.return.echeqs",
        "echeq.request-certificate.action",
        "load.debin.recurrency",
        "change.status.debin.recurrency",
        "scheduled.transactions.cancel",
        "fundcorp.register.rescue.request",
        "fundcorp.register.suscription.request",
        "emit.massive.echeqs.send",
        "transfer.multiple.send",
    ];

    return activitesToRedirect.find((object) => object === activity);
}

import { maskedAccountNumber } from "util/accounts";
import * as i18n from "util/i18n";
import { string, number, shape, bool } from "prop-types";
import moment from "moment";

export const accountDescription = (productAlias, numberV, currency, productType) => {
    if (numberV && numberV !== undefined) {
        if (productAlias) {
            return productAlias.concat(" ", productType, " ", i18n.get(`currency.label.${currency}`));
        }
        return maskedAccountNumber(numberV).concat(" ", productType, " ", i18n.get(`currency.label.${currency}`));
    }
    return "";
};
export const accountUnMaskedDescription = (checksRequestData) =>
    checksRequestData?.account.number.concat(
        " ",
        checksRequestData?.account.productType,
        " ",
        i18n.get(`currency.label.${checksRequestData?.account?.currency}`),
    );

export const productTypes = { CA: "savings", CC: "checking" };

export const checkDisableMultiple = (echeq, environmentCuit, status) => {
    if (
        status === "Activo-pendiente" ||
        status === "Emitido-Pendiente" ||
        status === "Activo" ||
        status.includes(",") ||
        status === "Cesion-pendiente" ||
        status === "Custodia"
    ) {
        if (echeq.estado === "EMITIDO-PENDIENTE") {
            return false;
        }
        if (echeq.estado === "ACTIVO") {
            if (echeq.tenencia_beneficiario_documento === environmentCuit) {
                return false;
            }
            return true;
        }

        if (echeq.estado === "ACTIVO-PENDIENTE") {
            if (echeq.total_endosos === 0 || echeq.tenencia_beneficiario_documento !== environmentCuit) {
                return true;
            }
            return false;
        }
        if (echeq.estado === "CESION-PENDIENTE") {
            if (echeq.total_cesiones > 0 && echeq.cesiones[0].cesionario_documento === environmentCuit) {
                return false;
            }
            return true;
        }
        if (echeq.estado === "CUSTODIA") {
            const fechaPago = moment(echeq.fecha_pago)
                .utc()
                .format("YYYY-MM-DD");
            const fechaHoy = moment()
                .utc()
                .format("YYYY-MM-DD");
            if (echeq.tenencia_beneficiario_documento === environmentCuit && fechaPago > fechaHoy) {
                return false;
            }
        }
    }

    return true;
};

export const echeqConceptLabel = (concept, concatCharacter) => {
    if (concept === "") {
        return "";
    }
    const conceptText = i18n.get(`transfers.concept.${concept}`);

    if (conceptText.includes("transfers.concept")) {
        return concatCharacter.concat(concatCharacter !== "" ? " " : "", concept);
    }
    return concatCharacter.concat(concatCharacter !== "" ? " " : "", conceptText);
};

export const statusTagColor = (state) => {
    switch (state) {
        case "EMITIDO-PENDIENTE":
            return "#FFE5B6";
        case "ACTIVO":
            return "#84efd0";
        case "CUSTODIA":
            return "#84efd0";
        case "PRESENTADO":
            return "#84efd0";
        case "ACTIVO-PENDIENTE":
            return "#FFE5B6";
        case "DEVOLUCION-PENDIENTE":
            return "#FFE5B6";
        case "REPUDIADO":
        case "RECHAZADO":
            return "#feb0af";
        case "ANULADO":
            return "#feb0af";
        case "DEPOSITADO":
            return "#84efd0";
        case "PAGADO":
            return "#feb0af";
        case "CADUCADO":
            return "#feb0af";
        case "NEGOCIACION":
            return "#84efd0";
        case "NEGOCIACION-PENDIENTE":
            return "#FFE5B6";
        case "CESION-PENDIENTE":
            return "#FFE5B6";
        default:
            return "#84efd0";
    }
};

export const statusTagColorBatch = (state) => {
    switch (state) {
        case "PENDING":
        case "PROCESSING":
            return "#ffe5b6";
        case "PROCESS_ERROR":
            return "#e6e6e6";
        case "PROCESS_SUCCESS":
        case "SUCCESS":
            return "#84efd0";
        case "PROCESS_REJECTED":
        case "CANCELED":
        case "FAILED":
        case "ERROR":
            return "#feb0af";
        default:
            return "#e6e6e6";
    }
};

export const dynamicSort = (property, direction) => {
    let sortOrder = 1;
    let attribute = property;
    if (property[0] === "-") {
        sortOrder = -1;
        attribute = property.substr(1);
    }
    let result = 0;

    return (a, b) => {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */

        const aux1 = a[attribute].trim();
        const aux2 = b[attribute].trim();

        if (direction === "ascendent") {
            if (aux1 === "") {
                result = -1;
            } else if (aux2 === "") {
                result = 1;
            } else {
                result = aux1 < aux2 ? -1 : 1;
            }
        } else if (aux1 === "") {
            result = 1;
        } else if (aux2 === "") {
            result = -1;
        } else {
            result = aux1 > aux2 ? -1 : 1;
        }
        return result * sortOrder;
    };
};

export const receivedEcheqsStatusActions = {
    "EMITIDO-PENDIENTE": ["ADMIT", "REPUDIATE"],
};
export const emittedEcheqsStatusActions = {
    "EMITIDO-PENDIENTE": ["ANULATE-ECHEQ"],
};

export const echeqShape = shape({
    numero_chequera: string.isRequired,
    cheque_numero: string.isRequired,
    beneficiario_nombre: string.isRequired,
    beneficiario_documento_tipo: string.isRequired,
    beneficiario_documento: string.isRequired,
    monto: number.isRequired,
    fecha_pago: string.isRequired,
    emisor_cuit: string.isRequired,
    emisor_razon_social: string.isRequired,
    emisor_moneda: string.isRequired,
    fecha_emision: string.isRequired,
    extra_info: string.isRequired,
    estado: string.isRequired,
    cheque_tipo: string.isRequired,
    cheque_modo: number.isRequired,
    cheque_caracter: string.isRequired,
    cheque_concepto: string.isRequired,
    cheque_motivo_pago: string.isRequired,
    cheque_id: string.isRequired,
    tenencia_beneficiario_nombre: string.isRequired,
    tenencia_beneficiario_documento_tipo: string.isRequired,
    tenencia_beneficiario_documento: string.isRequired,
    total_endosos: number.isRequired,
    total_cesiones: number.isRequired,
    endosos: shape({}).isRequired,
    cesiones: shape({}).isRequired,
    cesion_pendiente: bool.isRequired,
    certificado_emitido: bool.isRequired,
    re_presentar: bool.isRequired,
    cheque_acordado: bool.isRequired,
    solicitando_acuerdo: bool.isRequired,
    cuit_solic_devol: string.isRequired,
});
export function findEmisor(echeq, environmentCuit) {
    let emisor = {
        emisorRazonSocial: echeq.emisor_razon_social,
        emisorDocumento: "CUIT ".concat(" ", echeq.emisor_cuit),
    };

    if (echeq.total_endosos > 0 && echeq.cheque_caracter === "A la orden") {
        const miEndoso = echeq.endosos.find(
            (endoso) => endoso.benef_documento === environmentCuit && endoso.emisor_documento !== environmentCuit,
        );

        if (miEndoso?.emisor_documento) {
            emisor = {
                emisorDocumento: miEndoso.emisor_documento_tipo.concat(" ", miEndoso.emisor_documento),
                emisorRazonSocial: miEndoso.emisor_razon_social,
            };
        }
    }
    if (echeq.total_cesiones > 0 && echeq.cheque_caracter.toLowerCase() === "no a la orden") {
        const miCesion = echeq.cesiones.find(
            (cesion) => cesion.cesionario_documento === environmentCuit && cesion.cedente_documento !== environmentCuit,
        );

        if (miCesion?.cedente_documento) {
            emisor = {
                emisorDocumento: miCesion.cedente_documento_tipo.concat(" ", miCesion.cedente_documento),
                emisorRazonSocial: miCesion.cedente_nombre,
            };
        }
    }
    return emisor;
}
export function findBeneficiary(echeq, environmentCuit) {
    let beneficiario = {
        beneficiarioRazonSocial: echeq.beneficiario_nombre,
        beneficiarioDocumento: echeq.beneficiario_documento_tipo
            .toUpperCase()
            .concat(" ", echeq.beneficiario_documento),
    };

    if (echeq.total_endosos > 0 && echeq.cheque_caracter === "A la orden") {
        const miEndoso = echeq.endosos.find((endoso) => endoso.emisor_documento === environmentCuit);

        if (miEndoso?.emisor_documento) {
            beneficiario = {
                beneficiarioDocumento: miEndoso.benef_documento_tipo.concat(" ", miEndoso.benef_documento),
                beneficiarioRazonSocial: miEndoso.benef_razon_social,
            };
        }
    }
    if (echeq.total_cesiones > 0 && echeq.cheque_caracter.toLowerCase() === "no a la orden") {
        const miCesion = echeq.cesiones.find((cesion) => cesion.cedente_documento === environmentCuit);

        if (miCesion?.cedente_documento) {
            beneficiario = {
                beneficiarioDocumento: miCesion.cesionario_documento_tipo.concat(" ", miCesion.cesionario_documento),
                beneficiarioRazonSocial: miCesion.cesionario_nombre,
            };
        }
    }
    return beneficiario;
}

export const checksDetailStatusTagColor = (state) => {
    switch (state.toUpperCase()) {
        case "ACREDITADO":
        case "PAGADO":
            return "#84efd0";
        case "RECHAZADO":
            return "#feb0af";
        default:
            return "#c7c7c8";
    }
};

export const checksDetailGetCodeMsg = (code) => {
    switch (code.toUpperCase()) {
        case "EN CUSTODIA DEL BANCO":
            return "EN CUSTODIA";
        case "CHEQUES ELECTRONICOS":
            return "ELECTRÓNICO";
        case "CHEQUES":
            return "FÍSICO";
        default:
            return code;
    }
};

export const getChecksDetailAccountName = (checkDetail) => {
    const isEmitted = checkDetail?.isEmittedSelected;

    const accountCheck = isEmitted ? checkDetail.codigoCuentaDebitoCheque : checkDetail.codigoCuentaAcreditacionCheque;

    const systemCheck = isEmitted ? checkDetail.codigoSistemaDebitoCheque : checkDetail.codigoSistemaAcreditacionCheque;

    const currencyCheck = isEmitted ? checkDetail.codigoMonedaDebitoCheque : checkDetail.codigoMonedaAcreditacionCheque;

    return `${maskedAccountNumber(accountCheck)} ${i18n.get(`checksDetail.productType.${systemCheck}`)} ${i18n.get(
        `currency.label.${currencyCheck}`,
    )}`;
};

export const statuses = [
    { value: "1", label: "EN CUSTODIA" },
    { value: "2", label: "RECHAZADO" },
    { value: "3", label: "AL COBRO" },
    { value: "4", label: "ACREDITADO" },
];

export const discountedChecksDetailGetCodeMsg = (code) => {
    switch (code) {
        case 1:
            return "EN CUSTODIA";
        case 2:
            return "RECHAZADO";
        case 3:
            return "AL COBRO";
        default:
            return "ACREDITADO";
    }
};

export const multipleEcheqsTicketData = (echeqAction) => {
    const body = {
        negotiate: [
            { accountLabel: "multiple.echeq.account" },
            { type: "multiple.echeq.type" },
            { reference: "multiple.echeq.reference" },
            { referencevalue: "multiple.echeq.reference.value" },
        ],
        endorse: [
            { type: "multiple.echeq.type" },
            { reference: "multiple.echeq.reference" },
            { referencevalue: "multiple.echeq.reference.value" },
        ],
        cesion: [
            { address: "multiple.echeq.address" },
            { reference: "multiple.echeq.reference" },
            { referencevalue: "multiple.echeq.reference.value" },
        ],
    };

    return body[echeqAction];
};
export const createEmisionEcheqList = (form) => {
    let echeqList = [];
    const { amount, reference, referencevalue, date, type, quantity } = form;

    for (let i = 0; i < form.quantity; i++) {
        echeqList = [
            ...echeqList,
            { amount: parseFloat((amount / quantity).toFixed(2)), reference, referencevalue, date, type },
        ];
    }

    return echeqList;
};

export const setTenenciaBeneficiario = (echeqAction) => {
    if (!echeqAction.echeqList) {
        return echeqAction;
    }

    const echeqListObject = [];
    echeqAction.echeqList.forEach((echeq) => {
        const object = { ...echeq };
        if (
            object.total_endosos > 0 &&
            (!object.tenencia_beneficiario_documento_tipo || !object.tenencia_beneficiario_documento)
        ) {
            const lastEndorsement = object.endosos[0];
            object.tenencia_beneficiario_nombre = lastEndorsement.benef_razon_social;
            object.tenencia_beneficiario_documento_tipo = lastEndorsement.benef_documento_tipo;
            object.tenencia_beneficiario_documento = lastEndorsement.benef_documento;
        }
        echeqListObject.push(object);
    });

    return { ...echeqAction, echeqList: echeqListObject };
};

export const cleanEcheqObject = (echeqAction, environmentCuit) => {
    let echeqListObject = [];
    let quantity = 0;
    if (!echeqAction.echeqList) {
        return echeqAction;
    }
    // I´m cleaning some keys from the json because of the length in extended_data
    echeqAction.echeqList.forEach((echeqJson) => {
        const object = { ...echeqJson, from: findEmisor(echeqJson, environmentCuit) };
        delete object.firmantes;
        delete object.avalistas;
        delete object.endosos;
        delete object.rechazos;
        delete object.cesiones;
        echeqListObject = [...echeqListObject, object];
        quantity += object.monto;
    });

    return { ...echeqAction, echeqList: echeqListObject, amount: { currency: "0", quantity } };
};

export const getTotalAmountEcheqs = (echeqsToEmit) => echeqsToEmit.reduce((a, b) => a + parseFloat(b.amount, 10), 0);

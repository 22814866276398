import React from "react";
import { func, bool, shape } from "prop-types";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik, Form } from "formik";

import { selectors as cedipSelectors, actions as cedipActions } from "reducers/cedip";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import CedipSummaryData from "./_components/CedipSummaryData";

function AcceptOrRejectCedip({ isDesktop, dispatch, isSubmitting, cedip, isAdmitting }) {
    const title = isAdmitting ? "deposits.cedip.accept.summary" : "deposits.cedip.reject.summary";
    const confirmText = isAdmitting ? "deposits.cedip.accept.confirm" : "deposits.cedip.reject.confirm";

    const handleBack = () => {
        dispatch(push(`/cedip`));
    };

    const confirmButton = (
        <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
            <div className="admin-content-center">
                <Button
                    image="images/arrowRight.svg"
                    imageStyle={isDesktop ? "mr-2" : "mr-1"}
                    bsStyle="primary"
                    label="global.confirm"
                    loading={isSubmitting}
                    type="submit"
                />
            </div>
        </Col>
    );

    if (!cedip) {
        handleBack();
    }

    return (
        <>
            <Notification scopeToShow="subscribeSummary" />
            <div className="admin-detail-head px-0">
                <Head
                    onBack={handleBack}
                    title={!isDesktop && title}
                    titleClassName="text-white mt-2"
                    accessibilityTextId={title}
                    headerClassName={classNames({
                        "blue-main-header-mobile": !isDesktop,
                    })}
                    centerContentClassName={classNames({
                        "mx-5": !isDesktop,
                    })}
                />
                {isDesktop && <h1 className="mt-1 mb-3">{i18nUtils.get(title)}</h1>}
            </div>
            <Form className="confirmation__container">
                <Container
                    className={classNames({
                        "mb-9 flex-grow align-items-center cmf-container-white pt-3": !isDesktop,
                        "mx-4": isDesktop,
                    })}
                    gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}>
                    <div
                        className={classNames("admin-content-center", {
                            "mb-3 px-4": !isDesktop,
                        })}>
                        <I18n
                            id={confirmText}
                            component={!isDesktop ? "h2" : "h1"}
                            componentProps={{ className: classNames({ "f-size-35": !isDesktop }) }}
                        />
                    </div>
                    <Col className={`col-12 ${!isDesktop && "pt-3 mt-3"}`} lg={12} md={9} sm={12}>
                        <CedipSummaryData isDesktop={isDesktop} />
                    </Col>
                    <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                        {confirmButton}
                    </Container>
                </Container>
            </Form>
        </>
    );
}

AcceptOrRejectCedip.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isSubmitting: bool.isRequired,
    isAdmitting: bool.isRequired,
    cedip: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    cbu: cedipSelectors.getSelectedCBU(state),
    cedip: cedipSelectors.getSelectedCedip(state),
    isAdmitting: cedipSelectors.isAdmitting(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: (_, formikBag) => {
            const { cbu, cedip, dispatch, isAdmitting } = formikBag.props;

            if (isAdmitting) {
                dispatch(
                    cedipActions.acceptCedip({
                        idCedip: cedip.cedipId,
                        fraction: cedip.fraccionNumero,
                        cbu,
                        accreditationType: cedip.tipoAcreditacion,
                    }),
                );
            } else if (!isAdmitting) {
                dispatch(cedipActions.rejectCedip({ idCedip: cedip.cedipId, fraction: cedip.fraccionNumero }));
            }
        },
    }),
)(AcceptOrRejectCedip);

import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { string, shape, bool, node, func, objectOf } from "prop-types";

import { actions as formActions } from "reducers/form";
import { selectors, actions as adminActions } from "reducers/administration/common/administrationTicket";

import searchForFileData from "util/administrationTransactionsExports";
import * as i18n from "util/i18n";
import { exportGenericTicketPdf } from "util/transaction";

import Col from "react-bootstrap/lib/Col";
import ContextMenu from "pages/_components/ContextMenu";
import Heading from "pages/_components/Heading";
import TransactionTicket from "pages/_components/TransactionTicket";

class AdministrationTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        children: node.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        extraDataDownload: shape({}).isRequired,
    };

    state = {
        // eslint-disable-next-line react/no-unused-state
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const { idTransaction } = match.params;

        dispatch(adminActions.loadAdministrationTicketRequest(idTransaction));
    }

    onClick = () => {
        const { dispatch, transactionData } = this.props;

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData.idActivity,
                idTransaction: transactionData.idTransaction,
                ticketData: transactionData.data,
            }),
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "global.goToHome",
                bsStyle: "primary",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 m-0 px-2">{i18n.get("forms.transaction.ticket")}</h1>
        </div>
    );

    getExportNameActivity = (idActivity) => {
        if (idActivity?.match("simple")) {
            return idActivity?.replace("simple", "advanced");
        }
        return idActivity?.replace("medium", "advanced");
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { transactionData, extraDataDownload } = this.props;

        const ticketData = {
            ticketName: transactionData.activityName,
            ticketBody: searchForFileData(
                { ...transactionData, ...extraDataDownload },
                this.getExportNameActivity(transactionData.idActivity),
            ),
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    rightContentTransactionScheme = () => {
        const { isDesktop } = this.props;

        return (
            <>
                <ContextMenu
                    isDesktop={isDesktop}
                    buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                    items={[
                        {
                            label: "pendingTransaction.info.scheme",
                            // eslint-disable-next-line react/no-unused-state
                            onClick: () => this.setState({ showDrawerMobile: true }),
                        },
                    ]}
                />
            </>
        );
    };

    render() {
        const { transactionData, isDesktop, isSmallDesktop, children } = this.props;
        const { fetchingDownload } = this.state;

        if (transactionData && transactionData.data) {
            const { idTransaction, submitDateTimeAsString, activityName } = transactionData;
            return (
                <TransactionTicket
                    notificationScope="administration"
                    isFetchingExport={fetchingDownload}
                    handleClick={this.exportPdf}
                    handleBack={this.handleBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.centerContentMobile}
                    summary={transactionData}
                    buttonsDetail={this.buttonsDetail()}
                    isDesktop={isDesktop}
                    isSmallDesktop={isSmallDesktop}
                    summaryTitle={activityName}
                    isAdministrationTicket
                    onSignTicket={() => this.onClick("signTransaction")}
                    ignoreHomeButton
                    hasInlineButtons
                    defaultConfiguration>
                    <Col sm={12} className="px-0 mt-4">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="forms.transaction.ticket.date"
                            data={<p className="data-desc m-0">{submitDateTimeAsString}</p>}
                        />
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="pay.paymentAFIP.idTransaction"
                            data={<p className="data-desc m-0">{idTransaction.substring(0, 8).toUpperCase()}</p>}
                        />
                    </Col>
                    {children && React.cloneElement(children, this.props)}
                </TransactionTicket>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    fetching: selectors.isFetching(state),
    transactionData: selectors.getData(state),
    extraDataDownload: selectors.getExtraData(state),
});

export default connect(mapStateToProps)(AdministrationTicket);

import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { Col } from "react-bootstrap";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { getAccountDescription } from "util/accounts";
import { idTransactionFormat } from "util/format";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

class ThirdPaymentDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        const typePaymentOptions = [
            {
                value: `PH`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.salaryPayments.label"),
            },
            {
                value: `PP`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.supplierPayments.label"),
            },
        ];

        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: {
                    typePayment,
                    dateProcess,
                    amount: { quantity, currency },
                    debitAccountData,
                },
            } = object.transaction;

            const accountName = debitAccountData?.number !== undefined && getAccountDescription(debitAccountData);

            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{typePaymentOptions.find(({ value }) => value === typePayment).label}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{accountName}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />
                        </Table.Data>
                        <Table.Data align="right">
                            <FormattedAmount
                                notBold
                                currency={currency}
                                quantity={quantity}
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            />
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="thirdPay.confirm.table.header.typePayment"
                        data={<span>{typePaymentOptions.find(({ value }) => value === typePayment).label}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="thirdPay.confirm.table.header.account"
                        data={<span>{accountName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="thirdPay.confirm.table.header.dateProcess"
                        data={<FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="payments.thirdPayment.detail.amount"
                        data={
                            <FormattedAmount
                                notBold
                                currency={currency}
                                quantity={quantity}
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            />
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="thirdPay.confirm.table.header.typePayment" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="thirdPay.confirm.table.header.account" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="thirdPay.confirm.table.header.dateProcess" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="payments.thirdPayment.detail.amount" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(ThirdPaymentDataTable);

import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";

class EcheqNegotiateDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const echeq = object.transaction.data.echeqList[0];
            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="center">
                            <span>{echeq.cheque_numero}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{i18n.get("banco.cmf.name")}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{object.transaction.data.beneficiary}</span>
                        </Table.Data>
                        <Table.Data align="center">
                            <FormattedDate date={echeq.fecha_pago} dateFormat="dd/MM/yyyy" withSpan />
                        </Table.Data>
                        <Table.Data align="center">
                            <span>{echeq.cheque_tipo}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{echeq.cheque_motivo_pago}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <FormattedAmount currency="0" quantity={echeq.monto} />
                        </Table.Data>
                    </Table.Row>
                );
            }

            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="table.emitCesion.echeq"
                        data={<span>{echeq.cheque_numero}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.echeq.beneficiary"
                        data={<span>{i18n.get("banco.cmf.name")}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.echeq.beneficiary.cuit"
                        data={<span>{object.transaction.data.beneficiary}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.date"
                        data={<FormattedDate date={echeq.fecha_pago} dateFormat="dd/MM/yyyy" withSpan />}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.type"
                        data={<span>{echeq.cheque_tipo}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.motive"
                        data={<span>{echeq.cheque_motivo_pago}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.amount"
                        data={<FormattedAmount currency="0" quantity={echeq.monto} />}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="center">
                            <I18n id="table.emitCesion.echeq" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.echeq.beneficiary" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.echeq.beneficiary.cuit" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.emitCesion.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.emitCesion.type" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.emitCesion.motive" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="table.emitCesion.amount" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(EcheqNegotiateDataTable);

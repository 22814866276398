/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, string, node, bool, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions } from "reducers/transactions";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";
import { findScheduleDesc } from "util/scheduler";
import I18n from "pages/_components/I18n";
import Card from "pages/_components/Card/Card";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionAditionalInfo from "./TransactionAditionalInfo";
import TransactionCustomInfo from "./TransactionCustomInfo";
import TransactionFrom from "./TransactionFrom";
import TransactionTo from "./TransactionTo";

const idActivities = [
    "account.checks",
    "accounts.opening",
    "pay.multiline.send",
    "administration.signatures.create.send",
    "administration.signatures.modify.send",
    "administration.signatures.delete.send",
    "administration.restrictions.user.delete.send",
    "administration.restrictions.manage.send",
    "administration.simple.modify.permissions.send",
    "administration.medium.modify.permissions.send",
    "administration.medium.modify.signature.send",
    "administration.medium.modify.channels.send",
    "administration.users.invite.send",
    "administration.users.update",
    "administration.users.blockunblock.send",
    "administration.users.delete.send",
    "administration.advanced.group.modify.send",
    "administration.groups.delete.send",
    "administration.groups.blockunblock.send",
    "administration.advanced.group.modify.send",
    "administration.groups.modify.send",
    "administration.advanced.group.create.send",
];

class CardTransactionsItem extends Component {
    static propTypes = {
        transaction: shape({
            cancelEnabled: string.isRequired,
            transaction: shape({
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
            }),
            transactionAmounts: shape(),
        }).isRequired,
        administrationScheme: string.isRequired,
        checks: node.isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
    };

    getAditionalInfo = () => {
        const { transaction: propsTransaction, administrationScheme } = this.props;
        const {
            transaction: { data, idActivity },
        } = propsTransaction;

        if (idActivities.includes(idActivity)) {
            return "";
        }
        const returnContent = TransactionAditionalInfo?.[idActivity];
        return returnContent ? returnContent(data, administrationScheme) : "";
    };

    getBottomInfo = () => {
        const { transaction: propsTransaction, administrationScheme } = this.props;
        const {
            transaction: { data, idActivity },
        } = propsTransaction;
        const returnContent = TransactionCustomInfo?.[idActivity];
        return returnContent ? returnContent(data, administrationScheme) : "";
    };

    getFrom = () => {
        const { transaction: propsTransaction } = this.props;
        const {
            transaction: { data, idActivity },
        } = propsTransaction;
        const returnContent = TransactionFrom?.[idActivity];
        return returnContent ? returnContent(data) : "";
    };

    getTo = () => {
        const { transaction: propsTransaction } = this.props;
        const {
            transaction: { data, idActivity },
        } = propsTransaction;
        const returnContent = TransactionTo?.[idActivity];
        return returnContent ? returnContent(data) : "";
    };

    renderContexMenu = ({ idTransaction }) => {
        const { dispatch } = this.props;
        const items = [
            {
                label: "transactions.list.actions.delete",
                onClick: () => {
                    dispatch(transactionsActions.deleteDraftRequest(idTransaction));
                },
            },
        ];
        return <ContextMenu imageStyle="pr-0" items={items} />;
    };

    render() {
        const { transaction: propsTransaction, checks, isDesktop } = this.props;
        const { transaction, transactionAmounts } = propsTransaction;
        const {
            creationDateTime,
            activityName,
            programed,
            idTransactionStatus,
            data: { checkBookChecksAmount },
        } = transaction;
        const [currency] = Object.keys(transactionAmounts);
        const amount = transactionAmounts[currency];
        const showAmount = amount !== 0;

        const titleCard = () => (
            <div
                className={`card__pending-transaction-data-title ${
                    idTransactionStatus === "DRAFT" ? "space-between d-flex" : ""
                }`}>
                <div className="d-flex align-items-center">
                    <I18n
                        componentProps={{ className: "card__pending-transaction-data-creation" }}
                        id="transactions.list.header.creationDate"
                    />
                    <FormattedDate showTime date={creationDateTime} />
                    {this.getAditionalInfo() && <span className="px-2">|</span>}
                    {programed && isDesktop && (
                        <InfoTag
                            type="default"
                            message={findScheduleDesc(transaction?.program?.frequency?.frequencyUnit)}
                        />
                    )}
                    <div className="card__pending-transaction-aditional-info">{this.getAditionalInfo()}</div>
                </div>
                {!isDesktop && idTransactionStatus === "DRAFT" && (
                    <button type="button" className="btn btn-link p-0 m-0 mh-auto" onClick={(e) => e.stopPropagation()}>
                        {this.renderContexMenu(transaction)}
                    </button>
                )}
            </div>
        );

        const contentCard = () => (
            <div className="card__pending-transaction-data-description">
                <span>{activityName}</span>
            </div>
        );

        const bottomCard = () => (
            <>
                <div className="card__pending-transaction-data-bottom">
                    {this.getBottomInfo() && this.getBottomInfo()}
                </div>
                <div>
                    {programed && !isDesktop && (
                        <InfoTag
                            type="default"
                            message={findScheduleDesc(transaction?.program?.frequency?.frequencyUnit)}
                        />
                    )}
                </div>
            </>
        );

        const contentRight = () => (
            <div className="card__component-item-currency d-flex align-items-center">
                {showAmount && currency && <FormattedAmount currency={currency} quantity={amount} />}
                {checkBookChecksAmount || undefined}
                {isDesktop && idTransactionStatus === "DRAFT" && (
                    <button type="button" className="btn btn-link p-0 m-0" onClick={(e) => e.stopPropagation()}>
                        {this.renderContexMenu(transaction)}
                    </button>
                )}
            </div>
        );

        return (
            <>
                <Card
                    className="card__pending-transaction"
                    icon={`images/transfer-${idTransactionStatus}.svg`}
                    iconClass="card__pending-transaction-icon"
                    rightArrow={idTransactionStatus !== "DRAFT"}
                    title={titleCard()}
                    content={contentCard()}
                    bottom={bottomCard()}
                    rightContent={contentRight()}
                    checks={checks}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    administrationScheme: sessionSelectors.getAdministrationScheme(state),
});

export default connect(mapStateToProps)(CardTransactionsItem);

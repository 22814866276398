import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, shape } from "prop-types";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedAmount from "pages/_components/FormattedAmount";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";
import { getAccountDescription } from "util/accounts";

class TransferInternalDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: { formikBag, creditReference },
            } = object.transaction;

            const [currency] = Object.keys(object.transactionAmounts);
            const amount = object.transactionAmounts[currency];

            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{idTransaction.substring(0, 8).toUpperCase()}</span>
                        </Table.Data>
                        <Table.Data>
                            <span>{getAccountDescription(formikBag?.props?.summary?.debitAccount)}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <FormattedAmount currency={currency} quantity={amount} />
                        </Table.Data>
                        <Table.Data>
                            <span>{getAccountDescription(formikBag?.props?.summary?.creditAccount)}</span>
                        </Table.Data>
                        <Table.Data align="right">{creditReference}</Table.Data>
                    </Table.Row>
                );
            }
            const tableLabel = "table.transferInternal";
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="table.transferInternal.idTransaction"
                        data={<span>{idTransaction.substring(0, 8).toUpperCase()}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.debitAccount`}
                        data={<span>{getAccountDescription(formikBag?.props?.summary?.debitAccount)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />

                    <Heading.DataGroup
                        label={`${tableLabel}.amount`}
                        data={<FormattedAmount currency={currency} quantity={amount} />}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.creditAccount`}
                        data={<span>{getAccountDescription(formikBag?.props?.summary?.creditAccount)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.reference`}
                        data={<span>{creditReference}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="table.transferInternal.idTransaction" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.transferInternal.debitAccount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="table.transferInternal.amount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.transferInternal.creditAccount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="table.transferInternal.reference" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(TransferInternalDataTable);

/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import { func, shape, bool, string } from "prop-types";

export default function CedipFilterSelect({
    columnFilter,
    changeFilter,
    isDesktop,
    options,
    radioOptions,
    className,
    cleanFilter,
    setCleanFilter,
    optionType,
    handleChangeOrder,
    handleChangeFilterOrder,
}) {
    const [ascendent, setAscendent] = useState(true);
    const [descendent, setDescendent] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(
        columnFilter.filterSelected === "fecha_emision"
            ? { value: "fecha_emision", label: i18n.get("deposits.cedip.searchBy.creationDate") }
            : { value: "fecha_vencimiento", label: i18n.get("deposits.cedip.searchBy.dueDate") },
    );
    const [selectFilter, setSelectedValue] = useState(columnFilter.directionFilter === "ascendent" ? "0" : "1");

    CedipFilterSelect.propTypes = {
        columnFilter: shape({}),
        options: shape({}).isRequired,
        radioOptions: shape({}).isRequired,
        changeFilter: func.isRequired,
        isDesktop: bool.isRequired,
        className: string,
        cleanFilter: bool.isRequired,
        setCleanFilter: func.isRequired,
        optionType: bool.isRequired,
        handleChangeOrder: func.isRequired,
        handleChangeFilterOrder: func.isRequired,
    };

    CedipFilterSelect.defaultProps = {
        columnFilter: { filterSelected: "" },
        className: "",
    };

    const hasMounted = useRef(false);

    const changeOrder = (value) => {
        setSelectedValue(value);
        if (value === "0") {
            handleChangeFilterOrder("ascendent");
            // eslint-disable-next-line no-param-reassign
            columnFilter.directionFilter = "ascendent";
        } else {
            handleChangeFilterOrder("descendent");
            // eslint-disable-next-line no-param-reassign
            columnFilter.directionFilter = "descendent";
        }
    };
    // eslint-disable-next-line no-shadow
    const handleChange = (selectedFilter) => {
        handleChangeOrder(selectedFilter.value);
        // eslint-disable-next-line no-param-reassign
        columnFilter.filterSelected = selectedFilter.value;
        setSelectedFilter(selectedFilter);
    };
    useEffect(() => {
        const newColumnFilter = { ...columnFilter };

        if (columnFilter && columnFilter.filterSelected === selectedFilter) {
            if (newColumnFilter.directionFilter === "ascendent") {
                setDescendent(false);
            } else {
                setAscendent(false);
            }
        } else {
            setAscendent(true);
            setDescendent(false);
        }

        if (cleanFilter) {
            setSelectedFilter(options[0]);
            setSelectedValue("1");
            setAscendent(false);
            setDescendent(true);

            setCleanFilter(false);
        }

        if (!hasMounted.current && !optionType) {
            setSelectedFilter(options[0]);
            hasMounted.current = true;
        } else if (options.length > 0 && !options.some((opt) => opt.value === selectedFilter?.value)) {
            setSelectedFilter(options[0]);
        }
    }, [
        columnFilter,
        cleanFilter,
        setCleanFilter,
        options,
        changeFilter,
        optionType,
        handleChangeOrder,
        handleChangeFilterOrder,
    ]);

    return (
        <Col sm={12} md={12} lg={12} className="px-0 mr-4">
            <Col sm={12} lg={12} md={12} className="pl-0">
                <div className="d-flex justify-content-start">
                    <div>
                        <div className="form-group-text">
                            <label htmlFor="activitySelect" className="control-label">
                                <I18n id="echeqs.filters.orderby.label" />
                            </label>
                        </div>
                        <div className={`echeqs__orderby ${className}`}>
                            <div className="input-group mr-3">
                                <Select
                                    inputId="activitySelect"
                                    name="activity"
                                    className="slideFromBottom"
                                    clearable={false}
                                    searchable={isDesktop}
                                    onChange={handleChange}
                                    options={options}
                                    optionClassName="needsclick"
                                    placeholder={i18n.get("echeqs.filters.placeholder")}
                                    value={selectedFilter}
                                    defaultValue={selectedFilter}
                                    noResultsText={i18n.get("global.no.results")}
                                    handleChangeOrder={handleChangeOrder}
                                />
                            </div>
                            <RadioButtonGroup
                                inLineControl
                                name="radioGroup"
                                selectorId="radioGroup"
                                onChange={changeOrder}
                                optionClassName="needsclick"
                                options={radioOptions}
                                value={selectFilter}
                                valueKey="id"
                                labelKey="label"
                                handleChangeFilterOrder={handleChangeFilterOrder}
                            />
                        </div>
                    </div>
                </div>
            </Col>
        </Col>
    );
}

import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";
import { shape, objectOf, string } from "prop-types";
import Heading from "pages/_components/Heading";

class UpdateUserTicketData extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
    };

    render() {
        const { transactionData } = this.props;

        return (
            <Col sm={12} className="update-user-ticket-data">
                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="administration.groups.edit.name"
                    data={`${transactionData.data?.userFullName}`}
                    spaceBetween
                />
                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="forms.document.number.label"
                    data={`${transactionData.data?.userDocument}`}
                    spaceBetween
                />
            </Col>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(UpdateUserTicketData);

import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import { push } from "react-router-redux";
import moment from "moment";

import { actions as formActions } from "reducers/form";
import { selectors, actions } from "reducers/administration/common/administrationTicket";

import { maskedAccountNumber } from "util/accounts";
import * as i18nUtils from "util/i18n";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

import TransactionTicket from "pages/_components/TransactionTicket";
import PayPaymentAFIPTransactionData from "pages/servicePayments/_components/PayPaymentAFIPTransactionData";

class PayPaymentAFIPTicket extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
    };

    state = {
        fetchingDownload: false,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const { idTransaction } = match.params;

        dispatch(actions.loadAdministrationTicketRequest(idTransaction));
    }

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "-0.5rem 2rem" }}>{i18nUtils.get("pay.paymentsAFIP.confirmation.label")}</h1>
        </div>
    );

    getAccountDescription = (account) => {
        const { number: accountNumber, productType, currency } = account;

        return `${maskedAccountNumber(accountNumber)} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    };

    preparedBy = () => {
        const { transactionData } = this.props;

        const preparedBy = `${transactionData?.userCreatorLastName},
         ${transactionData?.userCreatorFirstName}-
          ${transactionData?.creationDateTimeAsString}`;
        return preparedBy;
    };

    signBy = () => {
        const { transactionData } = this.props;
        let variable1 = "";
        variable1 = transactionData?.signatures
            .map(
                (object) =>
                    ` ${object?.userFullName} -${moment(object?.creationDateTime).format("DD/MM/yyyy hh:mm:ss")}`,
            )
            .join("\n");

        return variable1;
    };

    generateTicketBody = () => {
        const {
            transactionData,
            transactionData: { data },
        } = this.props;
        const summary = {
            ...data,
            idTransaction: transactionData?.idTransaction,
            creationDate: transactionData?.creationDateTime,
        };
        const { debitAccount, paymentAFIP, idTransaction, creationDate } = summary;

        const {
            fiscalPeriod,
            dueDate,
            amount,
            conceptDesc,
            subConceptDesc,
            description,
            formNumber,
            advancePayment,
            taxpayerCUIT,
            contributorsCUIT,
        } = paymentAFIP;

        const responseArray = [
            getGeneralTicketItem("pay.paymentAFIP.openDate", creationDate, "datefulltime"),
            getGeneralTicketItem(
                "pay.paymentAFIP.idTransaction",
                idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem(
                "pay.paymentAFIP.numberVEP",
                paymentAFIP?.numberVEP && Number(paymentAFIP?.numberVEP),
                "string",
            ),
            getGeneralTicketItem("pay.paymentAFIP.fiscalPeriod", fiscalPeriod, "string"),
            getGeneralTicketItem("pay.paymentAFIP.dueDate", moment(dueDate, "YYYY-MM-DD"), "date"),
            getGeneralTicketItem("pay.paymentAFIP.amount", amount?.quantity, "amountARS"),
            getGeneralTicketItem(
                "pay.paymentAFIP.debitAccount",
                debitAccount?.number !== undefined && this.getAccountDescription(debitAccount),
                "string",
            ),
            getGeneralTicketItem("pay.paymentAFIP.taxpayerCUIT", contributorsCUIT || taxpayerCUIT, "string"),
            getGeneralTicketItem("pay.paymentAFIP.concept", conceptDesc, "string"),
            getGeneralTicketItem("pay.paymentAFIP.subConcept", subConceptDesc, "string"),
            getGeneralTicketItem("pay.paymentAFIP.description", description, "string"),
            getGeneralTicketItem("pay.paymentAFIP.formNumber", formNumber, "string"),
            getGeneralTicketItem("pay.paymentAFIP.advancePayment", advancePayment, "string"),
            getGeneralTicketItem("pay.paymentAFIP.signatures.label", "", "string"),
            getGeneralTicketItem("pay.paymentAFIP.signatures.preparedBy.label", this.preparedBy(), "string"),
            getGeneralTicketItem("pay.paymentAFIP.signatures.signBy.label", this.signBy(), "string"),
        ];

        if (!debitAccount) {
            responseArray.splice(6, 1);
        }

        return responseArray;
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const ticketData = {
            ticketName: i18nUtils.get("pay.paymentsAFIP.confirmation.label"),
            ticketBody: [...this.generateTicketBody()],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    onSign = () => {
        const {
            dispatch,
            transactionData: { data },
            transactionData,
        } = this.props;

        const summary = { paymentAFIP: data?.paymentAFIP, idTransaction: transactionData.idTransaction };

        const signTransactionData = <PayPaymentAFIPTransactionData summary={summary} />;

        dispatch(push("/transactions/sign"));

        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity: transactionData?.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "pay.paymentsAFIP.confirmation.label",
                pendingDispatch: summary?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData?.idActivity,
                idTransaction: transactionData?.idTransaction,
                idForm: "",
                ticketData: transactionData?.data,
            }),
        );
    };

    buttonsDetail = () => {
        const { dispatch, transactionData } = this.props;

        const buttons = [
            {
                label: "services.newPayment.confirmationTicket.goToDesktop",
                bsStyle: "outline",
                onClick: () => dispatch(push(`/desktop`)),
            },
            {
                label: "services.newPayment.confirmationTicket.goToPayments",
                bsStyle: "primary",
                onClick: () => dispatch(push(`/servicePayments`)),
            },
        ];

        if (transactionData?.idTransactionStatus !== "PENDING") {
            return buttons.slice(1);
        }

        return buttons;
    };

    render() {
        const {
            transactionData,
            transactionData: { data },
            isDesktop,
        } = this.props;

        const { fetchingDownload } = this.state;
        const summary = { ...data, idTransaction: transactionData.idTransaction, transactionData };

        return (
            <>
                {data && (
                    <TransactionTicket
                        notificationScope="paymentAFIPConfirmation"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        headerClassName={
                            !isDesktop ? "blue-main-header-mobile blue-main-title-mobile new-payment-title-mobile" : ""
                        }
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        uniqueDownload={transactionData?.idTransactionStatus === "FINISHED"}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={transactionData}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        summaryTitle="pay.paymentsAFIP.confirmation.label"
                        downloadImageWhite={!isDesktop}
                        isFetchingExport={fetchingDownload}
                        onSignTicket={this.onSign}
                        ignoreHomeButton
                        isPaymentAFIPTicket>
                        <PayPaymentAFIPTransactionData summary={summary} isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(PayPaymentAFIPTicket);

/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import { string, func, shape, arrayOf, bool, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import classNames from "classnames";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import CreateFrequentDesitinationModal from "pages/forms/_components/CreateFrequentDesetinationModal";
import GeneralMsg from "pages/_components/GeneralMsg";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import SelectedReceivers from "pages/charges/_components/SelectedReceivers";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import NewReceiver from "pages/charges/_components/NewReceiver";
import Image from "pages/_components/Image";
import Notification from "pages/_components/Notification";
import FieldError from "pages/_components/fields/FieldError";
import Limits from "pages/transfer/_components/Limits";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import { saveLocation } from "util/transaction";
import * as i18n from "util/i18n";
import PageLoading from "pages/_components/PageLoading";

const FORM_ID = "requestCredin";

class RequestCredin extends Component {
    static propTypes = {
        setValues: func.isRequired,
        values: shape({ account: string.isRequired }).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        errors: shape({}).isRequired,
        cbuList: arrayOf(
            shape({
                email: string.isRequired,
                cbu: string.isRequired,
                document: string.isRequired,
                headline: string.isRequired,
                reference: string.isRequired,
                idFrequentDestination: bool.isRequired,
                key: number.isRequired,
                isOwn: string.isRequired,
                comprobante: number.isRequired,
                schedule: bool.isRequired,
            }),
        ).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        fetching: bool.isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        concepts: arrayOf(shape({})).isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
        credinData: shape({}).isRequired,
        recipients: arrayOf(shape({})).isRequired,
        isBackFromSummary: bool.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                frequentDestination: bool,
            }),
        }).isRequired,
        seller: shape({
            idTransaction: string,
        }).isRequired,
    };

    state = {
        frequentVisible: false,
        currency: 1,
        chargeCbuList: false,
        buttonDisabled: false,
    };

    componentDidMount() {
        const { dispatch, accounts, loggedUser, isBackFromSummary } = this.props;
        saveLocation(this.props);

        dispatch(debinActions.clearRequestData());
        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
        if (!isBackFromSummary) {
            dispatch(debinActions.setCbuSelected([]));
        }

        dispatch(debinActions.debinPreActivity());
    }

    closeModal = () => {
        this.setState({ frequentVisible: false });
    };

    emptyReceivers = (errors) => {
        const { isDesktop } = this.props;
        return (
            <>
                <GeneralMsg
                    idEnviroment
                    imagePath="images/loupeInFile.svg"
                    imageStyle={{ width: "96px", height: "96px" }}
                    description={i18n.get("requestCredin.noRecords")}
                    isDesktop={isDesktop}
                />
                {errors.emptyReceivers && (
                    <div className="form-group has-error">
                        <FieldError error={i18n.get("charges.debin.requestDebin.emptyReceivers.label")} />
                    </div>
                )}
            </>
        );
    };

    selectCBU = (selected) => {
        const { dispatch, cbuList } = this.props;
        const { account, reference, recipientDocumentType, recipientDocumentNumber, name } = selected;

        const recipient = cbuList?.find((e) => e.account === account);

        if (recipient) {
            this.closeModal();
            return;
        }

        const mapInfo = recipientDocumentType
            ? { document: `${recipientDocumentType} ${recipientDocumentNumber}`, reference: name }
            : {};
        const newRecipient = { ...selected, ...mapInfo, headline: reference || name, cbu: account };
        let list = [{ ...newRecipient, schedule: false }];
        if (cbuList !== null) {
            list = [...cbuList, ...list];
        }
        this.closeModal();
        dispatch(debinActions.setCbuSelected(list));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("requestCredin.title")}</h1>
        </div>
    );

    renderLimits = () => {
        const { limit, limitUsed } = this.props;
        const { currency } = this.state;
        return <Limits limit={limit} limitUsed={limitUsed} currency={currency} />;
    };

    onChangeAccount = (account) => {
        const { accounts, values, dispatch } = this.props;
        const { debitAccount } = values;
        const selectedAccount = accounts.find((object) => object.idProduct === account);
        if (selectedAccount) {
            this.setState({ currency: parseInt(selectedAccount.currency, 10) });
            if (debitAccount !== account) {
                dispatch(transferActions.accountLimitsActivities(selectedAccount.idProduct, "credin.sentRequest.send"));
            }
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    saveDraft = () => {
        const { dispatch, values, credinData, cbuList, accounts, recipients, seller } = this.props;
        const account = accounts.find((a) => a.idProduct === values.account);
        let summary = null;

        this.setState({ buttonDisabled: true });

        if (!emptyFieldReceiver(cbuList, dispatch, recipients)) {
            summary = {
                debinSeller: {
                    ...values,
                    cbu: account?.cbu,
                    idAccount: account?.idProduct,
                    idTransaction: seller?.idTransaction,
                    debitAccount: account,
                },
                cbuList,
            };
            dispatch(
                formActions.saveDraft({
                    idForm: "credinSentRequest",
                    data: {
                        ...values,
                        cbuList,
                    },
                    idTransaction: credinData?.idTransaction || null,
                    summary,
                    idActivityDraft: "credin.sentRequest.send",
                }),
            );
        }
    };

    render() {
        const {
            accounts,
            fetching,
            isDesktop,
            cbuList,
            errors,
            values,
            concepts,
            recipients,
            activeEnvironment,
        } = this.props;
        const { frequentVisible, chargeCbuList, buttonDisabled } = this.state;
        const { account } = values;

        if (!chargeCbuList) {
            cbuList.concat(recipients);
            this.setState({ chargeCbuList: true });
        }

        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="credin" />
                <CreateFrequentDesitinationModal
                    isVisible={frequentVisible}
                    onClick={this.selectCBU}
                    closeModal={this.closeModal}
                    isDesktop={isDesktop}
                />

                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <Head title="requestCredin.title" rightContent={this.rightContent} />}
                </div>
                <MainContainer>
                    <Form autoComplete="off">
                        <div className="above-the-fold">
                            <Container
                                className="flex-grow align-items-center container-white my-2 py-2"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={7} className="align-items-center">
                                    <Col className="col col-12 col-no-pad-mobile py-2">
                                        {!isDesktop && account && this.renderLimits()}
                                        <Field
                                            name="account"
                                            component={Productselector}
                                            onCustomChange={this.onChangeAccount}
                                            idField="account"
                                            data={{
                                                emptyOptionLabel: "",
                                                options: accounts,
                                            }}
                                            disabled={accounts.length === 0}
                                            isRequired
                                            mode="edit"
                                            labelText="transfers.debitaccount.label"
                                            idForm={FORM_ID}
                                            isDesktop={isDesktop}
                                            customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                            formGroupClassName="mb-md-2"
                                        />
                                        {isDesktop && account && (
                                            <div className="credin-limits">{this.renderLimits()}</div>
                                        )}
                                    </Col>
                                </Col>
                            </Container>
                            <Container
                                className="flex-grow align-items-center container-white with-debin-toolbar-margin py-2"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={7}>
                                    <Col sm={12} className="align-items-center d-flex space-between w-100 px-0 px-md-3">
                                        <div className="d-flex align-items-center">
                                            <I18n
                                                id="requestCredin.receiver"
                                                componentProps={{ className: "data-label" }}
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <ButtonDrawer
                                                backButton={!isDesktop}
                                                content={<NewReceiver date={new Date()} isDesktop={isDesktop} />}
                                                width={isDesktop ? "31.25rem" : "100%"}
                                                buttonElement={
                                                    <Button
                                                        className={classNames("btn-outline plus-button", {
                                                            "radius mr-4 small-svg bold-svg": !isDesktop,
                                                        })}
                                                        disabled={values.account === ""}>
                                                        <Image
                                                            src="images/plus.svg"
                                                            styles={isDesktop ? {} : { fontSize: "1.8rem" }}
                                                        />
                                                        {isDesktop && (
                                                            <I18n
                                                                id="requestCredin.new.receiver"
                                                                componentProps={{ className: "line-height-1" }}
                                                            />
                                                        )}
                                                    </Button>
                                                }
                                                headerContent={
                                                    <>
                                                        {!isDesktop && (
                                                            <div className="title-account-header-multiline-background-blue">
                                                                <h1 className="w-100 m-0">
                                                                    {i18n.get(
                                                                        "charges.debin.requestDebin.add.receiver",
                                                                    )}
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            />
                                            {activeEnvironment?.permissions.frequentDestination ? (
                                                <Button
                                                    bsStyle="link"
                                                    image="images/frequent-destination.svg"
                                                    className="no-shadow p-0 m-0 svg30"
                                                    disabled={values.account === ""}
                                                    onClick={() => {
                                                        this.setState({ frequentVisible: true });
                                                    }}
                                                />
                                            ) : (
                                                undefined
                                            )}
                                        </div>
                                    </Col>
                                </Col>
                            </Container>
                            {isDesktop && (
                                <Container
                                    className="flex-grow align-items-center container-white with-debin-toolbar-margin py-2 relative"
                                    gridClassName="form-content cbu-list">
                                    {cbuList?.length !== 0 && <hr />}

                                    <Col sm={12} md={9} lg={6} xl={7} className="col col-12 align-items-center">
                                        <Col className="col-12 col-no-pad-mobile py-2 pr-2">
                                            {cbuList?.length === 0 && this.emptyReceivers(errors)}
                                            {cbuList?.length !== 0 && (
                                                <SelectedReceivers accountSelected={values.account} {...this.props} />
                                            )}
                                        </Col>
                                    </Col>
                                </Container>
                            )}
                            {cbuList?.length !== 0 && !isDesktop && (
                                <SelectedReceivers accountSelected={values.account} {...this.props} />
                            )}

                            <Container
                                className="flex-grow align-items-center container-white my-2 py-2"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={7}>
                                    <Col xs={12} className="align-items-center px-0 px-md-3">
                                        <Field
                                            component={Selector}
                                            searchable
                                            options={concepts.map(({ id, name }) => ({
                                                value: id,
                                                label: `${id} - ${name}`,
                                            }))}
                                            idForm={FORM_ID}
                                            name="concept"
                                            hidePlaceholder
                                            isRequired
                                        />
                                    </Col>
                                    <Col xs={12} className="px-0 px-md-3">
                                        <Field
                                            component={TextField}
                                            renderAs="input"
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            maxLength="100"
                                            name="description"
                                            type="text"
                                            optional={i18n.get("transfers.reference.optional.label")}
                                        />
                                    </Col>
                                </Col>
                            </Container>
                            <Container className="flex-grow align-items-center" gridClassName="form-content mt-3">
                                <Col sm={12} md={9} lg={6} xl={7} className="px-0 px-md-3">
                                    {activeEnvironment?.permissions.createDraft ? (
                                        <Col xs={12} md={6} className="align-items-center">
                                            <Button
                                                bsStyle="outline"
                                                onClick={this.saveDraft}
                                                label="transfers.actions.saveDraft"
                                                disabled={buttonDisabled}
                                            />
                                        </Col>
                                    ) : (
                                        undefined
                                    )}
                                    <Col
                                        xs={12}
                                        md={activeEnvironment?.permissions.createDraft ? 6 : 12}
                                        className="align-items-center">
                                        <Button
                                            loading={fetching}
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.continue"
                                        />
                                    </Col>
                                </Col>
                            </Container>
                        </div>
                    </Form>
                </MainContainer>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    seller: debinSelectors.getDebinSeller(state),
    cbuList: debinSelectors.getListCbu(state),
    fetching: debinSelectors.getFetching(state),
    concepts: debinSelectors.getConcepts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    recipients: debinSelectors.getRecipients(state),
    isBackFromSummary: debinSelectors.getIsBackFromSummary(state),
});

function addId(cbuList, item, index, recipients) {
    const recipient = recipients?.find((e) => e.cbu === item.cbu);

    if (recipient) {
        cbuList.splice(index, 1, { ...item, id: recipient.id });
    }
}

function emptyFieldReceiver(cbuList, dispatch, recipients) {
    let empty = false;
    let messageI18n = "";

    cbuList.forEach((item, index) => {
        if (!item.amount || !item.amount.amount) {
            empty = true;
            messageI18n = "amount";
        } else if (!item.comprobante || !item.comprobante) {
            empty = true;
            messageI18n = "comprobante";
        }

        addId(cbuList, item, index, recipients);
    });

    if (empty) {
        dispatch(
            notificationActions.showNotification(i18n.get(`credin.empty.${messageI18n}.receivers`), "error", [
                "credin",
            ]),
        );
    }

    return empty;
}

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            account: props.isBackFromSummary && props.seller ? props.seller.account : "",
            concept: props.isBackFromSummary && props.seller ? props.seller.concept : "VAR",
            description: props.isBackFromSummary && props.seller ? props.seller.description : "",
            cbuList: props.isBackFromSummary && props.cbuList ? props.cbuList : [],
        }),
        validationSchema: () =>
            Yup.object().shape({
                account: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
                concept: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
            }),
        handleSubmit: ({ ...fields }, formikBag) => {
            const { props, setErrors } = formikBag;
            const { dispatch, cbuList, accounts, seller, recipients } = props;
            const account = accounts.find((a) => a.idProduct === fields.account);
            if (cbuList.length === 0) {
                setErrors({ emptyReceivers: i18n.get(`${FORM_ID}.emptyReceivers.label`) });
                return;
            }
            if (!emptyFieldReceiver(cbuList, dispatch, recipients)) {
                dispatch(
                    debinActions.preGenerateCredin(
                        {
                            ...fields,
                            cbu: account?.cbu,
                            idAccount: account?.idProduct,
                            idTransaction: seller?.idTransaction,
                            debitAccount: account,
                        },
                        cbuList,
                    ),
                );
            }
        },
    }),
)(RequestCredin);

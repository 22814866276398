import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool, string } from "prop-types";
import { Col } from "react-bootstrap";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";
import { idTransactionFormat } from "util/format";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";

class AdminSignaturesDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
        administrationScheme: string.isRequired,
    };

    dataRow = (schemeLabel, typeLabel) => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { signatureLevelsCounts, signatureType, topAmount, maxAmount, capFrequencies },
            } = object.transaction;

            const amount = topAmount?.amount || maxAmount || undefined;
            const period = capFrequencies && capFrequencies.length ? capFrequencies[0] : topAmount?.period || undefined;

            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="left">
                            <span>{submitDateTimeAsString}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{this.signersCount(signatureLevelsCounts)}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <span>
                                {i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`)}
                            </span>
                        </Table.Data>
                        <Table.Data align="right">{this.renderTopAmount(amount, period, signatureType)}</Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="forms.transaction.ticket.date"
                        data={<span>{submitDateTimeAsString}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={schemeLabel}
                        data={<span>{this.signersCount(signatureLevelsCounts)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={typeLabel}
                        data={
                            <span>
                                {i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`)}
                            </span>
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="administration.signatures.create.topAmount.label"
                        data={<span>{this.renderTopAmount(amount, period, signatureType)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    renderTopAmount = (amount, period, signatureType) => {
        const { isDesktop } = this.props;
        if (amount && signatureType === "AMOUNT") {
            return (
                <FormattedAmount
                    currency={configUtils.get("core.masterCurrency")}
                    frequency={i18nUtils.get("administration.signatures.create.advanced.topAmountPerPeriod", null, {
                        period: i18nUtils.get(`administration.signatures.transactions.capFrequency.${period}`),
                    })}
                    quantity={amount}
                    notBold
                    className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                />
            );
        }
        return null;
    };

    signersCount = (signatureLevelsCounts) => {
        const { administrationScheme } = this.props;
        return administrationScheme === "medium"
            ? `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts).reduce(
                  (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                  "",
              );
    };

    render() {
        const { isDesktop, administrationScheme } = this.props;

        const schemeLabel =
            administrationScheme === "advanced"
                ? `administration.signatures.create.${administrationScheme}.confirm.schemeReference`
                : `administration.signatures.create.${administrationScheme}.confirm.signersCount`;
        const typeLabel = `administration.signatures.create.${administrationScheme}.confirm.transactionType`;

        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id={schemeLabel} />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id={typeLabel} />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="administration.signatures.create.topAmount.label" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow(schemeLabel, typeLabel);
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
    administrationScheme: sessionSelectors.getAdministrationScheme(state),
});

export default connect(mapStateToProps)(AdminSignaturesDataTable);

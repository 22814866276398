import moment from "moment";
import * as i18nUtils from "util/i18n";

export const types = {
    DOWNLOAD_LIST_FUNDCORP_REQUEST: "fundcorp/DOWNLOAD_LIST_FUNDCORP_REQUEST",
    DOWNLOAD_LIST_FUNDCORP_FAILURE: "fundcorp/DOWNLOAD_LIST_FUNDCORP_FAILURE",
    DOWNLOAD_LIST_FUNDCORP_SUCCESS: "fundcorp/DOWNLOAD_LIST_FUNDCORP_SUCCESS",
    SAVE_OPTION_SELECTED: "fundcorp/SAVE_OPTION_SELECTED",
    LIST_FUNDCORP_REQUEST: "fundcorp/LIST_FUNDCORP_REQUEST",
    LIST_FUNDCORP_FAILURE: "fundcorp/LIST_FUNDCORP_FAILURE",
    LIST_FUNDCORP_SUCCESS: "fundcorp/LIST_FUNDCORP_SUCCESS",
    LIST_FUNDCORP_PRE_REQUEST: "fundcorp/LIST_FUNDCORP_PRE_REQUEST",
    LIST_FUNDCORP_INF_PRE_REQUEST: "fundcorp/LIST_FUNDCORP_INF_PRE_REQUEST",
    LIST_FUNDCORP_PRE_FAILURE: "fundcorp/LIST_FUNDCORP_PRE_FAILURE",
    LIST_FUNDCORP_PRE_SUCCESS: "fundcorp/LIST_FUNDCORP_PRE_SUCCESS",
    FILTER_CHANGE: "fundcorp/FILTER_CHANGE",
    CREATE_RESCUE_REQUEST_PRE: "fundcorp/CREATE_RESCUE_REQUEST_PRE",
    CREATE_RESCUE_REQUEST_PRE_SUCCESS: "fundcorp/CREATE_RESCUE_REQUEST_PRE_SUCCESS",
    CREATE_RESCUE_REQUEST: "fundcorp/CREATE_RESCUE_REQUEST",
    CREATE_RESCUE_SUCCESS: "fundcorp/CREATE_RESCUE_SUCCESS",
    CREATE_RESCUE_FAILURE: "fundcorp/CREATE_RESCUE_FAILURE",
    SEND_RESCUE_REQUEST: "fundcorp/SEND_RESCUE_REQUEST",
    SEND_RESCUE_SUCCESS: "fundcorp/SEND_RESCUE_SUCCESS",
    SEND_RESCUE_FAILURE: "fundcorp/SEND_RESCUE_FAILURE",
    LOAD_CONFIRM_RESCUE: "fundcorp/LOAD_CONFIRM_RESCUE",
    CREATE_SUBSCRIBE_REQUEST: "fundcorp/CREATE_SUBSCRIBE_REQUEST",
    CREATE_SUBSCRIBE_SUCCESS: "fundcorp/CREATE_SUBSCRIBE_SUCCESS",
    CREATE_SUBSCRIBE_FAILURE: "fundcorp/CREATE_SUBSCRIBE_FAILURE",
    SEND_SUBSCRIBE_REQUEST: "fundcorp/SEND_SUBSCRIBE_REQUEST",
    SEND_SUBSCRIBE_SUCCESS: "fundcorp/SEND_SUBSCRIBE_SUCCESS",
    SEND_SUBSCRIBE_FAILURE: "fundcorp/SEND_SUBSCRIBE_FAILURE",
    LOAD_CONFIRM_SUBSCRIBE: "fundcorp/LOAD_CONFIRM_SUBSCRIBE",
    CREATE_SUBSCRIBE_PRE_REQUEST: "fundcorp/CREATE_SUBSCRIBE_PRE_REQUEST",
    CREATE_SUBSCRIBE_PRE_SUCCESS: "fundcorp/CREATE_SUBSCRIBE_PRE_SUCCESS",
    CREATE_SUBSCRIBE_PRE_FAILURE: "fundcorp/CREATE_SUBSCRIBE_PRE_FAILURE",
    CREATE_SHARE_ACCOUNT_PRE_REQUEST: "fundcorp/CREATE_SHARE_ACCOUNT_PRE_REQUEST",
    CREATE_SHARE_ACCOUNT_PRE_SUCCESS: "fundcorp/CREATE_SHARE_ACCOUNT_PRE_SUCCESS",
    CREATE_SHARE_ACCOUNT_PRE_FAILURE: "fundcorp/CREATE_SHARE_ACCOUNT_PRE_FAILURE",
    CREATE_SHARE_ACCOUNT: "fundcorp/CREATE_SHARE_ACCOUNT",
    CREATE_SHARE_ACCOUNT_SUCCESS: "fundcorp/CREATE_SHARE_ACCOUNT_SUCCESS",
    CREATE_SHARE_ACCOUNT_FAILURE: "fundcorp/CREATE_SHARE_ACCOUNT_FAILURE",
    DOWNLOAD_TERMS_REQUEST: "fundcorp/DOWNLOAD_TERMS_REQUEST",
    DOWNLOAD_TERMS_SUCCESS: "fundcorp/DOWNLOAD_TERMS_SUCCESS",
    DOWNLOAD_TERMS_FAILURE: "fundcorp/DOWNLOAD_TERMS_FAILURE",
    SEND_BANK_ACCOUNT_CPT_REQUEST: "fundcorp/SEND_BANK_ACCOUNT_CPT_REQUEST",
    SEND_BANK_ACCOUNT_CPT_SUCCESS: "fundcorp/SEND_BANK_ACCOUNT_CPT_SUCCESS",
    SEND_BANK_ACCOUNT_CPT_FAILURE: "fundcorp/SEND_BANK_ACCOUNT_CPT_FAILURE",
    INVESTOR_PROFILE_REQUEST: "fundcorp/INVESTOR_PROFILE_REQUEST",
    INVESTOR_PROFILE_SUCCESS: "fundcorp/INVESTOR_PROFILE_SUCCESS",
    INVESTOR_PROFILE_FAILURE: "fundcorp/INVESTOR_PROFILE_FAILURE",
    UPDATE_INVESTOR_PROFILE_REQUEST: "fundcorp/UPDATE_INVESTOR_PROFILE_REQUEST",
    UPDATE_INVESTOR_PROFILE_SUCCESS: "fundcorp/UPDATE_INVESTOR_PROFILE_SUCCESS",
    UPDATE_INVESTOR_PROFILE_FAILURE: "fundcorp/UPDATE_INVESTOR_PROFILE_FAILURE",
    SET_SUBSCRIBE_SUMMARY: "fundcorp/SET_SUBSCRIBE_SUMMARY",
    CANCEL_SCHEDULED_TRANSACTION_REQUEST: "fundcorp/CANCEL_SCHEDULED_TRANSACTION_REQUEST",
    CANCEL_SCHEDULED_TRANSACTION_FAILURE: "fundcorp/CANCEL_SCHEDULED_TRANSACTION_FAILURE",
    CANCEL_SCHEDULED_TRANSACTION_SUCCESS: "fundcorp/CANCEL_SCHEDULED_TRANSACTION_SUCCESS",
    CLEAN_UP: "fundcorp/CLEAN_UP",
    RESET_SUMMARY: "fundcorp/RESET_SUMMARY",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    optionSelected: "positions",
    isDisabledDownload: true,
    isFetchingFundcorp: false,
    isEmptyState: false,
    funds: [],
    shareAccounts: [],
    positions: [],
    positionsByShareAccount: [],
    movements: [],
    requests: [],
    shareValues: [],
    shareValuesExist: false,
    filtersData: null,
    portfolioComposition: {
        portfolioCompositionsList: [],
    },
    portfolioCompositionExist: false,
    statusRegisterShareAccount: null,
    numCuotapartista: null,
    format: "pdf",
    configVar: null,
    filter: {
        filterSelected: "",
        directionFilter: "ascendent",
    },
    summary: {
        amount: null,
        cuotapartes: 0,
        rescueType: "",
        maxAmount: null,
        maxCuotapartes: "",
        selectedShareAccount: null,
        selectedFund: null,
        equivalentAmount: null,
        equivalentCuotapartes: "",
        creditAccountLabel: "",
        rescueDate: null,
    },
    summaryShareaccount: {
        accountArs: null,
        accountUsd: null,
        labelArs: null,
        labelUsd: null,
    },
    investorProfile: {
        documentType: "",
        documentNumber: "",
        investorProfileType: "",
        lastUpdate: "",
        valid: false,
    },
    fetchingBankAccount: false,
    successAssociate: false,
    managementReport: [],
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        optionSelected,
        funds,
        shareAccounts,
        positions,
        positionsByShareAccount,
        movements,
        requests,
        positionsType,
        positionDate,
        movementsShareAccount,
        movementsShareAccountDescription,
        movementsFund,
        movementsFundDescription,
        movementsDateFrom,
        movementsDateTo,
        requestsShareAccount,
        requestsShareAccountDescription,
        requestsDateFrom,
        requestsDateTo,
        shareValues,
        shareValuesExist,
        shareValuesFund,
        shareValuesFundDescription,
        shareValuesDate,
        portfolioCompositionFund,
        portfolioCompositionFundDescription,
        portfolioCompositionDate,
        portfolioComposition,
        portfolioCompositionExist,
        filter,
        summary,
        fundFeatures,
        summaryShareaccount,
        statusRegisterShareAccount,
        numCuotapartista,
        format,
        configVar,
        investorProfile,
        managementReport,
    } = action;

    switch (action.type) {
        case types.SAVE_OPTION_SELECTED:
            return {
                ...state,
                optionSelected,
                portfolioCompositionExist,
                shareValuesExist,
                isDisabledDownload:
                    (optionSelected === "shareValues" && !shareValuesExist > 0) ||
                    (optionSelected === "portfolioComposition" && !portfolioCompositionExist > 0),
                isEmptyState:
                    (state.optionSelected === "shareValues" && shareValues?.length === 0) ||
                    (state.optionSelected === "portfolioComposition" &&
                        portfolioComposition?.portfolioCompositionsList.length === 0),
            };
        case types.LIST_FUNDCORP_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingDownload: false,
            };
        case types.LIST_FUNDCORP_INF_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingDownload: false,
            };
        case types.LIST_FUNDCORP_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_FUNDCORP_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                funds,
                shareAccounts,
                positions,
                positionsByShareAccount,
                optionSelected,
                filtersData: {
                    positionsType: "byShare",
                    positionDate: moment(new Date()),

                    movementsShareAccount: shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].id : null,
                    movementsShareAccountDescription:
                        shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].description : null,
                    movementsFund: "all",
                    movementsFundDescription: i18nUtils.get("fundcorp.list.fund.options.all"),
                    movementsDateFrom: moment().subtract(1, "weeks"),
                    movementsDateTo: moment(new Date()),

                    requestsShareAccount: shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].id : null,
                    requestsShareAccountDescription:
                        shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].description : null,
                    requestsDateFrom: moment().subtract(1, "weeks"),
                    requestsDateTo: moment(new Date()),

                    shareValuesFund: "",
                    shareValuesFundDescription: i18nUtils.get("fundcorp.list.fund.options.all"),
                    shareValuesDate: moment(new Date()),

                    portfolioCompositionFund: "",
                    portfolioCompositionFundDescription: i18nUtils.get("fundcorp.list.fund.options.all"),
                    portfolioCompositionDate: moment(new Date()),
                },
                fundFeatures,
            };
        case types.LIST_FUNDCORP_REQUEST:
            return {
                ...state,
                isDisabledDownload: true,
                isFetchingFundcorp: true,
            };
        case types.LIST_FUNDCORP_FAILURE:
            return {
                ...state,
                isDisabledDownload: true,
                isFetchingFundcorp: false,
            };
        case types.LIST_FUNDCORP_SUCCESS:
            return {
                ...state,
                isEmptyState:
                    (state.optionSelected === "shareValues" && !shareValues.length > 0) ||
                    (state.optionSelected === "portfolioComposition" &&
                        !portfolioComposition?.portfolioCompositionsList?.length > 0),
                isDisabledDownload:
                    (state.optionSelected === "shareValues" && !shareValues.length > 0) ||
                    (state.optionSelected === "portfolioComposition" &&
                        !portfolioComposition?.portfolioCompositionsList?.length > 0),
                isFetchingFundcorp: false,
                positions: state.optionSelected === "positions" ? positions : state.positions,
                positionsByShareAccount:
                    state.optionSelected === "positions" ? positionsByShareAccount : state.positionsByShareAccount,
                movements:
                    state.optionSelected === "movements" || state.optionSelected === "movementsAndRequests"
                        ? movements
                        : state.movements,
                requests: state.optionSelected === "requests" ? requests : state.requests,
                shareValues: state.optionSelected === "shareValues" ? shareValues : state.shareValues,
                portfolioComposition:
                    state.optionSelected === "portfolioComposition" ? portfolioComposition : state.portfolioComposition,
                managementReport:
                    state.optionSelected === "portfolioComposition" ? managementReport : state.managementReport,
                filtersData: {
                    positionsType,
                    positionDate,
                    movementsShareAccount,
                    movementsShareAccountDescription,
                    movementsFund,
                    movementsFundDescription,
                    movementsDateFrom,
                    movementsDateTo,
                    requestsShareAccount,
                    requestsShareAccountDescription,
                    requestsDateFrom,
                    requestsDateTo,
                    shareValuesFund,
                    shareValuesFundDescription,
                    shareValuesDate,
                    portfolioCompositionFund,
                    portfolioCompositionFundDescription,
                    portfolioCompositionDate,
                },
                fundFeatures: state.optionSelected === "positions" ? fundFeatures : state.fundFeatures,
            };
        case types.DOWNLOAD_LIST_FUNDCORP_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LIST_FUNDCORP_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_LIST_FUNDCORP_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.FILTER_CHANGE:
            return {
                ...state,
                filter: {
                    filterSelected: filter.filterSelected,
                    directionFilter: filter.directionFilter,
                },
                movements: movements.sort((movement1, movement2) => {
                    const ascendentValue = filter.directionFilter === "ascendent" ? 1 : -1;
                    if (filter.filterSelected === "fechaConcertacion" || filter.filterSelected === "fechaLiquidacion") {
                        return (
                            ascendentValue *
                            (moment(movement1[filter.filterSelected]).valueOf() -
                                moment(movement2[filter.filterSelected]).valueOf())
                        );
                    }
                    if (
                        filter.filterSelected === "sharesQuantity" ||
                        filter.filterSelected === "valorCuotaparte" ||
                        filter.filterSelected === "netAmount" ||
                        filter.filterSelected === "numero"
                    ) {
                        if (filter.directionFilter === "ascendent") {
                            return movement1[filter.filterSelected] - movement2[filter.filterSelected];
                        }
                        return movement2[filter.filterSelected] - movement1[filter.filterSelected];
                    }
                    return (
                        ascendentValue *
                        movement1[filter.filterSelected].localeCompare(movement2[filter.filterSelected])
                    );
                }),
            };
        case types.CLEAN_UP:
            return INITIAL_STATE;
        case types.RESET_SUMMARY:
            return {
                ...state,
                summary: INITIAL_STATE.summary,
            };
        case types.CREATE_RESCUE_REQUEST_PRE:
            return {
                ...state,
                fetching: true,
            };
        case types.CREATE_RESCUE_REQUEST_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.CREATE_RESCUE_REQUEST:
            return {
                ...state,
                fetching: true,
                summary,
            };
        case types.CREATE_RESCUE_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary,
            };
        case types.CREATE_RESCUE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.SEND_RESCUE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.SEND_RESCUE_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_CONFIRM_RESCUE:
            return {
                ...state,
                summary,
                fetching: false,
            };
        case types.CREATE_SUBSCRIBE_REQUEST:
            return {
                ...state,
                fetching: true,
                summary,
            };
        case types.CREATE_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary,
            };
        case types.CREATE_SUBSCRIBE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.SEND_SUBSCRIBE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.SEND_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_CONFIRM_SUBSCRIBE:
            return {
                ...state,
                summary,
                fetching: false,
            };
        case types.CREATE_SUBSCRIBE_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.CREATE_SUBSCRIBE_PRE_SUCCESS:
        case types.CREATE_SUBSCRIBE_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.CREATE_SHARE_ACCOUNT_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.CREATE_SHARE_ACCOUNT_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                shareAccounts,
                investorProfile,
            };
        case types.CREATE_SHARE_ACCOUNT_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.CREATE_SHARE_ACCOUNT:
            return {
                ...state,
                fetching: true,
                summaryShareaccount,
            };
        case types.CREATE_SHARE_ACCOUNT_SUCCESS:
            return {
                ...state,
                fetching: false,
                shareAccounts,
                summaryShareaccount,
                statusRegisterShareAccount,
                numCuotapartista,
            };
        case types.CREATE_SHARE_ACCOUNT_FAILURE:
            return {
                ...state,
                fetching: false,
                summaryShareaccount,
                statusRegisterShareAccount,
                numCuotapartista,
            };
        case types.DOWNLOAD_TERMS_REQUEST:
            return {
                ...state,
                format,
                configVar,
            };
        case types.DOWNLOAD_TERMS_SUCCESS:
            return {
                ...state,
            };
        case types.DOWNLOAD_TERMS_FAILURE:
            return {
                ...state,
            };
        case types.SEND_BANK_ACCOUNT_CPT_REQUEST:
            return {
                ...state,
                fetchingBankAccount: true,
            };
        case types.SEND_BANK_ACCOUNT_CPT_SUCCESS:
            return {
                ...state,
                fetchingBankAccount: false,
                successAssociate: true,
                shareAccounts,
            };
        case types.SEND_BANK_ACCOUNT_CPT_FAILURE:
            return {
                ...state,
                fetchingBankAccount: false,
                successAssociate: false,
            };
        case types.INVESTOR_PROFILE_SUCCESS:
            return {
                ...state,
                fetching: false,
                investorProfile,
            };
        case types.INVESTOR_PROFILE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.INVESTOR_PROFILE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.UPDATE_INVESTOR_PROFILE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.UPDATE_INVESTOR_PROFILE_SUCCESS:
            return {
                ...state,
                fetching: false,
                investorProfile,
            };
        case types.UPDATE_INVESTOR_PROFILE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_SUBSCRIBE_SUMMARY:
            return {
                ...state,
                summary,
            };
        case types.CANCEL_SCHEDULED_TRANSACTION_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.CANCEL_SCHEDULED_TRANSACTION_FAILURE:
        case types.CANCEL_SCHEDULED_TRANSACTION_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
};

export const actions = {
    listFundcorpPre: (page, defaultOption) => ({
        type: types.LIST_FUNDCORP_PRE_REQUEST,
        page,
        defaultOption,
    }),
    listFundcorpInfPre: (page, defaultOption) => ({
        type: types.LIST_FUNDCORP_INF_PRE_REQUEST,
        page,
        defaultOption,
    }),
    listFundcorp: (
        optionSelected,
        positionsType,
        positionDate,
        movementsShareAccount,
        movementsShareAccountDescription,
        movementsFund,
        movementsFundDescription,
        movementsDateFrom,
        movementsDateTo,
        requestsShareAccount,
        requestsShareAccountDescription,
        requestsDateFrom,
        requestsDateTo,
        verifyCode,
    ) => ({
        type: types.LIST_FUNDCORP_REQUEST,
        optionSelected,
        positionsType,
        positionDate,
        movementsShareAccount,
        movementsShareAccountDescription,
        movementsFund,
        movementsFundDescription,
        movementsDateFrom,
        movementsDateTo,
        requestsShareAccount,
        requestsShareAccountDescription,
        requestsDateFrom,
        requestsDateTo,
        verifyCode,
    }),
    listFundcorpInformation: (
        optionSelected,
        shareValuesFund,
        shareValuesFundDescription,
        shareValuesDate,
        portfolioCompositionFund,
        portfolioCompositionFundDescription,
        portfolioCompositionDate,
    ) => ({
        type: types.LIST_FUNDCORP_REQUEST,
        optionSelected,
        shareValuesFund,
        shareValuesFundDescription,
        shareValuesDate,
        portfolioCompositionFund,
        portfolioCompositionFundDescription,
        portfolioCompositionDate,
    }),
    downloadFundcorpList: (summary, format) => ({
        type: types.DOWNLOAD_LIST_FUNDCORP_REQUEST,
        summary,
        format,
    }),
    saveOptionSelected: (optionSelected, portfolioCompositionExist, shareValuesExist) => ({
        type: types.SAVE_OPTION_SELECTED,
        optionSelected,
        portfolioCompositionExist,
        shareValuesExist,
    }),
    filterChange: (movements, filter) => ({
        type: types.FILTER_CHANGE,
        movements,
        filter,
    }),
    createRescueRequestPre: () => ({
        type: types.CREATE_RESCUE_REQUEST_PRE,
    }),
    createRescueRequest: (summary, formikBag) => ({
        type: types.CREATE_RESCUE_REQUEST,
        summary,
        formikBag,
    }),
    sendRescueRequest: (summary, formikBag) => ({
        type: types.SEND_RESCUE_REQUEST,
        summary,
        formikBag,
    }),
    loadConfirmRescue: (summary) => ({
        type: types.LOAD_CONFIRM_RESCUE,
        summary,
    }),
    createSubscribeRequest: (summary, formikBag) => ({
        type: types.CREATE_SUBSCRIBE_REQUEST,
        summary,
        formikBag,
    }),
    sendSubscribeRequest: (summary, formikBag) => ({
        type: types.SEND_SUBSCRIBE_REQUEST,
        summary,
        formikBag,
    }),
    loadConfirmSubscribe: (summary) => ({
        type: types.LOAD_CONFIRM_SUBSCRIBE,
        summary,
    }),
    createSubscribePreRequest: () => ({
        type: types.CREATE_SUBSCRIBE_PRE_REQUEST,
    }),
    createShareAccountPreRequest: () => ({
        type: types.CREATE_SHARE_ACCOUNT_PRE_REQUEST,
    }),
    createShareAccount: (summaryShareaccount, formikBag) => ({
        type: types.CREATE_SHARE_ACCOUNT,
        summaryShareaccount,
        formikBag,
    }),
    downloadDocumentRequest: (format, configVar) => ({
        type: types.DOWNLOAD_TERMS_REQUEST,
        format,
        configVar,
    }),
    sendBankAccountCpt: (debitAccount, shareaccount, formikBag) => ({
        type: types.SEND_BANK_ACCOUNT_CPT_REQUEST,
        debitAccount,
        shareaccount,
        formikBag,
    }),
    getInvestorProfile: (redirectionUrl) => ({
        type: types.INVESTOR_PROFILE_REQUEST,
        redirectionUrl,
    }),
    updateInvestorProfileRequest: (investorProfile) => ({
        type: types.UPDATE_INVESTOR_PROFILE_REQUEST,
        investorProfile,
    }),
    setSubscribeSummaryData: (summary) => ({
        type: types.SET_SUBSCRIBE_SUMMARY,
        summary,
    }),
    cancelScheduled: (summary) => ({
        type: types.CANCEL_SCHEDULED_TRANSACTION_REQUEST,
        summary,
    }),
    cleanUp: () => ({
        type: types.CLEAN_UP,
    }),
};

export const selectors = {
    getIsEmptyState: ({ fundcorp }) => fundcorp.isEmptyState,
    getDisabledDownload: ({ fundcorp }) => fundcorp.isDisabledDownload,
    getFetching: ({ fundcorp }) => fundcorp.fetching,
    getFetchingDownload: ({ fundcorp }) => fundcorp.fetchingDownload,
    getOptionSelected: ({ fundcorp }) => fundcorp.optionSelected,
    isFetchingFundcorp: ({ fundcorp }) => fundcorp.isFetchingFundcorp,
    getFunds: ({ fundcorp }) => fundcorp.funds,
    getShareAccounts: ({ fundcorp }) => fundcorp.shareAccounts,
    getPositions: ({ fundcorp }) => fundcorp.positions,
    getPositionsByShareAccount: ({ fundcorp }) => fundcorp.positionsByShareAccount,
    getMovements: ({ fundcorp }) => fundcorp.movements,
    getRequests: ({ fundcorp }) => fundcorp.requests,
    getFiltersData: ({ fundcorp }) => fundcorp.filtersData,
    getShareValues: ({ fundcorp }) => fundcorp.shareValues,
    getPortfolioComposition: ({ fundcorp }) => fundcorp.portfolioComposition,
    getManagementReport: ({ fundcorp }) => fundcorp.managementReport,
    getFilter: ({ fundcorp }) => fundcorp.filter,
    getSummary: ({ fundcorp }) => fundcorp.summary,
    getIdTransaction: ({ fundcorp }) => fundcorp.idTransaction,
    getFundFeatures: ({ fundcorp }) => fundcorp.fundFeatures,
    getSummaryShareAccount: ({ fundcorp }) => fundcorp.summaryShareaccount,
    getStatusRegisterShareAccount: ({ fundcorp }) => fundcorp.statusRegisterShareAccount,
    getNumCuotapartista: ({ fundcorp }) => fundcorp.numCuotapartista,
    getInvestorProfile: ({ fundcorp }) => fundcorp.investorProfile,
    getFetchingBankAccount: ({ fundcorp }) => fundcorp.fetchingBankAccount,
    isSuccessAssociate: ({ fundcorp }) => fundcorp.successAssociate,
};

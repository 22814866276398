/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors, actions } from "reducers/checks";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { shape, func, bool, number, arrayOf, string } from "prop-types";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import ReceivedEcheqs from "pages/checks/Echeqs/ReceivedEcheqs";
import EmittedEcheqs from "pages/checks/Echeqs/EmittedEcheqs";
import Pagination from "pages/_components/pagination/Pagination";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import { echeqShape } from "util/checks";
import BatchList from "./Massive/_components/BatchList";

class EcheqsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        setSubmitting: func.isRequired,
        echeqsList: arrayOf(echeqShape).isRequired,
        filters: shape({
            createdFrom: string.isRequired,
            createdTo: string.isRequired,
            payedFrom: string.isRequired,
            payedTo: string.isRequired,
            payedCheck: bool.isRequired,
            emitedCheck: bool.isRequired,
            receiver: string.isRequired,
            emitted: string.isRequired,
            status: shape({ value: string.isRequired, label: string.isRequired }).isRequired,
            number: string.isRequired,
            grouper: string.isRequired,
            id: string.isRequired,
            orderColumName: string.isRequired,
            orderDirection: string.isRequired,
        }).isRequired,
        echeqPagination: shape({
            extra_info: number.isRequired,
            estado: number.isRequired,
        }).isRequired,
        option: string.isRequired,
        multipleSelection: bool.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        selectedAll: bool.isRequired,
        selectedRows: arrayOf(echeqShape).isRequired,
        addSelectedRow: func.isRequired,
        selectAll: func.isRequired,
        unCheckSelectAll: func.isRequired,
        getSelectedOption: func.isRequired,
        orderByData: func.isRequired,
    };

    fetchMoreTransactions = (pageNumber) => {
        const { filters, dispatch, option, setSubmitting, isDesktop, unCheckSelectAll } = this.props;
        setSubmitting(true);
        unCheckSelectAll();
        const filtersEcheq = {
            ...filters,
            option,
            status: filters.status,
            pageNumber,
        };
        if (isDesktop) {
            dispatch(actions.filterEcheqList(filtersEcheq, setSubmitting, isDesktop));
        } else {
            dispatch(actions.filterEcheqListMobile(filtersEcheq, setSubmitting, isDesktop));
        }
    };

    fetchNextTransactions = () => {
        const { echeqPagination } = this.props;

        this.fetchMoreTransactions(echeqPagination.pageNumber + 1);
    };

    renderList = () => {
        const {
            getSelectedOption,
            multipleSelection,
            selectedRows,
            selectedAll,
            addSelectedRow,
            selectAll,
            filters,
            setSubmitting,
            isDesktop,
            isSmallDesktop,
            option,
            orderByData,
        } = this.props;

        switch (getSelectedOption()) {
            case "received":
                return (
                    <ReceivedEcheqs
                        multipleSelection={multipleSelection}
                        selectedRows={selectedRows}
                        selectedAll={selectedAll}
                        addSelectedRow={addSelectedRow}
                        selectAll={selectAll}
                        filters={filters}
                        setSubmitting={setSubmitting}
                        option={option}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        fetchNextTransactions={this.fetchNextTransactions}
                        orderByData={orderByData}
                    />
                );
            case "emitted":
                return (
                    <EmittedEcheqs
                        filters={filters}
                        setSubmitting={setSubmitting}
                        option={option}
                        isDesktop={isDesktop}
                        fetchNextTransactions={this.fetchNextTransactions}
                        orderByData={orderByData}
                    />
                );
            case "massive":
                return (
                    <BatchList
                        filters={filters}
                        setSubmitting={setSubmitting}
                        option={option}
                        isDesktop={isDesktop}
                        fetchNextTransactions={this.fetchNextTransactions}
                    />
                );
            default:
                return <></>;
        }
    };

    render() {
        const { echeqsList, echeqPagination, selectedRows, isDesktop, getSelectedOption } = this.props;

        return (
            <Col xs={12} className="px-0">
                <div
                    className="echeq__list"
                    ref={(tableRef) => {
                        this.child = tableRef;
                    }}>
                    {echeqsList?.length ? (
                        <>
                            {this.renderList()}
                            <div
                                className={`d-flex w-100 mr-3 ${
                                    isDesktop ? "space-between mb-6" : "justify-content-end"
                                }`}>
                                {isDesktop && (
                                    <div className="echeq__totals mt-0 pl-2">
                                        {selectedRows.length > 0 && (
                                            <SelectedAccountsAmount
                                                list={selectedRows}
                                                summaryView
                                                leftOperations
                                            />
                                        )}
                                    </div>
                                )}
                                {isDesktop && (
                                    <div className="d-flex pr-2">
                                        <Pagination
                                            totalPages={echeqPagination.totalPages}
                                            pageNumber={echeqPagination.pageNumber}
                                            action={this.fetchMoreTransactions}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="text-center no-more-data px-3" key="noMoreMovements">
                            <div className="illustration-wrapper">
                                <Image src="images/coloredIcons/find-in-page.svg" className="svg-big-icon" />
                            </div>
                            <I18n
                                id={`echeqs.${getSelectedOption()}.empty.message`}
                                component="p"
                                componentProps={{ className: "text-lead mt-3" }}
                            />
                        </div>
                    )}
                </div>
            </Col>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqsList: selectors.getEcheqsList(state),
    echeqPagination: selectors.getEcheqsPagination(state),
    filters: selectors.getEcheqFilters(state),
});

export default connect(mapStateToProps)(EcheqsList);

import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import Col from "react-bootstrap/lib/Col";
import isEmpty from "lodash/isEmpty";
import { func, shape, bool, string } from "prop-types";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import classNames from "classnames";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { selectors as loginSelectors } from "reducers/login";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Checkbox from "pages/_components/Checkbox";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import * as deviceUtils from "util/device";
import { downloadUrl } from "util/download";
import * as fingerprintUtils from "util/fingerprint";

const FORM_ID = "settings.biometrics";

class FingerprintConfiguration extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        setValues: func.isRequired,
        fingerprintConfiguredUserDevices: shape({
            device: shape({
                deviceId: string,
                deviceModel: string,
            }),
        }),
        isDesktop: bool.isRequired,
        isDeviceWithFingerprint: bool,
        isMobileNative: bool.isRequired,
        availability: shape({
            isHardwareDetected: bool,
            isAvailable: bool,
        }),
        fetching: bool,
        values: shape({}).isRequired,
    };

    static defaultProps = {
        fingerprintConfiguredUserDevices: null,
        isDeviceWithFingerprint: false,
        availability: null,
        fetching: false,
    };

    componentDidMount() {
        const { dispatch, isMobileNative, availability, setValues } = this.props;
        const hardwareAvailability = isMobileNative && availability && availability.isHardwareDetected;
        let device = "";
        if (isMobileNative) {
            device = deviceUtils.getDeviceId();
            setValues({
                rememberDocument: hardwareAvailability,
                acceptTermsConditions: hardwareAvailability,
            });
        }
        dispatch(fingerprintActions.fingerprintConfigurationPre(device));
        dispatch(fingerprintActions.fingerprintAvailability());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintAvailability());
    }

    getContent() {
        const { isDesktop } = this.props;

        const configTitle = configUtils.get("biometric.AllowsAuthetication.Text");
        const configSubtitle = configUtils.get("biometric.SetupBiometrics.Text1");
        return (
            <form className={classNames({ "container-white": isDesktop })}>
                <Container className="container--layout align-items-center flex-grow">
                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                        <p className="my-3">{`${configTitle} ${configSubtitle}`}</p>
                        <I18n
                            id="settings.fingerprintConfiguration.configuredDevices"
                            component="p"
                            componentProps={{ className: "my-4 text-center bold" }}
                        />
                        {this.renderDevices()}
                        {this.renderText()}
                    </Col>
                </Container>
                <Container className="align-items-center">
                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                        {this.renderConfigureButton()}
                    </Col>
                </Container>
            </form>
        );
    }

    configureNow = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintVerification());
    };

    deleteDevicePre = (e, deviceIndex) => {
        e.preventDefault();
        const { dispatch, fingerprintConfiguredUserDevices } = this.props;
        dispatch(fingerprintActions.fingerprintDeleteUserDevicePre(fingerprintConfiguredUserDevices[deviceIndex]));
        dispatch(push("/settings/fingerprintConfiguration/deleteConfirmation"));
    };

    enrollFingerprintOnDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.enrollFingerprintOnDevice());
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push(`/settings`));
    };

    isDeviceIdInSession = () => {
        const { fingerprintConfiguredUserDevices, isMobileNative } = this.props;
        if (isMobileNative && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    isBiometricAvailable = () => {
        const { isMobileNative, isDeviceWithFingerprint, availability } = this.props;
        return (
            isMobileNative &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !this.isDeviceIdInSession()
        );
    };

    renderDevices() {
        const { fingerprintConfiguredUserDevices } = this.props;

        const configNoDevices = configUtils.get("biometric.SetupBiometrics.Text2");

        if (fingerprintConfiguredUserDevices && fingerprintConfiguredUserDevices.length) {
            return (
                <ul className="navigational-list">
                    {Object.keys(fingerprintConfiguredUserDevices).map((deviceIndex) => {
                        const { deviceId, deviceModel } = fingerprintConfiguredUserDevices[deviceIndex];
                        return (
                            <li className="background-none border-radius box-shadow pl-3 my-3" key={deviceIndex}>
                                <div className="navigational-list-item-container py-2">
                                    <I18n id={`devices.apple.identifier.${deviceModel}`} defaultValue={deviceModel} />
                                    <span>{deviceId?.substring(deviceId.length - 4, deviceId.length)}</span>
                                    <Button
                                        block={false}
                                        className="btn-only-icon btn-circle border-none"
                                        image="images/trash.svg"
                                        onClick={(e) => this.deleteDevicePre(e, deviceIndex)}
                                        label="settings.fingerprintConfiguration.device.remove"
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        }
        return (
            <div className="notification-wrapper">
                <p className="my-3">{configNoDevices}</p>
            </div>
        );
    }

    renderConfigureButton() {
        const { availability, values } = this.props;
        const termsAndConditions = configUtils.get("biometric.TyC.URL");
        const handleDownloadTyC = () => {
            downloadUrl(termsAndConditions || "");
        };

        if (this.isBiometricAvailable()) {
            return (
                <Form>
                    <div>
                        <Field name="rememberDocument" component={Checkbox} idForm={FORM_ID} />
                    </div>
                    <div>
                        <Field
                            name="acceptTermsConditions"
                            component={Checkbox}
                            onClickLabel={handleDownloadTyC}
                            hideLabel
                            idForm={FORM_ID}
                        />
                    </div>
                    <I18n component="p" componentProps={{ className: `hint-text bold mx-0` }} id={`${FORM_ID}.hint`} />
                    {availability.hasEnrolledFingerprints && availability.isAvailable ? (
                        <Button
                            disabled={!(values.acceptTermsConditions && values.rememberDocument)}
                            label="settings.fingerprintConfiguration.device.enrolledFingerprints.enroll"
                            bsStyle="primary"
                            type="submit"
                        />
                    ) : (
                        <Button
                            disabled={!(values.acceptTermsConditions && values.rememberDocument)}
                            label="settings.fingerprintConfiguration.device.register"
                            bsStyle="primary"
                            type="submit"
                        />
                    )}
                </Form>
            );
        }
        return null;
    }

    renderText() {
        const { isMobileNative, availability, isDeviceWithFingerprint } = this.props;
        const notConfig = configUtils.get("biometric.SetupBiometrics.Text3");
        if (isMobileNative) {
            if (!availability?.hasEnrolledFingerprints) {
                return <I18n id="settings.fingerprintConfiguration.device.enrolledFingerprints.none" />;
            }
            return availability?.isAvailable && isDeviceWithFingerprint ? (
                <I18n id="settings.fingerprintConfiguration.warning" />
            ) : (
                <p>{notConfig}</p>
            );
        }
        return null;
    }

    render() {
        const { fetching, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="fingerprintConfiguration" />
                <Head
                    title={!isDesktop && "settings.biometrics"}
                    titleClassName={!isDesktop && "align-center"}
                    headerClassName={classNames({
                        "blue-main-header-mobile": !isDesktop,
                        "admin-detail-head h-auto": isDesktop,
                    })}
                    onBack={this.handleBack}
                />
                {isDesktop && <I18n id="settings.biometrics" component="h1" componentProps={{ className: "mt-0" }} />}
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fingerprintSelectors.getFetching(state),
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
    document: loginSelectors.getRememberedUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({}),
        validationSchema: () =>
            Yup.object().shape({
                rememberDocument: Yup.bool()
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty"))
                    .oneOf([true], i18nUtils.get("enrollment.index.acceptTerms.required")),
                acceptTermsConditions: Yup.bool()
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty"))
                    .oneOf([true], i18nUtils.get("enrollment.index.acceptTerms.required")),
            }),
        handleSubmit: async (data, formikBag) => {
            const { dispatch } = formikBag.props;

            const availability = await fingerprintUtils.isAvailable();

            const isDeviceIdInSession = () => {
                const { fingerprintConfiguredUserDevices, isMobileNative } = formikBag.props;
                if (isMobileNative && fingerprintConfiguredUserDevices) {
                    const device = fingerprintConfiguredUserDevices.filter(
                        (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
                    );
                    return !isEmpty(device);
                }
                return false;
            };
            const isBiometricAvailable = () => {
                const { isMobileNative, isDeviceWithFingerprint } = formikBag.props;
                return (
                    isMobileNative &&
                    !isDeviceWithFingerprint &&
                    availability &&
                    availability.isHardwareDetected &&
                    !isDeviceIdInSession()
                );
            };

            if (isBiometricAvailable()) {
                if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                    formikBag.setValues({
                        rememberDocument: true,
                        acceptTermsConditions: true,
                    });
                    dispatch(fingerprintActions.fingerprintVerification());
                } else {
                    dispatch(fingerprintActions.enrollFingerprintOnDevice());
                }
            }
        },
    }),
)(FingerprintConfiguration);

import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { push } from "react-router-redux";
import { bool, func, arrayOf, number, shape } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as statusActions } from "reducers/status";

import Head from "pages/_components/Head";
import GeneralMsg from "pages/_components/GeneralMsg";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import EquivalentTotalBalance from "pages/accounts/_components/EquivalentTotalBalance";
import AccountsList from "pages/accounts/List";
import * as i18n from "util/i18n";
import Container from "pages/_components/Container";
import classNames from "classnames";

class Accounts extends Component {
    static propTypes = {
        isRequestAvailable: bool,
        accounts: arrayOf({ length: number.isRequired }),
        dispatch: func.isRequired,
        isMobile: bool,
        fetching: bool,
        ARSTotalBalance: number,
        USDTotalBalance: number,
        loggedUser: func.isRequired,
        fetchingDownload: bool,
        isDesktop: bool,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        isRequestAvailable: false,
        isMobile: false,
        fetching: false,
        accounts: [],
        ARSTotalBalance: null,
        USDTotalBalance: null,
        fetchingDownload: false,
        isDesktop: true,
    };

    componentDidMount() {
        const { dispatch, loggedUser, location } = this.props;
        dispatch(accountsActions.listAccounts(loggedUser.userId));
        dispatch(statusActions.saveLastHref(location));
        dispatch(accountsActions.accountsLastHref(location.pathname));
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleExport = (format) => {
        const { dispatch } = this.props;
        dispatch(accountsActions.downloadAccountsList(format));
    };

    renderHeader = () => {
        const {
            accounts,
            activeEnvironment,
            ARSTotalBalance,
            dispatch,
            fetching,
            fetchingDownload,
            isMobile,
            USDTotalBalance,
        } = this.props;

        const showNewAccountButton = activeEnvironment && activeEnvironment?.permissions.accountOpening;

        if (isMobile) {
            return (
                <>
                    <div className="admin-detail-head px-0">
                        {showNewAccountButton ? (
                            <Head
                                onBack={this.handleBack}
                                title="accounts.page.header"
                                titleClassName="header-mobile-title-background-blue"
                                exportList={!fetching}
                                handleCreateClick={
                                    !fetching &&
                                    (() => {
                                        dispatch(push(`/accountOpening`));
                                    })
                                }
                                handleCreateMessage="accounts.page.newAccount"
                                handleClick={this.handleExport}
                                handleClickMessage="accounts.page.balance.download"
                                isFetchingExport={false}
                                hasInlineButtons={!fetching}
                                csvDownload={!fetching}
                                headerClassName="blue-main-header-mobile"
                                hideNavbarInMobile
                                downloadImageWhite={!fetching}
                            />
                        ) : (
                            <Head
                                onBack={this.handleBack}
                                title="accounts.page.header"
                                titleClassName="header-mobile-title-background-blue"
                                exportList
                                handleClick={this.handleExport}
                                handleClickMessage="accounts.page.balance.download"
                                isFetchingExport={false}
                                hasInlineButtons
                                csvDownload
                                headerClassName="blue-main-header-mobile"
                                hideNavbarInMobile
                                downloadImageWhite
                            />
                        )}
                    </div>
                    {!isEmpty(accounts) && (ARSTotalBalance || USDTotalBalance) ? (
                        <EquivalentTotalBalance {...this.props} />
                    ) : (
                        <div className="mb-4" />
                    )}
                </>
            );
        }

        return (
            <>
                <div className="admin-detail-head px-0">
                    <Head onBack={this.handleBack} />
                    {showNewAccountButton && (
                        <Head
                            title="accounts.page.header"
                            exportList
                            transfer
                            handleCreateClick={() => {
                                dispatch(push(`/accountOpening`));
                            }}
                            handleCreateMessage="accounts.page.newAccount"
                            handleClick={this.handleExport}
                            handleClickMessage="accounts.page.balance.download"
                            headerClassName="accounts-header-desktop"
                            isFetchingExport={fetchingDownload}
                            hasInlineButtons
                            csvDownload
                            imageStyle="mr-2"
                        />
                    )}
                    {!showNewAccountButton && (
                        <Head
                            title="accounts.page.header"
                            exportList
                            transfer
                            handleClick={this.handleExport}
                            handleClickMessage="accounts.page.balance.download"
                            isFetchingExport={fetchingDownload}
                            hasInlineButtons
                            csvDownload
                            headerClassName="accounts-header-desktop"
                            titleClassName="my-0"
                            imageStyle="mr-2"
                        />
                    )}
                    {!isEmpty(accounts) ? <EquivalentTotalBalance {...this.props} /> : ""}
                </div>
            </>
        );
    };

    render() {
        const { accounts, fetching, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="accounts" />
                {(!fetching || !isDesktop) && this.renderHeader()}
                <MainContainer
                    showLoader={fetching}
                    loadingClassName="screen-loader"
                    viewContentClassName="overflow-visible"
                    className="main-container without-auto-margin">
                    <div className={classNames({ "mx-3": !isDesktop }, { "pb-7": !isDesktop })}>
                        <Container className={isDesktop ? "align-items-center " : ""}>
                            <div className="above-the-fold">
                                {accounts.length ? (
                                    <AccountsList extendedData accounts={accounts} isDesktop={isDesktop} />
                                ) : (
                                    <GeneralMsg
                                        imagePath="images/coloredIcons/find-in-page.svg"
                                        description={i18n.get("accounts.list.empty")}
                                        isDesktop={isDesktop}
                                    />
                                )}
                            </div>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    loggedUser: sessionSelectors.getUser(state),
    ARSTotalBalance: accountsSelectors.getARSTotalBalance(state),
    USDTotalBalance: accountsSelectors.getUSDTotalBalance(state),
    fetching: accountsSelectors.getFetching(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
    isRequestAvailable: sessionSelectors
        .getActiveEnvironmentForms(state, "accounts")
        .find(({ idForm }) => idForm === "accountOpening"),
});

export default connect(mapStateToProps)(Accounts);

import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, number, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";

class SelectedTransactionsAmount extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        totalRows: number.isRequired,
        selectedTransactionRows: arrayOf().isRequired,
        showTotalOperations: bool,
    };

    static defaultProps = {
        showTotalOperations: true,
    };

    render() {
        const { totalRows, isDesktop, selectedTransactionRows, showTotalOperations } = this.props;

        const initialValue = 0;

        const amountArs = selectedTransactionRows?.reduce(
            (prev, curr) => prev + (curr?.transactionAmounts["0"] || 0),
            initialValue,
        );
        const amountUsd = selectedTransactionRows?.reduce(
            (prev, curr) => prev + (curr?.transactionAmounts["2"] || 0),
            initialValue,
        );
        const valuesClass = "font-weight-medium f-size-4";
        let operations = i18n.get("transactions.selected.operations").concat(" ", selectedTransactionRows?.length);

        if (showTotalOperations) {
            operations = operations.concat(" de ", totalRows);
        }

        if (amountArs > 0 || amountUsd > 0) {
            return (
                <Row className="mx-0 justify-content-end">
                    <Col xs={12} className="d-flex ml-md-5 mb-1 mb-md-0 justify-content-end px-md-0">
                        <span className={`p-0 mr-2 ${valuesClass}`}>
                            {i18n.get("transactions.selected.total.amount").toUpperCase()}
                        </span>
                        <FormattedAmount
                            cleanClassName
                            className="data-amount"
                            fontClassName={valuesClass}
                            currency="0"
                            quantity={amountArs}
                        />
                        {isDesktop ? (
                            <>
                                <span className={`px-1 ${valuesClass}`}>|</span>
                                <FormattedAmount
                                    cleanClassName
                                    className="data-amount"
                                    fontClassName={valuesClass}
                                    currency="2"
                                    quantity={amountUsd}
                                />
                            </>
                        ) : (
                            amountArs > 0 &&
                            amountUsd > 0 && (
                                <>
                                    <span className={`px-1 ${valuesClass}`}>|</span>
                                    <FormattedAmount
                                        cleanClassName
                                        className="data-amount"
                                        fontClassName={valuesClass}
                                        currency="2"
                                        quantity={amountUsd}
                                    />
                                </>
                            )
                        )}
                    </Col>
                    <Col xs={12} className="px-md-0">
                        <p className="m-0 text-align-right font-weight-normal f-size-55"> {operations} </p>
                    </Col>
                </Row>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    totalRows: transactionsSelectors.getTotalRows(state),
});

export default connect(mapStateToProps)(SelectedTransactionsAmount);

export const types = {
    TRANSFER_PRE_ACTIVITY_REQUEST: "transfer/TRANSFER_PRE_ACTIVITY_REQUEST",
    TRANSFER_PRE_ACTIVITY_SUCCESS: "transfer/TRANSFER_PRE_ACTIVITY_SUCCESS",
    TRANSFER_PRE_ACTIVITY_FAILURE: "transfer/TRANSFER_PRE_ACTIVITY_FAILURE",
    CREATE_INTERNAL_TRANSFER_REQUEST: "transfer/CREATE_INTERNAL_TRANSFER_REQUEST",
    CREATE_INTERNAL_TRANSFER_SUCCESS: "transfer/CREATE_INTERNAL_TRANSFER_SUCCESS",
    CREATE_INTERNAL_TRANSFER_FAILURE: "transfer/CREATE_INTERNAL_TRANSFER_FAILURE",
    CREATE_EXTERNAL_TRANSFER_REQUEST: "transfer/CREATE_EXTERNAL_TRANSFER_REQUEST",
    CREATE_EXTERNAL_TRANSFER_SUCCESS: "transfer/CREATE_EXTERNAL_TRANSFER_SUCCESS",
    CREATE_EXTERNAL_TRANSFER_FAILURE: "transfer/CREATE_EXTERNAL_TRANSFER_FAILURE",
    CLEAN_TRANSFER_STATE: "transfer/CLEAN_TRANSFER_STATE",
    GET_ACCOUNT_INFO: "transfer/GET_ACCOUNT_INFO",
    LOAD_INTERNAL_TRANSFER: "transfer/LOAD_INTERNAL_TRANSFER",
    LOAD_EXTERNAL_TRANSFER: "transfer/LOAD_EXTERNAL_TRANSFER",
    LOAD_SCHEDULED_TRANSACTION_CANCEL: "transfer/LOAD_SCHEDULED_TRANSACTION_CANCEL",
    LOAD_THIRD_PARTIES_TRANSFER: "transfer/LOAD_THIRD_PARTIES_TRANSFER",
    SEND_INTERNAL_TRANSFER_REQUEST: "transfer/SEND_INTERNAL_TRANSFER_REQUEST",
    SEND_INTERNAL_TRANSFER_SUCCESS: "transfer/SEND_INTERNAL_TRANSFER_SUCCESS",
    SEND_INTERNAL_TRANSFER_PENDING: "transfer/SEND_INTERNAL_TRANSFER_PENDING",
    SEND_INTERNAL_TRANSFER_FAILURE: "transfer/SEND_INTERNAL_TRANSFER_FAILURE",
    SEND_EXTERNAL_TRANSFER_REQUEST: "transfer/SEND_EXTERNAL_TRANSFER_REQUEST",
    SEND_EXTERNAL_TRANSFER_SUCCESS: "transfer/SEND_EXTERNAL_TRANSFER_SUCCESS",
    SEND_EXTERNAL_TRANSFER_PENDING: "transfer/SEND_EXTERNAL_TRANSFER_PENDING",
    SEND_EXTERNAL_TRANSFER_FAILURE: "transfer/SEND_EXTERNAL_TRANSFER_FAILURE",
    CHANGE_TRANSFER_GROUP: "transfer/CHANGE_TRANSFER_GROUP",
    SET_DEBIT_ACCOUNT_INFO: "transfer/SET_DEBIT_ACCOUNT_INFO",
    SET_CREDIT_ACCOUNT_INFO: "transfer/SET_CREDIT_ACCOUNT_INFO",
    CLEAR_TRANSFER_DATA: "transfer/CLEAR_TRANSFER_DATA",
    CANCEL_SCHEDULED_TRANSACTION_REQUEST: "transfer/CANCEL_SCHEDULED_TRANSACTION_REQUEST",
    CANCEL_SCHEDULED_TRANSACTION_FAILURE: "transfer/CANCEL_SCHEDULED_TRANSACTION_FAILURE",
    CANCEL_SCHEDULED_TRANSACTION_SUCCESS: "transfer/CANCEL_SCHEDULED_TRANSACTION_SUCCESS",
    ACCOUNT_LIMITS_REQUEST: "transfer/ACCOUNT_LIMITS_REQUEST",
    ACCOUNT_LIMITS_FAILURE: "transfer/ACCOUNT_LIMITS_FAILURE",
    ACCOUNT_LIMITS_SUCCESS: "transfer/ACCOUNT_LIMITS_SUCCESS",
    SEND_SCHEDULER_EMAIL_REQUEST: "transfer/SEND_SCHEDULER_EMAIL_REQUEST",
    SEND_SCHEDULER_EMAIL_FAILURE: "transfer/SEND_SCHEDULER_EMAIL_FAILURE",
    SEND_SCHEDULER_EMAIL_SUCCESS: "transfer/SEND_SCHEDULER_EMAIL_SUCCESS",
    GET_CBU_CVU_INFO: "transfer/GET_CBU_CVU_INFO",
    SET_IS_CVU: "transfer/SET_IS_CVU",
    SET_SEND_EMAIL: "transfer/SET_SEND_EMAIL",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    summary: {
        type: null,
        debitAccount: "",
        creditAccount: "",
        cbuOrAlias: null,
        concept: null,
        transferKind: null,
        currency: 0,
        amount: null,
        email: null,
        text: null,
        sendEmail: false,
        idTransaction: null,
        reference: "",
        tadTrx: false,
    },
    creditAccountDetail: null,
    clientUser: null,
    otp: null,
    internalTransfer: true,
    accounts: [],
    concepts: [],
    limit: 0.0,
    limitUsed: 0.0,
    scheduledCancel: false,
    serverDate: null,
    isCVU: false,
    pspEntityName: null,
    sendEmail: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        creditAccountDetail,
        clientUser,
        otp,
        idTransaction,
        internalTransfer,
        summary,
        accountInfo,
        concepts,
        accounts,
        limit,
        limitUsed,
        tadTrx,
        serverDate,
        isCVU,
        pspEntityName,
        sendEmail,
    } = action;

    switch (action.type) {
        case types.GET_ACCOUNT_INFO:
            return {
                ...state,
                fetching: true,
            };
        case types.SET_DEBIT_ACCOUNT_INFO:
            return {
                ...state,
                summary: { ...state.summary, debitAccount: accountInfo },
                fetching: false,
            };
        case types.SET_CREDIT_ACCOUNT_INFO:
            return {
                ...state,
                summary: { ...state.summary, creditAccount: accountInfo },
                fetching: false,
            };
        case types.CLEAR_TRANSFER_DATA:
            return {
                ...state,
                summary: {},
            };
        case types.CHANGE_TRANSFER_GROUP:
            return {
                ...INITIAL_STATE,
                internalTransfer,
                concepts: state.concepts,
                accounts: state.accounts,
                fetching: state.fetching,
                serverDate: state.serverDate,
            };
        case types.CREATE_INTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                fetching: true,
                internalTransfer: true,
                summary,
            };

        case types.CREATE_INTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: { ...state.summary, creditAccountDetail, clientUser, tadTrx },
            };

        case types.CREATE_INTERNAL_TRANSFER_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case types.CREATE_EXTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                internalTransfer: false,
                fetching: true,
                summary,
            };

        case types.CREATE_EXTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: {
                    ...state.summary,
                    creditAccountDetail,
                    clientUser,
                    tadTrx,
                    pspEntityName,
                    cbu: creditAccountDetail?.cbu,
                },
            };

        case types.CREATE_EXTERNAL_TRANSFER_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case types.LOAD_INTERNAL_TRANSFER:
            return {
                ...state,
                internalTransfer: true,
                scheduledCancel: false,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };
        case types.LOAD_EXTERNAL_TRANSFER:
            return {
                ...state,
                internalTransfer: false,
                scheduledCancel: false,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };

        case types.LOAD_SCHEDULED_TRANSACTION_CANCEL:
            return {
                ...state,
                scheduledCancel: true,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };

        case types.LOAD_THIRD_PARTIES_TRANSFER:
            return {
                ...state,
                internalTransfer: false,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
            };

        case types.CLEAN_TRANSFER_STATE:
            return {
                ...INITIAL_STATE,
            };
        case types.SEND_INTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                otp,
            };
        case types.SEND_INTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                idTransaction,
            };
        case types.SEND_INTERNAL_TRANSFER_PENDING:
            return {
                ...state,
                idTransaction,
                summary: { ...state.summary, idTransaction },
            };
        case types.SEND_EXTERNAL_TRANSFER_REQUEST:
            return {
                ...state,
                internalTransfer: false,
                idTransaction,
                summary,
                isCVU: !!summary?.cbu?.startsWith("000"),
                otp,
            };
        case types.SEND_EXTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                idTransaction,
                summary: { ...state.summary, idTransaction },
            };
        case types.SEND_EXTERNAL_TRANSFER_PENDING:
            return {
                ...state,
                idTransaction,
            };
        case types.TRANSFER_PRE_ACTIVITY_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TRANSFER_PRE_ACTIVITY_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.TRANSFER_PRE_ACTIVITY_SUCCESS:
            return {
                ...state,
                fetching: false,
                concepts,
                accounts,
                serverDate,
            };
        case types.CANCEL_SCHEDULED_TRANSACTION_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.CANCEL_SCHEDULED_TRANSACTION_FAILURE:
        case types.CANCEL_SCHEDULED_TRANSACTION_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.ACCOUNT_LIMITS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ACCOUNT_LIMITS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ACCOUNT_LIMITS_SUCCESS:
            return {
                ...state,
                fetching: false,
                limit,
                limitUsed,
            };
        case types.SEND_SCHEDULER_EMAIL_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.SEND_SCHEDULER_EMAIL_FAILURE:
        case types.SEND_SCHEDULER_EMAIL_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_IS_CVU:
            return {
                ...state,
                isCVU,
            };
        case types.SET_SEND_EMAIL:
            return {
                ...state,
                sendEmail,
            };
        default:
            return state;
    }
};

export const actions = {
    preActivity: () => ({
        type: types.TRANSFER_PRE_ACTIVITY_REQUEST,
    }),
    createInternalTransfer: (summary, formikBag) => ({
        type: types.CREATE_INTERNAL_TRANSFER_REQUEST,
        summary,
        formikBag,
    }),
    clearTransferData: () => ({
        type: types.CLEAR_TRANSFER_DATA,
    }),
    createExternalTransfer: (summary, formikBag) => ({
        type: types.CREATE_EXTERNAL_TRANSFER_REQUEST,
        summary,
        formikBag,
    }),
    cleanData: () => ({
        type: types.CLEAN_TRANSFER_STATE,
    }),
    sendInternalTransfer: (summary, otp) => ({
        type: types.SEND_INTERNAL_TRANSFER_REQUEST,
        otp,
        summary,
    }),
    sendExternalTransfer: (summary, otp) => ({
        type: types.SEND_EXTERNAL_TRANSFER_REQUEST,
        otp,
        summary,
    }),
    changeTransferGroup: (internalTransfer) => ({
        type: types.CHANGE_TRANSFER_GROUP,
        internalTransfer,
    }),
    loadInternalTransfer: (summary) => ({
        type: types.LOAD_INTERNAL_TRANSFER,
        summary,
    }),
    loadThirdPartiesTransfer: (summary) => ({
        type: types.LOAD_THIRD_PARTIES_TRANSFER,
        summary,
    }),
    loadTransfer: (summary) => ({
        type: types.LOAD_INTERNAL_TRANSFER,
        summary,
    }),
    loadExternalTransfer: (summary) => ({
        type: types.LOAD_EXTERNAL_TRANSFER,
        summary,
    }),
    loadScheduledTransactionCancel: (summary) => ({
        type: types.LOAD_SCHEDULED_TRANSACTION_CANCEL,
        summary,
    }),
    getAccountInfo: (account, accountType, isTransfer) => ({
        type: types.GET_ACCOUNT_INFO,
        account,
        accountType,
        isTransfer,
    }),
    cancelScheduled: (
        idTransaction,
        otp,
        debitAccount,
        creditAccount,
        cbu,
        amount,
        currency,
        formikBag,
        clientUser,
    ) => ({
        type: types.CANCEL_SCHEDULED_TRANSACTION_REQUEST,
        idTransaction,
        otp,
        debitAccount,
        creditAccount,
        cbu,
        amount,
        currency,
        formikBag,
        clientUser,
    }),
    accountLimitsActivities: (idAccount, idActivity) => ({
        type: types.ACCOUNT_LIMITS_REQUEST,
        idAccount,
        idActivity,
    }),
    sendSchedulerEmail: (summary) => ({
        type: types.SEND_SCHEDULER_EMAIL_REQUEST,
        summary,
    }),
    getCbuCvuFromAlias: (alias) => ({
        type: types.GET_CBU_CVU_INFO,
        alias,
    }),
    setIsCVU: (isCVU) => ({
        type: types.SET_IS_CVU,
        isCVU,
    }),
    setSendEmail: (sendEmail) => ({
        type: types.SET_SEND_EMAIL,
        sendEmail,
    }),
};

export const selectors = {
    getTadTrx: ({ transfer }) => transfer.summary.tadTrx,
    getFetching: ({ transfer }) => transfer.fetching,
    getDebitAccount: ({ transfer }) => transfer.summary.debitAccount,
    getCreditAccount: ({ transfer }) => transfer.summary.creditAccount,
    getCurrency: ({ transfer }) => transfer.summary.currency,
    getAmount: ({ transfer }) => transfer.summary.amount,
    getEmail: ({ transfer }) => transfer.summary.email,
    getEmailText: ({ transfer }) => transfer.summary.emailText,
    getReferente: ({ transfer }) => transfer.summary.reference,
    getSummary: ({ transfer }) => transfer.summary,
    getIdTransaction: ({ transfer }) => transfer.idTransaction,
    getInternalTransfer: ({ transfer }) => transfer.internalTransfer,
    getCreditAccountDetails: ({ transfer }) => transfer.creditAccountDetail,
    getClientUser: ({ transfer }) => transfer.clientUser,
    getConcepts: ({ transfer }) => transfer.concepts,
    getAccounts: ({ transfer }) => transfer.accounts,
    getFetchingDownload: ({ transfer }) => transfer.fetchingDownload,
    getLimit: ({ transfer }) => transfer.limit,
    getLimitUsed: ({ transfer }) => transfer.limitUsed,
    getScheduledCancel: ({ transfer }) => transfer.scheduledCancel,
    getServerDate: ({ transfer }) => transfer.serverDate,
    getIsCVU: ({ transfer }) => transfer.isCVU,
    getSendEmail: ({ transfer }) => transfer.sendEmail,
};

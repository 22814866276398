import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";

class EcheqEmisionDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const {
            multipleSignaturesData: { selectedTransactionRows },
            isDesktop,
        } = this.props;
        return selectedTransactionRows.map((object) => {
            const {
                data: { echeqsToEmit, beneficiary, beneficiaryName, motive, echeqNumeration },
            } = object.transaction;
            const numeration = JSON.parse(echeqNumeration);

            if (isDesktop) {
                return (
                    <Table.Row>
                        <Table.Data align="center">
                            <span>{echeqsToEmit.length}</span>
                        </Table.Data>
                        <Table.Data align="center">
                            <span>{numeration.length === 1 ? numeration[0].cheque_numero : "-"}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>
                                {numeration.length > 1
                                    ? i18n.get("table.emisionEcheq.multiple")
                                    : i18n.get("table.emisionEcheq.individual")}
                            </span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{beneficiaryName}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{beneficiary}</span>
                        </Table.Data>
                        <Table.Data align="center">
                            <FormattedDate date={echeqsToEmit[0].date} dateFormat="dd/MM/yyyy" withSpan />
                        </Table.Data>
                        <Table.Data align="center">
                            <span>{echeqsToEmit[0].type}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{motive}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <FormattedAmount currency="0" quantity={echeqsToEmit[0]?.amount * echeqsToEmit.length} />
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    <Heading.DataGroup
                        label="table.emisionEcheq.quantity"
                        data={<span>{numeration.length}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.echeq"
                        data={<span>{numeration.length === 1 ? numeration[0].cheque_numero : "-"}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emisionEcheq.mode"
                        data={
                            <span>
                                {numeration.length > 1
                                    ? i18n.get("table.emisionEcheq.multiple")
                                    : i18n.get("table.emisionEcheq.individual")}
                            </span>
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.echeq.beneficiary"
                        data={<span>{beneficiaryName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.echeq.beneficiary.cuit"
                        data={<span>{beneficiary}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.date"
                        data={<FormattedDate date={echeqsToEmit[0].date} dateFormat="dd/MM/yyyy" withSpan />}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.type"
                        data={<span>{echeqsToEmit[0].type}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.motive"
                        data={<span>{motive}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="table.emitCesion.amount"
                        data={<FormattedAmount currency="0" quantity={echeqsToEmit[0]?.amount * echeqsToEmit.length} />}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="center">
                            <I18n id="table.emisionEcheq.quantity" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.emitCesion.echeq" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.emisionEcheq.mode" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.echeq.beneficiary" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.echeq.beneficiary.cuit" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.emitCesion.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id="table.emitCesion.type" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="table.emitCesion.motive" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="table.emitCesion.amount" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getTransactions(state),
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(EcheqEmisionDataTable);

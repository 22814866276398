import { push } from "react-router-redux";
import * as i18nUtils from "util/i18n";

export const maskedAccountNumber = (accountNumber) => {
    const amountOfVisibleNumbers = 4;
    const minAmountOfMaskedNumbers = 4;
    const visibleNumbers = accountNumber?.substring(accountNumber?.length - amountOfVisibleNumbers);
    const amountOfMaskedNumbers = accountNumber?.length - amountOfVisibleNumbers;
    const amountOfAsteriskMask =
        amountOfMaskedNumbers >= minAmountOfMaskedNumbers ? amountOfMaskedNumbers : minAmountOfMaskedNumbers;
    const mask = Array(amountOfAsteriskMask + 1).join("*");
    return mask + visibleNumbers;
};

export const productTypes = { CA: "savings", CC: "checking" };

export const getAccountNumber = (cbu) => cbu.slice(8, 21);

export const getIncomingDebinAccountName = (account) => {
    if (!account) {
        return "";
    }

    const { productAlias, number: accountNumber, productType, currency } = account;
    return `${productAlias || ""} ${maskedAccountNumber(accountNumber)} ${productType} ${i18nUtils.get(
        `currency.label.${currency}`,
    )}`;
};

export const getUserAccount = (accounts, cbu) => accounts.find((account) => cbu === account?.cbu);

export function accountContextMenuItems(dispatch, activeEnvironment, debitAccount, arrayItemsDropdown = []) {
    let items = [];

    if (activeEnvironment && arrayItemsDropdown.length > 0) {
        items = [...items, ...arrayItemsDropdown];
    }

    if (activeEnvironment && activeEnvironment.permissions.transferInternal) {
        items = [
            ...items,
            {
                label: "accounts.actions.transfer.internal",
                onClick: () => dispatch(push({
                    pathname: "/transfer", 
                    state: { isInternal: true, debitAccount }
                })),
            },
        ];
    }

    if (activeEnvironment && activeEnvironment.permissions.transferThirdParties) {
        items = [
            ...items,
            {
                label: "accounts.actions.transfer.external",
                onClick: () => dispatch(push({
                    pathname: "/transfer", 
                    state: { isInternal: false, debitAccount }
                })),
            },
        ];
    }

    if (activeEnvironment && activeEnvironment.permissions["pay.afip"]) {
        items = [
            ...items,
            {
                label: "accounts.actions.payment.services",
                onClick: () => dispatch(push("/servicePayments")),
            },
        ];
    }

    if (activeEnvironment && activeEnvironment?.permissions.requestCheckbook) {
        items = [...items, { label: "accounts.actions.checkbook", onClick: () => dispatch(push(`/checks`)) }];
    }

    return items;
}

export function getAccountDescription({ number, productType, currency }) {
    return `${maskedAccountNumber(number)} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { Col } from "react-bootstrap";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import { getAccountDescription } from "util/accounts";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";

class RecurrencyDebinDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
        showAction: bool,
    };

    static defaultProps = {
        showAction: false,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop, showAction } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: { debitAccount, sellerName, prestacion, action },
            } = object.transaction;

            const accountName = debitAccount?.number !== undefined && getAccountDescription(debitAccount);

            if (isDesktop) {
                return (
                    <Table.Row>
                        {showAction ? (
                            <Table.Data align="left">
                                <span>{i18nUtils.get(`debin.recurrency.${action.toLowerCase()}.debin`)}</span>
                            </Table.Data>
                        ) : (
                            undefined
                        )}
                        <Table.Data align="left">
                            <span>{idTransactionFormat(idTransaction)}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{accountName}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{sellerName}</span>
                        </Table.Data>
                        <Table.Data align="right">
                            <span>{prestacion}</span>
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Col className="col col-12 container-white py-2 my-2">
                    {showAction ? (
                        <Heading.DataGroup
                            label="transaction.ticket.action"
                            data={<span>{i18nUtils.get(`debin.recurrency.${action.toLowerCase()}.debin`)}</span>}
                            containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                            labelClassName="mb-0"
                        />
                    ) : (
                        undefined
                    )}
                    <Heading.DataGroup
                        label="forms.transaction.ticket.number"
                        data={<span>{idTransactionFormat(idTransaction)}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="debin.recurrency.account.title"
                        data={<span>{accountName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="debin.recurrency.seller.name.title"
                        data={<span>{sellerName}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label="debin.recurrency.prestacion.title"
                        data={prestacion}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </Col>
            );
        });
    };

    render() {
        const { isDesktop, showAction } = this.props;
        if (isDesktop) {
            return (
                <>
                    <Table.Header className="container-white">
                        {showAction ? (
                            <Table.HeaderData align="left">
                                <I18n id="transaction.ticket.action" />
                            </Table.HeaderData>
                        ) : (
                            undefined
                        )}
                        <Table.HeaderData align="left">
                            <I18n id="forms.transaction.ticket.number" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="debin.recurrency.account.title" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="debin.recurrency.seller.name.title" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id="debin.recurrency.prestacion.title" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{this.dataRow()}</Table.Body>
                </>
            );
        }
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(RecurrencyDebinDataTable);

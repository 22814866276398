import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import { push } from "react-router-redux";
import SelectedTransactionsAmount from "pages/transactions/SelectedTransactionsAmount";
import * as i18n from "util/i18n";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { compose } from "redux";
import Container from "pages/_components/Container";
import Credential from "pages/_components/fields/credentials/Credential";
import MultipleSignaturesDataTables from "pages/transactions/MultipleSignaturesTables/MultipleSignaturesDataTables";
import { selectors as sessionSelectors } from "reducers/session";
import MainContainer from "pages/_components/MainContainer";
import isTokenActive from "util/token";

class MultipleSignaturesSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        multipleSignaturesData: shape({}).isRequired,
        isSubmitting: bool.isRequired,
    };

    componentDidMount() {
        const { multipleSignaturesData, dispatch } = this.props;

        if (!multipleSignaturesData) {
            dispatch(push("/pendingTransaction/list"));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/pendingTransaction/list"));
    };

    montoOperaciones = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return <SelectedTransactionsAmount selectedTransactionRows={selectedTransactionRows} isDesktop={isDesktop} />;
    };

    centerContentMobile = () => (
        <div
            className="title-account-header-multiline-background-blue"
            style={{ display: "flex", justifyContent: "center" }}>
            <h1 style={{ margin: "-0.3rem", width: "70%" }}>{i18n.get("pendingDispatch.list.title")}</h1>
        </div>
    );

    render() {
        const { isDesktop, multipleSignaturesData, isSubmitting } = this.props;

        return (
            <>
                {multipleSignaturesData && (
                    <>
                        <Notification scopeToShow="multipleSignatures" />
                        <div className="admin-detail-head px-0">
                            <Head
                                onBack={this.handleBack}
                                headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                                centerElement={isDesktop ? undefined : this.centerContentMobile}
                                emptyRightOption
                            />
                            {isDesktop && (
                                <div className="view-title" style={{ display: "flex" }}>
                                    <h1>{i18n.get("pendingDispatch.list.title")}</h1>
                                </div>
                            )}
                            <div className={isDesktop ? "" : "admin-content-center"}>
                                <h3>{i18n.get("sign.transaction")}</h3>
                                <h4>{multipleSignaturesData?.activitySelected?.activityName?.toUpperCase()}</h4>
                            </div>
                            <div style={isDesktop ? {} : { marginRight: "1rem" }}>{this.montoOperaciones()}</div>
                        </div>
                        <MainContainer>
                            <Form className="above-the-fold">
                                <Container
                                    className={`flex-grow align-items-center ${
                                        isDesktop ? "container-white with-margin with-padding" : ""
                                    }`}
                                    gridClassName="form-content">
                                    <MultipleSignaturesDataTables isDesktop={isDesktop} />
                                    <Col
                                        className="col-12 col-xl-4"
                                        lg={4}
                                        md={9}
                                        sm={12}
                                        style={{ marginTop: "1rem" }}>
                                        {!isTokenActive(!isDesktop) ? (
                                            <div className="admin-content-center">
                                                <I18n id="transfer.summary.confirm.label" />
                                            </div>
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                        )}

                                        <div className="admin-content-center">
                                            <Field
                                                name="otp"
                                                type="otp"
                                                idForm="administration"
                                                component={Credential}
                                                loading={isSubmitting}
                                            />
                                        </div>
                                    </Col>
                                </Container>
                                <Container className="flex-grow align-items-center" gridClassName="form-content">
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            className="text-capitalize"
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </MainContainer>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
    selectedTransactionRows: transactionsSelectors.getSelectedTransactionRows(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, multipleSignaturesData, userId, selectedTransactionRows } = formikBag.props;
            const selectedRows = selectedTransactionRows.map((transaction) => transaction.transaction.idTransaction);
            const { idForm } = selectedTransactionRows[0].transaction;

            dispatch(
                transactionsActions.signMultipleTransactions({
                    otp,
                    idActivity: multipleSignaturesData.activitySelected.idActivity,
                    transactions: selectedRows,
                    userId,
                    formikBag,
                    idForm: idForm === null ? "" : idForm,
                }),
            );
        },
    }),
)(MultipleSignaturesSummary);

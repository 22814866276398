/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { string, shape } from "prop-types";
import Notification from "pages/_components/Notification";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import { getDisplay } from "util/device";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18nUtils from "util/i18n";
import * as thirdPaymentUtils from "util/thirdPayment";

export default function ThirdPaymentCancelData({ transactionOrder, summary, accountName, code }) {
    ThirdPaymentCancelData.propTypes = {
        summary: shape({}).isRequired,
        accountName: string.isRequired,
        transactionOrder: string.isRequired,
        code: string.isRequired,
    };

    const [isDesktop] = useState(getDisplay() === "desktop");
    const { reference, typePayment, dateProcess, file, pendingLines } = summary.data;

    const containerClassName = `transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between mb-0"}`;

    const labelClassName = isDesktop ? "" : "mb-0";
    return (
        <>
            <Notification scopeToShow="form" />
            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="thirdPay.confirm.table.header.typePayment"
                data={typePayment}
            />
            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="thirdPay.confirm.table.header.account"
                data={accountName}
            />
            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="thirdPay.confirm.table.header.reference"
                data={reference}
            />

            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="thirdPay.confirm.table.header.dateProcess"
                data={<FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />}
            />
            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="payments.thirdPayment.detail.fileName"
                data={file[0]?.fileName}
            />
            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="payments.thirdPayment.detail.linesNumber"
                data={pendingLines || transactionOrder}
            />
            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="payments.thirdPayment.detail.amount"
                data={
                    <FormattedAmount
                        currency={i18nUtils.get("currency.label.ARS")}
                        quantity={summary.totalAmount || summary.data?.totalAmount}
                        notBold="true"
                    />
                }
            />
            <Heading.DataGroup
                containerClassName={containerClassName}
                labelClassName={labelClassName}
                label="payments.thirdPayment.table.status"
                data={code || thirdPaymentUtils.getCodeMsg(summary.idTransactionStatus)}
            />
        </>
    );
}

import { call, put, takeLatest, take } from "redux-saga/effects";
import { routerActions } from "react-router-redux";
import * as i18n from "util/i18n";
import { adjustIdFieldErrors } from "util/form.js";
import { types, actions as servicePaymentsActions } from "reducers/servicePayments";
import * as servicePaymentsMiddleware from "middleware/servicePayments";
import { actions as notificationActions } from "reducers/notification";
import { actions as formActions } from "reducers/form";
import { flatScheduledPayments } from "util/servicePayments";
import * as configUtils from "util/config";

const sagas = [
    takeLatest(types.LIST_RUBROS_REQUEST, getRubros),
    takeLatest(types.LIST_ENTITIES_REQUEST, getEntities),
    takeLatest(types.CHECK_CPE_REQUEST, getPaymentsConcept),
    takeLatest(types.SUBMIT_PAYMENT_REQUEST, submitPayment),
    takeLatest(types.LIST_ADHESIONES_REQUEST, getAdhesiones),
    takeLatest(types.UNATTACH_SERVICE_REQUEST, unattachAdhesion),
    takeLatest(types.MODIFY_ADHESION_REFERENCE_REQUEST, modifyAdhesionReference),
    takeLatest(types.LIST_AGENDA_PAGOS_REQUEST, getAgendaPagos),
    takeLatest(types.PREPARE_PAYMENT_REQUEST, preparePayment),
    takeLatest(types.PREPARE_MULTIPLE_PAYMENTS_REQUEST, prepareMultiplePayments),
    takeLatest(types.SUBMIT_MULTIPLE_PAYMENTS_REQUEST, submitMultiplePayments),
];

export default sagas;

function* getRubros({ account }) {
    const response = yield call(servicePaymentsMiddleware.listRubros, account.idProduct);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["servicePayments"]));
        yield put({ type: types.LIST_RUBROS_FAILURE });
    } else if (response.data.data.codigo !== "0" && response.data.data.codigo !== "00") {
        yield put(
            notificationActions.showNotification(
                `${response.data.data.description || `Error en el servicio`} Codigo de error: ${
                    response.data.data.codigo
                }`,
                "error",
                ["servicePayments"],
            ),
        );
        yield put({ type: types.LIST_RUBROS_FAILURE });
    } else {
        yield put({
            type: types.LIST_RUBROS_SUCCESS,
            rubros: response.data.data.rubros,
        });
    }
}

function* getEntities({ categoryCode }) {
    const response = yield call(servicePaymentsMiddleware.listEntities, categoryCode);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["servicePayments"]));
        yield put({ type: types.LIST_ENTITIES_FAILURE });
    } else if (response.data.data.codigo !== "0" && response.data.data.codigo !== "00") {
        yield put(
            notificationActions.showNotification(
                `${response.data.data.description || `Error en el servicio`} Codigo de error: ${
                    response.data.data.codigo
                }`,
                "error",
                ["servicePayments"],
            ),
        );
        yield put({ type: types.LIST_ENTITIES_FAILURE });
    } else {
        yield put({
            type: types.LIST_ENTITIES_SUCCESS,
            entities: response.data.data.entities,
        });
    }
}

function* getPaymentsConcept({ entityCode, cpe }) {
    const response = yield call(servicePaymentsMiddleware.getPaymentsConcept, entityCode, cpe);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["servicePayments"]));
        yield put({ type: types.CHECK_CPE_FAILURE });
    } else if (response.data.data.codigo !== "0" && response.data.data.codigo !== "00") {
        yield put(
            notificationActions.showNotification(
                `${response.data.data.description || `Error en el servicio`} Codigo de error: ${
                    response.data.data.codigo
                }`,
                "error",
                ["servicePayments"],
            ),
        );
        yield put({ type: types.CHECK_CPE_FAILURE });
    } else if (
        (response.data.data.statusCPE.handleBaseDebts && response.data.data.paymentsConcept.expirations.length === 0) ||
        (!response.data.data.statusCPE.handleBaseDebts && response.data.data.paymentsConcept.length === 0)
    ) {
        yield put(
            notificationActions.showNotification(i18n.get("services.newPayment.noConcepts.message"), "warning", [
                "servicePayments",
            ]),
        );
        yield put({ type: types.CHECK_CPE_FAILURE });
    } else {
        yield put({
            type: types.CHECK_CPE_SUCCESS,
            paymentsConcept: response.data.data.paymentsConcept,
            statusCPE: response.data.data.statusCPE,
        });
    }
}

function* submitPayment({ summaryInfo, otp, formikBag }) {
    const { setSubmitting, setErrors } = formikBag;

    try {
        const {
            statusCPE: { handleBaseDebts },
        } = summaryInfo;

        let addReferenceResponse = {
            type: "I",
            data: {
                data: {
                    codigoAdhesion: "0",
                },
            },
        };

        if (summaryInfo.isAddServiceSelected && summaryInfo.serviceReference !== "NO_REFERENCE" && handleBaseDebts) {
            addReferenceResponse = yield call(servicePaymentsMiddleware.addServiceReference, summaryInfo);
        }

        const response = yield call(servicePaymentsMiddleware.submitPayment, summaryInfo, otp);

        if ((response.type === "W" && response.data.code !== "API516W") || addReferenceResponse.type === "W") {
            let errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            if (
                response.data.code === "COR054W" ||
                response.data.code === "API524W" ||
                response.data.code === "COR065W"
            ) {
                errorMessage = response.data.message;
            }

            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(errorMessage, "error", ["servicePayments"]));
            }
            yield put({ type: types.SUBMIT_PAYMENT_FAILURE });
        } else if (
            response.data.data.codigo !== "0" &&
            response.data.code !== "COR101I" &&
            response.data.code !== "COR023I"
        ) {
            const chequesFunctionalities = configUtils.getBoolean(
                "frontend.show.failedVoucher.chequesFunctionalities",
                false,
            );
            const error = response.data.data.voucherErrorCode;
            const message = response.data.data.voucherErrorMessage;

            if (chequesFunctionalities && error && message) {
                const notificationMessage = i18n.get("notification.snackbar.failed.voucher", null, {
                    VOUCHER_MESSAGE: message,
                    VOUCHER_ERROR: error,
                });
                yield put(notificationActions.showNotification(notificationMessage, "error", ["forms"]));
            } else {
                yield put(
                    notificationActions.showNotification(
                        `${response.data.data.description || `Error en el servicio`} Codigo de error: ${
                            response.data.data.codigo
                        }`,
                        "error",
                        ["forms"],
                    ),
                );
            }

            yield put({ type: types.SUBMIT_PAYMENT_FAILURE });
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        } else if (
            addReferenceResponse.data.data.codigoAdhesion !== "0" &&
            addReferenceResponse.data.data.codigoAdhesion !== "97"
        ) {
            yield put(
                notificationActions.showNotification(
                    `${addReferenceResponse.data.data.description || `Error en el servicio`} Codigo de error: ${
                        addReferenceResponse.data.data.codigoAdhesion
                    }`,
                    "error",
                    ["forms"],
                ),
            );
            yield put({ type: types.SUBMIT_PAYMENT_FAILURE });
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        } else {
            yield put({
                type: types.SUBMIT_PAYMENT_SUCCESS,
            });

            if (addReferenceResponse.data.data.codigoAdhesion === "97") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("services.newPayment.partialSuccess.message"),
                        "warning",
                        ["forms"],
                    ),
                );
            } else if (response.data.code === "COR000I") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("services.newPayment.success.final.message"),
                        "success",
                        ["forms"],
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("services.newPayment.success.message"), "success", [
                        "forms",
                    ]),
                );
            }
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        yield put({
            type: types.HANDLE_BACK_TICKET,
        });
        setSubmitting(false);
    }
}

function* getAdhesiones() {
    const response = yield call(servicePaymentsMiddleware.listAdhesiones);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["adhesiones"]));
        yield put({ type: types.LIST_ADHESIONES_FAILURE });
    } else if (response.data.data.codigo !== "0" && response.data.data.codigo !== "00") {
        yield put(
            notificationActions.showNotification(
                `${response.data.data.description || `Error en el servicio`} Codigo de error: ${
                    response.data.data.codigo
                }`,
                "error",
                ["adhesiones"],
            ),
        );
        yield put({ type: types.LIST_ADHESIONES_FAILURE });
    } else {
        yield put({
            type: types.LIST_ADHESIONES_SUCCESS,
            adhesiones: response.data.data.adhesiones,
        });
    }
}

function* unattachAdhesion({ adhesion }) {
    const response = yield call(servicePaymentsMiddleware.unattachAdhesion, adhesion);

    if (response.type === "W") {
        let errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

        if (response.data.code === "COR054W") {
            errorMessage = response.data.message;
        }
        yield put(notificationActions.showNotification(errorMessage, "error", ["adhesiones"]));
        yield put({ type: types.UNATTACH_SERVICE_FAILURE });
    } else if (response.data.data.codigo !== "0" && response.data.data.codigo !== "00") {
        yield put(
            notificationActions.showNotification(
                `${response.data.data.description || `Error en el servicio`} Codigo de error: ${
                    response.data.data.codigo
                }`,
                "error",
                ["adhesiones"],
            ),
        );
        yield put({ type: types.UNATTACH_SERVICE_FAILURE });
    } else {
        yield put({
            type: types.UNATTACH_SERVICE_SUCCESS,
        });
        yield put(
            notificationActions.showNotification(
                i18n.get("services.adhesiones.unattach.service.success.message"),
                "success",
                ["adhesiones"],
            ),
        );
        yield put(servicePaymentsActions.listAdhesiones());
        yield put(routerActions.push("/adhesiones/list"));
    }
}

function* modifyAdhesionReference({ reference, adhesion }) {
    const response = yield call(servicePaymentsMiddleware.modifyAdhesionReference, reference, adhesion);

    if (response.type === "W") {
        let errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

        if (response.data.code === "COR054W") {
            errorMessage = response.data.message;
        }
        yield put(notificationActions.showNotification(errorMessage, "error", ["adhesiones"]));
        yield put({ type: types.MODIFY_ADHESION_REFERENCE_FAILURE });
    } else {
        yield put({
            type: types.MODIFY_ADHESION_REFERENCE_SUCCESS,
        });
        yield put(
            notificationActions.showNotification(
                i18n.get("services.adhesiones.edit.reference.success.message"),
                "success",
                ["adhesiones"],
            ),
        );
        yield put(servicePaymentsActions.listAdhesiones());
    }
}

function* getAgendaPagos() {
    const response = yield call(servicePaymentsMiddleware.listAgendaPagos);
    const { type } = response;
    const { message, agendaPagos, transactions } = response.data.data;

    if (type === "W") {
        yield put(notificationActions.showNotification(message, "error", ["servicePayments"]));
        yield put({ type: types.LIST_AGENDA_PAGOS_FAILURE });
    } else {
        const flatPayments = flatScheduledPayments(agendaPagos, transactions);
        yield put({
            type: types.LIST_AGENDA_PAGOS_SUCCESS,
            agendaPagos,
            flatPayments,
        });
    }
}

function* preparePayment({ paymentInfo, formikBag }) {
    try {
        const response = yield call(servicePaymentsMiddleware.preparePayment, paymentInfo);

        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            if (formikBag.props.match.path === "/agenda/payment") {
                yield put(routerActions.push("/agendaServicePaymentsConfirmationTicket"));
            } else {
                yield put(routerActions.push("/servicePaymentsConfirmationTicket"));
            }

            yield put({
                type: types.PREPARE_PAYMENT_SUCCESS,
                summaryInfo: paymentInfo,
            });
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* prepareMultiplePayments({ preparePayments, account, formikBag }) {
    try {
        const { type, ...response } = yield call(
            servicePaymentsMiddleware.prepareMultiplePayments,
            preparePayments,
            account,
        );
        if (type === "W") {
            const errors = response.data.data;
            const { message, ...restErrors } = errors;
            if (message) {
                yield put(notificationActions.showNotification(message, "error", ["servicePayments"]));
            }
            formikBag.setTouched(Object.keys(restErrors).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
            formikBag.setErrors(adjustIdFieldErrors(restErrors));
        } else {
            yield put(routerActions.push("/multiplePaymentsConfirmation"));
            yield put({
                type: types.SET_MULTIPLE_PAYMENTS_ACCOUNT,
                multiplePaymentsAccount: account,
            });
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* submitMultiplePayments({ submitPayments, formikBag }) {
    const { checkedPayments } = submitPayments;
    const { setSubmitting } = formikBag;

    try {
        const {
            type,
            data: { code, data, message },
        } = yield call(servicePaymentsMiddleware.submitMultiplePayments, submitPayments);

        yield put({
            type: types.SUBMIT_MULTIPLE_PAYMENTS_SUCCESS,
        });

        if (type === "W" && code !== "API516W") {
            let errorMessage = data.NO_FIELD || i18n.get("global.unexpectedError");

            if (code === "API524W") {
                errorMessage = message;
                yield put(notificationActions.showNotification(errorMessage, "error", ["servicePayments"]));
            }
            yield put({ type: types.SUBMIT_PAYMENT_FAILURE });
        }

        if (code === "COR000I") {
            yield put(
                notificationActions.showNotification(
                    i18n.get("services.multiplePayment.success.final.message", null, {
                        PAYMENTS_QUANTITY: checkedPayments.length,
                    }),
                    "warning",
                    ["payments"],
                ),
            );
            yield put(routerActions.push("/multiplePaymentsSuccess"));
        }
    } finally {
        yield put({
            type: types.HANDLE_BACK_TICKET,
        });
        setSubmitting(false);
    }
}

import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { bool, string, func } from "prop-types";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class RowButtons extends Component {
    static propTypes = {
        isButton1Selected: bool,
        isButton2Selected: bool,
        FORM_ID: string.isRequired,
        button1Id: string.isRequired,
        button2Id: string.isRequired,
        handleChangeButton: func.isRequired,
    };

    static defaultProps = {
        isButton1Selected: true,
        isButton2Selected: false,
    };

    render() {
        const { isButton1Selected, isButton2Selected, FORM_ID, button1Id, button2Id, handleChangeButton } = this.props;
        return (
            <Container className="flex-grow align-items-center container-white py-3">
                <Col xs={6} className="d-flex justify-content-end align-item-center pr-1">
                    <Button
                        className="btn-pills w-100 max-width-btn"
                        block={false}
                        image={isButton1Selected ? "images/check.svg" : ""}
                        style={{
                            backgroundColor: isButton1Selected ? "#f2f8fd" : "",
                            borderColor: isButton1Selected ? "#0071ce" : "#ccc",
                            color: isButton1Selected ? "#0071ce" : "#666",
                        }}
                        onClick={() => handleChangeButton(button1Id)}
                        label={`${FORM_ID}.${button1Id}`}
                    />
                </Col>
                <Col xs={6} className="d-flex justify-content-start align-item-center pl-1">
                    <Button
                        className="btn-pills w-100 max-width-btn"
                        block={false}
                        image={isButton2Selected ? "images/check.svg" : ""}
                        style={{
                            backgroundColor: isButton2Selected ? "#f2f8fd" : "",
                            borderColor: isButton2Selected ? "#0071ce" : "#ccc",
                            color: isButton2Selected ? "#0071ce" : "#666",
                        }}
                        onClick={() => handleChangeButton(button2Id)}
                        label={`${FORM_ID}.${button2Id}`}
                    />
                </Col>
            </Container>
        );
    }
}

export default RowButtons;

import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { shape, bool, func, number } from "prop-types";
import { Field, Form, withFormik } from "formik";

import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Notification from "pages/_components/Notification";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import CreateCredinSellerSummary from "pages/transfer/_components/CreateCredinSellerSummary";
import CreateCredinRecipientSummary from "pages/transfer/_components/CreateCredinRecipientSummary";
import Yup from "yup";
import isTokenActive from "util/token";
import * as i18nUtils from "util/i18n";

const FORM_ID = "requestCredin.summary";

class GenerateCredin extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        seller: shape({}).isRequired,
        recipients: shape({}).isRequired,
        dispatch: func.isRequired,
        id: number.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(debinActions.setIsBackFromSummary("/requestCredin"));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "0.2rem" }}>{i18nUtils.get("requestCredin.title")}</h1>
        </div>
    );

    render() {
        const { isDesktop, fetching, seller, recipients, id } = this.props;
        const continueButton = (
            <Col className="col-12 confirmation__content-button" lg={12} md={12} sm={12}>
                <div className="admin-content-center">
                    <Button
                        loading={fetching}
                        type="submit"
                        bsStyle="primary"
                        label="global.confirm"
                        image="images/arrowRight.svg"
                        imageStyle={isDesktop ? "mr-2" : "mr-1"}
                    />
                </div>
            </Col>
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center">
                        <I18n id="transfer.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}

                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        const containerClassName = `flex-grow align-items-center container-white my-2 py-3 px-md-3 ${
            !isDesktop ? "mb-0" : ""
        }`;

        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop ? (
                        <>
                            <div className="d-flex">
                                <div className="view-title">
                                    <h1 className="mb-0">{i18nUtils.get("requestCredin.title")}</h1>
                                    <div className="row">
                                        <div className="px-0 col-md-8 d-flex align-items-end">
                                            <I18n
                                                id="requestCredin.summary.label"
                                                componentProps={{ style: { fontFamily: "Graphik-Light" } }}
                                            />
                                        </div>
                                        <div className="col-md-4 debin__totals justify-content-end d-flex mt-md-0">
                                            <SelectedAccountsAmount
                                                list={recipients}
                                                currency={recipients && recipients[0].amount.currency}
                                                summaryView
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="admin-content-center d-flex justify-content-center">
                            <I18n
                                id="requestCredin.summary.label"
                                component="h3"
                                componentProps={{ style: { width: "80%" } }}
                            />
                        </div>
                    )}
                </div>
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container className={containerClassName} gridClassName="form-content">
                            <CreateCredinSellerSummary isDesktop={isDesktop} seller={seller} />
                        </Container>
                        {recipients &&
                            recipients.map((recipient) => (
                                <Container className={containerClassName} gridClassName="form-content">
                                    <CreateCredinRecipientSummary isDesktop={isDesktop} recipient={recipient} id={id} />
                                </Container>
                            ))}
                        <Container
                            className={`confirmation__container ${containerClassName}`}
                            gridClassName="form-content">
                            <Col className="confirmation__form" lg={5} md={7} sm={12}>
                                {credentials}
                                <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                    {continueButton}
                                </Container>
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    recipients: debinSelectors.getRecipients(state),
    id: debinSelectors.getId(state),
    seller: debinSelectors.getDebinSeller(state),
    fetching: debinSelectors.getFetching(state),
    summary: debinSelectors.getSummary(state),
    cbuList: debinSelectors.getListCbu(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, seller, recipients, id, cbuList } = formikBag.props;

            const amount = cbuList.reduce(
                (newElem, elem) => ({
                    currency: elem.amount.currency,
                    quantity: newElem.quantity + elem.amount.quantity,
                }),
                {
                    currency: null,
                    quantity: 0,
                },
            );
            dispatch(debinActions.generateCredin(otp, { ...seller, amount }, recipients, id, formikBag));
        },
    }),
)(GenerateCredin);

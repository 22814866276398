import { takeLatest, takeEvery, call, put, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import { selectors as assistantSelectors, actions as assistantActions } from "reducers/assistant";
import { types as tokenTypes, actions as tokenActions } from "reducers/token";
import { actions as i18nActions } from "reducers/i18n";
import { actions as statusActions } from "reducers/status";
import { globalTypes } from "reducers/types/global";
import formTypes from "reducers/types/form";
import { types as enrollmentTypes } from "reducers/enrollment";
import { types, selectors, actions as loginActions } from "reducers/login";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import * as session from "middleware/session";
import * as i18n from "util/i18n";
import * as fingerprintUtils from "util/fingerprint";
import * as secureStorageUtils from "util/secureStorage";
import * as configUtils from "util/config";
import { adjustIdFieldErrors } from "util/form";
import currentLocation from "util/geolocation";
import { AVAILABLE_REGIONS, REGION_REST_OF_LATAM, REGION_USA } from "constants.js";
import * as utilDownload from "util/download";
import { types as fingerprintTypes } from "reducers/fingerprint";
import * as deviceUtils from "util/device";
import { parseInt } from "lodash";

const NOTOKENLOGIN = "notokenlogin";
const sagas = [
    takeEvery(types.RESET, reset),

    takeLatest(types.LOGIN_STEP_1_REQUEST, handleLoginStep1Request),
    takeLatest(types.LOGIN_STEP_2_REQUEST, handleLoginStep2Request),
    takeLatest(types.LOGIN_OAUTH_REQUEST, handleOauthRequest),
    takeLatest(types.LOGIN_STEP_3_REQUEST, handleLoginStep3Request),
    takeLatest(types.LOGIN_STEP_4_REQUEST, handleLoginStep4Request),
    takeLatest(types.FINALIZE_LOGIN, handleFinalizeLogin),

    takeLatest(types.FINGERPRINT_LOGIN_PRE, handleFingerprintLoginPre),
    takeLatest(types.GO_BACK_LOGIN_SHOW_MESSAGE, goBackToLoginAndShowMessage),
    takeLatest(types.GET_CLIENT_COUNTRY_REQ, getClientCountry),
    takeLatest(types.GET_RANDOM_SECURITY_SEAL, getRandomSecuritySeal),
    takeLatest(types.DOWNLOAD_TERMS_REQUEST, handleDownloadTerms),
];

export default sagas;

function* getRandomSecuritySeal() {
    const { type, data } = yield call(session.getRandomSecuritySeal);
    if (type !== "W") {
        const { _securitySeal, _securitySealAlt } = data.data;
        yield put({
            type: types.SET_RANDOM_SECURITY_SEAL,
            securitySeal: _securitySeal,
            securitySealAlt: _securitySealAlt,
        });
    }
}

function* getClientCountry() {
    const activeRegion = yield select(selectors.getRegion);
    if (!activeRegion) {
        try {
            const { type, data } = yield call(session.getClientCountry);
            if (type !== "W") {
                const { country } = data.data;
                if (country) {
                    yield put({ type: types.GET_CLIENT_COUNTRY_RES, clientCountry: country.iso_code });

                    // Verify if the client country is in our supported countries
                    if (AVAILABLE_REGIONS.indexOf(country.iso_code) !== -1) {
                        yield put({ type: types.SET_REGION, region: country.iso_code });
                    } else {
                        yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
                    }
                }
            } else {
                yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
            }
        } catch (e) {
            yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
        }
    }
}

function* goBackToLoginAndShowMessage({ message }) {
    yield put(notificationActions.showNotification(message, "error", ["login"]));
    yield put({ type: globalTypes.BACK_TO_STEP_0 });
    yield put(replace("/"));
}

function* reset() {
    yield put(sessionActions.logout());
    yield put(replace("/"));
}

function* handleFinalizeLogin({ response }) {
    const activeRegion = yield select(selectors.getRegion);
    const { isAdministrator, generalConditions, userId } = response.data.data;
    let { _accessToken: accessToken } = response.data.data;
    const isAssistantLogin = yield select(assistantSelectors.isAssistantLogin);

    if (
        configUtils.get("core.sessionHandler.componentFQN") !==
        "com.technisys.omnichannel.core.session.DbSessionHandler"
    ) {
        accessToken = yield select(sessionSelectors.getAccessToken);
    }

    if (generalConditions) {
        const {
            generalConditionsShowExpiration,
            generalConditionsText,
            generalConditionsExpirationDate,
        } = response.data.data;
        const termsAndConditions = {
            // just joining the long named variables into a single object
            showExpiration: generalConditionsShowExpiration,
            generalConditions,
            body: generalConditionsText,
            expirationDate: generalConditionsExpirationDate,
        };

        yield put({ type: types.LOGIN_STEP_3_SUCCESS, termsAndConditions });
        if (activeRegion === REGION_USA) {
            const firstName = yield select(selectors.getUserFirstName);
            const email = yield select(selectors.getUsername);
            yield put({
                type: enrollmentTypes.SET_INVITATION,
                invitation: {
                    email,
                    firstName,
                    lastName: "",
                },
            });
            yield put(replace("/enrollment/Step3Part4"));
        } else {
            yield put(replace("/loginStep4"));
        }
    } else if (isAssistantLogin) {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            if (accessToken.includes("|")) {
                const [splittedAccessToken] = accessToken.split("|");
                accessToken = splittedAccessToken;

                yield put(assistantActions.setMessengerPSID(splittedAccessToken[1]));
            }
        }
        yield put(assistantActions.setAccessToken(accessToken));
        yield put(replace("/loginStep5"));
    } else {
        const loginData = yield call(processLoginSuccess, response);
        const { environments, lang } = response.data.data;

        configUtils.setRecaptchaLang(lang);
        yield put(i18nActions.setLang(lang));
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            yield call(session.setAuthToken, accessToken);
        }
        yield put({
            type: types.LOGIN_SUCCESS,
            environment: loginData.environment,
            user: loginData.user,
            environments,
            isAdministrator,
        });
        if (window.isMobileApp()) {
            yield put({
                type: tokenTypes.TOKEN_STATUS_PASSWORD_REQUEST,
                userId,
            });
        }
        yield put(replace("/desktop"));
        yield put(statusActions.resetComeFromLogin());
    }
}

function* handleLoginStep1Request({
    username,
    rememberDocument,
    document,
    documentType,
    recaptchaResponse,
    formikBag,
}) {
    const response = yield call(session.loginStep1, username, document, documentType, recaptchaResponse);
    const userpassNotifyDays = configUtils.getInteger("login.userpass.validity.notify.days", 10);
    const passwordNotifyDays = configUtils.getInteger("login.password.validity.notify.days", 10);
    const userpassNotificationShowed = yield select(selectors.getUserpassNotificationShowed);

    if (response.data.data.error) {
        yield put({ type: types.LOGIN_RESET_CAPTCHA, resetCaptcha: true });
        if (response.data.data.error === "login.step1.pin.expired") {
            formikBag.setValues({
                documentType: "DNI",
                document: formikBag.props.document ? formikBag.props.document : "",
                username: "",
            });
        }
        formikBag.setSubmitting(false);
        if (response.data.data.error === "login.step1.deleted.user") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(response.data.data.error),
                    "error",
                    ["login"],
                    i18n.get(`${response.data.data.error}.title`),
                ),
            );
        } else if (response.data.data.error === "login.step1.pin.expired") {
            yield put(
                notificationActions.showNotification(
                    configUtils.get("login.Username.vencido.snackbar"),
                    "error",
                    ["login"],
                    i18n.get(`${response.data.data.error}.title`),
                    false,
                ),
            );
        } else if (
            response.data.data.error === "login.step1.blocked.disable.document" ||
            response.data.data.error === "login.step1.blocked.document"
        ) {
            yield put(
                notificationActions.showNotification(
                    response.data.data.message,
                    "error",
                    ["login"],
                    i18n.get(`${response.data.data.error}.title`),
                    false,
                ),
            );
        } else if (response.data.data.error === "login.ambientebloqueado.snackbar") {
            yield put(
                notificationActions.showNotification(response.data.data.message, "error", ["login"], null, false),
            );
            formikBag.setValues({
                documentType: "DNI",
                document: "",
                username: "",
            });
        } else if (response.data.data.error === "login.step1.wrong.document") {
            formikBag.setErrors({ document: i18n.get("login.step1.wrong.format") });
        } else if (response.data.data.error === "login.step1.wrong.user") {
            formikBag.setErrors({ username: i18n.get("login.step1.wrong.user") });
            if (response.data.data.showCaptcha) {
                yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
                formikBag.setValues({
                    documentType,
                    document,
                    username,
                    captcha: "",
                });
            }
        } else if (response.data.data.error === "login.step1.captcha.required") {
            yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
            formikBag.setErrors({ captcha: i18n.get("login.step1.captcha.required") });
            formikBag.setValues({
                documentType,
                document,
                username,
                captcha: "",
            });
        } else {
            yield put(notificationActions.showNotification(i18n.get(response.data.data.error), "error", ["login"]));
        }
        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
    } else {
        const {
            _exchangeToken,
            _securitySeal,
            _securitySealAlt,
            _userFirstName,
            _userFullName,
            lang,
            lastLogin,
            _userMaskedEmail,
            _userMaskedPhoneNumber,
            daysToExpireCredential,
        } = response.data.data;

        if (
            parseInt(daysToExpireCredential.passwordDaysToExpire) <= passwordNotifyDays &&
            parseInt(daysToExpireCredential.passwordDaysToExpire) > 0
        ) {
            yield put(
                notificationActions.showNotification(
                    daysToExpireCredential.passwordMessage,
                    "warning",
                    ["loginStep2"],
                    i18n.get("enrollment.stepIRS.warning.title"),
                ),
            );
        }
        if (
            parseInt(daysToExpireCredential.userpassDaysToExpire) <= userpassNotifyDays &&
            !userpassNotificationShowed
        ) {
            formikBag.setSubmitting(false);

            yield put(
                notificationActions.showNotification(
                    daysToExpireCredential.userpassMessage,
                    "warning",
                    ["login"],
                    i18n.get("enrollment.stepIRS.warning.title"),
                ),
            );
            yield put({
                type: types.USERPASS_NOTIFICATION_SHOWED,
            });
        } else {
            yield put({
                type: types.LOGIN_STEP_1_SUCCESS,
                exchangeToken: _exchangeToken,
                securitySeal: _securitySeal,
                securitySealAlt: _securitySealAlt,
                userFirstName: _userFirstName,
                userFullName: _userFullName,
                username: documentType + document,
                lastLogin,
                lang,
                userMaskedEmail: _userMaskedEmail,
                userMaskedPhone: _userMaskedPhoneNumber,
                daysToExpireCredential,
            });
            yield put(tokenActions.setFirstName(_userFirstName));
            if (rememberDocument) {
                yield put(
                    loginActions.setRememberedUser({
                        document,
                        documentType,
                        userFullName: _userFullName,
                        userFirstName: _userFirstName,
                    }),
                );
            }
            yield put(replace("/loginStep2"));
        }
    }
}

function* handleLoginStep2Request({ password, recaptchaResponse, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);

    if (exchangeToken === NOTOKENLOGIN) {
        formikBag.setSubmitting(false);
        yield put(
            notificationActions.showNotification(i18n.get("login.dataEntered.notValid"), "error", ["loginStep2"]),
        );
        yield put({
            type: formTypes.SEND_FORM_DATA_FAILURE,
            idForm: "loginStep2",
            code: "COR020W",
            errors: null,
        });
        return;
    }
    const response = yield call(session.loginStep2, exchangeToken, password, recaptchaResponse);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);

        if (
            response.data.code === "COR020W" ||
            response.data.code === "API019W" ||
            response.data.code === "API020W" ||
            response.data.code === "COR050W"
        ) {
            // Wrong credentials || capcha required || capcha invalid
            yield put(
                notificationActions.showNotification(i18n.get("login.step2.wrong.password"), "error", ["loginStep2"]),
            );

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                idForm: "loginStep2",
                code: response.data.code,
                errors: response.data.data,
            });
        } else {
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    } else {
        const { environments, selectEnvironment } = response.data.data;

        yield put({ type: types.LOGIN_STEP_2_SUCCESS, environments });
        if (selectEnvironment && Object.keys(environments).length > 1) {
            yield put(replace("/loginStep3"));
        } else {
            yield put({
                type: types.LOGIN_STEP_3_REQUEST,
                formikBag,
            });
        }
    }
}

function* handleOauthRequest({ password, recaptchaResponse, formikBag, showCaptcha }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    // eslint-disable-next-line camelcase
    const { redirect_uri } = yield select(assistantSelectors.getParams);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);

    let response;
    if (isFromMessenger || isFromWhatsapp) {
        const thirdPartyToken = yield select(assistantSelectors.getThirdPartyToken);
        response = yield call(
            session.thirdPartyOauth,
            username,
            password,
            thirdPartyToken,
            redirect_uri,
            exchangeToken,
            recaptchaResponse,
        );
        if (response.data) {
            response.data.access_token = thirdPartyToken;
        } else {
            response.data = {
                access_token: thirdPartyToken,
            };
        }
    } else {
        if (showCaptcha) {
            response = yield call(session.validateCaptcha, recaptchaResponse);
            if (response.type === "W") {
                yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
                yield put({ type: types.LOGIN_RESET_CAPTCHA, resetCaptcha: true });
                formikBag.setErrors({ captcha: i18n.get("login.step1.captcha.required") });
                formikBag.setValues({
                    captcha: "",
                });
                yield put({
                    type: formTypes.SEND_FORM_DATA_FAILURE,
                    code: response.data.error_description,
                });
                return;
            }
        }
        response = yield call(session.oauth, username, password, exchangeToken);
    }

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors({}));
        formikBag.setSubmitting(false);
        formikBag.setValues({
            password,
            captcha: "",
        });
        yield put({ type: types.LOGIN_RESET_CAPTCHA, resetCaptcha: true });
        const errorMsg = i18n.get(`returnCode.${response.data.error_description}`, response.data.error_description);
        if (
            response.data.error_description === "COR020W" ||
            response.data.error_description === "API019W" ||
            response.data.error_description === "API020W" ||
            response.data.error_description === "COR050W" ||
            response.data.error_description === "API022E" ||
            response.data.error_description === "API517W"
        ) {
            if (
                response.data.error_description === "API020W" ||
                response.data.error_description === "COR050W" ||
                response.data.error_description === "API022E" ||
                response.data.error_description === "API517W"
            ) {
                if (response.data.error_description === "API020W") {
                    // Captcha required || captcha invalid
                    yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
                    yield put({ type: types.LOGIN_RESET_CAPTCHA, resetCaptcha: true });
                    formikBag.setErrors({ password: i18n.get("login.step2.wrong.password") });
                }
                // Se bloqueo o expiro la password
                else if (
                    response.data.error_description === "API022E" ||
                    response.data.error_description === "COR050W" ||
                    response.data.error_description === "API517W"
                ) {
                    // se bloqueo la password por cantidad de intentos
                    if (
                        response.data.error_description === "API022E" ||
                        response.data.error_description === "COR050W"
                    ) {
                        yield put(
                            notificationActions.showNotification(
                                configUtils.get("login.credencialesbloqueadas.snackbar"),
                                "error",
                                ["loginStep2"],
                                i18n.get("login.step2.password.blocked.tittle"),
                                false,
                            ),
                        );
                    } else {
                        // Expiro la password
                        yield put(
                            notificationActions.showNotification(
                                configUtils.get("login.Password.vencida.snackbar"),
                                "error",
                                ["loginStep2"],
                                i18n.get("login.step2.password.expired.title"),
                                false,
                            ),
                        );
                    }
                } else {
                    yield put(notificationActions.showNotification(errorMsg, "error", ["loginStep2"]));
                }
            } else {
                // Wrong credentials || captcha required || captcha invalid
                formikBag.setErrors({ password: i18n.get("login.step2.wrong.password") });
            }

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                code: response.data.error_description,
            });
        } else {
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    } else {
        // Ignored because it's the OAUTH standard
        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = response.data;

        if (!isFromMessenger && !isFromWhatsapp) {
            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));
        } else {
            yield put(assistantActions.setAccessToken(access_token));
            yield put(replace("/loginStep5"));
            return;
        }
        const environmentsResponse = yield call(session.listEnvironments);

        const { environments, selectEnvironment } = environmentsResponse.data.data;
        yield put({ type: types.LOGIN_OAUTH_SUCCESS, environments });
        if (selectEnvironment && Object.keys(environments).length > 1) {
            yield put(replace("/loginStep3"));
        } else if (!environments && !selectEnvironment) {
            yield put({ type: globalTypes.BACK_TO_STEP_0 }); // por lo que borro lo que tengo de sesion y voy al step0
            yield put(
                notificationActions.showNotification(i18n.get("login.step2.environments.unavailable"), "error", [
                    "login",
                ]),
            );
            yield put(replace("/"));
        } else {
            yield put({
                type: types.LOGIN_STEP_3_REQUEST,
                formikBag,
            });
        }
    }
}

function* handleLoginStep3Request({ idEnvironment, rememberEnvironment, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    const isFromGoogle = yield select(assistantSelectors.isFromGoogle);
    const isFromAmazon = yield select(assistantSelectors.isFromAmazon);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);
    const clientNumber = yield select(assistantSelectors.getClientNumber);
    const accountLinkingToken = yield select(assistantSelectors.getAccountLinkingToken);

    const position = yield call(currentLocation, { lat: 0.0, lng: 0.0 });
    const location = {
        latitude: position.lat,
        longitude: position.lng,
    };

    let extraInfo = {};
    let idDevice = null;
    const registrationId = null;

    if (window.app) {
        extraInfo = window.device;
        extraInfo.isTablet = false;
        extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
        extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
        extraInfo.uuid = window.app.getDeviceUUID();
        idDevice = window.app.getDeviceUUID();
    } else {
        idDevice = yield deviceUtils.getDeviceFingerprint();
        extraInfo = yield deviceUtils.getExtraInfo();
    }
    yield call(session.registerUserDevice, exchangeToken, idDevice, registrationId, JSON.stringify(extraInfo));

    const response = yield call(
        session.loginStep3,
        exchangeToken,
        idEnvironment,
        rememberEnvironment,
        location,
        idDevice,
        {
            isFromMessenger,
            isFromGoogle,
            isFromAmazon,
            isFromWhatsapp,
            accountLinkingToken,
            clientNumber,
        },
    );

    if (response.type === "W") {
        const { code, data } = response.data;
        if (code === "COR020W" && data.environment) {
            // mark the environment as disabled and redirecting the user to the step 3 again
            const environments = yield select(selectors.getEnvironments);
            if (environments[idEnvironment]) {
                environments[idEnvironment].allowedToAccess = false;
                yield put({ type: types.MARK_ENVIRONMENTS_DISABLED, environments });
            }
            yield put(replace("/loginStep3"));
        } else {
            // en este paso lo unico que podria suceder es que se venciera el exchange token
            if (formikBag) {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                formikBag.setSubmitting(false);
            }
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 }); // por lo que borro lo que tengo de sesion y voy al step0
            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
            yield put(replace("/"));
        }
    } else {
        yield put({ type: types.FINALIZE_LOGIN, response });
    }
}

function* handleLoginStep4Request({ acceptConditions, formikBag }) {
    // const activeRegion = yield select(selectors.getRegion);
    const exchangeToken = yield select(selectors.getExchangeToken);
    const idEnvironmentToAcceptConditions = yield select(selectors.getIdEnvironmentToAcceptConditions);
    const response = yield call(session.loginStep4, exchangeToken, acceptConditions, idEnvironmentToAcceptConditions);

    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep4"]));
        yield put({ type: types.LOGIN_FAILURE, errors: response.data.data });
    } else {
        const loginData = yield call(processLoginSuccess, response);
        const { data } = response.data;

        configUtils.setRecaptchaLang(data.lang);
        yield put(i18nActions.setLang(data.lang));

        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            // eslint-disable-next-line no-underscore-dangle
            yield call(session.setAuthToken, data._accessToken);
        }
        yield put({
            type: types.LOGIN_SUCCESS,
            environment: loginData.environment,
            user: loginData.user,
            environments: data.environments,
            isAdministrator: data.isAdministrator,
        });

        // const { pepCompleted, irsCompleted } = loginData.user;

        yield put(statusActions.resetComeFromLogin());
        // si no, voy a desktop
        yield put(replace("/desktop"));
    }
}

function processLoginSuccess(response) {
    const {
        _accessToken,
        _securitySeal,
        username,
        userFullName,
        userId,
        previousLoginInfo,
        defaultAvatarId,
        email,
        clients,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        liberator,
        signatureLevel,
        ...data
    } = response.data.data;
    const user = {
        defaultAvatarId,
        previousLoginInfo,
        userFullName,
        userId,
        email,
        accessToken: _accessToken,
        securitySeal: _securitySeal,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        liberator,
        signatureLevel,
        idDefaultEnvironment:
            data.idDefaultEnvironment && data.idDefaultEnvironment > 0 ? data.idDefaultEnvironment : null,
    };

    let forms = null;
    if (data.forms) {
        forms = {};

        for (let i = 0; i < data.forms.length; i++) {
            let category = forms[data.forms[i].category];
            if (!category) {
                category = [];
                forms[data.forms[i].category] = category;
            }
            category.push(data.forms[i]);
        }
    }

    const environment = {
        permissions: data.permissions,
        forms,
        name: data.activeEnvironmentName,
        type: data.activeEnvironmentType,
        id: data.activeIdEnvironment,
        administrationScheme: data.administrationScheme,
        clients,
    };

    return { user, environment };
}

function* handleFingerprintLoginPre() {
    const availability = yield call(fingerprintUtils.isAvailable);
    const hardwareAvailability = availability && availability.isAvailable && availability.isHardwareDetected;

    if (hardwareAvailability) {
        let fingerprintAuthToken = null;
        let fingerprintAuthTokenExists = true;

        try {
            fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
        } catch (error) {
            fingerprintAuthTokenExists = false;
        }

        if (fingerprintAuthTokenExists) {
            try {
                const result = yield call(fingerprintUtils.verifyAio);
                if (result && result.withFingerprint) {
                    yield put({ type: types.FINGERPRINT_SUBMIT });

                    const username = yield call(secureStorageUtils.get, "fingerprintUsername");
                    const idDevice = yield call(secureStorageUtils.get, "fingerprintIdDevice");

                    const responseFingerprintLogin = yield call(
                        session.fingerprintOauth,
                        username,
                        fingerprintAuthToken,
                        idDevice,
                    );

                    if (responseFingerprintLogin.type === "W") {
                        if (responseFingerprintLogin.data.error_description === "API045W") {
                            yield put(loginActions.removeRememberedUser());
                            yield put(loginActions.removeBiometricLoginShow());
                            yield put({ type: fingerprintTypes.CLEAN_UP });

                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("biometrics.error.invalid.config"),
                                    "error",
                                    ["login"],
                                ),
                            );
                            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                        } else {
                            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                            yield put(
                                push({
                                    pathname: "/serverError",
                                    isBiometricLogin: true,
                                }),
                            );
                        }
                    } else {
                        // Ignored because it's the OAUTH standard
                        // eslint-disable-next-line camelcase
                        const { access_token, refresh_token } = responseFingerprintLogin.data;

                        session.setAuthToken(access_token);
                        yield put(sessionActions.setTokens(access_token, refresh_token));

                        const environmentsResponse = yield call(session.listEnvironments);

                        const { environments, selectEnvironment } = environmentsResponse.data.data;

                        yield put({ type: types.LOGIN_OAUTH_SUCCESS, environments });

                        if (selectEnvironment && Object.keys(environments).length > 1) {
                            yield put(replace("/loginStep3"));
                        } else if (!environments && !selectEnvironment) {
                            // por lo que borro lo que tengo de sesion y voy al step0
                            yield put({ type: globalTypes.BACK_TO_STEP_0 });
                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("login.step2.environments.unavailable"),
                                    "error",
                                    ["login"],
                                ),
                            );
                            yield put(replace("/"));
                            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                        } else {
                            yield put({
                                type: types.LOGIN_STEP_3_REQUEST,
                            });
                        }
                    }
                } else {
                    yield put(
                        notificationActions.showNotification(i18n.get("biometrics.error.login.general"), "error", [
                            "login",
                        ]),
                    );
                }
            } catch (error) {
                const errorCode = error.code;
                if (errorCode === fingerprintUtils.fingerprintErrors.BIOMETRIC_DISMISSED) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                } else if (errorCode === fingerprintUtils.fingerprintErrors.BIOMETRIC_LOCKED_OUT) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                    const message = configUtils.get("biometric.NumberAttemptsPassed.Snackbar");
                    yield put(notificationActions.showNotification(message, "error", ["login"]));
                } else if (errorCode === fingerprintUtils.fingerprintErrors.BIOMETRIC_NOT_ENROLLED) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                    const message = configUtils.get("biometric.NumberAttemptsPassed.Snackbar");
                    yield put(notificationActions.showNotification(message, "error", ["login"]));
                } else {
                    // eslint-disable-next-line no-console
                    console.log("Fingerprint - The following error has ocurred: ", error);
                    if (error.status && error.status === 200) {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                        yield put(
                            push({
                                pathname: "/error",
                                code: error.data.code,
                                message: error.data.message,
                            }),
                        );
                    } else if (error.response && error.response.status && error.response.status === 401) {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("login.fingerprint.session.expired"),
                                "error",
                                ["login"],
                            ),
                        );
                        yield put({ type: fingerprintTypes.CLEAN_UP });
                        yield call(secureStorageUtils.remove, "fingerprintAuthToken");
                        yield put({ type: types.BACK_TO_STEP_0 });
                        yield put(replace("/"));
                    } else if (deviceUtils.getDevicePlatform() === "iOS" && error.code === -25293) {
                        yield put(loginActions.removeRememberedUser());
                        yield put(loginActions.removeBiometricLoginShow());

                        yield put({ type: fingerprintTypes.CLEAN_UP });

                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });

                        yield put(
                            notificationActions.showNotification(i18n.get("biometrics.error.invalid.config"), "error", [
                                "login",
                            ]),
                        );
                    } else if (error.response && error.response.status && error.response.status === 500) {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                        yield put(
                            push({
                                pathname: "/error",
                                code: error.response.status,
                                message: error.statusText,
                            }),
                        );
                    } else if (error.code !== -128) {
                        yield put(
                            notificationActions.showNotification(
                                deviceUtils.getDevicePlatform() === "iOS" ? error.localizedDescription : error,
                                "error",
                                ["login"],
                            ),
                        );
                    }
                }
            }
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("biometrics.error.invalid.token"), "error", ["login"]),
            );
        }
    } else {
        yield put(loginActions.removeRememberedUser());
        yield put(loginActions.removeBiometricLoginShow());

        yield put({ type: fingerprintTypes.CLEAN_UP });

        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });

        const message = configUtils.get("biometric.noAvailable.general.error");

        yield put(notificationActions.showNotification(message, "error", ["login"]));
    }
}

function* handleDownloadTerms({ format }) {
    const { type, data } = yield call(session.exportTermsAndConditions, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_TERMS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        const { content, fileName } = data.data;
        utilDownload.downloadPdf(fileName, content);
        yield put(notificationActions.showNotification(i18n.get("global.download.success"), "success", ["settings"]));
        yield put({ type: types.DOWNLOAD_TERMS_SUCCESS });
    }
}
